import Vue from 'vue'
import Vuex from 'vuex'

import notifications from './notification'
import magazineAward from './magazineAward'
import auth from '../domains/auth/auth'
import home from '../domains/home/home'
import creation from '../domains/creations/creation'
import user from '../domains/user/user'
import orders from '../domains/orders/orders'
import checkout from '../domains/orders/checkout'
import documents from '../domains/document/documents'
import documentInvite from '../domains/document/documentInvite'
import orderCheckout from '../domains/order/orderCheckout'
import invitation from '../domains/invitation/invitation'
import system from '../domains/system/system'
import general from './general'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    notifications,
    magazineAward,
    auth,
    creation,
    user,
    orders,
    checkout,
    documents,
    documentInvite,
    orderCheckout,
    invitation,
    system,
    home,
  },
})
