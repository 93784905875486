<template>
  <a :href="'https://s3.eu-west-1.amazonaws.com/php.jilster.nl/' + app_status + '_magazinepages/' + magazineId + '/pdf_screen/' + magazinepageId + '.pdf'" target="_blank"><i v-bind:class="`icon-download-digital downloadDocument`"></i></a>
</template>

<script>

export default {
  props: ['magazineId', 'magazinepageId'],
  name: 'DownloadDocument',
  data() {
    return {
      app_status: process.env.VUE_APP_STATUS
    }
  }
}

</script>