export default {
    methods:{
        systemSettingsSetup(){
            const that = this;

            let settingsChannel = this.$pusher.subscribe('system');
            const settings = this.$store.getters['getSettings'];
            settingsChannel.bind('SystemSettingUpdated', function(data){
                const response = data.systemSetting;
                const index = settings.findIndex(item => item.name === response.name);
                settings.splice(index, 1);
                settings.push(response);
                that.$store.commit('SET_SETTINGS', settings);
            })
        }
    }
}
