// import router from '../../router'
// import { login, logout, register, resetPassword, updatePassword, getCurrentMaker } from './authEndpoints'
import i18n from '../../i18n'

const state = {
  templates: [],
  categories: [],
  showTemplateModal: false,
  showScratchModal: false,
  showScratchModalLinda: false,
  selectedTemplate: null,
}

const getters = {
  getFilteredTemplates: (state) => (category) => {
    const templates = JSON.parse(JSON.stringify(state.templates))

    return templates.filter((t) => {
      if (t.canvasVersion === 'linda') return false
      if (!category) return true
      return t.tags.some((c) => c.id === category)
    })
  },
}

const actions = {}

const mutations = {
  SET_SHOW_TEMPLATE_MODAL(state, payload) {
    state.showTemplateModal = payload
  },
  SET_SHOW_SCRATCH_MODAL(state, payload) {
    state.showScratchModal = payload
  },
  SET_SHOW_SCRATCH_MODAL_LINDA(state, payload) {
    state.showScratchModalLinda = payload
  },
  SET_SELECTED_TEMPLATE(state, payload) {
    state.selectedTemplate = payload
  },
  SET_TEMPLATES(state, payload) {
    const locale = i18n.locale

    state.templates = payload.filter((item) => {
      const meta = item.meta
      let itemLocale = null

      if (meta && meta.length) {
        itemLocale = meta.find((m) => m.key === 'language' && m.value === locale)
      }

      if (itemLocale) {
        return true
      }
    })

    const tags = []

    state.templates.forEach((item) => {
      item.tags.forEach((tag) => {
        if (tag.type !== 'specials' && !tags.find((t) => t.id === tag.id)) {
          tags.push(tag)
        }
      })
    })

    state.categories = tags
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
