<template>
  <div class="documentModal" v-on:click="closeModalOutsideClick($event)">
    <b-row id="content">
      <b-col style="height: 100%; min-width: 496px">
        <Documents />
      </b-col>
      <b-col
        class="bg-white"
        id="settings"
        style="margin-top: 0"
        v-bind:class="{ flex10: this.$store.getters['getShowInviteForm'] }"
      >
        <div id="closeWrapper">
          <i @click="closeModal()" class="icon-close close-icon" id="closeIcon"></i>
        </div>
        <DocumentOptions v-if="!this.$store.getters['getShowInviteForm']" />
        <DocumentInvite v-else />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Documents from '@/components/documentmodal/Documents'
import DocumentOptions from '@/components/documentmodal/DocumentOptions'
import DocumentInvite from '@/components/DocumentInvite'

export default {
  name: 'DocumentModal',
  components: { Documents, DocumentOptions, DocumentInvite },
  methods: {
    closeModal() {
      this.$store.dispatch('setShowModal', false)
      this.$store.dispatch('setAllowDocumentSelect', false)
      this.$store.dispatch('clearSelectedDocuments')
      this.$store.dispatch('clearUserView')
      this.$store.dispatch('deleteAllSelectedUsersForInvite')
      this.$store.dispatch('setDocumentsToDelete', [])
      this.$store.dispatch('removeNewPages')
      this.$store.dispatch('updateDocumentPositions')
    },
    closeModalOutsideClick(event) {
      if (event.target.className === 'documentModal') {
        this.closeModal()
      }
    },
  },
}
</script>
