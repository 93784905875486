const state = {
  settings: [],
  topbarActive: false,
  // videoPopupActive : JSON.parse(localStorage.getItem("videopopup")) === null ? true : JSON.parse(localStorage.getItem("videopopup"))
  videoPopupActive: false,
}

const getters = {
  getSettings: (state) => {
    return state.settings
  },
  getTopbarActive: (state) => {
    return state.topbarActive
  },
  getVideoPopupActive: (state) => {
    return state.videoPopupActive
  },
}

const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings
  },
  SET_TOPBARACTIVE(state, bool) {
    state.topbarActive = bool
  },
  SET_VIDEOPOPUP_ACTIVE(state, bool) {
    state.videoPopupActive = bool
  },
}

export default {
  state,
  getters,
  mutations,
}
