<template>
  <div
    class="rounded-xl transform relative"
    style="
      background-color: #ebf5fe;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
      padding: 40px 40px;
      width: 484px;
    "
  >
    <h3 class="text-wg-100 font-bold text-xl text-center" style="margin-bottom: 8px">
      {{ $t('add.title.to.your.template') }}
    </h3>
    <p class="text-wg-70 text-center text-sm" style="margin-bottom: 16px">{{ $t('add.subtitle.to.your.template') }}</p>
    <div class="btn-container">
      <Input
        id="input"
        v-model="title"
        name="title"
        type="text"
        :is-focused="isFocused"
        placeholder-text="For ex. Sophie’s birthday"
        autocomplete="off"
        :is-disabled="loading"
        :class="{ errorBorder: this.errorStatus, 'input-custom': title }"
        @on-press-enter="changeTitle"
        @after-focus="isFocused = false"
        @input="handleInput"
      />
      <button
        v-show="showCrossBtn"
        class="p-0 border-0 bg-transparent position-absolute btn-position"
        @click="
          title = ''
          isFocused = true
        "
      >
        <svg width="16" height="15" class="w-4" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.32812 8L14.4062 2.96094C14.8229 2.51823 14.8229 2.0625 14.4062 1.59375C13.9375 1.17708 13.4818 1.17708 13.0391 1.59375L8 6.67188L2.96094 1.59375C2.49219 1.17708 2.03646 1.17708 1.59375 1.59375C1.17708 2.0625 1.17708 2.51823 1.59375 2.96094L6.67188 8L1.59375 13.0391C1.17708 13.4818 1.17708 13.9375 1.59375 14.4062C1.82812 14.5885 2.0625 14.6797 2.29688 14.6797C2.50521 14.6797 2.72656 14.5885 2.96094 14.4062L8 9.32812L13.0391 14.4062C13.2734 14.5885 13.4948 14.6797 13.7031 14.6797C13.9375 14.6797 14.1719 14.5885 14.4062 14.4062C14.8229 13.9375 14.8229 13.4818 14.4062 13.0391L9.32812 8Z"
            fill="#111111"
          />
        </svg>
      </button>
    </div>

    <Error icon="warning" icon-color="#C23934" :error-message="$t('add_title')" v-if="errorStatus" />

    <div class="flex justify-end items-center">
      <Button
        @click.native="changeTitle"
        :button-text="$t('add.title.to.your.template.save.button')"
        button-type="primary"
        :disabled="loading"
        class="px-6"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input'
import Button from '@/components/Button'
import Error from '@/components/Error'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TitlePopup',
  components: { Button, Input, Error },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      errorStatus: false,
      showCrossBtn: false,
      isFocused: false,
    }
  },

  watch: {
    title(val) {
      if (val) {
        this.showCrossBtn = true
      } else {
        this.showCrossBtn = false
      }
    },
  },

  methods: {
    async changeTitle() {
      if (!this.title) {
        this.errorStatus = true
      } else {
        this.$emit('add-title', this.title)
        this.errorStatus = false
      }
    },

    handleInput() {
      this.errorStatus = false
      this.$emit('on-input')
    },
  },
  validations: {
    title: {
      required,
    },
  },

  mounted() {
    this.isFocused = true
  },
}
</script>

<style scoped>
.btn-position {
  right: 0.75rem;
  top: 35%;
  transform: translateY(-50%);

  margin: auto;
}

.btn-container {
  position: relative;
}

.input-custom >>> input {
  padding-right: 2.25rem;
  width: 100%;
}
</style>
