<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35Z"
      fill="#51CF66"
    />
    <path
      d="M30.3906 41.25L25.4688 36.4844C25.0521 36.0677 24.5312 35.8594 23.9062 35.8594C23.2812 35.8594 22.7604 36.0677 22.3438 36.4844C21.9271 36.901 21.7188 37.4219 21.7188 38.0469C21.7188 38.6198 21.9271 39.1146 22.3438 39.5312L28.9844 46.0156C29.4531 46.4844 30 46.7188 30.625 46.7188C31.3021 46.6667 31.849 46.3802 32.2656 45.8594L47.8906 28.5156C48.2552 28.0469 48.3854 27.526 48.2812 26.9531C48.2292 26.3281 47.9688 25.8333 47.5 25.4688C46.3542 24.5833 45.3125 24.7135 44.375 25.8594L30.3906 41.25Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Ok",
};
</script>
