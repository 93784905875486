<template>
  <b-container fluid id="maintenance">
    <div class="jilster_maintenace_mask-container">
      <div class="jilster_maintenace_mask"></div>
    </div>
    <h1>{{$t('maintenance_title')}}</h1>
    <p>{{$t('maintenance_description')}}</p>
    <Button @click.native="goToWebsite" buttonType="primary" :buttonText="$t('maintenance_button')"/>
  </b-container>
</template>

<script>
import Button from "../../components/Button";

export default {
  name: "Maintenance",
  components: {Button},
  methods:{
    goToWebsite(){
      window.open('https://jilster.com', '_blank');
    }
  }
}
</script>
