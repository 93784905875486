<template>
  <div id="download-order">
    <b-container class="shadow-container">
      <h1 class="merriweather mt-2 pb-3">Choose pages to download</h1>
    </b-container>
    <b-container>
      <b-row>
        <b-col
          v-for="page in this.$store.getters['getCreations']"
          :key="page.id"
          lg="3"
        >
          <a :href="page.creation_image" download class="page">
            <img :src="page.creation_image" alt="" />
            <p>Naam</p>
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Downloadorder",
  methods: {
    // setSelectedPage(page){
    //   // this.$root.$emit('bv::toggle::collapse','sidebar-right');
    //
    //   if(this.$store.getters['getSelectedPage'].map(a => a.pageNumber).includes(page.pageNumber)){
    //     this.$store.dispatch('removeSelectedPage', page);
    //   }else{
    //     this.$store.dispatch('setSelectedPage', page);
    //   }
    // },
    // selectedPageStyle(page){
    //   return this.$store.getters['getSelectedPage'].map(a => a.pageNumber).includes(page.pageNumber);
    // }
  },
};
</script>
