<template>
  <div>
    <h2>Address details</h2>
    <label for="name">Name</label>
    <Input @change.native="checkForm" id="name" v-model="name" />
    <label for="surname">Surname</label>
    <Input @change.native="checkForm" id="surname" v-model="surname" />

    <div class="billingAddress" v-if="addressChanged">
      <label>Billing address</label>
      <b-card
        v-bind:class="{
          selectNewBillingAddressStyle: selectNewBillingAddressStyle(address),
        }"
        v-for="address in this.$store.getters['getAllMyAddresses']"
        :key="address.id"
        @click="selectNewBillingAddress(address)"
      >
        <p>{{ address.address }}</p>
      </b-card>
    </div>

    <label>Address</label>
    <b-card class="address" v-b-toggle.collapse-1-inner>
      <p>Current address (Click to change)</p>
      <p>{{ address }}</p>
      <b-collapse id="collapse-1-inner" class="mt-2">
        <b-card
          v-for="address in this.$store.getters['getAllMyAddresses']"
          :key="address.id"
          @click="selectNewAddress(address)"
        >
          <p>{{ address.address }}</p>
        </b-card>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import Input from "@/components/Input";
export default {
  name: "CheckoutAddress",
  components: { Input },
  data() {
    return {
      name: "Joey",
      surname: "Deckers",
      address: "Rouwkooplaan 5, 2251 AP Voorschoten",
      addressChanged: false,
      changedBillingAddress: "",
    };
  },
  methods: {
    selectNewAddress(address) {
      this.address = address.address;
      this.addressChanged = true;
    },
    selectNewBillingAddress(address) {
      this.changedBillingAddress = address;
    },
    selectNewBillingAddressStyle(address) {
      return this.changedBillingAddress.id === address.id;
    },
    checkForm() {
      if (this.name !== "" && this.surname !== "" && this.address !== "") {
        this.$store.dispatch("stepComplete", true);
      } else {
        this.$store.dispatch("stepComplete", false);
      }
    },
  },
  created() {
    this.checkForm();
  },
};
</script>
