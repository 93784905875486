<template>
  <div class="jilster_select">
    <i v-if="icon" :style="{ color: iconColor }" v-bind:class="`icon-${icon}`" v-on:click="selectOption(null)"></i>
    <label v-if="labelText">{{ labelText }}</label>
    <div
      :class="{
        activeSelect: this.$store.getters['getShowSettingsDropDown'] && this.$store.getters['getDropDownId'] == id,
      }"
      class="customSelect"
      v-on:click="selectOption(null)"
    >
      {{ selectedOption.text }}
    </div>
    <div
      class="dropDownMenu shadow-lg"
      v-if="this.$store.getters['getShowSettingsDropDown'] && this.$store.getters['getDropDownId'] == id"
    >
      <div v-for="o in options" :key="o.text" class="customOption" @click="selectOption(o)">
        {{ o.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['labelText', 'options', 'icon', 'iconColor', 'id', 'initialValue'],
  name: 'Select',
  data() {
    return {
      selected: null,
      selectedOption: this.setInitialValue(),
    }
  },
  methods: {
    setInitialValue() {
      if (this.initialValue === undefined || this.initialValue === '') {
        return this.options[0]
      } else {
        const o = this.options.find((o) => o.value === this.initialValue)
        return o === null || o === undefined ? this.options[0] : o
      }
    },
    toggleDropDown() {
      this.$store.dispatch('setShowSettingsDropDown', !this.$store.getters['getShowSettingsDropDown'])
      this.$store.dispatch('setDropDownId', this.id)
    },
    closeDropDown() {
      this.$store.dispatch('setShowSettingsDropDown', false)
    },
    selectOption(o) {
      if (o === null) {
        this.toggleDropDown()
      } else {
        this.selectedOption = o
        this.$emit('valueChanged', [o.value, this.id])
        this.closeDropDown()
      }
    },
  },
}
</script>
