<template>
  <div class="rounded-2xl my-14 p-8" style="background-color: #ecf4ff">
    <h2 class="text-2xl font-bold text-center mb-6">{{ $t('homepage.templates.more.options') }}</h2>

    <div class="grid grid-cols-2 gap-x-8 mx-auto" style="max-width: 1168px">
      <div class="flex justify-end">
        <Card :data="optionOne" @on-click="$emit('on-click-start-creation')" />
      </div>
      <Card :data="optionTwo" @on-click="$emit('on-click-binding-options')" />
    </div>
  </div>
</template>

<script>
import Card from './Card.vue'
import cardImageOne from '@/assets/start_scratch.png'
import cardImageTwo from '@/assets/card.png'

export default {
  components: {
    Card,
  },

  data() {
    return {
      optionOne: {
        title: this.$t('start.scratch.card.title'),
        description: this.$t('start.scratch.card.description'),
        buttonText: this.$t('start.scratch.card.button'),
        image: cardImageOne,
      },
      optionTwo: {
        title: this.$t('binding.options.card.title'),
        description: this.$t('binding.options.card.description'),
        buttonText: this.$t('binding.options.card.button'),
        image: cardImageTwo,
      },
    }
  },
}
</script>
