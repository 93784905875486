import { render, staticRenderFns } from "./Orderdetail.vue?vue&type=template&id=2d06833a"
import script from "./Orderdetail.vue?vue&type=script&lang=js"
export * from "./Orderdetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports