<template>
  <div>
    <div
      class="hover-parent relative overflow-hidden rounded shadow-custom"
      :class="`${(selectedTemplate && selectedTemplate.id === template.id && 'custom-border') || ''} ${
        isModal && 'cursor-pointer'
      }`"
      style="width: 176px; height: 247px"
      @click="onClickParentDiv"
    >
      <img :src="template.s3_cover_thumbnail_url" alt="" class="object-cover object-center w-full h-full" />

      <span
        class="bg-black bg-opacity-50 text-white font-semibold p-1 uppercase inline-block absolute top-3 left-3"
        style="font-size: 10px"
      >
        {{ template.pageCount }} {{ $t('homepage.template.card.pages') }}
      </span>

      <div class="w-full h-1/2 absolute top-1 transform rotate-45 left-5 flex justify-end items-start">
        <span
          v-if="checkForSpecial('new', template.tags)"
          class="text-xs font-bold text-white py-1.5 w-full h-7 inline-block text-center"
          style="background-color: #f9423a"
        >
          {{ $t('homepage.template.card.new') }}
        </span>
        <span
          v-if="checkForSpecial('popular', template.tags)"
          class="text-xs font-bold text-white py-1.5 w-full h-7 inline-block text-center"
          style="background-color: #23b732"
        >
          {{ $t('homepage.template.card.popular') }}
        </span>
        <span
          v-if="checkForSpecial('premium', template.tags)"
          class="text-xs font-bold text-white py-1.5 w-full h-7 inline-block text-center"
          style="background-color: #000"
        >
          {{ $t('homepage.template.card.premium') }}
        </span>
      </div>

      <!-- Hover Effect -->
      <div
        v-if="!isModal"
        class="hover-div absolute top-0 left-0 w-full h-full justify-center items-center bg-wg-100 bg-opacity-60 z-10"
      >
        <div class="flex flex-col items-stretch space-y-6" style="width: 102px">
          <button
            class="font-semibold text-white text-center inline-block w-full rounded py-2.5 bg-wg-light-accent hover:bg-wg-light-accent-hover"
            @click="
              $store.commit('SET_SELECTED_TEMPLATE', template)
              $store.commit('SET_SHOW_TEMPLATE_MODAL', true)
            "
          >
            {{ $t('homepage.template.card.preview.button') }}
          </button>

          <button
            class="font-semibold text-white text-center inline-block w-full rounded py-2.5 bg-wg-light-accent hover:bg-wg-light-accent-hover"
            @click="$router.push({ name: 'PreparingTemplate', params: { id: template.id } })"
          >
            {{ $t('homepage.template.card.start.button') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    selectedTemplate() {
      return this.$store.state.home.selectedTemplate
    },

    checkForSpecial() {
      return (tag, tags) => {
        return tags.filter((t) => t.type === 'specials').some((t) => t.name && t.name.en === tag)
      }
    },
  },

  methods: {
    onClickParentDiv() {
      if (this.isModal) {
        this.$store.commit('SET_SELECTED_TEMPLATE', this.template)

        this.$emit('on-click')
      }
    },
  },
}
</script>

<style scoped>
/* Create a class called .new-badge that will put this tag on the top right corner of the card at 45deg angle */

.shadow-custom {
  filter: drop-shadow(0px 1.11111px 3.33334px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 1.11111px 2.22223px rgba(0, 0, 0, 0.2));
}

.custom-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @apply border-wg-light-accent border-solid border-8 z-0;
}

.hover-div {
  display: none;
}

.hover-parent:hover .hover-div {
  display: flex;
}
</style>
