<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 bg-white overflow-hidden rounded-lg shadow-custom">
    <div class="overflow-hidden">
      <img :src="data.image" alt="" class="w-full h-full object-cover object-center relative z-0" />
      <span
        v-if="data.size"
        class="bg-wg-5 rounded-xl px-2 py-1 absolute bottom-0 left-0 z-10 text-xs text-wg-100 font-semibold"
        style="margin: 8px"
        >{{ data.size }}</span
      >
    </div>

    <div class="p-6 flex flex-col relative">
      <button v-if="showInfoBtn" class="absolute top-0 right-0 m-2" @click="$emit('on-info-click')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5">
          <rect width="24" height="24" rx="12" fill="#2A65C4" />
          <path
            d="M10.2413 6.31038C10.2413 7.03344 10.8197 7.61189 11.5428 7.61189C12.2747 7.61189 12.8443 7.03344 12.8354 6.31038C12.8354 5.57845 12.2747 5 11.5428 5C10.8197 5 10.2413 5.57845 10.2413 6.31038ZM8 18.2592C8 18.7019 8.31622 19 8.79517 19H14.5164C14.9954 19 15.312 18.7019 15.312 18.2588C15.312 17.8254 14.9958 17.5269 14.5164 17.5269H12.6997V10.6306C12.6997 10.1428 12.3835 9.81694 11.9134 9.81694H9.04815C8.57845 9.81694 8.26184 10.1062 8.26184 10.5404C8.26184 10.9923 8.57806 11.2904 9.04853 11.2904H11.0276V17.5269H8.79517C8.31622 17.5269 8 17.8254 8 18.2592Z"
            fill="white"
          />
        </svg>
      </button>

      <h3 class="md:text-base 2xl:text-lg font-bold text-wg-100">{{ data.title }}</h3>

      <div
        class="text-wg-80 text-sm 2xl:text-base md-body"
        style="margin-top: 0.75rem; margin-bottom: 0.75rem"
        v-html="data.description"
      ></div>

      <button
        @click="$emit('on-click')"
        class="font-semibold text-white text-sm xl:text-base text-center inline-block w-full rounded py-2.5 mt-auto"
        :class="data.isLinda ? 'bg-black' : 'bg-wg-light-accent hover:bg-wg-light-accent-hover'"
        v-html="data.buttonText"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    data: {
      type: Object,
      required: true,
    },
    showInfoBtn: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.shadow-custom {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
}

.md-body >>> ul {
  list-style-type: disc;
  padding-left: 1rem;
}
</style>
