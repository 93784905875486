<template>
  <div class="modalBoxContainer">
    <div :class="[
      {
        modalBox: duplication === false,
        duplicationBox: duplication === true
      }
  ]">
      <div class="boxContent">
        <div>
          <Ok/>
        </div>
        <div class="boxInfo">
          <div v-if="!duplication">
            <p class="m-0 pDone">{{ $t('settings_done') }}</p>
            <p class="m-0 pConfirm">{{ $t('settings_the_last') }} <span
                v-if="this.$store.getters['getUpdateCreationType'] === 'added'">{{
                this.$store.getters['getAddedPagesCount']
              }}</span><span
                v-else>4</span> {{ $t('settings_pages') }} <span
                v-if="this.$store.getters['getUpdateCreationType'] === 'deleted'">{{ $t('settings_deleted') }}</span>
              <span v-else>{{ $t('settings_pages_succesfully') }}</span>.</p>
            <div class="btnUndo jilster_btn hint_button" @click="undoAction()"><span>{{ $t("settings_undo") }}</span>
            </div>
          </div>
          <div v-else>
            <div @click="$router.push(`/document/${$store.getters['getDuplicateCreationState'].id}`)"
                 v-if="$store.getters['getDuplicateCreationState'].hasOwnProperty('title')">
              <p class="m-0 pDone">{{ $t('duplication_succes') }} {{
                  $store.getters['getDuplicateCreationState'].title
                }} {{ $t('duplication_succes_two') }} </p>
              <p class="m-0 pConfirm">{{ $t('duplication_succes_click') }}</p>
            </div>
            <div v-else>
              <p class="m-0 pDone">{{ $t('duplication_succes_start') }}</p>
              <p class="m-0 pConfirm">{{ $t('duplication_succes_start_body') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Ok from "./Ok";

export default {
  name: 'DoneEditing',
  components: {Ok},
  props: {
    duplication: Boolean
  },
  methods: {
    async undoAction() {
      if (this.$store.getters['getUpdateCreationType'] === "deleted") {
        await this.$store.dispatch('clearDocumentsFromApi')
        await this.$store.dispatch('undoDelete', this.$route.params.id)
      } else if (this.$store.getters['getUpdateCreationType'] === "added") {
        await this.$store.dispatch('clearDocumentsFromApi')
        await this.$store.dispatch('undoAdd', this.$route.params.id)
      }
    }
  }
}

</script>
