var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-container z-10"},[(_vm.topbar.active)?_c('Topbar',{attrs:{"topbar":_vm.topbar}}):_vm._e(),(this.$store.getters['getLoadingDocument'] !== true)?_c('b-navbar',{class:{
      zIndex:
        this.$route.name === 'DocumentOverview' || this.$route.name === 'Orders' || this.$route.name === 'Settings',
    },attrs:{"id":"jilster_navbar","toggleable":"lg","type":"dark","variant":"info"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('div',{staticClass:"navContent"},[_c('router-link',{attrs:{"to":_vm.$route.path.includes('/document') ? '/' : '/'}},[_c('JilsterLogo',{staticClass:"jilster_logo",nativeOn:{"click":function($event){return _vm.resetDocumentOverview.apply(null, arguments)}}})],1),(
          this.$route.name === 'OrderCheckout' &&
          this.$route.query.confirmation !== 'true' &&
          _vm.$store.getters['getShowDots']
        )?_c('OrderStepWizard'):_vm._e(),(
          (this.$route.name === 'DocumentOverview' && this.$store.getters['getDocumentRole'] !== 3) ||
          (this.$route.name === 'DocumentOverview' && this.$store.getters['getEditorPreview'])
        )?_c('div',{staticClass:"document-menu-header icons"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"view-as-project viewIcon",class:{ itemActive: !this.$store.getters['getViewedAsCreation'] },attrs:{"title":"Document view"},on:{"click":function($event){return _vm.$store.dispatch('setViewedAsCreationStatus', false)}}},[_c('i',{staticClass:"icon-book"})]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"view-as-creation viewIcon",class:{ itemActive: this.$store.getters['getViewedAsCreation'] },attrs:{"title":"Page view"},on:{"click":function($event){return _vm.$store.dispatch('setViewedAsCreationStatus', true)}}},[_c('i',{staticClass:"icon-page"})])]):_vm._e(),_c('div',{staticClass:"document-menu-header closeWrapper",on:{"click":_vm.resetDocumentOverview}},[(this.$route.name === 'DocumentOverview' || this.$route.name === 'NewDesign')?_c('router-link',{staticClass:"closeIcon",attrs:{"to":"/"}},[_c('i',{staticClass:"icon-close closing-icon",staticStyle:{"display":"flex"}})]):_vm._e()],1)],1),(
        this.$route.name !== 'DocumentOverview' &&
        this.$route.name !== 'NewDesign' &&
        this.$route.name !== 'OrderCheckout' &&
        this.$route.name !== 'PreparingTemplate'
      )?_c('span',[_c('Button',{staticClass:"whitespace-nowrap",attrs:{"button-text":_vm.$t('make.a.design'),"button-type":"primary","height-auto":true},nativeOn:{"click":function($event){return _vm.$emit('on-click')}}})],1):_vm._e(),(
        this.$route.name !== 'DocumentOverview' &&
        this.$route.name !== 'NewDesign' &&
        this.$route.name !== 'OrderCheckout' &&
        this.$route.name !== 'PreparingTemplate'
      )?_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.$store.getters['getLoggedInUser'])?_c('div',{staticClass:"user_nav mb-2"},[_c('button',{staticClass:"ml-2 h-9 w-9 rounded-full focus:outline-none text-2xl font-medium flex justify-center items-center",style:(_vm.getColors)},[_vm._v(" "+_vm._s((_vm.$store.getters['getLoggedInUser'].firstName && _vm.$store.getters['getLoggedInUser'].firstName[0].toUpperCase()) || (_vm.$store.getters['getLoggedInUser'].nickName && _vm.$store.getters['getLoggedInUser'].nickName[0].toUpperCase()) || '')+" ")])]):_vm._e()]},proxy:true}],null,false,718660831)},[(_vm.$store.getters['getLoggedInUser'])?_c('b-dropdown-item',{staticClass:"dropdown-item-email"},[_c('p',[_vm._v(_vm._s(_vm.$store.getters['getLoggedInUser'].email))])]):_vm._e(),_c('b-dropdown-item',{staticClass:"menu-option-item",attrs:{"href":"/orders"}},[_c('i',{staticClass:"icon-download"}),_c('span',[_vm._v(_vm._s(_vm.$t('orders.header.title')))])]),_c('b-dropdown-item',{staticClass:"menu-option-item",on:{"click":_vm.logout}},[_c('i',{staticClass:"icon-logout"}),_c('span',[_vm._v(_vm._s(_vm.$t('auth.logout')))])])],1)],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }