<template>
  <div class="successShared">
    <Ok />
    <span class="linkCopied">{{$t('link_copied')}}</span>
  </div>
</template>

<script>
import Ok from './Ok.vue'
export default {
  name: 'SuccessShared',
  components: {Ok}
}
</script>
