import httpClient from './../../../httpClient';

const END_POINT = 'v3';

const getCheckout = (id, extraOrderIds = "", printId = "0", volume = "0", deliveryId = "0", paymentId="0", proof = "false", countryId = "1") => httpClient.get(`${END_POINT}/checkout/${id}`, {
    params:{
        orderExtraIds: extraOrderIds,
        printId: printId,
        volume: volume,
        countryId: countryId,
        proof: proof,
        paymentId: paymentId,
        deliveryId: deliveryId
    }
});

const getCheckoutPDFOnly = (id, orderExtraIds, paymentId) => httpClient.get(`${END_POINT}/checkout/pdflow/${id}`, {
    params: {
        orderExtraIds: orderExtraIds,
        paymentId: paymentId
    }
})

const checkoutOrder = (id, orderExtraIds, printId, volume, countryId, proof, paymentId, useDeliveryAddress, firstName, lastName, gender, street, houseNumber, houseNumberSuffix, postalCode, city, phone, delivery_firstName, delivery_lastName, delivery_gender, delivery_street, delivery_houseNumber, delivery_houseNumberSuffix, delivery_postalCode, delivery_city, delivery_phone, delivery_countryId, shipmentId, company) => httpClient.post(`${END_POINT}/checkout/${id}`, {
    orderExtraIds,
    printId,
    volume,
    countryId,
    proof,
    paymentId,
    useDeliveryAddress,
    firstName,
    lastName,
    gender,
    street,
    houseNumber,
    houseNumberSuffix,
    postalCode,
    city,
    phone,
    delivery_firstName,
    delivery_lastName,
    delivery_gender,
    delivery_street,
    delivery_houseNumber,
    delivery_houseNumberSuffix,
    delivery_postalCode,
    delivery_city,
    delivery_phone,
    delivery_countryId,
    shipmentId,
    company
});

const createCheckoutPDFOnly = (id, orderExtraIds, paymentId, firstName, lastName, gender, street, houseNumber, houseNumberSuffix, postalCode, city, phone, countryId, delivery_firstName, delivery_lastName, delivery_gender, delivery_street, delivery_houseNumber, delivery_houseNumberSuffix, delivery_postalCode, delivery_city, delivery_phone, delivery_countryId, company) => httpClient.post(`${END_POINT}/checkout/pdflow/${id}`, {
    orderExtraIds: orderExtraIds,
    paymentId: paymentId,
    firstName: firstName,
    lastName: lastName,
    gender: gender,
    street: street,
    houseNumber: houseNumber,
    houseNumberSuffix: houseNumberSuffix,
    postalCode: postalCode,
    city: city,
    phone: phone,
    countryId: countryId,
    delivery_firstName: delivery_firstName,
    delivery_lastName: delivery_lastName,
    delivery_gender: delivery_gender,
    delivery_street: delivery_street,
    delivery_houseNumber: delivery_houseNumber,
    delivery_houseNumberSuffix: delivery_houseNumberSuffix,
    delivery_postalCode: delivery_postalCode,
    delivery_city: delivery_city,
    delivery_phone: delivery_phone,
    delivery_countryId: delivery_countryId,
    company: company
});

const checkoutOrderOnlyExtras = (order) => httpClient.post(`${END_POINT}/checkout/onlyextrasflow/${order.id}`, {
    orderExtraIds: order.orderExtraIds,
    printId: order.printId,
    volume: order.volume,
    countryId: order.countryId,
    proof: order.proof,
    paymentId: order.paymentId,
    useDeliveryAddress: order.useDeliveryAddress,
    firstName: order.firstName,
    lastName: order.lastName,
    gender: order.gender,
    street: order.street,
    houseNumber: order.houseNumber,
    houseNumberSuffix: order.houseNumberSuffix,
    postalCode: order.postalCode,
    city: order.city,
    phone: order.phone,
    delivery_firstName: order.delivery_firstName,
    delivery_lastName: order.delivery_lastName,
    delivery_gender: order.delivery_gender,
    delivery_street: order.delivery_street,
    delivery_houseNumber: order.delivery_houseNumber,
    delivery_houseNumberSuffix: order.delivery_houseNumberSuffix,
    delivery_postalCode: order.delivery_postalCode,
    delivery_city: order.delivery_city,
    delivery_phone: order.delivery_phone,
    delivery_countryId: order.delivery_countryId,
    shipmentId: order.shipmentId,
    company: order.company
});

const getSuccessOrder = (identifier) => httpClient.get(`${END_POINT}/checkout/${identifier}/order`)

const getLastorder = () => httpClient.get(`${END_POINT}/maker/orders/last`)

const updatePaymentUser = (data) => httpClient.patch(`${END_POINT}/maker/profile`, data);

export {
    getCheckout,
    getCheckoutPDFOnly,
    checkoutOrder,
    createCheckoutPDFOnly,
    getSuccessOrder,
    getLastorder,
    updatePaymentUser,
    checkoutOrderOnlyExtras
}
