const state = {
  notifications: {
    dates: [
      {
        date: "Today",
        notifications: [
          {
            id: Date.now().toString(36),
            content:
              "<b>You</b> released <b>page 2</b> to <b>Georgianna Herdman</b>",
            link: "#",
            icon: "avatar",
          },
        ],
      },
    ],
  },
  filters: ["All", "Today"],
};

const getters = {
  getNotifications: (state) => {
    return state.notifications;
  },
  getFilters: (state) => {
    return state.filters;
  },
};

const actions = {
  addNotification({ commit }, notification) {
    commit("ADD_NOTIFICATION", notification);
  },
};

const mutations = {
  ADD_NOTIFICATION(state, notification) {
    state.notifications.dates[0].notifications.push(notification);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
