<template>
  <ModalContainer :title="$t('take.a.look.modal.title')" description="" @on-close="$emit('on-close')">
    <!-- First section -->
    <div>
      <div class="" style="background-color: #d9d9d9; width: 658px; height: 343px"></div>

      <div class="mt-9">
        <h3 class="text-lg font-bold" style="color: #111">{{ firstSection.title }}</h3>

        <div>
          <ol style="color: #534c45; list-style: decimal; list-style-position: inside">
            <li v-for="(item, i) in firstSection.points" :key="i" v-html="item"></li>
          </ol>
        </div>

        <div class="mt-6">
          <button
            class="rounded text-white font-semibold bg-wg-light-accent hover:bg-wg-light-accent-hover"
            style="padding: 10px 12px"
            @click="
              $store.commit('SET_SELECTED_TEMPLATE', firstTemplate)
              $store.commit('SET_SHOW_TEMPLATE_MODAL', true)
              $emit('on-close')
            "
          >
            {{ firstSection.button }}
          </button>
        </div>
      </div>
    </div>

    <!-- Second section -->
    <div class="mt-16">
      <div class="" style="background-color: #d9d9d9; width: 658px; height: 343px"></div>

      <div class="mt-9">
        <h3 class="text-lg font-bold" style="color: #111">{{ secondSection.title }}</h3>

        <div>
          <ol style="color: #534c45; list-style: decimal; list-style-position: inside">
            <li v-for="(item, i) in secondSection.points" :key="i" v-html="item"></li>
          </ol>
        </div>

        <div class="mt-6">
          <button
            class="rounded text-white font-semibold bg-wg-light-accent hover:bg-wg-light-accent-hover"
            style="padding: 10px 12px"
            @click="
              $store.commit('SET_SHOW_SCRATCH_MODAL', true)
              $emit('on-close')
            "
          >
            {{ secondSection.button }}
          </button>
        </div>
      </div>
    </div>

    <!-- Third section -->
    <div class="mt-16">
      <div class="" style="background-color: #d9d9d9; width: 658px; height: 343px"></div>

      <div class="mt-9">
        <h3 class="text-lg font-bold" style="color: #111">{{ thirdSection.title }}</h3>

        <div>
          <ol style="color: #534c45; list-style: decimal; list-style-position: inside">
            <li v-for="(item, i) in thirdSection.points" :key="i" v-html="item"></li>
          </ol>

          <p class="mt-9">
            {{ thirdSection.tagline }}
          </p>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from '../domains/home/ModalContainer.vue'

export default {
  components: {
    ModalContainer,
  },
  data() {
    return {
      firstSection: {
        title: this.$t('take.a.look.modal.first.section.title'),
        points: this.$t('take.a.look.modal.first.section.points')
          ? this.$t('take.a.look.modal.first.section.points').split('*')
          : [],
        button: this.$t('take.a.look.modal.first.section.button'),
      },
      secondSection: {
        title: this.$t('take.a.look.modal.second.section.title'),
        points: this.$t('take.a.look.modal.second.section.points')
          ? this.$t('take.a.look.modal.second.section.points').split('*')
          : [],
        button: this.$t('take.a.look.modal.second.section.button'),
      },
      thirdSection: {
        title: this.$t('take.a.look.modal.third.section.title'),
        points: this.$t('take.a.look.modal.third.section.points')
          ? this.$t('take.a.look.modal.third.section.points').split('*')
          : [],
        tagline: this.$t('take.a.look.modal.third.section.tag.line'),
      },
    }
  },
}
</script>
