import axios from 'axios'
import router from './src/router'
//VUE_APP_API_URL=https://makerspace.jilster.dev/api/
const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

const getAuthToken = () => localStorage.getItem('jilster_token')
//

const authInterceptor = (config) => {
  config.headers['Authorization'] = `Bearer ${getAuthToken()}`
  return config
}

// interceptor to catch errors
const errorInterceptor = (error) => {
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error)
  }

  // all the other error responses
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message)
      break

    case 401: // authentication error, logout the user
      localStorage.removeItem('jilster_token')
      if (!window.location.href.includes('login')) {
        router.push('/login').catch(() => {})
      }
      break

    default:
      console.error(error.response.status, error.message)
  }
  return Promise.reject(error)
}

// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break
    // any other cases
    default:
    // default case
  }

  return response
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)

httpClient.interceptors.request.use(authInterceptor)

export default httpClient
