<template>
  <div>
    <div class="w-full pl-64 pr-12 pt-28">
      <div class="w-full">
        <PageTitle :title="headerInfo.title" :subtitle="headerInfo.subtitle" :image="headerInfo.image" />
      </div>

      <div v-if="loading" class="loading-icon"><img src="/loading.gif" alt="loading" /></div>

      <div v-else-if="!loading && this.invitations.length > 0" class="w-full flex flex-wrap gap-x-8">
        <div v-for="invitation in invitations" :key="invitation.id" class="">
          <Creation
            @refresh_invitation="reloadInvitations"
            :creation="{ ...invitation.magazine, token: invitation.token }"
          />
        </div>
      </div>
      <div v-else class="w-full mt-28">
        <div class="flex justify-center" style="margin-bottom: 1rem">
          <img :src="require('@/assets/invitation-icon.png')" alt="" />
        </div>
        <div class="flex justify-center">
          <p class="text-lg max-w-sm text-center" v-html="$t('invitation.empty')"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyInvitations } from './invitationEndpoint'
import Creation from '../../components/Creation'
// import InfoSvg from '../../vectors/InfoSvg'

import PageTitle from '@/components/PageTitle.vue'
import bgImageInvitations from '@/assets/invitations-header.png'

export default {
  name: 'InvitationOverview',
  components: { Creation, PageTitle },
  data() {
    return {
      invitations: [],
      loading: true,
      headerInfo: {
        title: this.$t('invitation.header.title'),
        subtitle: this.$t('invitation.header.description'),
        image: `url(${bgImageInvitations})`,
      },
    }
  },
  methods: {
    async reloadInvitations() {
      this.loading = true
      const response = await getMyInvitations()
      this.$store.commit('SET_INVITATIONS', response.data.data)
      this.invitations = response.data.data
      this.loading = false
    },
    async getInvitations() {
      const response = await getMyInvitations()
      this.invitations = response.data.data
      this.loading = false
    },
  },
  async created() {
    await this.getInvitations()
  },
}
</script>
