<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3235:14453)">
      <path d="M0.746094 16.1723C2.44161 20.7419 6.84012 23.9985 11.9997 23.9985C17.1593 23.9985 21.5578 20.7419 23.2533 16.1723L11.9997 15.1289L0.746094 16.1723Z" fill="#FFDA44"/>
      <path d="M11.9997 0C6.84012 0 2.44161 3.2565 0.746094 7.82611L11.9997 8.86955L23.2533 7.82606C21.5578 3.2565 17.1593 0 11.9997 0Z" fill="black"/>
      <path d="M0.746391 7.82422C0.264047 9.1242 0 10.5303 0 11.9981C0 13.466 0.264047 14.872 0.746391 16.172H23.2537C23.736 14.872 24 13.466 24 11.9981C24 10.5303 23.736 9.1242 23.2536 7.82422H0.746391Z" fill="#D80027"/>
    </g>
    <defs>
      <clipPath id="clip0_3235:14453">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Germany'
}
</script>