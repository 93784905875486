<template>
  <div class="checkout-payment-option">
    <h2>Payment options</h2>
    <!--    <b-form-group v-slot="{ ariaDescribedby }">-->
    <!--      <div class="radiocontainer">-->
    <!--       <b-form-radio @change="checkIfDone" v-model="payment" :aria-describedby="ariaDescribedby" name="radio-ideal" value="iDeal">-->
    <!--         <img src="https://www.pay.nl/uploads/logos/iDEAL_logo.png" alt="">-->
    <!--         iDeal-->
    <!--       </b-form-radio>-->
    <!--      </div>-->
    <!--      <div class="radiocontainer">-->
    <!--        <b-form-radio  @change="checkIfDone" v-model="payment" :aria-describedby="ariaDescribedby" name="radio-paypal" value="Paypal">-->
    <!--          <img src="https://www.pay.nl/uploads/logos/iDEAL_logo.png" alt="">-->
    <!--          Paypal-->
    <!--        </b-form-radio>-->
    <!--      </div>-->
    <!--      <div class="radiocontainer">-->
    <!--        <b-form-radio  @change="checkIfDone" v-model="payment" :aria-describedby="ariaDescribedby" name="radio-mastercard" value="MasterCard">-->
    <!--          <img src="https://www.pay.nl/uploads/logos/iDEAL_logo.png" alt="">-->
    <!--          MasterCard-->
    <!--        </b-form-radio>-->
    <!--      </div>-->
    <!--      <div class="radiocontainer">-->
    <!--        <b-form-radio  @change="checkIfDone" v-model="payment" :aria-describedby="ariaDescribedby" name="radio-viscard" value="VisaCard">-->
    <!--          <img src="https://www.pay.nl/uploads/logos/iDEAL_logo.png" alt="">-->
    <!--          VisaCard-->
    <!--        </b-form-radio>-->
    <!--      </div>-->
    <!--      <div class="radiocontainer">-->
    <!--        <b-form-radio  @change="checkIfDone" v-model="payment" :aria-describedby="ariaDescribedby" name="radio-american-express" value="American Express">-->
    <!--          <img src="https://www.pay.nl/uploads/logos/iDEAL_logo.png" alt="">-->
    <!--          American Express-->
    <!--        </b-form-radio>-->
    <!--      </div>-->
    <!--      <div class="radiocontainer">-->
    <!--        <b-form-radio  @change="checkIfDone" v-model="payment" :aria-describedby="ariaDescribedby" name="radio-Bancontact" value="Bancontact">-->
    <!--          <img src="https://www.pay.nl/uploads/logos/iDEAL_logo.png" alt="">-->
    <!--          Bancontact-->
    <!--        </b-form-radio>-->
    <!--      </div>-->
    <!--    </b-form-group>-->
    <b-row>
      <b-col lg="3" v-for="payment in paymentMethods" :key="payment.id">
        <div
          class="payment-option"
          @click="selectPaymentMethod(payment)"
          v-bind:class="{
            selectPaymentMethodStyle: selectPaymentMethodStyle(payment),
          }"
        >
          <img :src="payment.img" alt="" />
        </div>
      </b-col>
    </b-row>
    <div class="checkbox-container">
      <b-form-checkbox
        id="checkbox-terms"
        v-model="terms"
        name="checkbox-terms"
        :value="true"
        :unchecked-value="false"
        @change="checkIfDone"
      >
        I accept the terms of service
      </b-form-checkbox>
    </div>
    <div class="checkbox-container">
      <b-form-checkbox
        id="checkbox-privacy"
        v-model="privacy"
        name="checkbox-privacy"
        :value="true"
        :unchecked-value="false"
        @change="checkIfDone"
      >
        I accept the privacy policy
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutPaymentOption",
  data() {
    return {
      paymentMethodChosen: "",
      terms: false,
      privacy: false,
      paymentMethods: [
        {
          id: 1,
          name: "iDeal",
          value: "iDeal",
          img: "https://www.pay.nl/uploads/logos/iDEAL_logo.png",
        },
        {
          id: 2,
          name: "Payment",
          value: "Payment",
          img: "https://www.pay.nl/uploads/logos/iDEAL_logo.png",
        },
      ],
    };
  },
  methods: {
    checkIfDone() {
      if (
        this.paymentMethodChosen !== "" &&
        this.terms === true &&
        this.privacy === true
      ) {
        this.$store.dispatch("getCheckoutDataComplete", true);
      } else {
        this.$store.dispatch("getCheckoutDataComplete", false);
      }
    },
    selectPaymentMethod(payment) {
      this.paymentMethodChosen = payment;
      this.checkIfDone();
    },
    selectPaymentMethodStyle(payment) {
      return this.paymentMethodChosen.id === payment.id;
    },
  },
};
</script>
