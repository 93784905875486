<template>
  <div
    :style="{
      backgroundImage: image,
      minHeight: '150px',
    }"
    class="bg-no-repeat bg-cover bg-bottom px-6 py-4 rounded-lg text-white relative z-0 overflow-hidden shadow-custom"
  >
    <div class="z-10 relative">
      <h1 class="text-4xl font-bold">{{ title }}</h1>
      <p class="text-xl mt-2.5 max-w-2xl">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },

  data() {
    return {}
  },
}
</script>

<style scoped>
.shadow-custom::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
  z-index: 1;
}
</style>
