import Home from './Home.vue'
import PreparingTemplate from './PreparingTemplate.vue'

export default [
  {
    path: '/all-templates',
    name: 'Homepage',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/preparing-magazine/:id',
    name: 'PreparingTemplate',
    component: PreparingTemplate,
    meta: {
      requiresAuth: true,
    },
  },
]
