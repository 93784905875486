<template>
  <div class="bg-black bg-opacity-25 flex justify-center items-center fixed top-0 left-0 w-full h-screen z-50">
    <div id="video-popup">
      <Close color="#534C45" @click.native="setVideoPopup" />
      <iframe
        width="100%"
        height="100%"
        :src="videoUrl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import Close from '../Close'

export default {
  name: 'VideoPopup',
  components: { Close },
  props: {
    videoUrl: {
      type: String,
      default: '',
    },
  },
  methods: {
    setVideoPopup() {
      this.$emit('on-close')
    },
  },
}
</script>
