<template>
  <div class="modal-unnominate">
    <div
      class="overlay"
      @click="
        $store.commit('SET_SHOW_UNNOMINATE_MODAL', false)
        $store.commit('SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL', false)

        reasonText = ''
        errors.reasonText = ''
        isLoading = false
        showError = false
      "
    ></div>

    <div class="modal-unnominate-content">
      <div class="inputs-btns">
        <h2>{{ $t('magazine.award.unnominate.modal.headline') }}</h2>

        <p>{{ $t('magazine.award.unnominate.modal.text') }}</p>

        <div style="position: relative">
          <input
            type="text"
            v-model="reasonText"
            :placeholder="$t('magazine.award.unnominate.modal.reason.placeholder')"
            @input="errors.reasonText = ''"
          />
          <span class="error">{{ errors.reasonText }}</span>
        </div>

        <div style="position: relative">
          <button class="start-btn" :disabled="isLoading" @click="onSubmit">
            {{ $t('magazine.award.unnominate.modal.confirm.button') }}
          </button>

          <Error v-if="showError" type="system" :errorMessage="$t('magazine.award.modal.system.error.message')" />
        </div>
      </div>

      <div class="animation">
        <lottie-vue-player
          :src="`/lottie/unnominate-dog.json`"
          :player-size="'standard'"
          :minimizable="false"
          :player-controls="false"
          :autoplay="true"
          :loop="true"
          :speed="1"
          style="width: 100%; height: 185px"
        />
      </div>

      <button
        class="close-btn"
        @click="
          $store.commit('SET_SHOW_UNNOMINATE_MODAL', false)
          $store.commit('SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL', false)

          reasonText = ''
          errors.reasonText = ''
          isLoading = false
          showError = false
        "
      >
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.6562 15L27.8125 4.92188C28.6458 4.03646 28.6458 3.125 27.8125 2.1875C26.875 1.35417 25.9635 1.35417 25.0781 2.1875L15 12.3438L4.92188 2.1875C3.98438 1.35417 3.07292 1.35417 2.1875 2.1875C1.35417 3.125 1.35417 4.03646 2.1875 4.92188L12.3438 15L2.1875 25.0781C1.35417 25.9635 1.35417 26.875 2.1875 27.8125C2.65625 28.1771 3.125 28.3594 3.59375 28.3594C4.01042 28.3594 4.45312 28.1771 4.92188 27.8125L15 17.6562L25.0781 27.8125C25.5469 28.1771 25.9896 28.3594 26.4062 28.3594C26.875 28.3594 27.3438 28.1771 27.8125 27.8125C28.6458 26.875 28.6458 25.9635 27.8125 25.0781L17.6562 15Z"
            fill="#999187"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

import Error from '@/components/Error'

Vue.use(LottieVuePlayer)

export default {
  components: { Error },

  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      reasonText: '',
      showError: false,
      isLoading: false,
      errors: {
        reasonText: '',
      },
    }
  },

  methods: {
    async onSubmit() {
      this.isLoading = true
      let okToProceed = true
      this.showError = false

      if (!this.reasonText) {
        this.errors.reasonText = this.$t('magazine.award.unnominate.modal.error.reason')
        okToProceed = false
      } else {
        this.errors.reasonText = ''
      }

      if (okToProceed) {
        try {
          const res = await fetch(process.env.VUE_APP_AWARD_APP_API_URL + '/candidate/remove', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
            body: JSON.stringify({
              CandidateId: (this.candidate && this.candidate.Id) || '',
              Comment: this.reasonText,
            }),
          })

          const data = await res.json()

          if (data.status === 'Success') {
            this.$emit('on-success')
            this.$store.commit('SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL', true)
            this.$store.commit('SET_SHOW_UNNOMINATE_MODAL', false)

            this.reasonText = ''
            this.errors.reasonText = ''
            this.isLoading = false
            this.showError = false
          } else {
            this.showError = true
          }

          this.isLoading = false
        } catch (err) {
          this.showError = true
          this.isLoading = false
        }
      } else {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.modal-unnominate {
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.modal-unnominate-content {
  background: white;
  border-radius: 16px;
  padding: 52px 47px 0px 47px;
  max-width: 674px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.modal-unnominate-content .animation {
  width: 164px;
  height: 335px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.modal-unnominate-content .inputs-btns {
  width: 100%;
  padding-right: 24px;
}

.modal-unnominate-content h2 {
  color: #2a65c4;
  font-size: 24px;
  font-weight: bold;
}

.modal-unnominate-content p {
  color: #534c45;
  font-size: 16px;
  font-weight: normal;
}

.modal-unnominate-content .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 32px;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-unnominate-content .start-btn {
  background-color: #2a65c4;
  color: white;
  border-radius: 4px;
  padding: 12px;
  margin-top: 32px;
  outline: none !important;
  border: none !important;
}

.modal-unnominate-content .start-btn:hover {
  background-color: #4585de;
}

.modal-unnominate-content .start-btn:active {
  background-color: #184ea7;
}

.modal-unnominate-content .start-btn:disabled {
  background-color: #f2f0ed;
  color: #ccc4bc;
}

.modal-unnominate-content input {
  width: 100%;
  display: block;
  background: #f2f0ed;
  outline: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin-top: 16px;
  border: 0.6px solid transparent;
}

.modal-unnominate-content input:focus {
  outline: none;
  border: 0.6px solid #000000;
  background: white;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
</style>
