<template>
  <div id="order-contact">
    <h2>{{ $t('checkout.contact') }}</h2>
    <div class="d-flex">
      <v-gravatar class="user_img" :email="$store.getters['getLoggedInUser'].email" />
      <div class="user-order-info">
        <h5 class="userName">
          {{ $store.getters['getLoggedInUser'].nickName }}
        </h5>
        <h5 class="userEmail">{{ $store.getters['getLoggedInUser'].email }}</h5>
      </div>
    </div>
    <div
      class="orderInfo"
      v-if="$store.getters['getCanOrderPFDOnly'] || ($store.getters['getCanOrderExtrasOnly'] && checkIfPosterSelected)"
    >
      <div>
        <i v-bind:class="`icon-info-filled`"></i>
      </div>
      <div>
        <!--        <p>{{$t("checkout.digital.creation.message")}} <span class="skipStep" v-on:click="nextPage">{{$t("checkout.skip.step")}}</span></p>-->
        <p>{{ $t('checkout.digital.creation.message') }}</p>
      </div>
    </div>
    <div class="gender-select">
      <b-form-group :label="$t('salutation')" v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender-radio" value="male">{{
          $t('male')
        }}</b-form-radio>
        <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender-radio" value="female">{{
          $t('female')
        }}</b-form-radio>
        <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender-radio" value="other">{{
          $t('other')
        }}</b-form-radio>
      </b-form-group>
      <Error
        v-if="genderError && errorStatus"
        icon="warning"
        icon-color="#C23934"
        :errorMessage="$t('checkout.error.no.gender')"
      />
    </div>
    <div class="user-address-order-info">
      <div class="d-flex">
        <div>
          <Input
            style="width: 164px; margin-right: 16px"
            :label-text="$t('checkout.firstname')"
            :placeholder-text="$t('checkout.firstname')"
            :class="{ errorBorder: !$v.firstName.required && errorStatus }"
            v-model="firstName"
          />
          <Error
            v-if="!$v.firstName.required && errorStatus"
            icon="warning"
            icon-color="#C23934"
            :errorMessage="$t('checkout.error.no.firstname')"
          />
        </div>
        <div>
          <Input
            style="width: 222px"
            :label-text="$t('checkout.lastname')"
            :placeholder-text="$t('checkout.lastname')"
            :class="{ errorBorder: !$v.lastName.required && errorStatus }"
            v-model="lastName"
          />
          <Error
            v-if="!$v.lastName.required && errorStatus"
            icon="warning"
            icon-color="#C23934"
            :errorMessage="$t('checkout.error.no.lastname')"
          />
        </div>
      </div>
      <Input style="width: 402px" :label-text="$t('company')" :placeholder-text="$t('company')" v-model="company" />
      <Input
        style="width: 402px"
        :label-text="$t('webshop.street')"
        :placeholder-text="$t('webshop.street')"
        :class="{ errorBorder: !$v.street.required && errorStatus }"
        v-model="street"
      />
      <Error
        v-if="!$v.street.required && errorStatus"
        icon="warning"
        icon-color="#C23934"
        :errorMessage="$t('checkout.error.no.street')"
      />
      <div class="d-flex">
        <div>
          <Input
            style="width: 138px; margin-right: 16px"
            :label-text="$t('webshop.house_number')"
            :placeholder-text="$t('webshop.house_number')"
            :class="{ errorBorder: !$v.houseNumber.required && errorStatus }"
            v-model="houseNumber"
          />
          <Error
            v-if="!$v.houseNumber.required && errorStatus"
            icon="warning"
            icon-color="#C23934"
            :errorMessage="$t('checkout.error.no.housenumber')"
          />
        </div>
        <div>
          <Input
            style="width: 138px; margin-right: 16px"
            :label-text="$t('webshop.house_number_suffix')"
            :placeholder-text="$t('webshop.house_number_suffix')"
            v-model="suffix"
          />
        </div>
      </div>
      <div class="d-flex">
        <div>
          <Input
            style="width: 138px; margin-right: 16px"
            :label-text="$t('checkout.postalcode')"
            :placeholder-text="$t('checkout.postalcode')"
            :class="{ errorBorder: !$v.postalCode.required && errorStatus }"
            v-model="postalCode"
          />
          <Error
            v-if="!$v.postalCode.required && errorStatus"
            icon="warning"
            icon-color="#C23934"
            :errorMessage="$t('checkout.error.no.postalcode')"
          />
        </div>
        <div>
          <Input
            style="width: 248px"
            :label-text="$t('checkout.city')"
            :placeholder-text="$t('checkout.city')"
            :class="{ errorBorder: !$v.city.required && errorStatus }"
            v-model="city"
          />
          <Error
            v-if="!$v.city.required && errorStatus"
            icon="warning"
            icon-color="#C23934"
            :errorMessage="$t('checkout.error.no.city')"
          />
        </div>
      </div>

      <div v-if="!$store.getters['getIsLinda'] && options.length > 0">
        <Select
          @valueChanged="valueChanged"
          id="country"
          :initialValue="localCountryId"
          :label-text="$t('checkout.country')"
          :options="options"
          icon-color="#534C45"
          icon="chevron-down"
        />
      </div>
      <div class="d-flex">
        <div>
          <Input
            style="width: 136px; margin-right: 16px"
            :label-text="$t('checkout.area')"
            placeholder-text="+(31)"
            :class="{ errorBorder: !$v.area.required && errorStatus }"
            v-model="area"
          />
          <Error
            v-if="!$v.area.required && errorStatus"
            icon="warning"
            icon-color="#C23934"
            :errorMessage="$t('checkout.error.no.area')"
          />
        </div>
        <div>
          <Input
            style="width: 159px"
            :label-text="$t('checkout.phone')"
            :placeholder-text="$t('checkout.phone')"
            v-model="phonenumber"
          />
        </div>
      </div>
      <div class="d-flex" style="margin-top: 24px">
        <b-form-checkbox
          id="popup"
          name="popup"
          v-model="checked"
          @input.native="checked ? $store.dispatch('setShowPopup', false) : $store.dispatch('setShowPopup', true)"
        />
        <span style="margin-left: 8px" class="useDifferentBillingAddress">{{
          $t('checkout.use.different.billing.address')
        }}</span>
      </div>
    </div>
    <div class="d-flex return-order">
      <div
        class="d-flex return-order-text"
        @click="$store.dispatch('setCurrentPage', 'content')"
        v-if="!$store.getters['getCanOrderPFDOnly']"
      >
        <i class="icon-chevron-left"></i>
        <span>{{ $t('checkout.return.to.order') }}</span>
      </div>
      <div class="d-flex">
        <Button
          style="height: auto !important"
          @click.native="nextPage"
          :button-text="$t('checkout.continue.to.payment')"
          button-type="primary"
          v-if="
            $store.getters['getIsLinda'] ||
            $store.getters['getCanOrderPFDOnly'] ||
            $store.getters['getCanOrderExtrasOnly'] ||
            $store.getters['getVolume'] === 0
          "
        />
        <Button
          style="height: auto !important"
          @click.native="nextPage"
          :button-text="$t('checkout.continue.to.delivery')"
          button-type="primary"
          v-else
        />
        <OrderError style="height: auto !important" v-if="errorStatus" :error-text="$t('checkout.error.fields')" />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input'
import Select from '@/components/Select'
import Button from '@/components/Button'
import OrderError from '@/domains/order/OrderError'
import { required } from 'vuelidate/lib/validators'
import Error from '@/components/Error'

export default {
  name: 'OrderContact',
  components: { OrderError, Button, Select, Input, Error },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    street: {
      required,
    },
    houseNumber: {
      required,
    },
    postalCode: {
      required,
    },
    city: {
      required,
    },
    area: {
      required,
    },
  },
  data() {
    return {
      firstName: this.$store.getters['getPaymentUser'].firstName
        ? this.$store.getters['getPaymentUser'].firstName
        : this.$store.getters['getLoggedInUser'].firstName,
      lastName: this.$store.getters['getPaymentUser'].lastName
        ? this.$store.getters['getPaymentUser'].lastName
        : this.$store.getters['getLoggedInUser'].lastName,
      street: this.$store.getters['getPaymentUser'].street ? this.$store.getters['getPaymentUser'].street : '',
      houseNumber: this.$store.getters['getPaymentUser'].houseNumber
        ? this.$store.getters['getPaymentUser'].houseNumber
        : '',
      suffix: this.$store.getters['getPaymentUser'].houseNumberSuffix
        ? this.$store.getters['getPaymentUser'].houseNumberSuffix
        : ' ',
      postalCode: this.$store.getters['getPaymentUser'].postalCode
        ? this.$store.getters['getPaymentUser'].postalCode
        : '',
      city: this.$store.getters['getPaymentUser'].city ? this.$store.getters['getPaymentUser'].city : '',
      area: this.$store.getters['getPaymentUser'].area ? this.$store.getters['getPaymentUser'].area : '',
      phonenumber: this.$store.getters['getPaymentUser'].phonenumber
        ? this.$store.getters['getPaymentUser'].phonenumber
        : '',
      company: '',
      errorStatus: false,
      localCountryId: '',
      countryId: '',
      options: [],
      gender: this.$store.getters['getPaymentUser'].gender
        ? this.$store.getters['getPaymentUser'].gender.trim()
        : 'male',
      genderError: false,
    }
  },
  methods: {
    async nextPage() {
      this.errorStatus = false
      this.genderError = false

      let user = {
        firstName: this.firstName,
        lastName: this.lastName,
        street: this.street,
        houseNumber: this.houseNumber,
        houseNumberSuffix: this.suffix,
        postalCode: this.postalCode,
        city: this.city,
        area: this.area,
        phonenumber: this.phonenumber,
        countryId: this.countryId,
        company: this.company,
        gender: this.gender,
        salutation: this.gender,
      }

      if (this.countryId && !this.$store.getters['getLoggedInUser'].country_id) {
        await this.$store.dispatch('updateUserCountryId', {
          country_id: 1,
          locale: this.$store.getters['getLoggedInUser'].locale ?? this.$store.getters['getVisitor']?.toLowerCase(),
        })

        await this.$store.dispatch('getCurrentMaker')
      }

      if (!this.gender) {
        this.errorStatus = true
        this.genderError = true

        return
      }

      if (
        this.$store.getters['getCanOrderPFDOnly'] ||
        (this.$store.getters['getCanOrderExtrasOnly'] && this.checkIfPosterSelected)
      ) {
        if (!this.$v.firstName.$invalid && !this.$v.lastName.$invalid) {
          this.$store.dispatch('setCurrentPage', 'payment')

          this.$store.dispatch('setPaymentUser', user)

          return
        }
      }

      const pdfExtras = ['1', '2']
      const orderExtras = this.$store.getters['getOrderExtraIds'].split(',')
      const hasDigitalOrderExtras = orderExtras.find((i) => i === '1' || i === '2' || i === pdfExtras.join(','))
      const hasPosterOrderExtras = orderExtras.find((i) => Number(i) > 2)
      const creationVolume = this.$store.getters['getVolume']
      const isIncorrectAddress = this.$v.$invalid

      if (!isIncorrectAddress) {
        this.errorStatus = false
      }

      if (creationVolume !== 0) {
        if (!hasDigitalOrderExtras && !hasPosterOrderExtras && isIncorrectAddress) {
          this.errorStatus = true
        } else if (hasDigitalOrderExtras && !hasPosterOrderExtras && isIncorrectAddress) {
          this.errorStatus = true
        } else if (!hasDigitalOrderExtras && hasPosterOrderExtras && isIncorrectAddress) {
          this.errorStatus = true
        } else if (hasDigitalOrderExtras && hasPosterOrderExtras && isIncorrectAddress) {
          this.errorStatus = true
        }
      } else {
        if (!hasPosterOrderExtras && !hasDigitalOrderExtras && isIncorrectAddress) {
          this.errorStatus = true
        } else if (hasPosterOrderExtras && !hasDigitalOrderExtras && isIncorrectAddress) {
          this.errorStatus = true
        } else if (hasPosterOrderExtras && hasDigitalOrderExtras && isIncorrectAddress) {
          this.errorStatus = true
        }
      }

      if (!this.errorStatus) {
        this.$store.dispatch('setPaymentUser', user)

        if (this.$store.getters['getCanOrderExtrasOnly']) {
          this.$store.dispatch('setCurrentPage', 'payment')
        } else {
          if (!this.$store.getters['getIsLinda'] && this.$store.getters['getVolume'] !== 0) {
            this.$store.dispatch('setCurrentPage', 'delivery')
          } else {
            this.$store.dispatch('setCurrentPage', 'payment')
          }
        }
      }
    },
    async setCountryIdToUser(countryId) {
      await this.$store.dispatch('updateUserCountryId', {
        country_id: countryId,
        locale: this.$store.getters['getLoggedInUser'].locale ?? this.$store.getters['getVisitor']?.toLowerCase(),
      })
    },
    valueChanged(object) {
      this.countryId = object[0]

      this.$store.dispatch('setShipment', '0')
    },
    getCountries() {
      //TODO needs to be refactored or extracted to a shared function/class
      const defaultSelected = this.$store.getters['getVisitor']
      const language = localStorage.getItem('language')
      let code = language === undefined ? 'en' : language
      const countries = this.$store.getters['getCountries']
      let options = []
      for (let i = 0; i < countries.length; i++) {
        const country = countries[i]
        options.push({ value: country.id, text: country['name_' + code] })
      }
      if (code === 'en') {
        code = 'gb'
      }

      if (!this.$store.getters['getPaymentUser'].countryId) {
        this.localCountryId =
          (countries.find((c) => c.code === defaultSelected) && countries.find((c) => c.code === defaultSelected).id) ||
          1
        this.countryId =
          (countries.find((c) => c.code === defaultSelected) && countries.find((c) => c.code === defaultSelected).id) ||
          1
      } else {
        this.localCountryId =
          countries.find((c) => c.id === this.$store.getters['getPaymentUser'].countryId) &&
          countries.find((c) => c.id === this.$store.getters['getPaymentUser'].countryId).id
        this.countryId =
          countries.find((c) => c.id === this.$store.getters['getPaymentUser'].countryId) &&
          countries.find((c) => c.id === this.$store.getters['getPaymentUser'].countryId).id
      }

      this.options = options
    },
  },

  computed: {
    checked: {
      get() {
        return this.$store.getters['getHasNewBillingAddress']
      },
      set(value) {
        this.$store.dispatch('setHasNewBillingAddress', value)
      },
    },
    checkIfPosterSelected() {
      return this.$store.getters['getOrderExtraIds'].split(',').filter((o) => o > 2).length === 0
    },
  },
  async created() {
    this.getCountries()

    if (this.$store.getters['getCanOrderPFDOnly']) {
      const order = {
        id: this.$route.params.id,
        orderExtraIds: '1',
        paymentId: 0,
      }
      this.$store.dispatch('getCheckoutPDFOnly', order)
    }

    if (this.$store.getters['getPaymentUser'].countryId) {
      const option = this.options.find((o) => o.value === this.$store.getters['getPaymentUser'].countryId)

      const index = this.options.indexOf(option)
      this.options.splice(index, 1)
      this.options.unshift(option)
    }
    this.$store.dispatch('setCurrentPage', 'contact')
  },
}
</script>
