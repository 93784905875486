<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white"/>
    <path d="M11.9997 0C6.84013 0 2.44161 3.2565 0.746094 7.82611H23.2534C21.5578 3.2565 17.1593 0 11.9997 0Z" fill="#A2001D"/>
    <path d="M11.9997 24C17.1593 24 21.5578 20.5671 23.2533 15.75H0.746094C2.44161 20.5671 6.84012 24 11.9997 24Z" fill="#0052B4"/>
  </svg>
</template>

<script>
export default {
  name: 'Netherlands'
}
</script>