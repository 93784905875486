<template>
  <div class="navbar-container z-10">
    <Topbar :topbar="topbar" v-if="topbar.active" />
    <!--    {{$store.getters['getTopbarActive']}}-->
    <!--    {{topbar.active}}-->
    <b-navbar
      v-if="this.$store.getters['getLoadingDocument'] !== true"
      id="jilster_navbar"
      toggleable="lg"
      type="dark"
      variant="info"
      :class="{
        zIndex:
          this.$route.name === 'DocumentOverview' || this.$route.name === 'Orders' || this.$route.name === 'Settings',
      }"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <div class="navContent">
        <router-link :to="$route.path.includes('/document') ? '/' : '/'">
          <JilsterLogo class="jilster_logo" @click.native="resetDocumentOverview" />
        </router-link>

        <OrderStepWizard
          v-if="
            this.$route.name === 'OrderCheckout' &&
            this.$route.query.confirmation !== 'true' &&
            $store.getters['getShowDots']
          "
        />

        <div
          class="document-menu-header icons"
          v-if="
            (this.$route.name === 'DocumentOverview' && this.$store.getters['getDocumentRole'] !== 3) ||
            (this.$route.name === 'DocumentOverview' && this.$store.getters['getEditorPreview'])
          "
        >
          <div
            v-b-tooltip.hover
            title="Document view"
            class="view-as-project viewIcon"
            :class="{ itemActive: !this.$store.getters['getViewedAsCreation'] }"
            @click="$store.dispatch('setViewedAsCreationStatus', false)"
          >
            <i class="icon-book"></i>
          </div>
          <div
            v-b-tooltip.hover
            title="Page view"
            class="view-as-creation viewIcon"
            :class="{ itemActive: this.$store.getters['getViewedAsCreation'] }"
            @click="$store.dispatch('setViewedAsCreationStatus', true)"
          >
            <i class="icon-page"></i>
          </div>
        </div>
        <div class="document-menu-header closeWrapper" @click="resetDocumentOverview">
          <router-link
            to="/"
            class="closeIcon"
            v-if="this.$route.name === 'DocumentOverview' || this.$route.name === 'NewDesign'"
          >
            <i class="icon-close closing-icon" style="display: flex"></i>
          </router-link>
        </div>
      </div>

      <span
        v-if="
          this.$route.name !== 'DocumentOverview' &&
          this.$route.name !== 'NewDesign' &&
          this.$route.name !== 'OrderCheckout' &&
          this.$route.name !== 'PreparingTemplate'
        "
      >
        <Button
          :button-text="$t('make.a.design')"
          button-type="primary"
          :height-auto="true"
          class="whitespace-nowrap"
          @click.native="$emit('on-click')"
        />
      </span>

      <b-collapse
        id="nav-collapse"
        is-nav
        v-if="
          this.$route.name !== 'DocumentOverview' &&
          this.$route.name !== 'NewDesign' &&
          this.$route.name !== 'OrderCheckout' &&
          this.$route.name !== 'PreparingTemplate'
        "
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <div class="user_nav mb-2" v-if="$store.getters['getLoggedInUser']">
                <button
                  class="ml-2 h-9 w-9 rounded-full focus:outline-none text-2xl font-medium flex justify-center items-center"
                  :style="getColors"
                >
                  {{
                    ($store.getters['getLoggedInUser'].firstName &&
                      $store.getters['getLoggedInUser'].firstName[0].toUpperCase()) ||
                    ($store.getters['getLoggedInUser'].nickName &&
                      $store.getters['getLoggedInUser'].nickName[0].toUpperCase()) ||
                    ''
                  }}
                </button>
              </div>
            </template>
            <b-dropdown-item class="dropdown-item-email" v-if="$store.getters['getLoggedInUser']">
              <p>{{ $store.getters['getLoggedInUser'].email }}</p>
            </b-dropdown-item>
            <b-dropdown-item class="menu-option-item" href="/orders">
              <i class="icon-download"></i>
              <span>{{ $t('orders.header.title') }}</span>
            </b-dropdown-item>
            <b-dropdown-item class="menu-option-item" @click="logout">
              <i class="icon-logout"></i>
              <span>{{ $t('auth.logout') }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import JilsterLogo from '../vectors/JilsterLogo'
import OrderStepWizard from '@/domains/order/OrderStepWizard'
import Topbar from './topbar/Topbar'
import Button from './Button'

export default {
  name: 'Navbar',
  components: {
    Topbar,
    OrderStepWizard,
    JilsterLogo,
    Button,
  },
  computed: {
    topbar() {
      const settings = this.$store.getters['getSettings']
      if (settings.length !== 0) {
        const topbar = settings.find((item) => item.name === 'SYSTEM_URGENT_MESSAGE')

        if (topbar === undefined) {
          this.$store.commit('SET_TOPBARACTIVE', false)
          return false
        }
        this.$store.commit('SET_TOPBARACTIVE', topbar.active)
        return topbar ?? false
      }
      this.$store.commit('SET_TOPBARACTIVE', false)
      return false
    },

    getColors() {
      // generate light random color
      const r = Math.floor(Math.random() * 250) + 150
      const g = Math.floor(Math.random() * 250) + 150
      const b = Math.floor(Math.random() * 250) + 150

      return `background-color: rgb(${r}, ${g}, ${b}); color: black;`
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    async resetDocumentOverview() {
      await this.$store.dispatch('clearDocumentsFromApi')
    },
  },
  async created() {
    await this.$store.dispatch('getCurrentMaker')
  },
}
</script>

<style scoped></style>
