import httpClient from "../../../httpClient";

const INVITATION_END_POINT = "v3/invitation";
const INVITE_END_POINT = "v3/invite";

const acceptInvitation = (id, token) => httpClient.post(`${INVITATION_END_POINT}/${id}/${token}`);

const getInvitation = (token) => httpClient.get(`${INVITE_END_POINT}/${token}`)

const getMyInvitations = () => httpClient.get(`${INVITATION_END_POINT}/invitations`);

const declineInvitation = (token) => httpClient.delete(`${INVITATION_END_POINT}/${token}`);

export {
    acceptInvitation,
    getInvitation,
    getMyInvitations,
    declineInvitation
};
