<template>
  <div class="title-popup">
    <h3>{{ $t('start_creation') }}</h3>
    <div class="btn-container">
      <Input
        v-model="title"
        name="title"
        type="text"
        placeholder-text="For ex. Sophie’s birthday"
        autocomplete="off"
        :class="{ errorBorder: this.errorStatus, 'input-custom': title }"
        @on-press-enter="changeTitle"
        @input="handleInput"
      />
      <button
        v-show="showCrossBtn"
        class="p-0 border-0 bg-transparent position-absolute btn-position"
        @click="title = ''"
      >
        <svg width="16" height="15" class="w-4" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.32812 8L14.4062 2.96094C14.8229 2.51823 14.8229 2.0625 14.4062 1.59375C13.9375 1.17708 13.4818 1.17708 13.0391 1.59375L8 6.67188L2.96094 1.59375C2.49219 1.17708 2.03646 1.17708 1.59375 1.59375C1.17708 2.0625 1.17708 2.51823 1.59375 2.96094L6.67188 8L1.59375 13.0391C1.17708 13.4818 1.17708 13.9375 1.59375 14.4062C1.82812 14.5885 2.0625 14.6797 2.29688 14.6797C2.50521 14.6797 2.72656 14.5885 2.96094 14.4062L8 9.32812L13.0391 14.4062C13.2734 14.5885 13.4948 14.6797 13.7031 14.6797C13.9375 14.6797 14.1719 14.5885 14.4062 14.4062C14.8229 13.9375 14.8229 13.4818 14.4062 13.0391L9.32812 8Z"
            fill="#111111"
          />
        </svg>
      </button>
    </div>
    <Error icon="warning" icon-color="#C23934" :errorMessage="$t('add_title')" v-if="errorStatus" />
    <Button @click.native="changeTitle" button-text="Save" button-type="hint" />

    <div v-if="infoModalContent" class="mt-12">
      <h3 class="text-xl font-bold text-wg-light-accent">{{ infoModalContent.title }}</h3>
      <div
        class="text-wg-80 text-base md-body mt-4"
        v-html="showMore ? infoModalContent.description : getDescription(infoModalContent.description)"
      ></div>

      <div class="mt-4 flex justify-start">
        <span
          v-if="!showMore"
          class="text-wg-light-accent hover:text-wg-light-accent-hover underline cursor-pointer"
          @click="showMore = true"
          >Learn more</span
        >
        <span
          v-else
          class="text-wg-light-accent hover:text-wg-light-accent-hover underline cursor-pointer"
          @click="showMore = false"
          >Show less</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Input from './Input'
import Button from './Button'
import { required } from 'vuelidate/lib/validators'
import Error from '@/components/Error'

export default {
  name: 'TitlePopup',
  components: { Button, Input, Error },
  data() {
    return {
      title: '',
      errorStatus: false,
      showMore: false,
      showCrossBtn: false,
      infoModalContent: null,
      startCreationMagazine: {
        infoModalTitle: this.$t('start.magazine.modal.add.title'),
        infoModalDescription: this.$t('start.magazine.modal.info.description'),
      },
      startCreationBook: {
        infoModalTitle: this.$t('start.book.modal.add.title'),
        infoModalDescription: this.$t('start.book.modal.info.description'),
      },
      startCreationGlossy: {
        infoModalTitle: this.$t('start.glossy.modal.add.title'),
        infoModalDescription: this.$t('start.glossy.modal.info.description'),
      },
    }
  },

  computed: {
    magazine() {
      console.log(this.$store.getters['getPagesMagazine'])
      return this.$store.getters['getPagesMagazine']
    },

    getDescription() {
      return (description) => {
        if (!description) {
          return ''
        }

        let text = ''

        // There are li tags in the description. Just show first 2 li tags
        const liTags = description.match(/<li>(.*?)<\/li>/g)

        if (liTags && liTags.length > 2) {
          text = liTags.slice(0, 2).join('')
        }

        text = `<ul>${text}</ul>`

        console.log(text)

        return text
      }
    },
  },

  watch: {
    title(val) {
      if (val) {
        this.showCrossBtn = true
      } else {
        this.showCrossBtn = false
      }
    },
  },

  methods: {
    async changeTitle() {
      if (!this.title) {
        this.errorStatus = true
      } else {
        const payload = {
          id: this.$route.params.id,
          data: {
            title: this.title,
          },
        }
        await this.$store.dispatch('updateCreationTitle', payload)
        await this.$store.dispatch('setDocuments', this.$route.params.id)
      }
    },

    handleInput() {
      this.errorStatus = false
    },
  },
  validations: {
    title: {
      required,
    },
  },

  mounted() {
    if (this.magazine && this.magazine.canvasVersion === 'glossy') {
      this.infoModalContent = {
        title: this.startCreationGlossy.infoModalTitle,
        description: this.startCreationGlossy.infoModalDescription,
      }
    }
  },
}
</script>

<style scoped>
.btn-position {
  right: 0.75rem;
  top: 0;
  transform: translateY(40%);

  margin: auto;
}

.btn-container {
  position: relative;
}

.input-custom >>> input {
  padding-right: 2.25rem;
  width: 100%;
}

.md-body >>> ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.md-body >>> ul li ul {
  list-style-type: circle;
  padding-left: 2rem !important;
}
</style>
