<template>
  <svg width="180" height="52" viewBox="0 0 180 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="180" height="52" rx="6" fill="white"/>
    <path d="M38.5837 24.3046C38.5994 23.0872 38.9228 21.8935 39.5238 20.8346C40.1247 19.7757 40.9838 18.886 42.021 18.2483C41.3621 17.3073 40.4929 16.5329 39.4823 15.9865C38.4717 15.4402 37.3477 15.137 36.1994 15.1011C33.7501 14.844 31.3755 16.5668 30.1273 16.5668C28.855 16.5668 26.9333 15.1266 24.8639 15.1692C23.5254 15.2125 22.2208 15.6017 21.0775 16.299C19.9341 16.9963 18.9908 17.9779 18.3396 19.1481C15.5186 24.0322 17.6228 31.2102 20.3251 35.1581C21.6771 37.0913 23.2572 39.2507 25.3247 39.1741C27.3479 39.0902 28.1035 37.884 30.5457 37.884C32.9652 37.884 33.6741 39.1741 35.7836 39.1254C37.9546 39.0902 39.3224 37.1837 40.627 35.2322C41.5984 33.8547 42.3459 32.3323 42.8418 30.7214C41.5805 30.1879 40.5041 29.295 39.7469 28.1539C38.9897 27.0128 38.5852 25.6741 38.5837 24.3046Z" fill="black"/>
    <path d="M34.5993 12.5047C35.783 11.0837 36.3662 9.25722 36.2249 7.41318C34.4165 7.60312 32.746 8.46744 31.5463 9.83392C30.9597 10.5015 30.5105 11.2781 30.2242 12.1194C29.938 12.9607 29.8203 13.8501 29.878 14.7369C30.7826 14.7462 31.6775 14.5502 32.4953 14.1635C33.3131 13.7769 34.0325 13.2097 34.5993 12.5047Z" fill="black"/>
    <path d="M64.1585 34.2801H57.2543L55.5962 39.1759H52.6719L59.2115 21.0627H62.2498L68.7894 39.1759H65.8151L64.1585 34.2801ZM57.9693 32.0209H63.4421L60.7442 24.0754H60.6687L57.9693 32.0209Z" fill="black"/>
    <path d="M82.9128 32.5736C82.9128 36.6774 80.7163 39.314 77.4016 39.314C76.562 39.3579 75.7269 39.1645 74.9921 38.7559C74.2572 38.3473 73.6523 37.74 73.2466 37.0036H73.1838V43.5446H70.4731V25.9699H73.097V28.1664H73.1469C73.5712 27.4335 74.1864 26.8293 74.9268 26.4182C75.6673 26.0071 76.5053 25.8044 77.3518 25.8317C80.7034 25.8317 82.9128 28.4812 82.9128 32.5736ZM80.1266 32.5736C80.1266 29.8999 78.7449 28.1422 76.6367 28.1422C74.5656 28.1422 73.1725 29.937 73.1725 32.5736C73.1725 35.2344 74.5656 37.0164 76.6367 37.0164C78.7449 37.0164 80.1266 35.2715 80.1266 32.5736Z" fill="black"/>
    <path d="M97.4475 32.5736C97.4475 36.6775 95.251 39.3141 91.9363 39.3141C91.0967 39.358 90.2616 39.1646 89.5267 38.756C88.7919 38.3473 88.187 37.7401 87.7812 37.0036H87.7185V43.5446H85.0078V25.97H87.6316V28.1664H87.6815C88.1059 27.4335 88.721 26.8294 89.4615 26.4183C90.2019 26.0072 91.0399 25.8045 91.8864 25.8318C95.2382 25.8318 97.4475 28.4812 97.4475 32.5736ZM94.6613 32.5736C94.6613 29.9 93.2795 28.1422 91.1714 28.1422C89.1003 28.1422 87.7072 29.937 87.7072 32.5736C87.7072 35.2345 89.1003 37.0165 91.1714 37.0165C93.2796 37.0165 94.6613 35.2715 94.6613 32.5736H94.6613Z" fill="black"/>
    <path d="M107.054 34.1291C107.255 35.9254 109 37.1047 111.384 37.1047C113.669 37.1047 115.313 35.9253 115.313 34.3057C115.313 32.8998 114.322 32.058 111.974 31.481L109.627 30.9155C106.301 30.1122 104.757 28.5567 104.757 26.0326C104.757 22.9073 107.48 20.7607 111.347 20.7607C115.175 20.7607 117.799 22.9073 117.887 26.0326H115.151C114.987 24.2249 113.493 23.1338 111.309 23.1338C109.125 23.1338 107.631 24.2378 107.631 25.8445C107.631 27.1251 108.585 27.8786 110.92 28.4555L112.916 28.9455C116.632 29.8244 118.176 31.3172 118.176 33.9666C118.176 37.3554 115.477 39.4778 111.184 39.4778C107.167 39.4778 104.455 37.4052 104.279 34.129L107.054 34.1291Z" fill="black"/>
    <path d="M124.026 22.8447V25.9699H126.537V28.1166H124.026V35.3969C124.026 36.5279 124.529 37.0549 125.633 37.0549C125.931 37.0497 126.229 37.0288 126.524 36.9922V39.126C126.028 39.2187 125.524 39.2607 125.019 39.2514C122.345 39.2514 121.302 38.2471 121.302 35.686V28.1166H119.382V25.9699H121.302V22.8447H124.026Z" fill="black"/>
    <path d="M127.992 32.5736C127.992 28.4186 130.439 25.8076 134.255 25.8076C138.084 25.8076 140.52 28.4185 140.52 32.5736C140.52 36.7401 138.097 39.3397 134.255 39.3397C130.415 39.3397 127.992 36.7401 127.992 32.5736ZM137.758 32.5736C137.758 29.7233 136.451 28.0411 134.255 28.0411C132.058 28.0411 130.754 29.7362 130.754 32.5736C130.754 35.4353 132.058 37.1048 134.255 37.1048C136.451 37.1048 137.758 35.4353 137.758 32.5736H137.758Z" fill="black"/>
    <path d="M142.755 25.97H145.34V28.2177H145.403C145.578 27.5157 145.989 26.8953 146.568 26.4608C147.146 26.0263 147.857 25.8042 148.579 25.8318C148.892 25.8307 149.203 25.8646 149.508 25.9329V28.4684C149.114 28.3479 148.702 28.2926 148.29 28.3046C147.896 28.2886 147.504 28.3581 147.139 28.5082C146.775 28.6582 146.447 28.8854 146.179 29.174C145.911 29.4627 145.708 29.806 145.585 30.1804C145.462 30.5547 145.421 30.9513 145.466 31.3429V39.1759H142.755L142.755 25.97Z" fill="black"/>
    <path d="M162.006 35.2972C161.641 37.6945 159.307 39.3397 156.319 39.3397C152.478 39.3397 150.093 36.7657 150.093 32.6363C150.093 28.4941 152.491 25.8076 156.206 25.8076C159.859 25.8076 162.157 28.3174 162.157 32.3215V33.2502H152.83V33.414C152.787 33.9001 152.847 34.3897 153.008 34.8504C153.169 35.3111 153.426 35.7323 153.762 36.0861C154.098 36.4399 154.505 36.7181 154.957 36.9023C155.409 37.0866 155.895 37.1726 156.382 37.1546C157.023 37.2146 157.666 37.0663 158.215 36.7316C158.765 36.3969 159.191 35.8938 159.432 35.2971L162.006 35.2972ZM152.842 31.3557H159.445C159.469 30.9187 159.403 30.4814 159.25 30.0713C159.097 29.6611 158.862 29.2869 158.557 28.9722C158.253 28.6574 157.887 28.409 157.483 28.2424C157.078 28.0758 156.643 27.9946 156.206 28.004C155.764 28.0014 155.327 28.0862 154.918 28.2536C154.51 28.4209 154.138 28.6676 153.826 28.9792C153.513 29.2908 153.265 29.6612 153.096 30.0691C152.928 30.477 152.841 30.9143 152.842 31.3558V31.3557Z" fill="black"/>
    <path d="M57.6296 7.42884C58.1979 7.38806 58.7681 7.47392 59.2992 7.68021C59.8303 7.88651 60.309 8.20811 60.7008 8.62181C61.0926 9.03551 61.3876 9.53101 61.5647 10.0725C61.7418 10.6141 61.7965 11.1882 61.7248 11.7534C61.7248 14.5339 60.222 16.1322 57.6296 16.1322H54.4858V7.42884H57.6296ZM55.8376 14.9014H57.4786C57.8847 14.9256 58.291 14.8591 58.6682 14.7065C59.0453 14.5539 59.3836 14.3191 59.6586 14.0193C59.9335 13.7194 60.1381 13.3621 60.2575 12.9732C60.3769 12.5843 60.4081 12.1736 60.3488 11.7712C60.4038 11.3703 60.3695 10.9622 60.2482 10.5762C60.127 10.1901 59.9219 9.83566 59.6475 9.53821C59.3732 9.24075 59.0365 9.00765 58.6615 8.85561C58.2865 8.70357 57.8826 8.63635 57.4786 8.65877H55.8376V14.9014Z" fill="black"/>
    <path d="M63.252 12.8452C63.2107 12.4136 63.26 11.9781 63.3969 11.5667C63.5338 11.1552 63.7552 10.7769 64.0468 10.4561C64.3384 10.1352 64.6939 9.87882 65.0904 9.70338C65.487 9.52795 65.9158 9.43732 66.3494 9.43732C66.783 9.43732 67.2118 9.52795 67.6083 9.70338C68.0049 9.87882 68.3604 10.1352 68.652 10.4561C68.9436 10.7769 69.165 11.1552 69.3019 11.5667C69.4387 11.9781 69.4881 12.4136 69.4468 12.8452C69.4889 13.2773 69.4401 13.7134 69.3036 14.1255C69.1671 14.5376 68.9459 14.9166 68.6541 15.2381C68.3624 15.5596 68.0067 15.8165 67.6098 15.9923C67.2128 16.1681 66.7835 16.2589 66.3494 16.2589C65.9153 16.2589 65.486 16.1681 65.089 15.9923C64.6921 15.8165 64.3364 15.5596 64.0446 15.2381C63.7529 14.9166 63.5317 14.5376 63.3952 14.1255C63.2587 13.7134 63.2099 13.2773 63.252 12.8452ZM68.1135 12.8452C68.1135 11.4215 67.474 10.5889 66.3515 10.5889C65.2248 10.5889 64.591 11.4215 64.591 12.8452C64.591 14.2804 65.2248 15.1065 66.3515 15.1065C67.474 15.1065 68.1136 14.2746 68.1136 12.8452H68.1135Z" fill="black"/>
    <path d="M77.6816 16.132H76.337L74.9795 11.2946H74.8769L73.5251 16.132H72.1933L70.3828 9.56394H71.6976L72.8742 14.5758H72.971L74.3214 9.56394H75.5649L76.9153 14.5758H77.0178L78.1887 9.56394H79.485L77.6816 16.132Z" fill="black"/>
    <path d="M81.0073 9.56397H82.2551V10.6074H82.352C82.5163 10.2326 82.7935 9.91846 83.1448 9.70871C83.4961 9.49895 83.9042 9.40403 84.312 9.43718C84.6316 9.41315 84.9525 9.46134 85.2509 9.57817C85.5494 9.695 85.8177 9.87748 86.036 10.1121C86.2543 10.3467 86.4171 10.6274 86.5122 10.9335C86.6073 11.2395 86.6323 11.563 86.5854 11.8801V16.132H85.2892V12.2056C85.2892 11.1501 84.8305 10.6252 83.8719 10.6252C83.6549 10.6151 83.4383 10.652 83.2369 10.7334C83.0355 10.8149 82.8542 10.9389 82.7052 11.097C82.5562 11.2551 82.4432 11.4435 82.3739 11.6494C82.3046 11.8552 82.2806 12.0736 82.3036 12.2896V16.132H81.0073L81.0073 9.56397Z" fill="black"/>
    <path d="M88.6509 6.99996H89.9471V16.132H88.6509V6.99996Z" fill="black"/>
    <path d="M91.749 12.8452C91.7077 12.4135 91.7571 11.978 91.894 11.5666C92.031 11.1551 92.2524 10.7768 92.544 10.456C92.8357 10.1351 93.1912 9.87869 93.5878 9.70325C93.9843 9.52781 94.4132 9.43719 94.8468 9.43719C95.2804 9.43719 95.7093 9.52781 96.1058 9.70325C96.5024 9.87869 96.8579 10.1351 97.1496 10.456C97.4412 10.7768 97.6626 11.1551 97.7995 11.5666C97.9364 11.978 97.9858 12.4135 97.9446 12.8452C97.9866 13.2773 97.9378 13.7134 97.8012 14.1255C97.6647 14.5376 97.4434 14.9166 97.1516 15.2381C96.8599 15.5595 96.5041 15.8165 96.1072 15.9923C95.7102 16.1681 95.2809 16.2589 94.8468 16.2589C94.4127 16.2589 93.9833 16.1681 93.5864 15.9923C93.1894 15.8165 92.8337 15.5595 92.5419 15.2381C92.2502 14.9166 92.0289 14.5376 91.8924 14.1255C91.7558 13.7134 91.707 13.2773 91.749 12.8452ZM96.6106 12.8452C96.6106 11.4215 95.971 10.5889 94.8486 10.5889C93.7218 10.5889 93.088 11.4215 93.088 12.8452C93.088 14.2803 93.7219 15.1065 94.8486 15.1065C95.971 15.1065 96.6106 14.2746 96.6106 12.8452H96.6106Z" fill="black"/>
    <path d="M99.3091 14.2746C99.3091 13.0923 100.189 12.4107 101.752 12.3138L103.531 12.2113V11.6443C103.531 10.9506 103.072 10.5589 102.186 10.5589C101.463 10.5589 100.961 10.8246 100.818 11.289H99.5626C99.6951 10.1608 100.756 9.43719 102.246 9.43719C103.893 9.43719 104.822 10.2569 104.822 11.6443V16.132H103.574V15.209H103.471C103.263 15.5401 102.971 15.81 102.624 15.9912C102.278 16.1724 101.889 16.2583 101.498 16.2402C101.223 16.2689 100.944 16.2395 100.68 16.1539C100.417 16.0683 100.174 15.9284 99.9676 15.7432C99.7614 15.558 99.5962 15.3316 99.4828 15.0786C99.3693 14.8257 99.3102 14.5518 99.3091 14.2746ZM103.531 13.7133V13.1642L101.927 13.2668C101.023 13.3273 100.612 13.635 100.612 14.214C100.612 14.8052 101.125 15.1492 101.83 15.1492C102.037 15.1701 102.246 15.1492 102.444 15.0878C102.642 15.0264 102.826 14.9257 102.985 14.7918C103.144 14.6578 103.274 14.4933 103.368 14.308C103.462 14.1228 103.517 13.9205 103.531 13.7133Z" fill="black"/>
    <path d="M106.525 12.8452C106.525 10.7698 107.592 9.455 109.252 9.455C109.662 9.43608 110.07 9.5344 110.426 9.73845C110.783 9.9425 111.074 10.2439 111.266 10.6073H111.363V6.99996H112.659V16.132H111.417V15.0943H111.314C111.108 15.4553 110.806 15.7529 110.443 15.9549C110.079 16.1569 109.667 16.2556 109.252 16.2403C107.581 16.2403 106.525 14.9255 106.525 12.8452ZM107.864 12.8452C107.864 14.2383 108.521 15.0765 109.619 15.0765C110.712 15.0765 111.387 14.2262 111.387 12.8509C111.387 11.482 110.705 10.6195 109.619 10.6195C108.528 10.6195 107.864 11.4634 107.864 12.8452H107.864Z" fill="black"/>
    <path d="M118.022 12.8452C117.981 12.4136 118.03 11.9781 118.167 11.5667C118.304 11.1552 118.525 10.7769 118.817 10.4561C119.108 10.1352 119.464 9.87882 119.86 9.70338C120.257 9.52795 120.686 9.43732 121.119 9.43732C121.553 9.43732 121.982 9.52795 122.378 9.70338C122.775 9.87882 123.13 10.1352 123.422 10.4561C123.714 10.7769 123.935 11.1552 124.072 11.5667C124.209 11.9781 124.258 12.4136 124.217 12.8452C124.259 13.2773 124.21 13.7134 124.074 14.1255C123.937 14.5376 123.716 14.9166 123.424 15.2381C123.132 15.5596 122.777 15.8165 122.38 15.9923C121.983 16.1681 121.554 16.2589 121.119 16.2589C120.685 16.2589 120.256 16.1681 119.859 15.9923C119.462 15.8165 119.106 15.5596 118.815 15.2381C118.523 14.9166 118.302 14.5376 118.165 14.1255C118.029 13.7134 117.98 13.2773 118.022 12.8452ZM122.884 12.8452C122.884 11.4215 122.244 10.5889 121.122 10.5889C119.995 10.5889 119.361 11.4215 119.361 12.8452C119.361 14.2804 119.995 15.1065 121.122 15.1065C122.244 15.1065 122.884 14.2746 122.884 12.8452Z" fill="black"/>
    <path d="M125.956 9.56397H127.204V10.6074H127.301C127.465 10.2326 127.742 9.91846 128.094 9.70871C128.445 9.49895 128.853 9.40403 129.261 9.43718C129.58 9.41315 129.901 9.46134 130.2 9.57817C130.498 9.695 130.766 9.87748 130.985 10.1121C131.203 10.3467 131.366 10.6274 131.461 10.9335C131.556 11.2395 131.581 11.563 131.534 11.8801V16.132H130.238V12.2056C130.238 11.1501 129.779 10.6252 128.821 10.6252C128.604 10.6151 128.387 10.652 128.186 10.7334C127.984 10.8149 127.803 10.9389 127.654 11.097C127.505 11.2551 127.392 11.4435 127.323 11.6494C127.253 11.8552 127.229 12.0736 127.252 12.2896V16.132H125.956V9.56397Z" fill="black"/>
    <path d="M138.859 7.9287V9.59386H140.282V10.6857H138.859V14.063C138.859 14.751 139.142 15.0523 139.788 15.0523C139.953 15.0518 140.118 15.0418 140.282 15.0224V16.1021C140.049 16.1438 139.813 16.1659 139.577 16.1683C138.135 16.1683 137.561 15.6612 137.561 14.3949V10.6856H136.519V9.5938H137.561V7.9287H138.859Z" fill="black"/>
    <path d="M142.052 6.99996H143.337V10.6195H143.44C143.612 10.2412 143.897 9.92528 144.255 9.71473C144.613 9.50419 145.028 9.40925 145.442 9.44287C145.76 9.42557 146.078 9.47865 146.373 9.59832C146.668 9.71799 146.933 9.9013 147.149 10.1352C147.365 10.369 147.527 10.6477 147.623 10.9513C147.719 11.2549 147.747 11.5759 147.704 11.8915V16.132H146.407V12.2113C146.407 11.1622 145.918 10.6308 145.002 10.6308C144.78 10.6126 144.555 10.6432 144.346 10.7206C144.136 10.798 143.946 10.9202 143.788 11.0788C143.631 11.2374 143.51 11.4286 143.434 11.6388C143.358 11.849 143.329 12.0733 143.349 12.296V16.132H142.052L142.052 6.99996Z" fill="black"/>
    <path d="M155.263 14.3587C155.086 14.9589 154.705 15.4782 154.185 15.8255C153.664 16.1729 153.038 16.3263 152.417 16.2588C151.984 16.2703 151.554 16.1874 151.156 16.016C150.759 15.8445 150.403 15.5887 150.115 15.2661C149.826 14.9435 149.611 14.562 149.485 14.148C149.358 13.7341 149.323 13.2975 149.383 12.8687C149.325 12.4386 149.36 12.0011 149.486 11.5859C149.613 11.1706 149.827 10.7873 150.114 10.462C150.401 10.1366 150.755 9.8767 151.151 9.6999C151.547 9.52311 151.977 9.43353 152.411 9.43723C154.238 9.43723 155.341 10.6858 155.341 12.7483V13.2006H150.703V13.2732C150.683 13.5143 150.713 13.7569 150.792 13.9855C150.871 14.2142 150.997 14.4238 151.161 14.6009C151.326 14.7781 151.526 14.9189 151.748 15.0143C151.971 15.1097 152.21 15.1576 152.452 15.1549C152.762 15.1921 153.076 15.1363 153.355 14.9945C153.633 14.8527 153.863 14.6313 154.015 14.3586L155.263 14.3587ZM150.703 12.2419H154.02C154.037 12.0215 154.007 11.8001 153.932 11.5919C153.858 11.3837 153.741 11.1934 153.589 11.0332C153.437 10.8729 153.252 10.7463 153.048 10.6615C152.844 10.5766 152.625 10.5354 152.404 10.5405C152.18 10.5376 151.957 10.5797 151.749 10.6643C151.542 10.7488 151.353 10.874 151.195 11.0326C151.036 11.1912 150.911 11.3799 150.826 11.5876C150.742 11.7953 150.7 12.0178 150.703 12.2419H150.703Z" fill="black"/>
  </svg>

</template>

<script>
export default {
  name: "AppStore"
}
</script>
