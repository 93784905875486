<template>
  <div class="modal-unnominate">
    <div
      class="overlay"
      @click="
        $store.commit('SET_SHOW_UNNOMINATE_MODAL', false)
        $store.commit('SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL', false)
      "
    ></div>

    <div class="modal-unnominate-content">
      <div class="animation">
        <lottie-vue-player
          :src="`/lottie/ok.json`"
          :player-size="'standard'"
          :minimizable="false"
          :player-controls="false"
          :autoplay="true"
          :loop="false"
          :speed="1"
          style="width: 100%; height: 200px"
        />
      </div>

      <h2>{{ $t('magazine.award.unnominate.confirmation.modal.headline') }}</h2>

      <p>{{ $t('magazine.award.unnominate.confirmation.modal.text') }}</p>

      <button
        class="close-btn"
        @click="
          $store.commit('SET_SHOW_UNNOMINATE_MODAL', false)
          $store.commit('SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL', false)
        "
      >
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.6562 15L27.8125 4.92188C28.6458 4.03646 28.6458 3.125 27.8125 2.1875C26.875 1.35417 25.9635 1.35417 25.0781 2.1875L15 12.3438L4.92188 2.1875C3.98438 1.35417 3.07292 1.35417 2.1875 2.1875C1.35417 3.125 1.35417 4.03646 2.1875 4.92188L12.3438 15L2.1875 25.0781C1.35417 25.9635 1.35417 26.875 2.1875 27.8125C2.65625 28.1771 3.125 28.3594 3.59375 28.3594C4.01042 28.3594 4.45312 28.1771 4.92188 27.8125L15 17.6562L25.0781 27.8125C25.5469 28.1771 25.9896 28.3594 26.4062 28.3594C26.875 28.3594 27.3438 28.1771 27.8125 27.8125C28.6458 26.875 28.6458 25.9635 27.8125 25.0781L17.6562 15Z"
            fill="#999187"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

import Error from '@/components/Error'

Vue.use(LottieVuePlayer)

export default {}
</script>

<style scoped>
.modal-unnominate {
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.modal-unnominate-content {
  background: white;
  border-radius: 16px;
  max-width: 724px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  padding: 57px 162px;
}

.modal-unnominate-content h2 {
  color: #2a65c4;
  font-size: 24px;
  font-weight: bold;
}

.modal-unnominate-content p {
  color: #534c45;
  font-size: 18px;
  font-weight: normal;
}

.modal-unnominate-content .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 32px;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-unnominate-content .start-btn {
  background-color: #2a65c4;
  color: white;
  border-radius: 4px;
  padding: 10px 35px;
  margin-top: 2rem;
}

.modal-unnominate-content input {
  width: 100%;
  display: block;
  background: #f2f0ed;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 24px 12px;
  margin-top: 2rem;
}

.modal-unnominate-content input:focus {
  outline: none;
  border: none;
}
</style>
