import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../domains/Home.vue'

import authRoutes from '@/domains/auth/authRoutes'
import homeRoutes from '@/domains/home/homeRoutes'
import creationRoutes from '@/domains/creations/creationRoutes'
import userRoutes from '@/domains/user/userRoutes'
import orderRoutes from '@/domains/orders/orderRoutes'
import documentRoutes from '@/domains/document/documentRoutes'
import invitationRoutes from '@/domains/invitation/invitationRoutes'
import orderFlowRoutes from '@/domains/order/orderFlowRoutes'

import store from '@/store'

Vue.use(VueRouter)

const baseRoutes = []

const routes = baseRoutes.concat(
  authRoutes,
  creationRoutes,
  userRoutes,
  orderRoutes,
  documentRoutes,
  invitationRoutes,
  orderFlowRoutes,
  homeRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const startFromTemplate = to.query.startFromTemplate
  const templateId = to.query.templateId

  if (startFromTemplate && templateId && startFromTemplate === 'yes') {
    store.commit('SET_START_FROM_TEMPLATE', true)
    store.commit('SET_TEMPLATE_ID', templateId)
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jilster_token') === null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
