<template>
  <div id="order-review">
    <h2>{{ $t('checkout.review') }}</h2>
    <div class="d-flex review-item">
      <p class="infoItem">{{ $t('checkout.contact') }}</p>
      <p class="info">{{ $store.getters['getLoggedInUser'].email }}</p>
    </div>
    <div
      class="d-flex justify-content-between review-item"
      v-if="
        (!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']) ||
        (this.$store.getters['getCanOrderExtrasOnly'] && !checkIfPosterSelected)
      "
    >
      <div class="d-flex">
        <p class="infoItem">{{ $t('checkout.ship.to') }}</p>
        <p style="width: 165px" class="info">
          <span class="shipInfo">{{
            $store.getters['getPaymentUser'].firstName + ' ' + $store.getters['getPaymentUser'].lastName
          }}</span>
          <span class="shipInfo">{{ $store.getters['getPaymentUser'].company }}</span>
          <span class="shipInfo">{{
            $store.getters['getPaymentUser'].street +
            ' ' +
            $store.getters['getPaymentUser'].houseNumber +
            $store.getters['getPaymentUser'].houseNumberSuffix
          }}</span>
          <span class="shipInfo">{{
            $store.getters['getPaymentUser'].postalCode + ' ' + $store.getters['getPaymentUser'].city
          }}</span>
          <span class="shipInfo">{{ getCountry($store.getters['getPaymentUser'].countryId) }}</span>
          <span class="shipInfo">{{
            $store.getters['getPaymentUser'].area + ' ' + $store.getters['getPaymentUser'].phonenumber
          }}</span>
        </p>
      </div>
      <p class="change-text" v-on:click="change('contact')">{{ $t('checkout.change') }}</p>
    </div>
    <div
      class="d-flex justify-content-between review-item"
      v-if="
        !this.$store.getters['getCanOrderPFDOnly'] &&
        !this.$store.getters['getCanOrderExtrasOnly'] &&
        !this.$store.getters['getIsLinda'] &&
        this.$store.getters['getVolume'] !== 0
      "
    >
      <div class="d-flex">
        <p class="infoItem">{{ $t('checkout.delivery') }}</p>
        <p style="width: 165px" class="info" v-html="getDeliveryDescription()"></p>
      </div>
      <p class="change-text" v-on:click="change('delivery')">{{ $t('checkout.change') }}</p>
    </div>
    <div class="d-flex justify-content-between review-item">
      <div class="d-flex">
        <p class="infoItem">{{ $t('checkout.payment') }}</p>
        <div class="d-flex">
          <Ideal v-if="$store.getters['getPayment'] === 1" />
          <Mastercard v-if="$store.getters['getPayment'] === 2" />
          <VisaCard v-if="$store.getters['getPayment'] === 3" />
          <american-express v-if="$store.getters['getPayment'] === 4" />
          <Bancontant v-if="$store.getters['getPayment'] === 5" />
          <paypal v-if="$store.getters['getPayment'] === 6" />
          <p v-if="$store.getters['getPayment'] === 1" class="payment-method-name info">iDeal</p>
          <p v-if="$store.getters['getPayment'] === 2" class="payment-method-name info">Mastercard</p>
          <p v-if="$store.getters['getPayment'] === 3" class="payment-method-name info">VisaCard</p>
          <p v-if="$store.getters['getPayment'] === 4" class="payment-method-name info">American Express</p>
          <p v-if="$store.getters['getPayment'] === 5" class="payment-method-name info">Bancontant</p>
          <p v-if="$store.getters['getPayment'] === 6" class="payment-method-name info">PayPal</p>
        </div>
      </div>
      <p class="change-text" v-on:click="change('payment')">{{ $t('checkout.change') }}</p>
    </div>
    <div class="d-flex justify-content-between review-item">
      <div class="d-flex">
        <p class="infoItem">{{ $t('checkout.billing.address') }}</p>
        <p
          style="width: 165px"
          class="info"
          v-if="
            !$store.getters['getNewBillingAddress'].street &&
            !$store.getters['getCanOrderPFDOnly'] &&
            !$store.getters['getCanOrderExtrasOnly']
          "
        >
          <span class="billingInfo">{{
            $store.getters['getPaymentUser'].firstName + ' ' + $store.getters['getPaymentUser'].lastName
          }}</span>
          <span class="billingInfo">{{
            $store.getters['getPaymentUser'].street +
            ' ' +
            $store.getters['getPaymentUser'].houseNumber +
            $store.getters['getPaymentUser'].houseNumberSuffix
          }}</span>
          <span class="billingInfo">{{
            $store.getters['getPaymentUser'].postalCode + ' ' + $store.getters['getPaymentUser'].city
          }}</span>
          <span class="billingInfo">{{ getCountry($store.getters['getPaymentUser'].countryId) }}</span>
        </p>
        <p style="width: 165px" class="info" v-else-if="$store.getters['getNewBillingAddress'].street">
          <span class="billingInfo">{{
            $store.getters['getNewBillingAddress'].firstName + ' ' + $store.getters['getNewBillingAddress'].lastName
          }}</span>
          <span class="billingInfo">{{
            $store.getters['getNewBillingAddress'].street +
            ' ' +
            $store.getters['getNewBillingAddress'].houseNumber +
            $store.getters['getNewBillingAddress'].houseNumberSuffix
          }}</span>
          <span class="billingInfo">{{
            $store.getters['getNewBillingAddress'].postalCode + ' ' + $store.getters['getNewBillingAddress'].city
          }}</span>
          <span class="billingInfo">{{ getCountry($store.getters['getNewBillingAddress'].countryId) }}</span>
        </p>
        <p style="width: 115px" v-else>-</p>
      </div>
      <p @click="$store.dispatch('setShowPopup', true)" class="change-text">{{ $t('checkout.change') }}</p>
    </div>
    <div class="d-flex return-text-container">
      <div class="d-flex" @click="$store.dispatch('setCurrentPage', 'payment')">
        <i class="icon-chevron-left"></i>
        <span class="return-text">{{ $t('checkout.return.to.payment') }}</span>
      </div>
      <Button @click.native="checkoutOrder" :button-text="$t('checkout.button.pay')" button-type="primary" />
    </div>
  </div>
</template>

<script>
import Ideal from '@/vectors/Ideal'
import Button from '@/components/Button'
import Mastercard from '@/vectors/Mastercard'
import VisaCard from '@/vectors/VisaCard'
import Bancontant from '@/vectors/Bancontant'
import AmericanExpress from '@/vectors/AmericanExpress'
import Paypal from '@/vectors/Paypal'
import { checkoutOrderOnlyExtras } from './orderCheckoutEndpoints'

export default {
  name: 'OrderReview',
  components: { Button, Paypal, AmericanExpress, Bancontant, VisaCard, Mastercard, Ideal },
  created() {
    this.$store.dispatch('setCurrentPage', 'review')
  },
  methods: {
    async checkoutOrder() {
      const isLinda = this.$store.getters['getIsLinda']
      const user = this.$store.getters['getLoggedInUser']
      const hasNewBillingAddress = this.$store.getters['getHasNewBillingAddress']
      const newBillingAddress = this.$store.getters['getNewBillingAddress']
      const paymentUser = this.$store.getters['getPaymentUser']

      let countryBillingId = 0
      if (isLinda) {
        if (hasNewBillingAddress) {
          countryBillingId = newBillingAddress.countryId
        } else {
          countryBillingId = 1
        }
      } else {
        if (hasNewBillingAddress) {
          countryBillingId = newBillingAddress.countryId
        } else {
          countryBillingId = paymentUser.countryId
        }
      }

      let order = {
        id: this.$route.params.id,
        orderExtraIds: this.$store.getters['getOrderExtraIds'],
        delivery_firstName: paymentUser.firstName,
        delivery_lastName: paymentUser.lastName,
        delivery_gender: newBillingAddress.gender,
        delivery_salutation: newBillingAddress.gender,
        delivery_street: paymentUser.street,
        delivery_houseNumber: parseInt(paymentUser.houseNumber) || 0,
        delivery_houseNumberSuffix: paymentUser.houseNumberSuffix,
        delivery_postalCode: paymentUser.postalCode,
        delivery_city: paymentUser.city,
        delivery_phone: paymentUser.area + ' ' + paymentUser.phonenumber,
        delivery_countryId: isLinda ? 1 : paymentUser.countryId,
        firstName: hasNewBillingAddress ? newBillingAddress.firstName : paymentUser.firstName,
        lastName: hasNewBillingAddress ? newBillingAddress.lastName : paymentUser.lastName,
        gender: paymentUser.gender ? paymentUser.gender : 'other',
        salutation: paymentUser.gender ? paymentUser.gender : 'other',
        street: hasNewBillingAddress ? newBillingAddress.street : paymentUser.street,
        houseNumber: hasNewBillingAddress
          ? parseInt(newBillingAddress.houseNumber)
          : parseInt(paymentUser.houseNumber) || 0,
        houseNumberSuffix: hasNewBillingAddress ? newBillingAddress.houseNumberSuffix : paymentUser.houseNumberSuffix,
        postalCode: hasNewBillingAddress ? newBillingAddress.postalCode : paymentUser.postalCode,
        city: hasNewBillingAddress ? newBillingAddress.city : paymentUser.city,
        phone: paymentUser.area + ' ' + paymentUser.phonenumber,
        paymentId: this.$store.getters['getPayment'],
        countryId: countryBillingId !== 0 ? countryBillingId : user.country_id,
        company: paymentUser.company ? paymentUser.company : '',
      }

      if (this.$store.getters['getCanOrderPFDOnly']) {
        const pdfOrder = {
          ...order,
          id: this.$route.params.id,
          orderExtraIds: '1',
          delivery_firstName: user.firstName,
          delivery_lastName: user.lastName,
          delivery_gender: newBillingAddress.gender,
          delivery_street: user.street,
          delivery_houseNumber: parseInt(user.houseNumber),
          delivery_houseNumberSuffix: paymentUser.houseNumberSuffix,
          delivery_postalCode: user.postalCode,
          delivery_city: user.city,
          delivery_phone: user.phoneNumber,
          delivery_countryId: user.country_id,
          firstName: hasNewBillingAddress ? newBillingAddress.firstName : paymentUser.firstName,
          lastName: hasNewBillingAddress ? newBillingAddress.lastName : paymentUser.lastName,
          gender: paymentUser.gender,
          street: hasNewBillingAddress ? newBillingAddress.street : user.street,
          houseNumber: hasNewBillingAddress ? parseInt(newBillingAddress.houseNumber) : parseInt(user.houseNumber),
          houseNumberSuffix: hasNewBillingAddress ? newBillingAddress.houseNumberSuffix : paymentUser.houseNumberSuffix,
          postalCode: hasNewBillingAddress ? newBillingAddress.postalCode : user.postalCode,
          city: hasNewBillingAddress ? newBillingAddress.city : user.city,
          phone: user.phoneNumber,
        }

        await this.$store.dispatch('createCheckoutPDFOnly', pdfOrder)
        return
      }

      const orderVolume = Number(this.$store.getters['getVolume'])

      if (orderVolume === 0) {
        order.volume = orderVolume
        const response = await checkoutOrderOnlyExtras(order)
        window.location.href = response.data.data
        return
      }

      order.printId = orderVolume === 0 ? 0 : this.$store.getters['getPrint']
      order.volume = orderVolume
      order.proof = this.$store.getters['getProof'] === 'true'
      order.useDeliveryAddress = false
      order.shipmentId = isLinda ? 1 : orderVolume === 0 ? 0 : parseInt(this.$store.getters['getShipment'])
      await this.$store.dispatch('checkoutOrder', order)
    },
    change(view) {
      this.$store.dispatch('setCurrentPage', view)
    },
    getCountry(id) {
      const countries = this.$store.getters['getCountries']

      const a = countries.find((c) => c.id === id)
      return (a && a.name_en) || ''
    },
    getDeliveryDescription() {
      const language = localStorage.getItem('language')

      const shippingId = this.$store.getters['getShipment']
      const shippingMethod = this.$store.getters['getShipmentMethods'].find((s) => s.id === shippingId)

      if (shippingMethod === null || language === null) {
        return '-'
      }

      return shippingMethod['name_' + language] + '<br />' + shippingMethod['description_' + language]
    },
  },
  computed: {
    checkIfPosterSelected() {
      return this.$store.getters['getOrderExtraIds'].split(',').filter((o) => o > 2).length === 0
    },
  },
}
</script>
