<template>
  <div class="stepwizard">
    <div class="stepwizard-row setup-panel">
      <div class="stepwizard-step col-xs-3">
        <a
            href="#step-1"
            type="button"
            class="btn btn-circle btn-order"
            v-if="checkstep"
        >
          <i v-if="checkstep" class="icon-ok"></i>
        </a>
        <a
            href="#step-1"
            type="button"
            class="btn btn-circle"
            :class="this.$store.getters['getCurrentPage'] === 'contact'? 'btn-order' : 'btn-order-future' || this.$store.getters['getCurrentPage'] === 'delivery'? 'btn-order' : 'btn-order-future'"
            v-else-if="!$store.getters['getIsLinda']"
        >
          <i v-if="this.$store.getters['getCurrentPage'] === 'contact'" class="icon-ok"></i>
        </a>
        <a
            href="#step-1"
            type="button"
            class="btn btn-circle"
            :class="this.$store.getters['getCurrentPage'] === 'contact'? 'btn-order' : 'btn-order-future' || this.$store.getters['getCurrentPage'] === 'delivery'? 'btn-order' : 'btn-order-future'"
            v-else
        >

          <i v-if="this.$store.getters['getCurrentPage'] === 'contact'" class="icon-ok"></i>
        </a>
        <p>{{$t("checkout.order")}}</p>
      </div>
      <div class="stepwizard-step col-xs-3">
        <a
            href="#step-2"
            type="button"
            class="btn btn-circle btn-order"
            v-if="checkstep"
        >
          <i v-if="checkstep" class="icon-ok"></i>
        </a>
        <a
            href="#step-1"
            type="button"
            class="btn btn-circle"
            :class="this.$store.getters['getCurrentPage'] === 'contact'? 'btn-order' : 'btn-order-future'"
            v-else-if="!$store.getters['getIsLinda']"
        >
          <i v-if="this.$store.getters['getCurrentPage'] === 'delivery'" class="icon-ok"></i>
        </a>
        <a
          v-else-if="$store.getters['getIsLinda'] && this.$store.getters['getCurrentPage'] === 'contact'"
          href="#step-2"
          type="button"
          class="btn  btn-circle"
          :class="this.$store.getters['getCurrentPage'] === 'contact'? 'btn-order' : 'btn-order-future'"
          disabled="disabled">
          <i v-if="this.$store.getters['getCurrentPage'] === 'payment'" class="icon-ok"></i>
        </a>
        <a
            v-else-if="$store.getters['getIsLinda'] && this.$store.getters['getCurrentPage'] === 'content'"
            href="#step-2"
            type="button"
            class="btn  btn-circle"
            :class="this.$store.getters['getCurrentPage'] === 'contact'? 'btn-order' : 'btn-order-future'"
            disabled="disabled">
          <i v-if="this.$store.getters['getCurrentPage'] === 'payment'" class="icon-ok"></i>
        </a>
        <p>{{$t("checkout.contact")}}</p>
      </div>

      <div class="stepwizard-step col-xs-3" v-if="(!$store.getters['getIsLinda']) && (!$store.getters['getCanOrderPFDOnly'] && !$store.getters['getCanOrderExtrasOnly']) && this.$store.getters['getVolume'] !== 0">
        <a
            href="#step-3"
            type="button"
            class="btn btn-circle btn-order"
            v-if="checkstep"
        >
          <i v-if="this.$store.getters['getCurrentPage'] === 'payment' || this.$store.getters['getCurrentPage'] === 'review'" class="icon-ok"></i>
        </a>
        <a
            href="#step-3"
            type="button"
            class="btn btn-circle"
            v-else
            :class="this.$store.getters['getCurrentPage'] === 'delivery'? 'btn-order' : 'btn-order-future'"
            disabled="disabled">
          <i v-if="this.$store.getters['getCurrentPage'] === 'review'" class="icon-ok"></i>
        </a>
        <p>{{$t("checkout.delivery")}}</p>
      </div>

      <div class="stepwizard-step col-xs-3">
        <a
            href="#step-3"
            type="button"
            class="btn btn-circle btn-order"
            v-if="checkstep && $store.getters['getIsLinda']"
        >
          <i v-if="this.$store.getters['getCurrentPage'] === 'review' || this.$store.getters['getCurrentPage'] === 'error'" class="icon-ok"></i>
        </a>
        <a
            href="#step-3"
            type="button"
            class="btn btn-circle"
            :class="this.$store.getters['getCurrentPage'] === 'payment'? 'btn-order' : 'btn-order-future'"
            v-else-if="checkstep && !$store.getters['getIsLinda'] && this.$store.getters['getCurrentPage'] === 'payment'"
        >
          <i v-if="this.$store.getters['getCurrentPage'] === 'review' || this.$store.getters['getCurrentPage'] === 'error'" class="icon-ok"></i>
        </a>
        <a
           href="#step-3"
           type="button"
           v-else
           class="btn btn-circle"
           :class="this.$store.getters['getCurrentPage'] === 'review'? 'btn-order' : 'btn-order-future'"
           disabled="disabled">
          <i v-if="this.$store.getters['getCurrentPage'] === 'review'" class="icon-ok"></i>
        </a>
        <p>{{$t("checkout.payment")}}</p>
      </div>
      <div class="stepwizard-step col-xs-3">
        <a href="#step-4" type="button" class="btn btn-circle" :class="this.$store.getters['getCurrentPage'] === 'review'? 'btn-order' : 'btn-order-future'"
           disabled="disabled"></a>
        <p>{{$t("checkout.review")}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "OrderStepWizard",
    computed:{
      checkstep(){
        if(this.$store.getters['getIsLinda']){
          return this.$store.getters['getCurrentPage'] === 'payment' || this.$store.getters['getCurrentPage'] === 'review' || this.$store.getters['getCurrentPage'] === 'error';
        }
          return this.$store.getters['getCurrentPage'] === 'payment' || this.$store.getters['getCurrentPage'] === 'review' || this.$store.getters['getCurrentPage'] === 'error' || this.$store.getters['getCurrentPage'] === 'delivery';
      }
    }
  }
</script>
