<template>
  <div class="bg-white" v-if="!isLoading">
    <b-container id="auth" class="justify-content-center align-items-center">
      <div class="form-container register">
        <h1>{{ $t('auth.register.header') }}</h1>

        <Error v-if="systemError" type="system" :errorMessage="systemError" />

        <b-form @submit.prevent="register">
          <Input
            @input="errors.name = ''"
            @focusout.native="checkNameInput"
            @blur.native="checkNameInput"
            :label-text="$t('auth.register.your.name')"
            placeholder-text="Jilster Maker"
            type="text"
            v-model="name"
            :class="{ errorBorder: this.errors.name }"
          />
          <Error v-if="errors.name" icon="warning" icon-color="#C23934" :errorMessage="errors.name" />
          <Input
            @input="errors.email = ''"
            @focusout.native="checkEmailInput"
            @blur.native="checkEmailInput"
            :label-text="$t('auth.register.your.email')"
            placeholder-text="hello@jilster.app"
            type="text"
            v-model="email"
            :class="{ errorBorder: this.errors.email }"
          />
          <Error
            v-if="errors.email"
            icon="warning"
            :show-login-button="showLoginButton"
            icon-color="#C23934"
            :errorMessage="errors.email"
          />
          <Input
            @input="errors.confirmEmail = ''"
            @focusout.native="checkConfirmEmailInput"
            @blur.native="checkConfirmEmailInput"
            id="confirmEmailInput"
            :label-text="$t('auth.register.confirm.email')"
            placeholder-text="hello@jilster.app"
            type="text"
            v-model="confirmEmail"
            :class="{ errorBorder: this.errors.confirmEmail }"
          />
          <Error v-if="errors.confirmEmail" icon="warning" icon-color="#C23934" :errorMessage="errors.confirmEmail" />
          <Input
            @input="errors.password = ''"
            @focusout.native="checkPasswordInput"
            @blur.native="checkPasswordInput"
            icon="view"
            icon-color="#534C45"
            :label-text="$t('auth.register.your.password')"
            :placeholder-text="$t('auth.register.choose.password')"
            type="password"
            v-model="password"
            :class="{ errorBorder: this.errors.password }"
          />
          <Error v-if="errors.password" icon="warning" icon-color="#C23934" :errorMessage="errors.password" />
          <small v-if="!errors.password">{{ $t('auth.register.minimum.characters') }}</small>
          <b-form-checkbox
            id="terms"
            v-model="terms"
            name="terms"
            value="accepted"
            unchecked-value=""
            @change="onCheckboxChange"
          >
            <span
              >{{ $t('auth.register.agree') }}
              <a :href="setTermsAndConditions" target="_blank">{{ $t('auth.register.terms') }}</a>
            </span>
          </b-form-checkbox>
          <Error icon="warning" icon-color="#C23934" :errorMessage="errors.terms" v-if="errors.terms" />
          <b-form-checkbox
            id="marketingOptIn"
            v-model="marketingOptIn"
            name="marketingOptIn"
            :value="true"
            :unchecked-value="false"
          >
            <span class="whitespace-nowrap">{{ $t('auth.register.informed') }}</span>
          </b-form-checkbox>
          <Button
            type="submit"
            class="mx-auto w-25"
            :button-text="$t('auth.register.create.account')"
            button-type="primary"
          />
          <span class="login-redirect"
            >{{ $t('auth.register.already.account') }} <router-link to="/login">Log in</router-link></span
          >
        </b-form>
      </div>
    </b-container>

    <div class="w-full mt-4">
      <img :src="require('@/assets/login-create-account.png')" alt="" class="w-full" />
    </div>
  </div>

  <div v-else class="loading-icon">
    <img src="/loading.gif" alt="loading" />
  </div>
</template>

<script>
import Input from '@/components/Input'
import Button from '@/components/Button'
import Error from '@/components/Error'
import Pusher from 'pusher-js'
import Vue from 'vue'
import systemSettingsSetup from '../../mixins/systemSettingsSetup'

export default {
  name: 'Register',
  components: { Input, Button, Error },
  mixins: [systemSettingsSetup],
  data() {
    return {
      name: '',
      email: '',
      confirmEmail: '',
      password: '',
      terms: '',
      marketingOptIn: false,

      isLoading: false,

      systemError: '',

      errors: {
        name: '',
        email: '',
        confirmEmail: '',
        password: '',
        terms: '',
      },

      showLoginButton: false,
    }
  },
  computed: {
    setTermsAndConditions() {
      const selectedLanguage = localStorage.getItem('language')

      if (selectedLanguage === 'nl') {
        return 'https://www.jilster.nl/algemene-voorwaarden/'
      }

      if (selectedLanguage === 'de') {
        return 'https://www.jilster.de/allgemeine-geschaeftsbedingungen/'
      }

      return 'https://www.jilster.com/terms-and-conditions/'
    },
  },
  methods: {
    async register() {
      await this.$store.dispatch('setError', '')
      this.systemError = ''

      this.errors = {
        name: '',
        email: '',
        confirmEmail: '',
        password: '',
        terms: '',
      }

      this.showLoginButton = false

      let okToProceed = true

      if (!this.email) {
        this.errors.email = this.$t('auth.error.email.empty')
        okToProceed = false
      } else if (!this.emailCheck(this.email)) {
        this.errors.email = this.$t('auth.error.email.invalid')
        okToProceed = false
      }

      if (this.email && this.confirmEmail && !this.errors.email) {
        if (this.email !== this.confirmEmail) {
          this.errors.confirmEmail = this.$t('auth.register.error.email.not.same')
          okToProceed = false
        }
      } else if (!this.errors.email && !this.confirmEmail) {
        this.errors.confirmEmail = this.$t('auth.register.confirm.email.empty')
        okToProceed = false
      }

      if (!this.password) {
        this.errors.password = this.$t('auth.error.password.empty')
        okToProceed = false
      }

      if (!this.terms) {
        this.errors.terms = this.$t('auth.register.terms.not.selected')
        okToProceed = false
      }

      if (!this.name) {
        this.errors.name = this.$t('auth.register.name.empty')
        okToProceed = false
      } else if (!/^[a-zA-Z0-9 ]+$/.test(this.name)) {
        this.errors.name = this.$t('auth.register.name.only.letters.numbers')
        okToProceed = false
      }

      if (okToProceed) {
        let user = {
          nickName: this.name,
          email: this.email,
          password: this.password,
          locale: 'nl',
          device_name: 'Vue Makerspace',
          token: this.$route.query.token,
          magazine_id: this.$route.query.magazine_id,
          marketingOptIn: this.marketingOptIn,
        }
        this.isLoading = true

        await this.$store.dispatch('register', user)

        if (this.$store.getters['getError'] === 'That email is already being used. Login') {
          this.showLoginButton = true
          this.errors.email = this.$t('auth.register.email.taken')
        } else if (this.$store.getters['getError']) {
          this.systemError = this.$t('auth.error.system')
        }

        this.$emit('loggedIn')

        if (this.$store.getters['getLoggedInUser'].locale === null) {
          const locale = this.$store.getters['getVisitor'].toLowerCase()
          const settings = {
            country_id: this.$store.getters['getLoggedInUser'].country_id ?? 1,
            locale:
              locale === 'nl' || locale === 'de' || locale === 'en'
                ? this.$store.getters['getVisitor'].toLowerCase()
                : 'en',
          }
          await this.$store.dispatch('updateUserCountryId', settings)
          localStorage.setItem('language', settings.locale)
        }

        Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG === 'true'
        const getAuthToken = () => localStorage.getItem('jilster_token')
        Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
          cluster: 'eu',
          authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
          auth: {
            headers: {
              Authorization: `Bearer ${getAuthToken()}`,
            },
          },
        })

        this.systemSettingsSetup()

        this.isLoading = false
      }
    },

    emailCheck(email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },

    checkNameInput() {
      if (this.name && !/^[a-zA-Z0-9 ]+$/.test(this.name)) {
        this.errors.name = this.$t('auth.register.name.only.letters.numbers')
      }
    },

    checkEmailInput() {
      if (this.email && !this.emailCheck(this.email)) {
        this.errors.email = this.$t('auth.error.email.invalid')
      }
    },

    checkConfirmEmailInput() {
      if (this.email && this.confirmEmail && !this.errors.email) {
        if (this.email !== this.confirmEmail) {
          this.errors.confirmEmail = this.$t('auth.register.error.email.not.same')
        }
      } else if (this.email && !this.errors.email && !this.confirmEmail) {
        this.errors.confirmEmail = this.$t('auth.register.confirm.email.empty')
      }
    },

    checkPasswordInput() {
      if (this.password && this.password.length < 8) {
        this.errors.password = this.$t('auth.register.minimum.characters')
      }
    },

    onCheckboxChange(val) {
      if (val) {
        this.errors.terms = ''
      }
    },
  },
  created() {
    this.$store.dispatch('setError', '')
  },
  mounted() {
    const wrapper = document.getElementById('confirmEmailInput')

    wrapper.querySelector('input').addEventListener('paste', function (e) {
      e.preventDefault()
    })
  },
}
</script>
