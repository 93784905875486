<template>
  <div class="h-screen pl-64 pr-12 overflow-hidden flex flex-col pt-28">
    <div class="w-full">
      <PageTitle :title="headerInfo.title" :subtitle="headerInfo.subtitle" :image="headerInfo.image" />
    </div>

    <!-- Templates -->
    <Templates
      @on-click-start-creation="$store.commit('SET_SHOW_SCRATCH_MODAL', true)"
      @on-click-binding-options="showBindingOptionsModal = true"
    />

    <!-- Modal Binding Options -->
    <ModalContainer
      v-if="showBindingOptionsModal"
      :title="bindingOptionsModal.title"
      :description="bindingOptionsModal.description"
      @on-close="showBindingOptionsModal = false"
    >
      <div class="rounded-2xl my-14">
        <BindingOptions
          :show-banner="true"
          @on-close="showBindingOptionsModal = false"
          @on-start-from-template="
            $store.commit('SET_SELECTED_TEMPLATE', firstTemplate)
            $store.commit('SET_SHOW_TEMPLATE_MODAL', true)
          "
          @on-start-from-scratch="$store.commit('SET_SHOW_SCRATCH_MODAL', true)"
        />
      </div>
    </ModalContainer>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import Templates from './Templates.vue'
import ModalContainer from './ModalContainer.vue'
import BindingOptions from './BindingOptions.vue'

import bgImageHomepage from '@/assets/homepage-header.png'

export default {
  name: 'Home',

  components: {
    PageTitle,
    Templates,
    ModalContainer,
    BindingOptions,
  },

  data() {
    return {
      showBindingOptionsModal: false,
      showTemplateModal: false,
      showBindingOptions: false,

      startCreationModal: {
        title: this.$t('start.creation.modal.title'),
        description: this.$t('start.creation.modal.description'),
      },
      bindingOptionsModal: {
        title: this.$t('explore.binding.options.modal.title'),
        description: this.$t('explore.binding.options.modal.description'),
      },
      headerInfo: {
        title: this.$t('homepage.header.title'),
        subtitle: this.$t('homepage.header.description'),
        image: `url(${bgImageHomepage})`,
      },
    }
  },

  computed: {
    checkLindaSetting() {
      const settings = this.$store.getters['getSettings']

      const linda = settings.find((item) => item.name === 'LINDA_CARRIER')
      return (linda && linda.active) || false
    },

    firstTemplate() {
      const templates = this.$store.state.home.templates
      return templates && templates.length ? templates[0] : null
    },
  },
}
</script>

<style scoped>
/* width */
</style>
