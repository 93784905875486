<template>
  <img class="spine" :src="this.img" alt="spine"/>
</template>

<script>
export default {
  props:['img'],
  name: "Spine",
}
</script>
