var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    {
      'topbar-waring': _vm.topbar.content === 'warning',
      'topbar-info': _vm.topbar.content === 'info',
      'topbar-offers': _vm.topbar.content === 'offer',
    },
  ],attrs:{"id":"topbar"}},[_c('markdown-it-vue',{staticClass:"md-body",attrs:{"content":_vm.checkLanguage}}),_c('Close',{attrs:{"color":"#fff"},nativeOn:{"click":function($event){return _vm.setTopbarInactive.apply(null, arguments)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }