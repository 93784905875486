import { getOrders, previewOrder, getDownloads } from './orderEndpoint.js'

const state = {
  orders: [],
  previewOrder: {},
  pdfDownloads: [],
  backToOrders: false,
}

const getters = {
  getAllOrders: (state) => {
    return state.orders
  },
  getAllPdfDownloads: (state) => {
    return state.pdfDownloads
  },
  getBackToOrders: (state) => {
    return state.backToOrders
  },
  getPreviewOrder: (state) => {
    return state.previewOrder
  },
}

const actions = {
  setBackToOrders({ commit }, bool) {
    commit('SET_BACK_TO_ORDERS', bool)
    commit('SET_PREVIEW_ORDER', [])
  },
  async getOrders({ commit }) {
    try {
      const response = await getOrders()

      if (response.data && response.data.data) {
        const filteredOrders = response.data.data.filter((order) => order.orderstatus_id !== 7)

        commit('SET_ORDERS', filteredOrders)
      } else {
        commit('SET_ORDERS', [])
      }
    } catch (error) {
      //
    }
  },
  async previewOrder({ commit }, orderId) {
    try {
      const repsonse = await previewOrder(orderId)
      commit('SET_PREVIEW_ORDER', repsonse.data.data)
    } catch (error) {
      //
    }
  },
  async getDownloads({ commit }) {
    try {
      const response = await getDownloads()
      commit('SET_DOWNLOADS', response.data.data)
    } catch (error) {
      //
    }
  },
}

const mutations = {
  SET_BACK_TO_ORDERS(state, bool) {
    state.backToOrders = bool
  },
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_PREVIEW_ORDER(state, order) {
    state.previewOrder = order
  },
  SET_DOWNLOADS(state, downloads) {
    state.pdfDownloads = downloads
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
