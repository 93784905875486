const state = {
  startFromTemplate: false,
  templateId: null,
}

const mutations = {
  SET_START_FROM_TEMPLATE(state, value) {
    state.startFromTemplate = value
  },
  SET_TEMPLATE_ID(state, value) {
    state.templateId = value
  },
}

export default {
  state,
  mutations,
}
