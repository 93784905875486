import httpClient from "../../../httpClient";

const INVITATION_END_POINT = "v3/invitation";
const MAKERS_END_POINT = "v3/maker";

const sendInvitations = (invite) => {
  return httpClient.post(`${INVITATION_END_POINT}`, invite).then((result) => { return result }).catch((error) => { return error });
}

const updateInvitation = (magazineId, payload) =>
  httpClient.patch(
    `${INVITATION_END_POINT}/${magazineId}/member`, payload);
const getMakersByEmailOrUsername = (emailOrUsername) =>
  httpClient.get(`${MAKERS_END_POINT}/?search=${emailOrUsername}`);

const destroyInvitation = (invitationId, creationId) => httpClient.delete(`${INVITATION_END_POINT}/${invitationId}/${creationId}`);
const unsubscribeOtherUser = (creationId, userId) => httpClient.patch(`${INVITATION_END_POINT}/unsubscribe/${creationId}/${userId}`);

export { sendInvitations, getMakersByEmailOrUsername, updateInvitation, destroyInvitation, unsubscribeOtherUser };
