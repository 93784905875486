`<template>
  <div>
    <div class="sidebar-creations-header">
      <h3 v-if="this.$store.getters['getSidebarType'] === 'default'">
        {{$t("sidebar.actions")}}
      </h3>
      <h3 v-if="this.$store.getters['getSidebarType'] === 'invite_sidebar'">
        Invite
      </h3>
      <i @click="closeSidebar" class="icon-close"></i>
    </div>
    <div v-if="this.$store.getters['getSidebarType'] === 'default'">
      <p class="creationsSelectedCount">
        ({{ this.$store.getters["getSelectedCreations"].length }}) {{$t("sidebar.creations.selected")}}
      </p>
      <div class="creationInfoSidebar">
        <i v-bind:class="`icon-info-filled`"></i>
        <p
          v-if="
            this.$store.getters['getSelectedSharedCreationRole'] === 'owner'
          "
        >
          {{$t("sidebar.delete.creations.info")}}
        </p>
        <p v-else>
          {{$t("sidebar.unsubscribe.info")}}
        </p>
      </div>
<!--      <div class="infoWarning" v-if="this.$store.getters['getSelectedSharedCreationRole'] === 'owner'">-->
<!--        <div class="warningFlex">-->
<!--          <Warning />-->
<!--        </div>-->
<!--        <div class="warningFlex">-->
<!--          <p>{{$t("sidebar.action.cannot.be.reversed")}}</p>-->
<!--        </div>-->
<!--      </div>-->
      <Button
        style="width: 100%; margin-bottom: 20px"
        :button-text="$t('sidebar.button.delete')"
        button-type="wrong_button_5"
        icon-color="#d93025"
        icon="delete"
        class="btnDelete"
        @click.native="deleteCreation()"
        v-if="this.$store.getters['getSelectedSharedCreationRole'] === 'owner'"
      />
      <Button
        style="width: 100%; margin-bottom: 20px"
        :button-text="$t('sidebar.button.unsubscribe')"
        button-type="wrong_button_5"
        icon-color="#d93025"
        icon="unsubscribe"
        class="btnDelete"
        @click.native="unsubscribeCreation()"
        v-else
      />
      <Button
        style="width: 100%"
        :button-text="$t('sidebar.button.cancel')"
        button-type="outline_button_primary"
        class="btnCancel"
        @click.native="closeSidebar"
      />
      <div
        class="sharedCreationRole"
        v-if="!this.$store.getters['getViewMyCreations'] && $store.getters['getFilter'] === 'all'"
      >
        <b-form-checkbox
          :checked="
            this.$store.getters['getSelectedSharedCreationRole'] === 'owner'
          "
          @change="setViewOwnersCreation()"
          class="jilster_toggle"
          switch
          size="lg"
        ></b-form-checkbox>
        <p>{{$t("sidebar.view.owner.creations")}}</p>
      </div>
    </div>
    <div v-if="this.$store.getters['getSidebarType'] === 'invite_sidebar'">
      <h5 class="share_heading">Share with</h5>
      <Input
        id="jilster_input_search"
        v-on:removeInputValue="removeInputValue"
        @input="searchUsers"
        :icon="icon"
        icon-color="#8f9bb3"
        placeholderText="Add others with email or @"
        type="text"
      />
      <div class="search_result_container" v-if="filterdUsers !== undefined">
        <div
          @click="addSelectedUser(user)"
          class="search_result"
          v-for="user in filterdUsers"
          :key="user.id"
        >
          <img :src="user.avatar" alt="avatar" />
          <div class="container_text_search">
            <h5>{{ user.name }}</h5>
            <p>{{ user.email }}</p>
          </div>
        </div>
      </div>
      <div>
        <p>Members</p>
        <div id="owner" class="editors">
          <div class="info_container">
            <img :src="this.$store.getters['getAllUsers'][0].avatar" alt="" />
            <div>
              <h5>Joey Deckers</h5>
              <p class="email">joey.deckers@retrii.com</p>
            </div>
          </div>
          <p>Owner</p>
        </div>
        <div
          v-for="selecteduser in selectedUsers"
          :key="selecteduser.id"
          class="editors"
        >
          <div class="info_container">
            <img :src="selecteduser.avatar" alt="" />
            <div>
              <h5>{{ selecteduser.name }}</h5>
              <p class="email">{{ selecteduser.email }}</p>
            </div>
          </div>
          <div class="editor_actions">
            <Select
              :options="options"
              icon-color="#8f9bb3"
              icon="chevron-down"
            />
            <i
              @click="removeSelectedUser(selecteduser)"
              class="icon-delete"
            ></i>
          </div>
        </div>
      </div>
      <div v-if="this.$store.getters['getSelectedPage']">
        <span
          class="selected_page_name"
          v-for="page in this.$store.getters['getSelectedPage']"
          :key="page.id"
          >{{ page.pageNumber }},</span
        >
      </div>
      <div class="button_container">
        <Button
          @click.native="inviteSend"
          style="width: 100%; margin-bottom: 20px"
          button-text="Invite"
          icon="invite"
          icon-color="#2e3a59"
          button-type="basic"
        />
        <Button
          style="width: 100%"
          button-text="Cancel"
          button-type="white_button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Select from "@/components/Select";
import Input from "@/components/Input";
//import Warning from "../../Warning";

export default {
  name: "SideBarRightHomeContent",
  components: {Button, Select, Input },
  data() {
    return {
      filterdUsers: [],
      selectedUsers: [],
      icon: "",
      options: [{ value: null, text: "All" }],
      role: "owner",
    };
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch("setSidebar", false);
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
      this.$store.dispatch("setSidebarType", "default");
      this.$store.dispatch("clearSelectedCreations");
      this.$store.dispatch("setSelectedSharedCreationRole", "none");
    },
    searchUsers(search) {
      if (search === "") {
        this.filterdUsers = [];
        this.icon = "";
      } else {
        this.icon = "close";
        let users = this.$store.getters["getAllUsers"];
        if (search.charAt(0).includes("@")) {
          let result = [];
          result.push(
            users.find(
              (user) =>
                user.name.toLocaleLowerCase() ===
                search.substring(1).toLocaleLowerCase()
            )
          );
          this.filterdUsers = result;
        } else {
          let result = [];
          result.push(
            users.find(
              (user) =>
                user.email.toLocaleLowerCase() === search.toLocaleLowerCase()
            )
          );
          this.filterdUsers = result;
        }
      }
    },
    removeInputValue() {
      document.getElementById("jilster_input_search").lastChild.value = "";
      this.filterdUsers = [];
    },
    addSelectedUser(user) {
      this.selectedUsers.push(user);
    },
    removeSelectedUser(selecteduser) {
      let itemTodelete = this.selectedUsers.findIndex(
        (x) => x.email === selecteduser.email
      );
      this.selectedUsers.splice(itemTodelete, 1);
    },
    inviteSend() {
      this.$store.dispatch("setLoadingDocument", true);
    },
    async deleteCreation() {
      const creations = this.$store.getters["getSelectedCreations"];
      await this.$store.dispatch('setCreationsToDelete', creations)

      await this.$store.dispatch('setShowDeleteModal', true)
    },
    async unsubscribeCreation() {
      const creations = this.$store.getters["getSelectedCreations"];

      await this.$store.dispatch('unsubscribeCreation', creations);
    },
    setViewOwnersCreation() {
      this.$store.dispatch("clearSelectedCreations");
      if (this.$store.getters["getSelectedSharedCreationRole"] === "owner") {
        this.$store.dispatch("setSelectedSharedCreationRole", "chiefmaker");
      } else {
        this.$store.dispatch("setSelectedSharedCreationRole", "owner");
      }
    },
  },
};
</script>
