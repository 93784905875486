<template>
  <div
    class="orderFlex"
    :class="topbarActive && 'fix-topbar'"
    v-if="this.doneLoading && !$store.getters['getIsLoadingOrder']"
  >
    <BillingAddressPopup v-if="this.$store.getters['getShowPopup']" />
    <div class="overlay" v-if="this.$store.getters['getShowPopup']"></div>
    <OrderCannot
      class="flex-grow-1"
      v-if="
        $store.getters['getCannotOrder'] &&
        $route.query.confirmation !== 'true' &&
        $route.query.confirmation !== 'false'
      "
    />
    <b-container
      v-else
      id="order-flow"
      :class="`flex-grow-1 ${topbarActive && 'fix-topbar'}`"
      v-on:click="closeDropDowns($event)"
      fluid
    >
      <div class="order-content-wrapper">
        <div class="order-content-item" v-if="doneLoading">
          <OrderContent v-if="this.$store.getters['getCurrentPage'] === 'content'" />
          <OrderContact v-if="this.$store.getters['getCurrentPage'] === 'contact'" />
          <OrderPayment v-if="this.$store.getters['getCurrentPage'] === 'payment'" />
          <OrderReview v-if="this.$store.getters['getCurrentPage'] === 'review'" />
          <OrderConfirmation v-if="this.$store.getters['getCurrentPage'] === 'confirmation'" />
          <OrderErrorReview v-if="this.$store.getters['getCurrentPage'] === 'error'" />
          <OrderDeliveryMethod v-if="this.$store.getters['getCurrentPage'] === 'delivery'" />
        </div>
        <div class="order-content-item">
          <div v-if="!$store.getters['getIsLoadingOrderItem']">
            <OrderItem
              :calculator="this.$store.getters['getCalculator']"
              :magazine="this.$store.getters['getMagazine']"
            />
          </div>
          <div v-else class="order-loading-icon">
            <img alt="loading" src="/loading.gif" />
          </div>
        </div>
      </div>
    </b-container>
    <OrderCheckoutBar />
  </div>
  <div v-else class="loading-icon">
    <img src="/loading.gif" alt="loading" />
  </div>
</template>

<script>
import OrderContact from '@/domains/order/OrderContact'
import OrderItem from '@/domains/order/OrderItem'
import OrderReview from '@/domains/order/OrderReview'
import OrderCheckoutBar from '@/domains/order/OrderCheckoutBar'
import OrderPayment from '@/domains/order/OrderPayment'
import BillingAddressPopup from '@/domains/order/BillingAddressPopup'
import OrderContent from '@/domains/order/OrderContent'
import OrderConfirmation from '@/domains/order/OrderConfirmation'
import OrderErrorReview from './OrderErrorReview'
import OrderDeliveryMethod from './OrderDeliveryMethod'
import OrderCannot from '@/domains/order/OrderCannot'

export default {
  name: 'Order.vue',
  components: {
    OrderDeliveryMethod,
    OrderErrorReview,
    OrderConfirmation,
    OrderContent,
    OrderCheckoutBar,
    OrderItem,
    OrderPayment,
    OrderReview,
    OrderContact,
    BillingAddressPopup,
    OrderCannot,
  },
  data() {
    return {
      doneLoading: false,
    }
  },
  async created() {
    await this.$store.dispatch('setShowDots', false)
    await this.$store.dispatch('clearCheckout')
    await this.$store.dispatch('setDocuments', this.$route.params.id)
    await this.$store.dispatch('getLastOrder')

    const documentsNotApproved = this.$store.getters['getDocuments'].filter((d) => d.status != 3)
    if (documentsNotApproved.length > 0) {
      const firstDocument = this.$store.getters['getDocuments'].find((d) => d.number == 1)
      if (firstDocument !== null && firstDocument.status == 3) {
        await this.$store.dispatch('setCanOrderExtrasOnly', true)
        await this.$store.dispatch('setCannotOrder', false)
      } else {
        await this.$store.dispatch('setCanOrderPFDOnly', true)
        await this.$store.dispatch('setCannotOrder', true)
      }
    }

    await this.$store.dispatch('setShowDots', true)

    if (this.$route.query.confirmation === 'true' && this.$route.query.identifier !== '') {
      await this.$store.dispatch('setCurrentPage', 'confirmation')
      await this.$store.dispatch('getOrderConfirm', this.$route.query.identifier)
      this.doneLoading = true
      return
    }

    if (this.$route.query.confirmation === 'false' && this.$route.query.identifier !== '') {
      await this.$store.dispatch('setCurrentPage', 'error')
      await this.$store.dispatch('getOrderConfirm', this.$route.query.identifier)
      this.doneLoading = true
      return
    }

    let order = {
      id: this.$route.params.id,
      extraOrderIds: this.$store.getters['getOrderExtraIds'],
      printId: this.$store.getters['getPrint'],
      proof: this.$store.getters['getProof'],
      volume: this.$store.getters['getVolume'],
      countryId: this.$store.getters['getPaymentUser'].countryId || 1,
      paymentId: this.$store.getters['getPayment'],
      deliveryId: this.$store.getters['getShipment'],
    }

    await this.$store.dispatch('getCheckout', order)
    this.doneLoading = true
  },
  computed: {
    topbarActive() {
      return this.$store.getters['getTopbarActive']
    },
  },
  methods: {
    closeDropDowns(event) {
      if (event.target.className === 'col-lg-6' || event.target.className === '') {
        this.$store.dispatch('setShowSettingsDropDown', false)
      }
    },
  },
  beforeCreate: function () {
    document.body.className = ''
    document.body.style.height = '100vh'
  },
  mounted() {
    document.getElementById('app').style.height = '100%'
  },
}
</script>

<style scoped>
.fix-topbar {
  margin-top: 44px;
}
</style>
