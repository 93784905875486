<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white"/>
    <path d="M2.4813 4.69531C1.53869 5.9217 0.827922 7.33522 0.414062 8.8708H6.65678L2.4813 4.69531Z" fill="#0052B4"/>
    <path d="M23.5883 8.87076C23.1745 7.33522 22.4637 5.9217 21.5211 4.69531L17.3457 8.87076H23.5883Z" fill="#0052B4"/>
    <path d="M0.414062 15.1328C0.827969 16.6683 1.53873 18.0819 2.4813 19.3082L6.65664 15.1328H0.414062Z" fill="#0052B4"/>
    <path d="M19.3063 2.48134C18.0799 1.53873 16.6664 0.827969 15.1309 0.414062V6.65673L19.3063 2.48134Z" fill="#0052B4"/>
    <path d="M4.69531 21.5191C5.9217 22.4617 7.33522 23.1725 8.87075 23.5864V17.3438L4.69531 21.5191Z" fill="#0052B4"/>
    <path d="M8.8707 0.414062C7.33517 0.827969 5.92166 1.53873 4.69531 2.4813L8.8707 6.65669V0.414062Z" fill="#0052B4"/>
    <path d="M15.1309 23.5864C16.6664 23.1725 18.0799 22.4617 19.3063 21.5191L15.1309 17.3438V23.5864Z" fill="#0052B4"/>
    <path d="M17.3457 15.1328L21.5211 19.3082C22.4637 18.0819 23.1745 16.6683 23.5883 15.1328H17.3457Z" fill="#0052B4"/>
    <path d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z" fill="#D80027"/>
    <path d="M15.1309 15.129L20.4857 20.4838C20.732 20.2376 20.9669 19.9802 21.191 19.7134L16.6066 15.1289H15.1309V15.129Z" fill="#D80027"/>
    <path d="M8.86853 15.1289H8.86843L3.51367 20.4837C3.75986 20.73 4.01725 20.9649 4.28406 21.189L8.86853 16.6045V15.1289Z" fill="#D80027"/>
    <path d="M8.87073 8.87058V8.87049L3.51592 3.51562C3.26964 3.76181 3.0347 4.0192 2.81055 4.28602L7.39506 8.87054H8.87073V8.87058Z" fill="#D80027"/>
    <path d="M15.1309 8.86883L20.4857 3.51392C20.2395 3.26764 19.9821 3.0327 19.7153 2.80859L15.1309 7.39311V8.86883Z" fill="#D80027"/>
  </svg>
</template>

<script>
export default {
  name: 'UnitedKingdom'
}
</script>