<template>
  <div class="LindaMagazine">
    <img style="height: 206px; width: 249px;" src="@/assets/linda.jpg" />
    <div class="bottomInfo">
      <div class="bottomFlex">
        <div class="bottomLindaText">
          <p>LINDA.</p>
        </div>
        <div class="bottomCertificateText">
          <p>{{$t("linda.carrier")}}</p>
        </div>
      </div>
      <transition name="fade">
        <p v-if="hovering" class="linda-description">Starts with a minimum of 48 pages</p>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  props:['hovering'],
  name: 'LindaMagazine',
}

</script>
