<template>
  <div class="modal-participate">
    <div
      class="overlay"
      @click="
        $store.commit('SET_SHOW_PARTICIPATE_AWARD_MODAL', false)
        $store.commit('SET_SHOW_PARTICIPATE_AWARD_CONFIRMATION_MODAL', false)
        school = ''
        searchStateText = ''
        selectedState = null
        termsAgreed = false
        errors = {
          school: '',
          province: '',
          terms: '',
        }
        showError = false
        isLoading = false
      "
    ></div>

    <div class="modal-participate-content">
      <h2>{{ $t('magazine.award.participate.award.modal.headline') }}</h2>

      <div class="inputs-container">
        <div class="template-cover">
          <img v-show="coverImage" :src="coverImage" alt="" />
        </div>

        <div class="inputs">
          <div style="position: relative">
            <p>{{ $t('magazine.award.participate.award.modal.school.name') }}</p>
            <input
              type="text"
              :placeholder="$t('magazine.award.participate.award.modal.school.name.placeholder')"
              v-model="school"
              @input="errors.school = ''"
            />

            <span class="error">{{ errors.school }}</span>
          </div>

          <div style="position: relative">
            <p>{{ $t('magazine.award.participate.award.modal.province') }}</p>
            <input
              type="text"
              :placeholder="$t('magazine.award.participate.award.modal.province.placeholder')"
              v-model="searchStateText"
              @input="errors.province = ''"
            />

            <span class="error">{{ errors.province }}</span>

            <div v-show="suggestedStates.length" class="drop-down-list">
              <ul>
                <li
                  v-for="state in suggestedStates"
                  :key="state.Id"
                  @click="
                    selectedState = state
                    searchStateText = state.Name
                  "
                >
                  {{ state.Name }}
                </li>
              </ul>
            </div>
          </div>

          <div class="checkbox" style="position: relative">
            <input type="checkbox" v-model="termsAgreed" @change="errors.terms = ''" />

            <p>{{ $t('magazine.award.participate.award.modal.terms') }}</p>

            <span class="error">{{ errors.terms }}</span>
          </div>

          <div style="position: relative">
            <button class="start-btn" :disabled="isLoading" @click="onSubmit">
              {{ $t('magazine.award.participate.award.modal.button') }}
            </button>

            <Error v-if="showError" type="system" :errorMessage="$t('magazine.award.modal.system.error.message')" />
            <Error
              v-if="imageError"
              type="system"
              :errorMessage="$t('magazine.award.modal.image.not.present.message')"
            />
          </div>
        </div>
      </div>

      <button
        class="close-btn"
        @click="
          $store.commit('SET_SHOW_PARTICIPATE_AWARD_MODAL', false)
          $store.commit('SET_SHOW_PARTICIPATE_AWARD_CONFIRMATION_MODAL', false)
          school = ''
          searchStateText = ''
          selectedState = null
          termsAgreed = false
          errors = {
            school: '',
            province: '',
            terms: '',
          }
          showError = false
          isLoading = false
        "
      >
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.6562 15L27.8125 4.92188C28.6458 4.03646 28.6458 3.125 27.8125 2.1875C26.875 1.35417 25.9635 1.35417 25.0781 2.1875L15 12.3438L4.92188 2.1875C3.98438 1.35417 3.07292 1.35417 2.1875 2.1875C1.35417 3.125 1.35417 4.03646 2.1875 4.92188L12.3438 15L2.1875 25.0781C1.35417 25.9635 1.35417 26.875 2.1875 27.8125C2.65625 28.1771 3.125 28.3594 3.59375 28.3594C4.01042 28.3594 4.45312 28.1771 4.92188 27.8125L15 17.6562L25.0781 27.8125C25.5469 28.1771 25.9896 28.3594 26.4062 28.3594C26.875 28.3594 27.3438 28.1771 27.8125 27.8125C28.6458 26.875 28.6458 25.9635 27.8125 25.0781L17.6562 15Z"
            fill="#999187"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Error from '@/components/Error'

import createThumbnailUrl from '@/mixins/createThumbnailUrl'

export default {
  components: { Error },

  mixins: [createThumbnailUrl],

  props: {
    frontCoverId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      suggestedStates: [],
      searchStateText: '',
      selectedState: null,
      school: '',
      termsAgreed: false,
      errors: {
        school: '',
        province: '',
        terms: '',
      },
      showError: false,
      isLoading: false,
      coverImage: '',
      imageError: false,
    }
  },

  watch: {
    searchStateText() {
      if (this.searchStateText) {
        console.log(this.searchStateText, this.states)
        const state = this.states.find((state) => state.Name.toLowerCase() === this.searchStateText.toLowerCase())

        if (!state) {
          this.suggestedStates = this.states.filter((state) => {
            return state.Name.toLowerCase().startsWith(this.searchStateText.toLowerCase())
          })
        } else {
          this.suggestedStates = []
        }
      } else {
        this.suggestedStates = []
      }
    },
  },

  computed: {
    states() {
      return this.$store.state.magazineAward.states
    },

    activeCampaign() {
      return this.$store.state.magazineAward.activeCampaign
    },

    getLoggedInUser() {
      return this.$store.getters['getLoggedInUser']
    },

    getTitle() {
      return this.$store.getters['getTitle']
    },
  },

  methods: {
    async onSubmit() {
      this.isLoading = true
      this.showError = false
      this.imageError = false

      let okToProceed = true

      if (!this.school) {
        this.errors.school = this.$t('magazine.award.participate.award.modal.error.school')
        okToProceed = false
      } else {
        this.errors.school = ''
      }

      if (!this.selectedState) {
        this.errors.province = this.$t('magazine.award.participate.award.modal.error.province')
        okToProceed = false
      } else {
        // Check if this state is in the states array
        const state = this.states.find((state) => state.Id === this.selectedState.Id)

        if (!state) {
          this.errors.province = this.$('magazine.award.participate.award.modal.error.province.second')
          okToProceed = false
        } else {
          this.errors.province = ''
        }
      }

      if (!this.termsAgreed) {
        this.errors.terms = this.$t('magazine.award.participate.award.modal.error.terms')
        okToProceed = false
      } else {
        this.errors.terms = ''
      }

      if (this.coverImage && !this.coverImage.includes('https://')) {
        this.imageError = true
        okToProceed = false
      }

      if (okToProceed) {
        try {
          const res = await fetch(process.env.VUE_APP_AWARD_APP_API_URL + '/candidate/add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
            body: JSON.stringify({
              CampaignId: this.activeCampaign.Id,
              OrganisationName: this.school,
              StateId: this.selectedState.Id,
              RequesterName: this.getLoggedInUser.nickName || this.getLoggedInUser.firstName || '',
              RequesterEmail: this.getLoggedInUser.email,
              ReferralId: this.$route.params.id,
              Image: this.coverImage || '',
              Name: this.getTitle || '',
            }),
          })

          const data = await res.json()

          if (data.status === 'Success') {
            this.$emit('on-success')
            this.$store.commit('SET_SHOW_PARTICIPATE_AWARD_CONFIRMATION_MODAL', true)
            this.$store.commit('SET_SHOW_PARTICIPATE_AWARD_MODAL', false)

            this.school = ''
            this.selectedState = null
            this.termsAgreed = false
            this.searchStateText = ''
            this.suggestedStates = []
          } else {
            this.showError = true
          }

          this.isLoading = false
        } catch (err) {
          this.showError = true
          this.isLoading = false
        }
      } else {
        this.isLoading = false
      }
    },
  },

  async mounted() {
    this.coverImage = await this.createThumbnailUrl(this.$route.params.id, this.frontCoverId)
  },
}
</script>

<style scoped>
.modal-participate {
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.modal-participate-content {
  background: white;
  border-radius: 16px;
  padding: 40px 212px 65px 74px;
  max-width: 958px;
  margin: 0 auto;
  position: relative;
}

.modal-participate-content h2 {
  color: #2a65c4;
  font-size: 24px;
  font-weight: bold;
}

.modal-participate-content p {
  color: #111;
  font-size: 14px;
  font-weight: normal;
  margin: 0 !important;
  margin-bottom: 8px !important;
}

.modal-participate-content .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 32px;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-participate-content .start-btn {
  background-color: #2a65c4;
  color: white;
  border-radius: 4px;
  padding: 10px 35px;
  margin-top: 8px;
  outline: none !important;
  border: none !important;
}

.modal-participate-content .start-btn:hover {
  background-color: #4585de;
}

.modal-participate-content .start-btn:active {
  background-color: #184ea7;
}

.modal-participate-content .start-btn:disabled {
  background-color: #f2f0ed;
  color: #ccc4bc;
}

.template-cover {
  width: 275px;
  height: 388px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: white;
}

.template-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.inputs-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
  background-color: #f9f8f8;
  border-radius: 4px;
  box-shadow: 0px 12px 32px -8px rgba(0, 0, 0, 0.16);
}

.inputs {
  width: 100%;
  padding: 32px 24px 0px 24px;
}

.inputs div {
  margin-top: 1.75rem;
}

.inputs div:first-of-type {
  margin-top: 0px;
}

.inputs input[type='text'] {
  width: 100%;
  display: block;
  background: white;
  outline: none;
  color: #111;
  border-radius: 4px;
  padding: 8px 12px;
  border: 0.6px solid transparent;
}

.inputs input[type='text']:focus {
  outline: none;
  border: 0.6px solid #000000;
  background: white;
  color: #111;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input[type='checkbox'] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.checkbox p {
  font-size: 14px;
  color: #111;
  font-weight: normal;
  margin: 0 !important;
}

.drop-down-list {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 100%;
  left: 0;
}

.drop-down-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.drop-down-list ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.drop-down-list ul li:hover {
  background-color: #f2f0ed;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
</style>
