<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="#47AD63" />
    <path
      d="M15.0677 8.48219C15.0677 8.48219 16.2844 5.36997 15.6508 4.24474C15.4375 3.86578 12.8254 4.95039 11.8637 6.16674C11.8637 6.16674 10.9504 5.86301 10.0003 5.86301C9.05027 5.86301 8.13695 6.16674 8.13695 6.16674C7.17525 4.95039 4.56313 3.86578 4.34981 4.24474C3.71621 5.36997 4.93291 8.48219 4.93291 8.48219C4.61611 9.09036 4.49744 10.0167 4.38372 11.0109C4.25869 12.1009 4.50733 12.9697 4.61611 13.1615C4.73972 13.3794 5.36591 14.0815 6.19977 14.6822C7.46662 15.5945 9.36672 15.8989 10.0003 15.8989C10.6339 15.8989 12.534 15.5949 13.8009 14.6822C14.6347 14.0815 15.2609 13.3794 15.3845 13.1615C15.4933 12.9697 15.742 12.1009 15.6173 11.0109C15.5029 10.0171 15.3845 9.09036 15.0677 8.48219Z"
      fill="#FFCC4D"
    />
    <path
      d="M4.47657 4.58755C4.54721 4.376 6.36537 5.35501 6.77859 6.49613C6.77859 6.49613 5.43369 7.42288 5.1794 8.12923C5.1794 8.12923 4.12339 5.64709 4.47657 4.58755Z"
      fill="#F18F26"
    />
    <path
      d="M5.75488 6.10275C5.75488 5.60335 7.68218 7.06799 7.329 7.42116C6.97582 7.77434 6.88576 7.67545 6.53258 8.02863C6.1794 8.38181 5.75488 6.45593 5.75488 6.10275Z"
      fill="#FFCC4D"
    />
    <path
      d="M15.5247 4.58755C15.454 4.376 13.6359 5.35501 13.2227 6.49613C13.2227 6.49613 14.5676 7.42288 14.8219 8.12923C14.8219 8.12923 15.8779 5.64709 15.5247 4.58755Z"
      fill="#F18F26"
    />
    <path
      d="M14.2453 6.10275C14.2453 5.60335 12.318 7.06799 12.6712 7.42116C13.0243 7.77434 13.1144 7.67545 13.4676 8.02863C13.8208 8.38181 14.2453 6.45593 14.2453 6.10275Z"
      fill="#FFCC4D"
    />
    <path
      d="M8.5885 10.6005C8.5885 10.9908 8.27205 11.3065 7.88214 11.3065C7.49223 11.3065 7.17578 10.9908 7.17578 10.6005C7.17578 10.2106 7.49223 9.89453 7.88214 9.89453C8.27205 9.89453 8.5885 10.211 8.5885 10.6005ZM12.8259 10.6005C12.8259 10.9908 12.5095 11.3065 12.1196 11.3065C11.7293 11.3065 11.4132 10.9908 11.4132 10.6005C11.4132 10.2106 11.7293 9.89453 12.1196 9.89453C12.5098 9.89453 12.8259 10.211 12.8259 10.6005Z"
      fill="#292F33"
    />
    <path
      d="M4.42081 14.2967C4.36854 14.2967 4.31697 14.2738 4.28201 14.2296C4.22162 14.153 4.23468 14.0421 4.31132 13.9817C4.36854 13.9368 5.7311 12.8798 8.07197 13.0617C8.1691 13.0691 8.24185 13.1538 8.23444 13.251C8.22702 13.3481 8.14367 13.4279 8.04478 13.4134C5.84341 13.246 4.54301 14.249 4.53029 14.2593C4.49745 14.284 4.45895 14.2967 4.42081 14.2967ZM7.5528 12.6498C7.53549 12.6498 7.51748 12.6474 7.50018 12.6421C5.80845 12.1134 4.41445 12.5195 4.40032 12.5234C4.30673 12.552 4.20855 12.4987 4.18029 12.4051C4.15239 12.3115 4.20537 12.2133 4.29861 12.1854C4.35971 12.1671 5.81233 11.745 7.60507 12.3048C7.69831 12.3341 7.75023 12.433 7.72091 12.5262C7.6976 12.6015 7.62803 12.6498 7.5528 12.6498ZM15.5806 14.2967C15.6328 14.2967 15.6844 14.2738 15.7194 14.2296C15.7798 14.153 15.7667 14.0421 15.69 13.9817C15.6328 13.9368 14.2703 12.8798 11.9294 13.0617C11.8323 13.0691 11.7595 13.1538 11.7669 13.251C11.7747 13.3481 11.8577 13.4279 11.9566 13.4134C14.158 13.246 15.4584 14.249 15.4711 14.2593C15.5039 14.284 15.5424 14.2967 15.5806 14.2967ZM12.4486 12.6498C12.4659 12.6498 12.4839 12.6474 12.5012 12.6421C14.1929 12.1134 15.5869 12.5195 15.601 12.5234C15.6946 12.552 15.7928 12.4987 15.8211 12.4051C15.849 12.3115 15.796 12.2133 15.7028 12.1854C15.6417 12.1671 14.189 11.745 12.3963 12.3048C12.3031 12.3341 12.2511 12.433 12.2805 12.5262C12.3038 12.6015 12.3733 12.6498 12.4486 12.6498Z"
      fill="#FEE7B8"
    />
    <path
      d="M12.3795 14.4505C12.3452 14.3594 12.2439 14.3121 12.1524 14.3467C11.9574 14.4194 11.7724 14.4565 11.6018 14.4565C11.1949 14.4565 11.0505 14.3485 10.8838 14.2238C10.7245 14.1044 10.5461 13.9713 10.1809 13.9402V13.1141C10.1809 13.0166 10.1018 12.9375 10.0043 12.9375C9.90687 12.9375 9.82776 13.0166 9.82776 13.1141V13.9522C9.54168 13.9928 9.35238 14.1072 9.18638 14.2146C8.98578 14.3442 8.81237 14.4565 8.45071 14.4565C8.28048 14.4565 8.09541 14.4198 7.90046 14.3467C7.80934 14.3131 7.70727 14.359 7.67337 14.4505C7.63911 14.542 7.68573 14.6437 7.77685 14.678C8.01136 14.7652 8.2381 14.8097 8.45036 14.8097C8.9162 14.8097 9.16166 14.6511 9.37816 14.5113C9.56323 14.3915 9.72428 14.2884 10.0308 14.287C10.3812 14.2898 10.5105 14.3852 10.6719 14.5063C10.8619 14.6487 11.077 14.8097 11.6018 14.8097C11.814 14.8097 12.0411 14.7652 12.2756 14.678C12.3671 14.6437 12.4137 14.542 12.3795 14.4505Z"
      fill="#67757F"
    />
    <path
      d="M10.4954 12.3008H9.50645C9.28042 12.3008 9.0957 12.4855 9.0957 12.7115C9.0957 12.9372 9.28042 13.1223 9.50645 13.1223H9.59015V13.2441C9.59015 13.4698 9.77487 13.6549 10.0009 13.6549C10.2269 13.6549 10.4116 13.4702 10.4116 13.2441V13.1223H10.4954C10.721 13.1223 10.9061 12.9376 10.9061 12.7115C10.9061 12.4855 10.721 12.3008 10.4954 12.3008Z"
      fill="#E75A70"
    />
    <path
      d="M10.008 9.59532C9.91048 9.59532 9.83136 9.51621 9.83136 9.41873V6.493C9.83136 6.39552 9.91048 6.31641 10.008 6.31641C10.1054 6.31641 10.1845 6.39552 10.1845 6.493V9.41873C10.1845 9.51656 10.1058 9.59532 10.008 9.59532ZM11.0597 9.03659C10.9622 9.03659 10.8831 8.95748 10.8831 8.86C10.8831 7.84214 11.3182 7.01994 11.337 6.98533C11.3829 6.89915 11.4906 6.86737 11.5761 6.91328C11.6619 6.95919 11.694 7.06621 11.6481 7.15238C11.6439 7.1598 11.2363 7.93397 11.2363 8.86C11.2363 8.95748 11.1572 9.03659 11.0597 9.03659ZM8.94065 9.03659C8.84317 9.03659 8.76406 8.95748 8.76406 8.86C8.76406 7.93361 8.35614 7.1598 8.3519 7.15238C8.30598 7.06621 8.33848 6.95955 8.4243 6.91328C8.50977 6.86701 8.61678 6.8995 8.66305 6.98533C8.68177 7.01994 9.11688 7.84214 9.11688 8.86C9.11724 8.95748 9.03812 9.03659 8.94065 9.03659Z"
      fill="#F18F26"
    />
  </svg>
</template>

<script>
export default {
  name: "Avatar",
};
</script>
