import Login from './Login.vue'
import Register from './Register.vue'
import ForgotPassword from './ForgotPassword.vue'
import SelectionCreationNewAccount from './SelectionCreationNewAccount.vue'
import UpdatePassword from './UpdatePassword'

export default [
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  { path: '*', name: 'notfound', component: Login },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/start',
    name: 'Start',
    component: SelectionCreationNewAccount,
  },
  {
    path: '/update-password',
    name: 'UpdatePassword',
    component: UpdatePassword,
  },
]
