import httpClient from './../../../httpClient'

const END_POINT = 'v3/auth'

const register = (email, nickName, password, locale, deviceName, marketingOptIn) =>
  httpClient.post(`${END_POINT}/register`, {
    email,
    nickName,
    password,
    locale,
    deviceName,
    marketingOptIn,
  })

const login = (email, password, deviceName) => httpClient.post(`${END_POINT}/login`, { email, password, deviceName })

const logout = () => httpClient.post(`${END_POINT}/logout`)

const updatePassword = (email, password, passwordresettoken) =>
  httpClient.post(`${END_POINT}/update-password`, { email, password, passwordresettoken })

const resetPassword = (email, locale) => httpClient.post(`${END_POINT}/reset-password`, { email, locale })

const getCurrentMaker = () => httpClient.get('v3/maker/profile')

export { login, register, logout, updatePassword, resetPassword, getCurrentMaker }
