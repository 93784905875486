<template>
  <b-container
    id="start-creation"
    class="justify-content-center align-items-center"
  >
    <div class="mx-auto form-container mt-5">
      <h3>Start nieuwe creatie</h3>
      <b-form>
        <label class="mt-3">Naam</label>
        <Input v-model="name" placeholder-text="Naam" />
        <label>Type</label>
        <b-select v-model="type" name="" id="">
          <option value="">Magazine</option>
        </b-select>
        <label class="mt-3">Aantal pagina's</label>
        <b-select v-model="pageCount" name="" id="">
          <option value="">4</option>
        </b-select>
        <label class="mt-3">Pagina nummers tonen</label>
        <b-form-checkbox v-model="showPageNumber" />
        <label class="mt-3"
          >Redacteuren zien alleen toegewezen pagina('s)</label
        >
        <b-form-checkbox v-model="showPages" />
        <label class="mt-3">Tijdschrift via link delen</label>
        <b-form-checkbox v-model="shareCreationLink" />
        <Button
          class="mt-3"
          button-type="primary"
          button-text="Aanmaken"
          @submit="createMagazine"
        />
      </b-form>
    </div>
  </b-container>
</template>

<script>
import Input from "@/components/Input";
import Button from "@/components/Button";

export default {
  name: "StartCreation",
  components: { Button, Input },
  data() {
    return {
      name: "",
      type: "",
      pageCount: "",
      showPageNumber: false,
      showPages: false,
      shareCreationLink: false,
    };
  },
  methods: {
    async createMagazine() {
      let creation = {
        name: this.name,
        type: this.type,
        pageCount: this.pageCount,
        showPageNumber: this.showPageNumber,
        showPages: this.showPages,
        shareCreationLink: this.shareCreationLink,
      };
      await this.$store.dispatch("createCreation", creation);
    },
  },
};
</script>
