<template>
  <div class="error" :class="type === 'system' && 'system-error'">
    <span v-if="type === 'system'">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#C23934" />
        <path
          d="M15.1641 5.51562C15.138 5.46354 15.099 5.42448 15.0469 5.39844C14.9948 5.3724 14.9557 5.34635 14.9297 5.32031H14.6562H9.34375C9.21354 5.32031 9.1224 5.34635 9.07031 5.39844C9.04427 5.39844 8.96615 5.46354 8.83594 5.59375L5.51562 8.91406C5.4375 8.96615 5.39844 9.01823 5.39844 9.07031C5.34635 9.1224 5.32031 9.21354 5.32031 9.34375V14.6562C5.32031 14.8125 5.38542 14.9818 5.51562 15.1641L8.83594 18.4844C8.86198 18.5365 8.90104 18.5755 8.95312 18.6016C9.00521 18.6276 9.04427 18.6536 9.07031 18.6797H9.34375H14.6562C14.8125 18.6797 14.9818 18.6146 15.1641 18.4844L18.4844 15.1641C18.5365 15.138 18.5755 15.099 18.6016 15.0469C18.6276 14.9948 18.6536 14.9557 18.6797 14.9297C18.6797 14.9036 18.6927 14.8646 18.7188 14.8125C18.7448 14.7344 18.7578 14.6823 18.7578 14.6562V9.34375C18.7578 9.21354 18.7318 9.1224 18.6797 9.07031C18.6797 8.99219 18.6146 8.91406 18.4844 8.83594L15.1641 5.51562ZM12.6641 15.3203H11.3359V13.9922H12.6641V15.3203ZM12.6641 12.6641H11.3359V8.67969H12.6641V12.6641Z"
          fill="white"
        />
      </svg>
    </span>
    <i v-if="icon && !type" :style="{ color: iconColor }" v-bind:class="`icon-${icon}`"></i>
    <p class="errorText">
      {{ errorMessage }}
      <router-link
        v-if="showLoginButton"
        to="/login"
        class="errorText"
        style="text-decoration: none; font-weight: bold"
        >{{ $t('auth.login.button') }}</router-link
      >
    </p>
  </div>
</template>

<script>
export default {
  props: ['icon', 'iconColor', 'errorMessage', 'type', 'showLoginButton'],
  name: 'Error',
}
</script>

<style scoped>
.system-error {
  background-color: #f9eceb;
  color: #c23934;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.system-error span {
  margin-right: 8px;
}
</style>
