<template>
  <div id="invitation" v-if="!this.isLoading && !$store.getters['getInvitationError']">
    <b-container fluid class="text-center justify-content-center">
      <b-row>
        <b-col>
          <IceCream id="iceCream" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p id="headerText">{{$t("invite.message")}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p id="projectText" class="font-weight-bold">"{{$store.getters['getInvitation'].magazine.title}}"</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p id="startText">
            {{$t("invite.action")}} {{ text }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Button
            class="d-flex mx-auto justify-content-center"
            :button-text="buttonText"
            button-type="primary"
            type="button"
            v-on:click.native="redirect"
          />
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <p>
            {{$store.getters['getInvitation'].message}}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-center">
        <b-col id="social">
          <a href="https://www.facebook.com/Jilsternl-100484153336299"
             style="color: #2a65c4; text-decoration: none;display: inline-block;">
            <img
                src="https://s3.eu-west-1.amazonaws.com/php.jilster.nl/_images/email-icon-facebook.png"
                alt="Jilster Facebook" height="24"
                style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%;">
          </a>
          <a href="https://www.instagram.com/jilster/"
             style="color: #2a65c4; text-decoration: none; margin-left: 10px; display: inline-block;">
            <img
                src="https://s3.eu-west-1.amazonaws.com/php.jilster.nl/_images/email-icon-instagram.png"
                alt="Jilster Instagram" height="24"
                style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%;">
          </a>
          <a href="https://nl.pinterest.com/jilsternl/"
             style="color: #2a65c4; text-decoration: none; margin-left: 10px; display: inline-block;">
            <img
                src="https://s3.eu-west-1.amazonaws.com/php.jilster.nl/_images/email-icon-pinterest.png"
                alt="Jilster Pinterest" height="24"
                style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%;">
          </a>
          <a href="https://www.linkedin.com/company/jilster"
             style="color: #2a65c4; text-decoration: none; margin-left: 10px; display: inline-block;">
            <img
                src="https://s3.eu-west-1.amazonaws.com/php.jilster.nl/_images/email-icon-linkedin.png"
                alt="Jilster Linkedin" height="24"
                style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%;">
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <InvitationError v-else-if="$store.getters['getInvitationError']"/>
  <div class="loading-icon" v-else-if=" isLoading">
    <img src="/loading.gif" alt="loading">
  </div>
</template>

<script>
import Button from "@/components/Button";
import IceCream from "@/components/IceCream";
import InvitationError from "./InvitationError";

export default {
  name: "Invitation",
  data() {
    return {
      type: this.$route.query.type,
      text: "create an account",
      buttonText: "create an account",
      token: this.$route.query.token,
      isLoading: false
    };
  },
  async created() {

    try{
      this.isLoading = true
      await this.$store.dispatch('getInvitation', this.token)
    }catch (e){
      this.isLoading = false
    }

    if (this.type === "1") {
      this.text = this.$t("invite.login.text")
      this.buttonText = this.$t("invite.login.button.text")
    } else {
      this.text = this.$t("invite.register.text")
      this.buttonText = this.$t("invite.register.button.text")
    }

    this.isLoading = false
  },
  methods: {
    redirect() {
      if (this.type === "1") {
        if(this.$route.query.token !== undefined) {
          this.$router.push('/login?magazine_id=' + this.$route.query.magazine_id + '&token=' + this.$route.query.token)
        }
        else {
          this.$router.push('/login')
        }
      }
      else {
        if(this.$route.query.token !== undefined) {
          this.$router.push('/register?magazine_id=' + this.$route.query.magazine_id + '&token=' + this.$route.query.token)
        }
        else {
          this.$router.push('/register')
        }
      }
    },
  },
  components: {InvitationError, Button, IceCream },
};
</script>
