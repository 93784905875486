<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="#FD7E14"/>
    <path d="M10.5 10.75C10.5 10.875 10.4479 10.9896 10.3438 11.0938C10.2604 11.1771 10.1458 11.2188 10 11.2188C9.85417 11.2188 9.72917 11.1771 9.625 11.0938C9.54167 10.9896 9.5 10.875 9.5 10.75V8.78125C9.5 8.44792 9.66667 8.28125 10 8.28125C10.3333 8.28125 10.5 8.44792 10.5 8.78125V10.75ZM10.3438 12.5625C10.2604 12.6458 10.1458 12.6875 10 12.6875C9.85417 12.6875 9.72917 12.6458 9.625 12.5625C9.54167 12.4583 9.5 12.3438 9.5 12.2188C9.5 11.8854 9.66667 11.7188 10 11.7188C10.3333 11.7188 10.5 11.8854 10.5 12.2188C10.5 12.3438 10.4479 12.4583 10.3438 12.5625ZM15.125 12.375L11.4062 6.125C11.1146 5.60417 10.6458 5.34375 10 5.34375C9.35417 5.34375 8.88542 5.60417 8.59375 6.125L4.875 12.375C4.58333 12.8333 4.57292 13.3229 4.84375 13.8438C5.15625 14.3854 5.63542 14.6562 6.28125 14.6562H13.7188C14.3646 14.6562 14.8438 14.3854 15.1562 13.8438C15.4271 13.3229 15.4167 12.8333 15.125 12.375Z" fill="white"/>
  </svg>
</template>

<script>

export default {
  name: 'Warning'
}

</script>