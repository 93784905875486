<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="space-y-8">
    <div
      v-for="(item, i) in isLinda ? onlyLinda : options"
      :key="i"
      class="flex justify-start items-start bg-white rounded-lg overflow-hidden"
      style="
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
        max-width: 1048px;
      "
    >
      <div class="" style="height: 257px; width: 269px">
        <img :src="item.image" alt="" class="w-full h-full object-cover object-left-bottom" />
      </div>

      <div class="p-6">
        <h3 class="text-lg font-bold mb-3 text-wg-100">{{ item.title }}</h3>

        <ul class="list-disc list-inside text-wg-80">
          <li class="mb-1">
            {{ $t('explore.binding.options.modal.pages.upper') }}:
            <span class="font-bold"
              >{{ $t('explore.binding.options.modal.from') }} {{ item.minPages }}
              {{ $t('explore.binding.options.modal.to') }} {{ item.maxPages }}
              {{ $t('explore.binding.options.modal.pages.lower') }}
              <span v-if="item.orMore">{{ $t('explore.binding.options.modal.or.more.text') }}</span></span
            >
          </li>
          <li v-for="(features, z) in item.options" :key="z" class="mb-1">{{ features }}</li>
        </ul>
        <span
          v-html="item.specialTagLine"
          class="rounded-lg p-2 -ml-2 mt-2 inline-block"
          :style="`background-color: ${item.isLinda ? 'rgba(17, 17, 17, 0.6)' : '#ebf5fe'}; color: ${
            item.isLinda ? 'white' : '#184ea7'
          }; max-width: 564px`"
        ></span>
      </div>
    </div>

    <!-- Price Calculator -->
    <!-- btns and image -->
    <div>
      <div class="relative custom-shadow" style="max-width: 1048px; margin-top: 40px">
        <!-- tab btns -->
        <div class="bg-black px-4 py-2.5 flex justify-between items-center">
          <div>
            <button
              v-for="(item, index) in pricingOptions"
              :key="index"
              class="px-4 py-2.5 hover-custom-category mr-2 hover:bg-white border border-white rounded-full custom-transition"
              :class="currentPage === item.category ? 'text-black bg-white' : 'text-white '"
              @click="currentPage = item.category"
            >
              {{ item.category }}
            </button>
          </div>

          <div>
            <h3 class="text-white text-2xl font-bold">
              {{ $t('explore.binding.options.modal.pricing.calculator.title') }}
            </h3>
          </div>
        </div>

        <div class="pt-6 bg-white body-text col-start-2 col-end-6 xl:col-start-3 xl:col-end-11">
          <!-- paper size name -->
          <div class="px-4 pb-7">
            <h4 v-if="currentPage !== 'Poster'" class="heading-3 font-bold text-wg-100">
              {{ (selectedOptions && selectedOptions.optionsTitle) || '' }}
            </h4>

            <div v-else class="flex justify-start items-center space-x-1 relative">
              <h4 class="text-xl font-bold text-wg-100">
                {{ $t('explore.binding.options.modal.pricing.calculator.paper.a4') }}
              </h4>
            </div>

            <!-- paper sizes options -->
            <div>
              <button
                v-for="(item, i) in (selectedOptions && selectedOptions.options) || []"
                :key="i"
                class="mt-3 mr-3 px-4 py-2.5 hover:text-white border hover-custom border-black rounded-full custom-transition"
                :class="
                  selectedPaperSize && selectedPaperSize.name === item.name
                    ? 'text-white bg-black'
                    : 'text-black bg-white'
                "
                @click="onSelectPaperSize(item)"
              >
                {{ item.name }}
              </button>
            </div>
          </div>

          <!-- page and copy selection and order summary -->
          <div class="bg-white grid grid-cols-12 p-4 pt-0">
            <!-- page and copy selection -->
            <div v-if="pageOptions.length" class="pb-6 col-start-1 col-end-5" style="padding-right: 52px !important">
              <!-- pages -->
              <div class="">
                <h5 class="" style="color: #111">
                  {{ $t('explore.binding.options.modal.pages.upper') }}
                  <span class="text-sm" style="color: #746a5f"
                    >({{ $t('explore.binding.options.modal.pricing.calculator.multiple.of') }}
                    {{ (selectedPaperSize && selectedPaperSize.pagesMultipleOf) || 4 }})</span
                  >
                </h5>

                <!-- desktop dropdown -->
                <div class="mt-2 relative">
                  <button
                    class="w-full bg-wg-10 border border-wg-10 flex justify-between items-center text-wg-80 rounded px-3 py-2 hover:text-wg-100 focus:bg-white focus:border-black custom-transition"
                    @click="showPageList = !showPageList"
                  >
                    <span>{{ selectedNoOfPages }}</span>
                    <span v-if="!showPageList" class="icon icon-chevron-down transform translate-y-1"></span>
                    <span v-else class="icon icon-chevron-up transform"></span>
                  </button>

                  <div
                    v-if="showPageList"
                    class="absolute top-full right-0 w-24 bg-white flex flex-col max-h-[300px] overflow-y-auto"
                    style="box-shadow: 0px 12px 32px -8px rgba(0, 0, 0, 0.16)"
                  >
                    <button
                      v-for="(item, i) in pageOptions"
                      :key="i"
                      class="text-wg-100 px-2 py-1 focus:bg-black focus:text-white select-hover"
                      @click="
                        selectedNoOfPages = item
                        showPageList = !showPageList
                      "
                    >
                      {{ item }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- copies -->
              <div class="mt-6">
                <h5 class="text-sm">{{ $t('explore.binding.options.modal.pricing.calculator.copies') }}</h5>

                <div class="mt-4 flex justify-start items-stretch space-x-2">
                  <button
                    :disabled="copy <= 1"
                    class="w-10 flex flex-shrink-0 justify-center items-center rounded text-2xl border border-white bg-white disabled:bg-white disabled:text-wg-40 focus:bg-wg-100 focus:text-white focus:border-wg-100 custom-transition font-mono hover:bg-wg-40"
                    @click="onMinusClick"
                  >
                    -
                  </button>

                  <input
                    v-model="copy"
                    type="text"
                    class="bg-wg-10 text-wg-80 w-14 lg:w-full h-10 py-2 px-3 border border-wg-10 rounded focus:bg-white focus:border-black"
                  />

                  <button
                    class="w-10 flex flex-shrink-0 justify-center items-center rounded text-2xl border border-white bg-white disabled:bg-white disabled:text-wg-40 focus:bg-wg-100 focus:text-white focus:border-wg-100 custom-transition font-mono hover:bg-wg-40"
                    @click="onPlusClick"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            <!-- order summary -->
            <div
              class="bg-white p-4 col-end-13 border-t-0 lg:py-0 border-wg-40"
              :class="pageOptions.length ? 'col-start-5 border-l' : 'col-start-1'"
              style="padding-left: 52px !important; padding-top: 0px !important"
            >
              <h4 class="text-wg-60 font-bold text-lg">
                {{ $t('explore.binding.options.modal.pricing.calculator.order.summary') }}
              </h4>

              <p class="mt-1 text-wg-100 flex justify-between items-center space-x-4">
                <span>
                  {{ copy }} {{ (selectedPaperSize && selectedPaperSize.name.toLowerCase()) || '' }}
                  {{ (selectedOptions && selectedOptions.category.toLowerCase()) || '' }}(s)
                </span>

                <span class="font-bold">{{ totalPrice | currency }}</span>
              </p>

              <p
                v-if="pageOptions.length && discountPerc"
                class="mt-1 font-bold flex justify-between items-center space-x-4"
                style="color: #1fa549"
              >
                <span>{{ $t('explore.binding.options.modal.pricing.calculator.discount') }} {{ discountPerc }}% </span>
                <span>- {{ totalDiscount | currency }}</span>
              </p>

              <div class="mt-6 border-t border-wg-100">
                <p class="text-wg-100 flex justify-between items-center space-x-4">
                  <span
                    >{{ $t('explore.binding.options.modal.pricing.calculator.total') }}
                    <span
                      >({{ taxAmount | currency }}
                      {{ $t('explore.binding.options.modal.pricing.calculator.taxes.included') }})</span
                    ></span
                  >
                  <span class="text-wg-100 font-bold text-base">{{ grandTotal | currency }}</span>
                </p>

                <p v-if="copy > 1" class="mt-4 text-wg-100 font-bold flex justify-between items-center space-x-4">
                  <span class="text-xl">{{ $t('explore.binding.options.modal.pricing.calculator.per.copy') }}</span>
                  <span class="text-3xl">{{ totalAmountWithTaxWithoutDisc | currency }} </span>
                </p>
              </div>
            </div>
          </div>

          <!-- alerm -->
          <!--
      <div class="flex justify-end px-4 py-4 md:p-0">
        <div class="text-sm text-[#2A65C4] bg-[#EBF5FE] p-2 rounded-lg flex justify-start items-center space-x-1.5">
          <span
            class="icon icon-info-filled text-white text-xl leading-none w-6 h-6 bg-[#339AF0] rounded-full flex justify-center items-center"
          ></span>

          <span>{{ data.shippingCost }}</span>
        </div>
      </div>

      <div class="mt-12 px-4">
        <h2 class="heading-1 text-center font-bold">
          {{ (selectedPaperSize && selectedPaperSize.shippingTitle) || '' }}
        </h2>

        <div class="mt-4 text-justify">
          <div
            class="body-text"
            v-html="fixLinkSlashesHref($md.render((selectedPaperSize && selectedPaperSize.shippingDetails) || ''))"
          ></div>
        </div>
      </div>
      -->
        </div>
      </div>

      <div class="flex justify-end" style="max-width: 1048px; margin-top: 16px">
        <span
          class="bg-wg-light-information rounded-lg flex justify-start items-center w-max space-x-2"
          style="padding: 10px"
        >
          <span
            class="jilster-font flex justify-center items-center bg-wg-information text-white rounded-full"
            style="height: 24px; width: 24px"
            >¥</span
          >
          <span class="text-wg-light-accent text-sm">{{
            $t('explore.binding.options.modal.shipping.cost.included')
          }}</span>
        </span>
      </div>
    </div>

    <!-- Shipping details -->
    <!-- Texts -->
    <markdown-it-vue :options="optionsMd" class="md-body" :content="markdownShippingText" />

    <!-- Shipping details -->
    <div v-if="showBanner" class="bg-image bg-no-repeat bg-cover bg-bottom rounded-2xl overflow-hidden">
      <h2 class="text-2xl font-bold pt-5 pb-4 text-white text-center">
        {{ $t('explore.binding.options.modal.ready.to.start.creating') }}
      </h2>

      <div class="flex justify-center items-center space-x-4" style="padding-bottom: 32px">
        <button
          class="font-semibold text-white text-center inline-block w-full rounded py-2.5 mt-auto bg-wg-light-accent"
          style="width: 221px"
          @click="
            $emit('on-close')
            $emit('on-start-from-template')
          "
        >
          {{ $t('start.template.modal.button') }}
        </button>

        <button
          class="font-semibold text-white text-center inline-block w-full rounded py-2.5 mt-auto bg-wg-light-accent"
          style="width: 221px"
          @click="
            $emit('on-close')
            $emit('on-start-from-scratch')
          "
        >
          {{ $t('start.scratch.card.button') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

export default {
  name: 'BindingOptions',

  components: {
    MarkdownItVue,
  },

  props: {
    showBanner: {
      type: Boolean,
      default: false,
    },
    isLinda: {
      type: Boolean,
      default: false,
    },
  },

  filters: {
    currency(value) {
      if (value) {
        if (typeof value === 'number') {
          return '€' + value.toFixed(2).toString().replace('.', ',')
        } else {
          return value
        }
      } else {
        return '€0,00'
      }
    },
  },

  data() {
    return {
      options: [
        {
          title: this.$t('explore.binding.options.modal.option.stapled.matte.title'),
          minPages: 4,
          maxPages: 60,
          options: this.$t('explore.binding.options.modal.option.stapled.matte.description')
            ? this.$t('explore.binding.options.modal.option.stapled.matte.description').split('*')
            : [],
          isLinda: false,
          specialTagLine: this.$t('explore.binding.options.modal.option.stapled.matte.special.tag.line'),
          image: require('@/assets/binding-options/stapled-matte.png'),
        },
        {
          title: this.$t('explore.binding.options.modal.option.stapled.glossy.title'),
          minPages: 4,
          maxPages: 52,
          options: this.$t('explore.binding.options.modal.option.stapled.glossy.description')
            ? this.$t('explore.binding.options.modal.option.stapled.glossy.description').split('*')
            : [],
          isLinda: false,
          specialTagLine: this.$t('explore.binding.options.modal.option.stapled.glossy.special.tag.line'),
          image: require('@/assets/binding-options/stapled-glossy.png'),
        },
        {
          title: this.$t('explore.binding.options.modal.option.glued.glossy.cover.title'),
          minPages: 32,
          maxPages: 300,
          options: this.$t('explore.binding.options.modal.option.glued.glossy.cover.description')
            ? this.$t('explore.binding.options.modal.option.glued.glossy.cover.description').split('*')
            : [],
          isLinda: false,
          specialTagLine: this.$t('explore.binding.options.modal.option.glued.glossy.cover.special.tag.line'),
          image: require('@/assets/binding-options/glued-glossy-cover.png'),
          orMore: true,
        },
        {
          title: this.$t('explore.binding.options.modal.option.glued.glossy.cover.book.title'),
          minPages: 32,
          maxPages: 300,
          options: this.$t('explore.binding.options.modal.option.glued.glossy.cover.book.description')
            ? this.$t('explore.binding.options.modal.option.glued.glossy.cover.book.description').split('*')
            : [],
          isLinda: false,
          specialTagLine: this.$t('explore.binding.options.modal.option.glued.glossy.cover.book.special.tag.line'),
          image: require('@/assets/binding-options/glued-glossy-hard-cover.png'),
          orMore: true,
        },
      ],

      onlyLinda: [
        {
          title: this.$t('explore.binding.options.modal.option.glued.glossy.cover.linda.title'),
          minPages: 48,
          maxPages: 300,
          options: this.$t('explore.binding.options.modal.option.glued.glossy.cover.linda.description')
            ? this.$t('explore.binding.options.modal.option.glued.glossy.cover.linda.description').split('*')
            : [],
          isLinda: true,
          specialTagLine: this.$t('explore.binding.options.modal.option.glued.glossy.cover.linda.special.tag.line'),
          image: require('@/assets/binding-options/linda.png'),
          orMore: true,
        },
      ],

      optionsMd: {
        markdownIt: {
          linkify: true,
          html: true,
        },
      },

      // Pricing calculator related data
      pricingOptions: [],
      currentPage: '',
      selectedOptions: null,
      showPageList: false,
      showPaperSizeImage: false,
      selectedNoOfPages: null,
      selectedPaperSize: null,
      copy: 3,
      perCopyPrice: 0,
      totalPrice: 0,
      discountPerCopy: 0,
      discountPerc: 0,
      totalDiscount: 0,
      totalAfterDiscount: 0,
      taxAmount: 0,
      totalAmountWithTaxWithoutDisc: 0,
      grandTotal: 0,

      needToCallApi: true,
    }
  },

  async created() {
    const locale = this.$i18n.locale

    try {
      // Collect page data from API
      const query = {
        locale: locale === 'nl' ? 'nl-NL' : locale === 'de' ? 'de' : 'en',
        populate: ['*', 'options'],
      }

      const res = await axios.get('https://cms.jilster.app/api/shipping-and-pricings?' + qs.stringify(query))

      if (res && res.data && res.data.results) {
        let options = res.data.results

        const selectedOptions =
          (options && options.length && options.find((item) => item.category === this.currentPage)) || null

        const selectedPaperSize = selectedOptions
          ? selectedOptions.options && selectedOptions.options.length && selectedOptions.options[0]
          : null
        const selectedNoOfPages = (selectedPaperSize && selectedPaperSize.minPage) || 4

        this.pricingOptions = options
        this.selectedOptions = selectedOptions
        this.selectedPaperSize = selectedPaperSize
        this.selectedNoOfPages = selectedNoOfPages
      }
    } catch (err) {
      //
    }
  },

  computed: {
    pageOptions() {
      const option = this.selectedPaperSize && JSON.parse(JSON.stringify(this.selectedPaperSize))

      const arr = []

      if (option && option.minPage && option.maxPage && option.pagesMultipleOf) {
        let i = option.minPage

        while (i <= option.maxPage) {
          arr.push(i)
          i += option.pagesMultipleOf
        }
      }

      return arr
    },

    markdownShippingText() {
      return this.$t('explore.binding.options.modal.shipping.details')
    },
  },

  watch: {
    async copy(val) {
      if (val && this.needToCallApi) {
        await this.getPriceFromApi()
      } else {
        // reset
        this.discountPerc = 0
        this.totalPrice = 0
        this.grandTotal = 0
        this.taxAmount = 0
        this.totalDiscount = 0
        this.perCopyPrice = 0
        this.discountPerCopy = 0
        this.totalAmountWithTaxWithoutDisc = 0
      }
    },

    async selectedNoOfPages(val) {
      if (val && this.needToCallApi) {
        await this.getPriceFromApi()
      } else {
        // reset
        this.discountPerc = 0
        this.totalPrice = 0
        this.grandTotal = 0
        this.taxAmount = 0
        this.totalDiscount = 0
        this.perCopyPrice = 0
        this.discountPerCopy = 0
        this.totalAmountWithTaxWithoutDisc = 0
      }
    },

    selectedPaperSize: {
      deep: true,
      handler(val) {
        if (val.amountIncludingTax) {
          this.totalPrice = val.amountIncludingTax
          this.grandTotal = val.amountIncludingTax
          this.taxAmount = val.taxAmount
          this.discountPerc = 0
          this.totalDiscount = 0
          this.perCopyPrice = 0
          this.discountPerCopy = 0
          this.totalAmountWithTaxWithoutDisc = 0

          this.needToCallApi = false
        } else {
          this.discountPerc = 0
          this.totalPrice = 0
          this.grandTotal = 0
          this.taxAmount = 0
          this.totalDiscount = 0
          this.perCopyPrice = 0
          this.discountPerCopy = 0
          this.totalAmountWithTaxWithoutDisc = 0

          this.needToCallApi = true

          this.getPriceFromApi()
        }
      },
    },

    currentPage() {
      const selectedOptions =
        (this.pricingOptions &&
          this.pricingOptions.length &&
          this.pricingOptions.find((item) => item.category === this.currentPage)) ||
        null

      const selectedPaperSize = selectedOptions
        ? selectedOptions.options && selectedOptions.options.length && selectedOptions.options[0]
        : null
      const selectedNoOfPages = (selectedPaperSize && selectedPaperSize.minPage) || 4

      this.selectedOptions = selectedOptions
      this.selectedPaperSize = selectedPaperSize
      this.selectedNoOfPages = selectedNoOfPages
      this.copy = 3
    },

    pricingOptions: {
      handler(val) {
        this.currentPage = (val && val.length && val[0].category) || ''
      },
      deep: true,
    },
  },

  methods: {
    onMinusClick(e) {
      e.target.blur()
      this.copy--
    },
    onPlusClick(e) {
      e.target.blur()
      this.copy++
    },
    updateNoOfPages(e) {
      this.selectedNoOfPages = (e.target.value && +e.target.value) || 4
    },

    onSelectPage(e) {
      const value = e.target.value && +e.target.value

      if (value && value % this.selectedPaperSize.pagesMultipleOf === 0) {
        if (value >= this.selectedPaperSize.minPage && value <= this.selectedPaperSize.maxPage) {
          this.selectedNoOfPages = value
          e.target.value = value
        } else if (value < this.selectedPaperSize.minPage) {
          this.selectedNoOfPages = this.selectedPaperSize.minPage
          e.target.value = this.selectedPaperSize.minPage
        } else if (value > this.selectedPaperSize.maxPage) {
          this.selectedNoOfPages = this.selectedPaperSize.maxPage
          e.target.value = this.selectedPaperSize.maxPage
        }
      } else if (value && value % this.selectedPaperSize.pagesMultipleOf !== 0) {
        if (value < this.selectedPaperSize.minPage) {
          this.selectedNoOfPages = this.selectedPaperSize.minPage
          e.target.value = this.selectedPaperSize.minPage
        } else if (value > this.selectedPaperSize.maxPage) {
          this.selectedNoOfPages = this.selectedPaperSize.maxPage
          e.target.value = this.selectedPaperSize.maxPage
        } else {
          this.selectedNoOfPages =
            Math.floor(value / this.selectedPaperSize.pagesMultipleOf) * this.selectedPaperSize.pagesMultipleOf +
            this.selectedPaperSize.pagesMultipleOf

          e.target.value = this.selectedNoOfPages
        }
      } else {
        this.selectedNoOfPages = this.selectedPaperSize.minPage
        e.target.value = this.selectedNoOfPages
      }
    },

    hidePageList() {
      if (this.showPageList) {
        this.showPageList = false
      }
    },

    hidePaperSizeImage() {
      if (this.showPaperSizeImage) {
        this.showPaperSizeImage = false
      }
    },

    onSelectCopy(e) {
      this.copy = e.target.value
    },

    async getPriceFromApi() {
      try {
        if (this.selectedPaperSize && this.selectedPaperSize.printing_type_reference_id) {
          const url = `https://api.jilster.app/api/v3/calculator/price?printingTypeRefId=${this.selectedPaperSize.printing_type_reference_id}&numberOfPages=${this.selectedNoOfPages}&numberOfCopies=${this.copy}&currencyId=1`

          const response = await axios.get(url)

          if (response && response.data && response.data.data) {
            this.discountPerc = response.data.data.discountPercentage
            this.totalPrice = response.data.data.totalPriceWithoutDiscountWithVATFormatted
            this.grandTotal = response.data.data.totalPriceWithVATFormatted
            this.taxAmount = response.data.data.totalPriceVATFormatted
            this.totalDiscount = response.data.data.discountWithVATFormatted
            this.perCopyPrice = response.data.data.pricePerMagazineWithoutDiscountWithVATFormatted
            this.discountPerCopy = response.data.data.discountPerMagazineWithVATFormatted
            this.totalAmountWithTaxWithoutDisc = response.data.data.pricePerMagazineWithVATFormatted
          } else {
            this.discountPerc = 0
            this.totalPrice = 0
            this.grandTotal = 0
            this.taxAmount = 0
            this.totalDiscount = 0
            this.perCopyPrice = 0
            this.discountPerCopy = 0
            this.totalAmountWithTaxWithoutDisc = 0
          }
        } else {
          this.discountPerc = 0
          this.totalPrice = 0
          this.grandTotal = 0
          this.taxAmount = 0
          this.totalDiscount = 0
          this.perCopyPrice = 0
          this.discountPerCopy = 0
          this.totalAmountWithTaxWithoutDisc = 0
        }
      } catch (err) {
        //
      }
    },

    onSelectPaperSize(paperSize) {
      this.selectedPaperSize = paperSize

      if (this.selectedNoOfPages < paperSize.minPage) {
        this.selectedNoOfPages = paperSize.minPage
      } else if (this.selectedNoOfPages > paperSize.maxPage) {
        this.selectedNoOfPages = paperSize.maxPage
      } else {
        this.getPriceFromApi()
      }
    },
  },
}
</script>

<style scoped>
.select-hover:hover {
  background-color: #f2f0ed;
}

.custom-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.hover-custom:hover {
  background-color: black !important;
}

.hover-custom-category:hover {
  color: black !important;
}

.bg-image {
  background-image: url('/banner-binding-options.png');
}

.md-body >>> .markdown-body ul {
  list-style: disc;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  list-style-position: inside;
}

.md-body >>> .markdown-body {
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
}
</style>
