<template>
  <div id="newDesign">
    <div id="info">
      <h1>{{ $t('what.do.you.want.to.make') }}</h1>
      <p>{{ $t('choose.an.option') }}</p>
    </div>
    <div id="examples">
      <div class="example">
        <p class="example-text">{{ $t('new_design_magazine') }}</p>
        <Magazine @click.native="selectOption('default', 16)" />
      </div>
      <div class="example">
        <p class="example-text">{{ $t('new_design_book') }}</p>
        <Book @click.native="selectOption('default', 32)" />
      </div>
      <div
        @mouseover="hoveringOverLinda = true"
        @mouseleave="hoveringOverLinda = false"
        class="example"
        v-if="checkLindaSetting"
      >
        <div class="ribbon ribbon-top-right"><span>Special</span></div>
        <LindaMagazine :hovering="hoveringOverLinda" @click.native="selectOption('linda', 48)" />
      </div>
    </div>
  </div>
</template>

<script>
import Magazine from '../../components/Magazine'
import Book from '../../components/Book'
import LindaMagazine from '../../components/LindaMagazine'
import { getSettings } from '../system/systemEndpoint'

export default {
  name: 'NewDesign',
  components: { Magazine, Book, LindaMagazine },
  data() {
    return {
      lindaIsActive: true,
      loading: true,
      hoveringOverLinda: false,
    }
  },
  methods: {
    async selectOption(type, pageCount) {
      const data = {
        defaultImagedirectory_id: null,
        status: 1,
        flag: 0,
        title: 'Untitled',
        pageCount: pageCount,
        showPageNumbers: 1,
        editorPreview: 0,
        shared: 0,
        lastContentChange: new Date(),
        type: type,
      }

      await this.$store.dispatch('createCreation', data)

      const id = this.$store.getters['getCreation'].id
      await this.$router.push('document/' + id)
    },
  },
  async created() {
    let settings

    try {
      settings = await getSettings()
    } catch (err) {
      //
    }

    this.$store.commit('SET_SETTINGS', settings.data.data)
    this.loading = false
  },
  computed: {
    checkLindaSetting() {
      if (!this.loading) {
        const settings = this.$store.getters['getSettings']
        const linda = settings.find((item) => item.name === 'LINDA_CARRIER')
        return linda.active
      }
      return false
    },
  },
}
</script>
