<template>
  <div id="sidebarHeaderGradient" style="position: relative">
    <!-- Nomination Badge -->
    <div v-if="showNominationBadge" class="badge-container" :style="getBgColorAwardBadge">
      <img
        v-if="ranking !== 1 && (ranking !== 2) & (ranking !== 3)"
        :src="require('@/assets/award_badge_long_2024.png')"
        alt=""
        class="badge-position"
      />
      <img
        v-if="ranking === 1"
        :src="require('@/assets/award_badge_long_best_2024.png')"
        alt=""
        class="badge-position"
      />
      <img
        v-if="ranking === 2"
        :src="require('@/assets/award_badge_long_second_2024.png')"
        alt=""
        class="badge-position"
      />
      <img
        v-if="ranking === 3"
        :src="require('@/assets/award_badge_long_third_2024.png')"
        alt=""
        class="badge-position"
      />
    </div>

    <div class="position-relative">
      <div class="position-absolute overview-container" style="z-index: 1" @click="onEditClick">
        <input
          v-if="editMode"
          @blur="saveTitle"
          @keyup.enter="saveTitle"
          class="inputCreationName input-custom"
          id="edit-creation-title"
          ref="editTitle"
          placeholder="Creation name"
          type="text"
          v-model="title"
        />

        <div v-else class="title-container" style="cursor: pointer; width: 100%">
          <span class="custom-btn">
            <svg
              style="margin-bottom: 0"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.599 3.23964L4.24896 12.2396C4.08955 12.3931 4 12.6012 4 12.8182V16.0909C4 16.5428 4.38056 16.9091 4.85 16.9091H8.25C8.47543 16.9091 8.69164 16.8229 8.85104 16.6695L18.201 7.66945C18.533 7.34993 18.533 6.83189 18.201 6.51237L14.801 3.23964C14.4691 2.92012 13.9309 2.92012 13.599 3.23964ZM14.2 4.97591L16.3973 7.09091L7.8964 15.2727H5.7V13.1569L14.2 4.97591ZM21 20.1818C21 19.7299 20.6194 19.3636 20.15 19.3636H4.85L4.75087 19.3691C4.32813 19.4164 4 19.7622 4 20.1818C4 20.6337 4.38056 21 4.85 21H20.15L20.2491 20.9945C20.6719 20.9472 21 20.6014 21 20.1818Z"
                fill="white"
              />
            </svg>
          </span>
          <span
            style="
              margin-left: 0.5rem;
              display: inline-block;
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              cursor: pointer;
              font-size: 18px;
            "
            >{{ title }}</span
          >
        </div>
      </div>

      <div style="position: relative; padding-top: 3.75rem; height: 187px; overflow: hidden; z-index: 0">
        <div class="ovelay"></div>

        <img
          v-if="!imageUrl"
          :src="require('@/assets/placeholder.png')"
          alt=""
          style="object-fit: cover; object-position: center; width: 100%; height: 100%; z-index: 0; position: relative"
        />
        <img
          v-else
          :src="imageUrl"
          alt=""
          style="object-fit: cover; object-position: center; width: 100%; height: 100%; z-index: 0; position: relative"
        />
      </div>
      <!-- <b-row>
        <b-col>
          <small class="text-white smallP">
            {{ $t('sidebar.change.title.text') }}
          </small>
        </b-col>
      </b-row> -->
    </div>
    <CertifiedCarrier
      v-if="isCertifiedCarrier"
      labelContainer="certified-carrier certified-carrier__side-bar"
      textContainer="certified-carrier-text__align-left"
    />
  </div>
</template>

<script>
import CertifiedCarrier from '../../CertifiedCarrier'
import createThumbnailUrl from '@/mixins/createThumbnailUrl'

export default {
  name: 'SideBarRightFlatHeader',
  components: { CertifiedCarrier },
  mixins: [createThumbnailUrl],

  props: {
    showNominationBadge: {
      type: Boolean,
      default: false,
    },
    magazineId: {
      type: String,
      default: null,
    },
    frontCoverId: {
      type: String,
      default: null,
    },
    ranking: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      editMode: false,
      imageUrl: '',
    }
  },

  methods: {
    async saveTitle() {
      if (this.title === '') {
        return
      }
      const payload = {
        id: this.$route.params.id,
        data: {
          title: this.title,
        },
      }
      await this.$store.dispatch('updateCreationTitle', payload)
      this.editMode = false
    },

    onEditClick() {
      this.editMode = true
      this.$nextTick(() => {
        this.$refs.editTitle.focus()
      })
    },
  },

  watch: {
    frontCoverId: {
      async handler(val) {
        if (val) {
          const res = await this.createThumbnailUrl(this.magazineId, val)

          if (res && res.includes('https://')) {
            this.imageUrl = res
          } else {
            this.imageUrl = ''
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    isCertifiedCarrier: {
      get() {
        return this.$store.getters['getIsLinda']
      },
    },
    title: {
      get() {
        return this.$store.getters['getTitle']
      },
      set(value) {
        this.$store.commit('SET_TITLE', value)
      },
    },
    getBgColorAwardBadge() {
      if (this.ranking) {
        switch (this.ranking) {
          case 1:
            return 'background: linear-gradient(90deg, #C49817 0.14%, #D0A751 46.07%, #AE8E25 99.99%)'
          case 2:
            return 'background: linear-gradient(90deg, #676767 0.14%, #AEABA5 46.07%, #686868 99.99%)'
          case 3:
            return 'background: linear-gradient(90deg, #863406 0.14%, #E6772C 50.82%, #843505 99.99%)'
          default:
            return 'background: linear-gradient(90deg, #000000 0.14%, #1F1010 46.07%, #000000 99.99%)'
        }
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.input-custom {
  border: 1px solid white !important;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  padding: 0.5rem 1rem;
}

.custom-btn {
  /* margin-bottom: 0.5rem; */
  cursor: pointer;
}

.title-container {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 24px;
}

.overview-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1rem;
}

.badge-container {
  position: absolute;
  margin-right: 0.5rem;
  top: 100%;
  left: 0px;
  /* height: 40px; */
  width: 100%;
  z-index: 1;
}

.badge-position {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
}

.badge-container {
  position: absolute;
  margin-right: 0.5rem;
  top: 100%;
  left: 0px;
  /* height: 40px; */
  width: 100%;
  z-index: 1;
  background-color: black;
}

.badge-position {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
}
</style>
