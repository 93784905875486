<template>
  <div class="documentModal" v-on:click="closeModal($event)">
    <div class="deleteModalContent" v-if="!isLoading">
      <p class="pDelete">{{$t("delete.creations.title")}}</p>
      <p class="pInfo">{{$t("delete.creations.are.you.sure")}}</p>
      <div class="buttons">
        <Button :button-text="$t('delete.creation.button.delete')" class="btnConfirm" buttonType="hint" @click.native="confirm()"/>
        <Button :button-text="$t('delete.creation.button.cancel')" class="btnCancel" buttonType="outline_button_primary" @click.native="cancel()"/>
      </div>
    </div>
    <div v-else class="loading-icon">
      <img src="/loading.gif" alt="loading">
    </div>
  </div>
</template>

<script>
import Button from "./Button";

export default {
  name: 'DeleteModal',
  components: {Button},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async confirm() {
      this.isLoading = true
      await this.$store.dispatch("deleteCreation", this.$store.getters['getCreationsToDelete']);
      this.isLoading = false
      this.closeSidebar()
    },
    cancel() {
      this.$store.dispatch('setCreationsToDelete', [])
      this.$store.dispatch('setShowDeleteModal', false)
    },
    closeSidebar() {
      this.$store.dispatch('setCreationsToDelete', [])
      this.$store.dispatch('setShowDeleteModal', false)

      if(this.$store.getters['getSidebarOpen']) {
        this.$store.dispatch("setSidebar", false);
        this.$root.$emit("bv::toggle::collapse", "sidebar-right");
        this.$store.dispatch("setSidebarType", "default");
        this.$store.dispatch("clearSelectedCreations");
        this.$store.dispatch("setSelectedSharedCreationRole", "none");
      }
    },
    closeModal(event) {
      if(event.target.className === 'documentModal') {
        this.cancel()
      }
    }
  }
}

</script>