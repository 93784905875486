var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-unnominate"},[_c('div',{staticClass:"overlay",on:{"click":function($event){_vm.$store.commit('SET_SHOW_UNNOMINATE_MODAL', false)
      _vm.$store.commit('SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL', false)

      _vm.reasonText = ''
      _vm.errors.reasonText = ''
      _vm.isLoading = false
      _vm.showError = false}}}),_c('div',{staticClass:"modal-unnominate-content"},[_c('div',{staticClass:"inputs-btns"},[_c('h2',[_vm._v(_vm._s(_vm.$t('magazine.award.unnominate.modal.headline')))]),_c('p',[_vm._v(_vm._s(_vm.$t('magazine.award.unnominate.modal.text')))]),_c('div',{staticStyle:{"position":"relative"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reasonText),expression:"reasonText"}],attrs:{"type":"text","placeholder":_vm.$t('magazine.award.unnominate.modal.reason.placeholder')},domProps:{"value":(_vm.reasonText)},on:{"input":[function($event){if($event.target.composing)return;_vm.reasonText=$event.target.value},function($event){_vm.errors.reasonText = ''}]}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.reasonText))])]),_c('div',{staticStyle:{"position":"relative"}},[_c('button',{staticClass:"start-btn",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('magazine.award.unnominate.modal.confirm.button'))+" ")]),(_vm.showError)?_c('Error',{attrs:{"type":"system","errorMessage":_vm.$t('magazine.award.modal.system.error.message')}}):_vm._e()],1)]),_c('div',{staticClass:"animation"},[_c('lottie-vue-player',{staticStyle:{"width":"100%","height":"185px"},attrs:{"src":`/lottie/unnominate-dog.json`,"player-size":'standard',"minimizable":false,"player-controls":false,"autoplay":true,"loop":true,"speed":1}})],1),_c('button',{staticClass:"close-btn",on:{"click":function($event){_vm.$store.commit('SET_SHOW_UNNOMINATE_MODAL', false)
        _vm.$store.commit('SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL', false)

        _vm.reasonText = ''
        _vm.errors.reasonText = ''
        _vm.isLoading = false
        _vm.showError = false}}},[_c('svg',{attrs:{"width":"30","height":"29","viewBox":"0 0 30 29","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M17.6562 15L27.8125 4.92188C28.6458 4.03646 28.6458 3.125 27.8125 2.1875C26.875 1.35417 25.9635 1.35417 25.0781 2.1875L15 12.3438L4.92188 2.1875C3.98438 1.35417 3.07292 1.35417 2.1875 2.1875C1.35417 3.125 1.35417 4.03646 2.1875 4.92188L12.3438 15L2.1875 25.0781C1.35417 25.9635 1.35417 26.875 2.1875 27.8125C2.65625 28.1771 3.125 28.3594 3.59375 28.3594C4.01042 28.3594 4.45312 28.1771 4.92188 27.8125L15 17.6562L25.0781 27.8125C25.5469 28.1771 25.9896 28.3594 26.4062 28.3594C26.875 28.3594 27.3438 28.1771 27.8125 27.8125C28.6458 26.875 28.6458 25.9635 27.8125 25.0781L17.6562 15Z","fill":"#999187"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }