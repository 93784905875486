<template>
  <div class="w-full flex-grow" :class="isModal ? '' : 'h-1'">
    <div v-if="templates && templates.length" class="h-full flex flex-col">
      <!-- Categories -->
      <div class="w-full" style="margin: 20px 0">
        <div :class="isModal ? 'hidden' : ''">
          <p class="text-2xl font-bold">{{ $t('homepage.templates.header.title') }}</p>
        </div>

        <div v-if="categories && categories.length" :class="isModal ? '' : 'mt-3.5'">
          <button
            class="text-sm py-1.5 px-2 mr-1.5 mt-1.5 rounded-2xl"
            :class="!selectedCat ? 'bg-black text-white' : 'bg-wg-10'"
            @click="
              selectedCat = null
              $emit('on-select-category', null)
            "
          >
            {{ $t('homepage.templates.all.button') }}
          </button>
          <button
            v-for="(cat, i) in categories"
            :key="i"
            class="text-sm py-1.5 px-2 mr-1.5 mt-1.5 rounded-2xl"
            :class="selectedCat === cat.id ? 'bg-black text-white' : 'bg-wg-10'"
            @click="
              selectedCat = cat.id
              $emit('on-select-category', cat.id)
            "
          >
            {{ getCatName(cat.name) || '' }}
          </button>
        </div>
      </div>

      <div class="w-full overflow-y-auto flex-grow scrollbar">
        <div class="flex flex-wrap gap-7 pr-2">
          <TemplateCard
            v-for="(temp, i) in filteredTemplates"
            :template="temp"
            :key="i"
            :is-modal="isModal"
            @on-click="$emit('on-template-click')"
          />
        </div>

        <MoreOptions
          v-if="showMoreOptions"
          @on-click-start-creation="$emit('on-click-start-creation')"
          @on-click-binding-options="$emit('on-click-binding-options')"
        />
      </div>
    </div>

    <div v-else class="flex justify-center items-center" style="min-height: 491px">
      <img :src="require('@/assets/loading.gif')" alt="" class="w-20 h-20" />
    </div>
  </div>
</template>

<script>
import TemplateCard from './TemplateCard.vue'
import MoreOptions from './MoreOptions.vue'

export default {
  components: {
    TemplateCard,
    MoreOptions,
  },

  props: {
    showMoreOptions: {
      type: Boolean,
      default: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedCat: null,
    }
  },

  computed: {
    categories() {
      return this.$store.state.home.categories
    },
    templates() {
      return this.$store.state.home.templates
    },
    filteredTemplates() {
      return this.$store.getters['getFilteredTemplates'](this.selectedCat)
    },
    getCatName() {
      return (name) => {
        const locale = this.$i18n.locale

        if (name && name[locale]) {
          // Capitalize first letter
          return name[locale].charAt(0).toUpperCase() + name[locale].slice(1)
        }
      }
    },
  },
}
</script>

<style scoped>
.scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
