<template>
  <div class="jilster_input">
    <label v-if="labelText">{{ labelText }}</label>
    <i
      @click="showPassword"
      @mouseleave="hidePassword"
      v-if="icon === 'view' || (icon === 'edit' && icon !== undefined)"
      :style="{ color: iconColor }"
      v-bind:class="`icon-${icon}`"
      class="icon-right"
    ></i>
    <i
      v-else-if="
        icon !== 'view' && icon !== 'lock' && icon !== 'chevron-right' && icon !== 'locker-filled' && icon !== undefined
      "
      :style="{ color: iconColor }"
      v-bind:class="`icon-${icon}`"
    ></i>
    <i
      v-else-if="icon === 'chevron-right' || icon === 'lock' || (icon === 'locker-filled' && icon !== undefined)"
      :style="{ color: iconColor }"
      v-bind:class="`icon-${icon}`"
      class="icon-right"
    ></i>
    <!--    <i @click="removeInputValue" v-if="icon" :style="{color: iconColor}" v-bind:class="`icon-${icon}`"></i>-->
    <b-input
      :disabled="isDisabled"
      :type="passwordType || type"
      :name="name"
      :value="value"
      :placeholder="placeholderText"
      :autocomplete="autocomplete ? autocomplete : 'on'"
      class="b-input"
      @input="handleInput"
      @keyup.enter="handleKeypress"
      @blur="
        ($event) => {
          if (onBlur) onBlur($event)
        }
      "
    ></b-input>
  </div>
</template>

<script>
export default {
  props: {
    placeholderText: String,
    autocomplete: String,
    value: [String, Number],
    name: String,
    icon: String,
    iconColor: String,
    type: String,
    labelText: String,
    isDisabled: Boolean,
    isFocused: { type: Boolean, default: false },
    onBlur: { type: Function, required: false, default: null },
  },
  name: 'Input',
  data() {
    return {
      content: this.value,
      isTyping: false,
      passwordType: '',
    }
  },
  watch: {
    isFocused(val) {
      if (val) {
        this.$nextTick(() => {
          this.focusOnInput()
        })

        this.$emit('after-focus')
      }
    },
  },
  methods: {
    showPassword() {
      this.passwordType = 'text'
    },
    hidePassword() {
      this.passwordType = ''
    },
    handleInput(e) {
      this.$emit('input', (this.content = e))
    },
    handleKeypress(e) {
      this.$emit('on-press-enter', e)
    },
    removeInputValue() {
      if (this.icon === 'close') {
        this.$emit('removeInputValue', (this.content = ''))
      }
    },
    focusOnInput() {
      const inputField = document.querySelector('.b-input')

      inputField.focus()
      inputField.select()
    },
  },
}
</script>
