import {getActiveCountries} from "./userEndpoints";

const state = {
  users: [
    {
      name: "Joey Deckers",
      email: "joey.deckers@retrii.com",
      avatar: "https://joeydeckers.com/assets/images/joey.jpg",
    },
    {
      name: "Joep Deckers",
      email: "joep.deckers@retrii.com",
      avatar: "https://joeydeckers.com/assets/images/joey.jpg",
    },
    {
      name: "Davey Cornelissen",
      email: "davey.cornelissen@retrii.com",
      avatar:
        "https://scontent-bru2-1.xx.fbcdn.net/v/t1.0-9/50119482_2022396431173690_2032509688473452544_n.jpg?_nc_cat=102&ccb=3&_nc_sid=09cbfe&_nc_ohc=uGTn6KvZ3f8AX-bleJd&_nc_ht=scontent-bru2-1.xx&oh=f9783b028785c1ff16acaf44a8ce1f2a&oe=60654B74",
    },
    {
      name: "Davey Cornelissenn",
      email: "daveyy.cornelissen@retrii.com",
      avatar:
        "https://scontent-bru2-1.xx.fbcdn.net/v/t1.0-9/50119482_2022396431173690_2032509688473452544_n.jpg?_nc_cat=102&ccb=3&_nc_sid=09cbfe&_nc_ohc=uGTn6KvZ3f8AX-bleJd&_nc_ht=scontent-bru2-1.xx&oh=f9783b028785c1ff16acaf44a8ce1f2a&oe=60654B74",
    },
  ],
  myAdresses: [
    {
      id: 1,
      address: "Torenallee 20, 5111BC, Eindhoven",
    },
    {
      id: 2,
      address: "Rouwkooplaan 5, 2251 AP Voorschoten",
    },
  ],
  showSettingsDropDown: false,
  dropDownId: 0,
  language: "en",
  activeCountries: [],
  visitor: "",
  contactPhone: "+31 851 306 800"
};

const getters = {
  getAllUsers: (state) => {
    return state.users;
  },
  getAllMyAddresses: (state) => {
    return state.myAdresses;
  },
  getShowSettingsDropDown: (state) => {
    return state.showSettingsDropDown;
  },
  getDropDownId: (state) => {
    return state.dropDownId;
  },
  getRole: (state) => {
    return state.role;
  },
  getLanguage: (state) => {
    return state.language
  },
  getVisitor: (state) => {
    return state.visitor;
  },
  getActiveCountries: (state) => {
    return state.activeCountries;
  },
  getContactPhone: (state) => {
    return state.contactPhone
  }
};

const actions = {
  setShowSettingsDropDown({ commit }, bool) {
    commit("SET_SHOW_SETTINGS_DROPDOWN", bool);
  },
  setDropDownId({ commit }, id) {
    commit("SET_DROPDOWN_ID", id);
  },
  setLanguage({commit}, language) {
    commit('SET_LANGUAGE', language)
  },
  setVisitor({commit}, visitor) {
    commit('SET_VISITOR', visitor)
  },
  setActiveCountries({ commit }) {
      getActiveCountries().then((response) => {
        commit('SET_ACTIVE_COUNTRIES', response.data.data)
      }).catch(() => {
        //
      })
  }
};

const mutations = {
  SET_SHOW_SETTINGS_DROPDOWN(state, bool) {
    state.showSettingsDropDown = bool;
  },
  SET_DROPDOWN_ID(state, id) {
    state.dropDownId = id;
  },
  SET_LANGUAGE(state, language) {
    state.language = language
  },
  SET_VISITOR(state, visitor) {
    state.visitor = visitor
  },
  SET_ACTIVE_COUNTRIES(state, countries) {
    state.activeCountries = countries
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
