import router from './../../router'
import { login, logout, register, resetPassword, updatePassword, getCurrentMaker } from './authEndpoints'
import i18n from './../../i18n'

const state = {
  jilster_token: '' || localStorage.getItem('jilster_token'),
  user: '',
  error: '',
  isAdmin: false || localStorage.getItem('is_jilster_admin'),
}

const getters = {
  getToken: (state) => {
    return state.jilster_token
  },
  getLoggedInUser: (state) => {
    return state.user
  },
  getError: (state) => {
    return state.error
  },
  getIsAdmin: (state) => {
    return state.isAdmin
  },
}

const actions = {
  async getCurrentMaker({ commit }) {
    try {
      const response = await getCurrentMaker()
      commit('SET_USER', response.data.data)
    } catch (error) {
      //
    }
  },

  async setAdminLogin({ commit }, token) {
    commit('SET_TOKEN', token)
    localStorage.setItem('jilster_token', token)
    localStorage.setItem('is_jilster_admin', token)
    commit('SET_IS_ADMIN', true)
    await router.push('/')
  },

  async register({ commit }, user) {
    try {
      const response = await register(
        user.email,
        user.nickName,
        user.password,
        user.locale,
        user.device_name,
        user.marketingOptIn
      )

      commit('SET_TOKEN', response.data.data.token)
      commit('SET_USER', response.data.data.user)
      i18n.locale = localStorage.getItem('language')
      localStorage.setItem('jilster_token', response.data.data.token)

      commit('SET_ERROR', '')
      await router.push('/')
      if (user.token === undefined) {
        await router.push('/')
      } else {
        await router.push('/?magazine_id=' + user.magazine_id + '&token=' + user.token)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors.email &&
        error.response.data.errors.email.length &&
        error.response.data.errors.email.find((e) => e === 'The e-mail has already been taken.')
      ) {
        commit('SET_ERROR', 'That email is already being used. Login')
      } else {
        commit('SET_ERROR', 'error')
      }
      //commit("SET_ERROR", "Something went wrong");
    }
  },
  async login({ commit }, user) {
    try {
      const response = await login(user.email, user.password, 'Vue Makerspace')
      commit('SET_TOKEN', response.data.data.token)
      commit('SET_USER', response.data.data.user)
      i18n.locale = response.data.data.user.locale ?? 'en'
      localStorage.setItem('jilster_token', response.data.data.token)
      commit('SET_ERROR', '')
      if (user.token === undefined) {
        await router.push('/')
      } else {
        await router.push('/document/' + user.magazine_id + '?token=' + user.token)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        commit('SET_ERROR', 'Credentials are incorrect')
      } else {
        commit('SET_ERROR', 'Something went wrong')
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  async resetPassword({ commit }, data) {
    try {
      await resetPassword(data.email, data.password)
      commit('SET_ERROR', '')
    } catch (error) {
      commit('SET_ERROR', 'Something went wrong')
    }
  },
  async updatePassword({ commit }, data) {
    try {
      await updatePassword(data.email, data.password, data.passwordresettoken)
      commit('SET_ERROR', '')
    } catch (error) {
      commit('SET_ERROR', 'Something went wrong')
    }
  },
  async logout({ commit }) {
    try {
      await logout()
      localStorage.removeItem('jilster_user')
      localStorage.removeItem('jilster_token')
      localStorage.removeItem('is_jilster_admin')
      commit('SET_TOKEN', '')
      commit('SET_USER', '')
      commit('SET_CREATIONS', [])
      commit('SET_IS_ADMIN', false)
      await router.push('/login')
    } catch (error) {
      //
    }
  },
  setError({ commit }, error) {
    commit('SET_ERROR', error)
  },
}

const mutations = {
  SET_IS_ADMIN(state, bool) {
    state.isAdmin = bool
  },
  SET_TOKEN(state, token) {
    state.jilster_token = token
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
