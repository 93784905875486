<template>
  <div>
    <div class="w-full pl-64 pr-12 pt-28">
      <PageTitle :title="headerInfo.title" :subtitle="headerInfo.subtitle" :image="headerInfo.image" />
    </div>

    <b-container fluid class="settings">
      <b-container class="">
        <div class="sub_menu">
          <p class="active_sub_menu">{{ $t('settings.subheader.account') }}</p>
        </div>
      </b-container>
      <b-container>
        <b-form v-if="$store.getters['getLoggedInUser']">
          <h4>{{ $t('settings.user_details.title') }}</h4>
          <label for="name">{{ $t('settings.user_details.name') }}</label>
          <Input
            :isDisabled="true"
            icon="locker-filled"
            icon-color="#CDC7C2"
            disabled
            id="name"
            placeholder-text="Name"
            :value="$store.getters['getLoggedInUser'].nickName"
          />
          <label for="email">{{ $t('settings.user_details.email') }}</label>
          <Input
            :isDisabled="true"
            icon="locker-filled"
            icon-color="#CDC7C2"
            id="email"
            placeholder-text="Email"
            :value="$store.getters['getLoggedInUser'].email"
          />
          <h4 class="language">{{ $t('settings.language_interface.title') }}</h4>
          <label for="" class="languageLabel">{{ $t('settings.language_interface.language') }}</label>
          <Select
            :id="'settings'"
            :options="options"
            icon-color="#534C45"
            icon="chevron-down"
            @valueChanged="valueChanged"
          />
          <!--        <h4 class="delete">{{ $t("settings.delete_account.title") }}</h4>-->
          <!--        <p class="deleteInfo">-->
          <!--          {{ $t("settings.delete_account.warning") }}-->
          <!--        </p>-->
          <!--        <Button-->
          <!--          style="width: 100%; margin-bottom: 20px"-->
          <!--          :button-text="$t('settings.delete_account.button_delete')"-->
          <!--          button-type="wrong_button_5"-->
          <!--          icon-color="#d93025"-->
          <!--          icon="delete"-->
          <!--        />-->
        </b-form>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import Input from '@/components/Input'
import Select from '@/components/Select'
import PageTitle from '@/components/PageTitle.vue'

import bgImageSettings from '@/assets/settings-header.png'

export default {
  name: 'Settings',
  components: { Input, Select, PageTitle },
  data() {
    return {
      options: [
        { value: 'en', text: 'English' },
        { value: 'nl', text: 'Nederlands' },
        { value: 'de', text: 'Deutsch' },
      ],
      headerInfo: {
        title: this.$t('settings.header.title'),
        subtitle: this.$t('settings.header.description'),
        image: `url(${bgImageSettings})`,
      },
    }
  },
  created: function () {
    // document.body.style.backgroundColor = '#FCFCFC'
    this.options = this.getOptions()
  },
  methods: {
    async valueChanged(object) {
      await this.$store.dispatch('setLanguage', object[0])

      const settings = {
        country_id: this.$store.getters['getLoggedInUser'].country_id ?? 1,
        locale: object[0],
      }

      await this.$store.dispatch('updateUserCountryId', settings)
      localStorage.setItem('language', object[0])
      window.location.reload()
    },
    getOptions() {
      const selectedLanguage = localStorage.getItem('language')
      if (selectedLanguage === null || selectedLanguage === 'en') {
        return [
          { value: 'en', text: 'English' },
          { value: 'nl', text: 'Nederlands' },
          { value: 'de', text: 'Deutsch' },
        ]
      }

      if (selectedLanguage === 'nl') {
        return [
          { value: 'nl', text: 'Nederlands' },
          { value: 'en', text: 'English' },
          { value: 'de', text: 'Deutsch' },
        ]
      } else if (selectedLanguage === 'de') {
        return [
          { value: 'de', text: 'Deutsch' },
          { value: 'en', text: 'English' },
          { value: 'nl', text: 'Nederlands' },
        ]
      } else if (selectedLanguage === 'fr') {
        return [
          { value: 'en', text: 'English' },
          { value: 'nl', text: 'Nederlands' },
          { value: 'de', text: 'Deutsch' },
        ]
      } else {
        return [
          { value: 'en', text: 'English' },
          { value: 'nl', text: 'Nederlands' },
          { value: 'de', text: 'Deutsch' },
        ]
      }
    },
  },
}
</script>
