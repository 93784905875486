const state = {
  checkoutDataComplete: false,
  stepComplete: false,
  orderCheckout: [
    {
      id: 1,
      productImage:
        "https://www.abonnement.nl/images/covers/plus-magazine-large.jpg",
      productType: "Magazine, 180 dpi",
      productTitle: "Jilster Magazine 2021",
      amount: 2,
      price: 5,
    },
    {
      id: 2,
      productImage:
        "https://www.abonnement.nl/images/covers/plus-magazine-large.jpg",
      productType: "Magazine, 190 dpi",
      productTitle: "Jilster Magazine 2021",
      amount: 5,
      price: 8,
    },
  ],
  checkoutPrice: 0,
  shippingCost: {
    value: 0,
  },
};

const getters = {
  getCheckoutDataComplete: (state) => {
    return state.checkoutDataComplete;
  },
  getStepComplete: (state) => {
    return state.stepComplete;
  },
  getOrderCheckout: (state) => {
    return state.orderCheckout;
  },
  getCheckoutPrice: (state) => {
    return state.checkoutPrice;
  },
  getShippingCost: (state) => {
    return state.shippingCost;
  },
};

const actions = {
  getCheckoutDataComplete({ commit }, status) {
    commit("SET_CHECKOUT_DATA", status);
  },
  stepComplete({ commit }, status) {
    commit("SET_STEP_COMPLETE", status);
  },
  removeSelectedOrder({ commit }, order) {
    commit("REMOVE_SELECTED_ORDER", order);
  },
  setCheckoutPrice({ commit }, price) {
    commit("SET_CHECKOUT_PRICE", price);
  },
  setNewPrice({ commit }, payload) {
    commit("SET_SINGLE_ORDER_PRICE", payload);
  },
  setShippingPrice({ commit }, shippingPrice) {
    commit("SET_SHIPPING_PRICE", shippingPrice);
  },
};

const mutations = {
  SET_SHIPPING_PRICE(state, shippingPrice) {
    state.shippingCost = shippingPrice;
  },
  SET_CHECKOUT_DATA(state, status) {
    state.checkoutDataComplete = status;
  },
  SET_STEP_COMPLETE(state, status) {
    state.stepComplete = status;
  },
  SET_CHECKOUT_PRICE(state, price) {
    state.checkoutPrice = price;
  },
  REMOVE_SELECTED_ORDER(state, id) {
    if (state.orderCheckout.length === 1) {
      alert("Last item!");
    } else {
      removeByAttr(state.orderCheckout, "id", id);
    }
  },
  SET_SINGLE_ORDER_PRICE(state, payload) {
    let order = state.orderCheckout.find((order) => order.id === payload.id);
    order.price = payload.price;
    order.amount = payload.amount;
    let index = state.orderCheckout.indexOf(order);
    state.orderCheckout[index].price = order.price;
    state.orderCheckout[index].amount = order.amount;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

let removeByAttr = function (arr, attr, value) {
  let i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      // eslint-disable-next-line no-prototype-builtins
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};
