<template>
  <div class="document-settings" v-if="$store.getters['getDocumentsFromApi'].length !== 0">
    <div class="heading">
      <p v-if="!this.$store.getters['getShowInviteForm']">
        {{$t("documentsettings.drag.pages")}}
      </p>
      <p v-else-if="this.$store.getters['getAllowDocumentSelect']">
        {{ $t('documentinvite.select.instructions') }}
      </p>
      <div class="settings-views">
        <div
          v-b-tooltip.hover
          title="Document view"
          :class="{
            itemActive: !this.$store.getters['getViewedAsCreationInSettings'],
          }"
          class="view-as-project viewIcon"
          @click="$store.dispatch('setViewedAsCreationInSettingsStatus', false)"
        >
          <i class="icon-book"></i>
        </div>
        <div
          v-b-tooltip.hover
          title="Page view"
          :class="{
            itemActive: this.$store.getters['getViewedAsCreationInSettings'],
          }"
          class="view-as-creation viewIcon"
          @click="$store.dispatch('setViewedAsCreationInSettingsStatus', true)"
        >
          <i class="icon-page"></i>
        </div>
      </div>
    </div>
    <div class="document-settings-content" style="overflow: hidden;">
      <div class="document-settings-item-container">
        <div
          v-if="this.$store.getters['getViewedAsCreationInSettings']"
          style="height: 100%"
        >
          <b-row v-if="!this.$store.getters['getInviteMenu']">
            <b-col lg="2" v-if="getFrontCover !== undefined" draggable="false" :class="{ lindaColSize: getIsLindaMagazine }">
              <Document
                :invitee="getMakerByPage(getFrontCover)"
                :page="getFrontCover.number"
                :pageCover="getFrontCover.type"
                :magazine-page-id="getFrontCover.id"
                :magazine-id="getFrontCover.magazine_id"
                :imageUrl="createThumbnailUrl(getFrontCover.magazine_id, getFrontCover.id)"
              />
            </b-col>
            <b-col
              v-for="document in sortDocuments"
              :key="document.id"
              class="can-be-dragged"
              :data-number="document.number"
              :class="{ lindaColSize: getIsLindaMagazine }"
              :draggable="!$store.getters['getShowInviteForm']"
              lg="2"
              @dragstart="startDrag($event, document)"
              @dragend="endDrag($event)"
              @drop="onDrop($event, document)"
              @dragover.prevent
              @dragenter.prevent
            >
              <Document
                :IdsToDelete="$store.getters['getDocumentsToDelete']"
                :class="[
                  {
                    documentAllowHover:
                      !$store.getters['getShowInviteForm'] &&
                      $store.getters['getDocumentsToDelete'].find(
                        (docId) => docId == document.id
                      ) === undefined,
                  },
                  { isNewPage: document.newPage },
                  { selected_document: selectedPageStyle(document) },
                ]"
                :documentId="document.id"
                :invitee="getMakerByPage(document)"
                :newPage="document.newPage"
                :page="document.number"
                :magazine-page-id="document.id"
                :magazine-id="document.magazine_id"
                :imageUrl="createThumbnailUrl(document.magazine_id, document.id)"
                @click.native="
                  $store.getters['getShowInviteForm'] &&
                  $store.getters['getAllowDocumentSelect']
                    ? selectDocumentToInviteTo(document)
                    : clickNotAllowed()
                "
              />
              <!--              @click.native="selectDocumentToInviteTo(document)"-->
            </b-col>
            <b-col v-if="getBackCover !== undefined" lg="2" draggable="false" :class="{ lindaColSize: getIsLindaMagazine }">
              <Document
                :invitee="getMakerByPage(getBackCover)"
                :page="getBackCover.number"
                :pageCover="getBackCover.type"
                :magazine-page-id="getBackCover.id"
                :magazine-id="getBackCover.magazine_id"
                :imageUrl="createThumbnailUrl(getBackCover.magazine_id, getBackCover.id)"
              />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col v-if="getFrontCover !== undefined" lg="2" draggable="false" :class="{ lindaColSize: getIsLindaMagazine }">
              <Document
                :documentId="getFrontCover.id"
                :invitee="getMakerByPage(getFrontCover)"
                :page="getFrontCover.number"
                :pageCover="getFrontCover.type"
                :magazine-page-id="getFrontCover.id"
                :magazine-id="getFrontCover.magazine_id"
                :imageUrl="createThumbnailUrl(getFrontCover.magazine_id, getFrontCover.id)"
              />
            </b-col>
            <b-col
              v-for="document in sortDocuments"
              :key="document.id"
              lg="2"
              class="can-be-dragged"
              :data-number="document.number"
              :class="{ lindaColSize: getIsLindaMagazine }"
              :draggable="!$store.getters['getShowInviteForm']"
              @dragstart="startDrag($event, document)"
              @dragend="endDrag($event)"
              @drop="onDrop($event, document)"
              @dragover.prevent
              @dragenter.prevent
            >
              <Document
                :IdsToDelete="$store.getters['getDocumentsToDelete']"
                :class="[
                  {
                    documentAllowHover:
                      !$store.getters['getShowInviteForm'] &&
                      $store.getters['getDocumentsToDelete'].find(
                        (docId) => docId == document.id
                      ) === undefined,
                  },
                  { isNewPage: document.newPage },
                  { selected_document: selectedPageStyle(document) },
                ]"
                :documentId="document.id"
                :invitee="getMakerByPage(document)"
                :magazine-page-id="document.id"
                :magazine-id="document.magazine_id"
                :newPage="document.newPage"
                :page="document.number"
                :imageUrl="createThumbnailUrl(document.magazine_id, document.id)"

                @click.native="
                  $store.getters['getShowInviteForm'] &&
                  $store.getters['getAllowDocumentSelect']
                    ? selectDocumentToInviteTo(document)
                    : clickNotAllowed()
                "
              />
              <!--              @click.native="selectDocumentToInviteTo(document)"-->
            </b-col>
            <b-col v-if="getBackCover !== undefined" lg="2" draggable="false" :class="{ lindaColSize: getIsLindaMagazine }">
              <Document
                :class="[
                  { isNewPage: getBackCover.newPage },
                  { selected_document: selectedPageStyle(getBackCover) },
                ]"
                :invitee="getMakerByPage(getBackCover)"
                :page="getBackCover.number"
                :pageCover="getBackCover.type"
                :magazine-page-id="getBackCover.id"
                :magazine-id="getBackCover.magazine_id"
                :imageUrl="createThumbnailUrl(getBackCover.magazine_id, getBackCover.id)"
              />
              <!--              @click.native="selectDocumentToInviteTo(getBackCover)"-->
            </b-col>
          </b-row>
        </div>
        <div
          v-else
          style="height: 100%; max-width: 100%;"
          class="settingsDocumentView"
        >
          <b-row style="flex-direction: row">
            <b-col  v-if="getFrontCover !== undefined" lg="2" draggable="false" :class="{ lindaColSize: getIsLindaMagazine, zeroPadding: getIsLindaMagazine }">
              <Document
                :class="[
                  { isNewPage: getFrontCover.newPage },
                  { selected_document: selectedPageStyle(getFrontCover) },
                ]"
                :invitee="getMakerByPage(getFrontCover)"
                :magazine-page-id="getFrontCover.id"
                :magazine-id="getFrontCover.magazine_id"
                :page="getFrontCover.number"
                :pageCover="getFrontCover.type"
                :imageUrl="createThumbnailUrl(getFrontCover.magazine_id, getFrontCover.id)"
              />
            </b-col>
            <b-col lg="12" :class="{ lindaColSize: getIsLindaMagazine }"></b-col>

            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
              <div v-for="i in Math.ceil(sortDocuments.length / 2)" :key="'d' + i" style="display: flex; flex-direction: row">
                <b-col
                    v-for="(document, index) in sortDocuments.slice((i - 1) * 2, ((i - 1) * 2) + 2)"
                    :key="document.id"
                    lg="2"
                    class="can-be-dragged"
                    :data-number="document.number"
                    :class="{ margingRight: (index === 1), lindaColSize: getIsLindaMagazine, zeroPadding: getIsLindaMagazine }"
                    :draggable="!$store.getters['getShowInviteForm']"
                    @dragstart="startDrag($event, document)"
                    @dragend="endDrag($event)"
                    @drop="onDrop($event, document)"
                    @dragover.prevent
                    @dragenter.prevent
                >
                  <Document
                      :IdsToDelete="$store.getters['getDocumentsToDelete']"
                      :class="[
                  {
                    documentAllowHover:
                      !$store.getters['getShowInviteForm'] &&
                      $store.getters['getDocumentsToDelete'].find(
                        (docId) => docId == document.id
                      ) === undefined,
                  },
                  { isNewPage: document.newPage },
                  { selected_document: selectedPageStyle(document) },
                ]"
                      :documentId="document.id"
                      :invitee="getMakerByPage(document)"
                      :magazine-page-id="document.id"
                      :magazine-id="document.magazine_id"
                      :newPage="document.newPage"
                      :page="document.number"
                      :imageUrl="createThumbnailUrl(document.magazine_id, document.id)"
                      @click.native="
                  $store.getters['getShowInviteForm'] &&
                  $store.getters['getAllowDocumentSelect']
                    ? selectDocumentToInviteTo(document)
                    : clickNotAllowed()
                "
                  />
                </b-col>
              </div>
            </div>


            <b-col lg="12"></b-col>
            <b-col v-if="getBackCover !== undefined" lg="2" draggable="false" :class="{ lindaColSize: getIsLindaMagazine, zeroPadding: getIsLindaMagazine }">
              <Document
                :class="[
                  { isNewPage: getBackCover.newPage },
                  { selected_document: selectedPageStyle(getBackCover) },
                ]"
                :invitee="getMakerByPage(getBackCover)"
                :magazine-page-id="getBackCover.id"
                :magazine-id="getBackCover.magazine_id"
                :page="getBackCover.number"
                :pageCover="getBackCover.type"
                :imageUrl="createThumbnailUrl(getBackCover.magazine_id, getBackCover.id)"
              />
              <!--              @click.native="selectDocumentToInviteTo(getBackCover)"-->
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-icon" v-else>
    <img src="/loading.gif" alt="loading">
  </div>
</template>

<script>
import Document from "@/components/Document";
import axios from "axios";

export default {
  name: "Documents",
  components: { Document },
  data() {
    return {
      documentsToDelete: [],
    };
  },
  methods: {
    async createThumbnailUrl(creationId, canvasId) {
      let imageUrl = "";
      await axios.get(`https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${creationId}/png_thumbnail/${canvasId}.png`)
          .then(() => {
            const randomInt = Math.floor(Math.random() * 1000);
            imageUrl = `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${creationId}/png_thumbnail/${canvasId}.png?id=${randomInt}`;
          })
          .catch(() => {
            imageUrl = "/page.png"
          })
      return imageUrl;
    },
    getDocuments() {
      return this.$store.getters["getDocuments"];
    },
    getMakerByPage(document) {
      let invitees = this.$store.getters["getInvitees"];
      let userInvited = "";
      invitees.find((user) => {
        user.pages.forEach((a) => {
          if (document.id === a.id) {
            userInvited = user;
          }
        });
      });

      return userInvited;
    },
    selectedPageStyle(page) {
      return this.$store.getters["getSelectedDocuments"]
        .map((a) => a.id)
        .includes(page.id);
    },
    startDrag(evt, item) {
      if (this.$store.getters['getShowInviteForm']) return;

      evt.dataTransfer.setData("position", item.number);
      evt.dataTransfer.setData("docId", item.id);

      this.$store.dispatch('setDraggingNumber', item.number)

      setTimeout(function () {
        evt.target.classList.add('hideDragElement');
      })
    },
    endDrag: (evt) => {
      evt.target.classList.remove('hideDragElement');
    },
    onDrop(evt, item) {
      evt.preventDefault();
      if (this.$store.getters['getShowInviteForm']) return;
      
      if (
        this.checkIfDocumentIsInDelete(item.id) ||
        this.checkIfDocumentIsInDelete(evt.dataTransfer.getData("docId"))
      ) {
        return;
      }

      const oldPosition = evt.dataTransfer.getData("position");
      let document = this.$store.getters["getDocuments"].find(
        (item) => item.number === parseInt(oldPosition)
      );

      const isHigher = document.number > item.number

      document.number = item.number;

      this.$store.dispatch("setSettingsChanged", true);

      if(isHigher) {
        this.$store.dispatch('movePositionsUp', document)
      }
      else {
        this.$store.dispatch('movePositionsDown', document)
      }
    },
    selectDocumentToInviteTo(document) {
      if (!this.$store.getters["getSelectedDocuments"].map((a) => a.id).includes(document.id)) {
        this.$store.dispatch("selectDocumentsToInviteTo", document);
      }
      else {
        this.$store.dispatch("removeSelectedDocument", document);
      }
    },
    clickNotAllowed() {
      return false;
    },
    checkIfDocumentIsInDelete(id) {
      const doc = this.$store.getters["getDocumentsToDelete"].find(
        (docId) => docId == id
      );
      return doc !== undefined && doc !== null;
    },
    // setDocumentsToDelete(list) {
    //   if (!this.pushedDelete) {
    //     let documentsToDelete = list.slice(Math.max(list.length - 4, 0))
    //     documentsToDelete.forEach(document => this.documentsToDelete.push(document.id));
    //     this.pushedDelete = true;
    //   } else {
    //     this.pushedDelete = false;
    //     this.documentsToDelete = [];
    //   }
    // }
  },
  computed: {
    sortDocuments() {
      let documents = this.getDocuments();
      let list = [];
      documents.filter((document) => {
        if (document.type === 2 || document.type === 3) list.push(document);
      });
      return list.sort((a, b) => {
        return a.number - b.number;
      });
    },
    getBackCover() {
      return this.getDocuments().find(
        (document) => document.type === 4
      );
    },
    getFrontCover() {
      return this.getDocuments().find(
        (document) => document.type === 1
      );
    },
    getIsLindaMagazine() {
      return this.$store.getters['getIsLindaMagazine']
    }
  },
};
</script>
