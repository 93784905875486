]<template>
  <div class="order-checkout-bar d-flex">
    <div class="d-flex return-editing left" v-on:click="closeScreen()">
      <i class="icon-chevron-left"></i>
      <span>{{$t("checkout.continue.editing")}}</span>
    </div>
    <p>{{$t("checkout.need.help")}} <span v-on:click="contactTel()" style="cursor: pointer">{{$store.getters['getContactPhone']}}</span></p>
    <div class="right"></div>
  </div>
</template>

<script>
export default {
name: "OrderCheckoutBar",
  methods: {
    closeScreen() {
      this.$router.push('/document/' + this.$route.params.id)
    },
    contactTel() {
      window.location.href = "tel: " + this.$store.getters['getContactPhone']
    }
  }
}
</script>
