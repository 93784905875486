import axios from 'axios'

export default {
  methods: {
    async createThumbnailUrl(creationId, canvasId, route = '', type = 'png_thumbnail', isLinda = false) {
      let imageUrl = ''
      const randomInt = Math.floor(Math.random() * 1000)

      try {
        await axios.get(
          `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${creationId}/${type}/${canvasId}.png`
        )
        imageUrl = `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${creationId}/${type}/${canvasId}.png?id=${randomInt}`
      } catch (e) {
        if (route === '') {
          imageUrl = '/page.png'
        } else if (isLinda) {
          imageUrl = `/linda_blank.png?id=${randomInt}`
        } else {
          imageUrl = `/blank.png?id=${randomInt}`
        }
      }

      return imageUrl
    },
  },
}
