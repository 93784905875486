<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="70" height="70" rx="35" fill="#FAB005" />
    <path
      d="M50.3125 33.9062C50.3125 31.8554 48.5956 30.1875 46.4844 30.1875H44.5703V24.6094C44.5703 19.4831 40.2771 15.3125 35 15.3125C29.7229 15.3125 25.4297 19.4831 25.4297 24.6094V30.1875H23.5156C21.4044 30.1875 19.6875 31.8554 19.6875 33.9062V48.7812C19.6875 50.8321 21.4044 52.5 23.5156 52.5H46.4844C48.5956 52.5 50.3125 50.8321 50.3125 48.7812V33.9062ZM29.2578 24.6094C29.2578 21.534 31.8341 19.0312 35 19.0312C38.1659 19.0312 40.7422 21.534 40.7422 24.6094V30.1875H29.2578V24.6094Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Lock",
};
</script>
