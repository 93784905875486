import httpClient from "../../../httpClient";

const ORDER_END_POINT = "v3/maker/orders";
const DOWNLOAD_END_POINT = "v3/maker/downloads";

const getOrders = () => httpClient.get(`${ORDER_END_POINT}`);
const previewOrder = (orderId) =>
  httpClient.get(`${ORDER_END_POINT}/${orderId}`);
const getDownloads = () => httpClient.get(`${DOWNLOAD_END_POINT}`);

export { getOrders, previewOrder, getDownloads };
