<template>
  <div>
    <b-sidebar id="sidebar-right" ref="sidebar_creation" no-header right shadow style="z-index: 0">
      <div class="sidebarRightHeader" v-if="route !== 'CreationsOverview'">
        <SideBarRightFlatHeader
          v-if="route !== ''"
          :show-nomination-badge="
            isCreationNominated && !isLinda && candidate && candidate.Status !== 'Rejected' && showAwardUI
          "
          :magazine-id="magazineId"
          :ranking="(candidate && candidate.CountryRanking) || null"
          :front-cover-id="frontCoverId"
        />
        <SideBarRightRoundedHeader v-else />
      </div>

      <div class="px-4 py-3 sidebarRightContent" v-if="route === 'CreationsOverview'">
        <SideBarRightHomeContent />
      </div>

      <div
        class="sidebarRightContent"
        :class="
          isCreationNominated && !isLinda && candidate && candidate.Status !== 'Rejected' && showAwardUI && 'pt-9'
        "
        v-else
      >
        <!-- Notice about creation nomiation -->
        <div
          v-if="
            isCreationNominated &&
            !isLinda &&
            candidate &&
            candidate.Status !== 'Rejected' &&
            showAwardUI &&
            !awardVotingEnded
          "
          class="award-notice"
          :style="getBgColorAwardBadge"
        >
          <span class="award-notice-btn" @click="$store.commit('SET_SHOW_UNNOMINATE_MODAL', true)">
            {{ $t('magazine.award.right.sidebar.unnominate.button') }}
          </span>
        </div>

        <div v-if="!showNotifications">
          <div
            class="d-flex can-order-creation text-sm"
            v-if="this.$store.getters['getDocumentRole'] !== 3 && this.$store.getters['getCreationStatus'] === 3"
          >
            <Ok />
            <p>
              {{ $t('can_order_creation_document_overview') }}
              <router-link class="underline" :to="{ name: 'OrderCheckout', params: { id: this.$route.params.id } }">
                {{ $t('can_order_creation_document_overview_print') }}
              </router-link>
            </p>
          </div>

          <div
            class="d-flex cannot-order-linda"
            v-else-if="
              this.$store.getters['getSpine'].status !== 3 &&
              (this.$store.getters['getIsLindaMagazine'] || this.$store.getters['getIsGlossyMagazine'])
            "
          >
            <InfoSvg />
            <p>
              {{ $t('linda_spine_order_check') }}<br />
              <a
                target="_blank"
                class="underline"
                :href="`${this.editorUrl}/${this.$route.params.id}/${
                  this.$store.getters['getDocumentsFromApi'][this.$store.getters['getDocumentsFromApi'].length - 1].id
                }/${this.$store.getters['getDocumentsFromApi'][0].id}/spine#${this.$store.getters['getToken']}`"
              >
                {{ $t('linda_spine_order_check_link') }}</a
              >
            </p>
          </div>

          <!-- <div class="contentWrapper">
            <SideBarRightInviteTeamContent v-if="this.$store.getters['getDocumentRole'] !== 3" />
            <SideBarRightViewTeamContent v-else />
          </div> -->

          <!-- Start Here content -->
          <div>
            <!-- Template loading -->
            <div v-if="disableButtons !== null && disableButtons === true" class="" style="padding: 8px">
              <p class="text-center" style="margin: 0 !important; margin-bottom: 8px; color: black !important">
                {{ updatedDocsLength }} {{ $t('document.overview.sidebar.of') }} {{ getTotalDocsLength }}
                {{ $t('document.overview.sidebar.pages.upload') }}
              </p>
              <div class="w-full flex justify-center items-center">
                <div
                  class="bg-wg-80 bg-opacity-30 h-3 rounded-2xl relative overflow-hidden w-full"
                  style="max-width: 211px; margin-bottom: 9px"
                >
                  <span class="absolute top-0 left-0 h-3 bg-black" :style="`width: ${progress}%`"></span>
                </div>
              </div>
              <p class="text-xs text-center" style="margin: 0 !important; color: #746a5f !important">
                {{ $t('document.overview.sidebar.minutes') }}
              </p>
            </div>

            <!-- Template loaded -->
            <div v-if="templateLoadedMessage" class="" style="padding: 8px">
              <div class="flex justify-center">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0Z"
                    fill="#2A65C4"
                  />
                  <path
                    d="M16.9391 7.47559C16.8888 7.61766 16.8086 7.74888 16.7033 7.86153L10.7051 14.5963C10.5984 14.716 10.4662 14.8137 10.3169 14.883C10.1676 14.9523 10.0046 14.9918 9.83809 14.9988C9.67162 15.0059 9.50539 14.9805 9.35002 14.9241C9.19464 14.8677 9.05352 14.7817 8.93564 14.6715L5.33675 11.3041C5.11823 11.0924 4.99731 10.8089 5.00005 10.5146C5.00278 10.2203 5.12894 9.93874 5.35136 9.73062C5.57379 9.52251 5.87467 9.40446 6.18921 9.40191C6.50375 9.39935 6.80678 9.51249 7.03303 9.71695L9.70221 12.2155L14.8606 6.42478C14.96 6.30753 15.0835 6.21008 15.2238 6.13814C15.3641 6.06621 15.5183 6.02125 15.6774 6.00591C15.8365 5.99057 15.9973 6.00516 16.1503 6.04883C16.3033 6.09249 16.4455 6.16435 16.5684 6.26016C16.6912 6.35598 16.7924 6.47382 16.8659 6.60676C16.9394 6.7397 16.9837 6.88505 16.9963 7.03426C17.0089 7.18347 16.9894 7.33352 16.9391 7.47559Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p class="text-center" style="margin: 0 !important; margin-top: 8px; color: black !important">
                {{ $t('document.overview.sidebar.template.fully.loaded') }}
              </p>
            </div>

            <!-- Template tabs -->
            <div class="bg-wg-light-information px-6 py-2" :class="isCreationNominated ? 'mt-12' : ''">
              <h3 class="text-wg-light-accent font-bold text-xl">
                {{ $t('document.overview.sidebar.checklist.title') }}
              </h3>
            </div>

            <div>
              <div v-for="(item, i) in tabs" :key="i" class="border-b border-solid" style="border-color: #efefef">
                <button
                  class="flex justify-between items-center w-full space-x-2"
                  style="padding: 16px 24px !important"
                  @click="activeTab = activeTab === i ? null : i"
                >
                  <span v-if="item.completed">
                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.9391 1.47559C11.8888 1.61766 11.8086 1.74888 11.7033 1.86153L5.7051 8.59627C5.59839 8.71604 5.46619 8.81369 5.31691 8.88301C5.16764 8.95232 5.00456 8.99177 4.83809 8.99884C4.67162 9.00592 4.50539 8.98046 4.35002 8.9241C4.19464 8.86774 4.05352 8.7817 3.93564 8.67148L0.336751 5.30411C0.118229 5.09241 -0.00268791 4.80887 4.5349e-05 4.51457C0.00277861 4.22026 0.128943 3.93874 0.351364 3.73062C0.573786 3.52251 0.874668 3.40446 1.18921 3.40191C1.50375 3.39935 1.80678 3.51249 2.03303 3.71695L4.70221 6.21554L9.86062 0.42478C9.96005 0.307533 10.0835 0.210076 10.2238 0.138142C10.3641 0.0662071 10.5183 0.0212483 10.6774 0.00590995C10.8365 -0.00942841 10.9973 0.00516291 11.1503 0.0488268C11.3033 0.0924907 11.4455 0.164346 11.5684 0.260162C11.6912 0.355979 11.7924 0.473823 11.8659 0.606762C11.9394 0.739701 11.9837 0.88505 11.9963 1.03426C12.0089 1.18347 11.9894 1.33352 11.9391 1.47559Z"
                        fill="#2A65C4"
                      />
                    </svg>
                  </span>
                  <span
                    class="flex-grow text-left"
                    :class="item.completed ? 'text-wg-70' : 'text-wg-100'"
                    style="font-size: 16px !important"
                    >{{ item.title }}</span
                  >
                  <span v-if="item.content" class="transform" :class="activeTab === i ? 'rotate-180' : 'rotate-0'">
                    <span class="jilster-font" style="font-size: 24px !important">g</span>
                  </span>
                </button>

                <div v-show="item.content && activeTab === i" class="px-6 w-full">
                  <div class="pb-3 text-wg-80">
                    {{ item.content }}
                    <span
                      v-if="item.videoUrl !== ''"
                      class="text-sm text-wg-light-accent underline cursor-pointer"
                      @click="onClickViewTutorial(item)"
                      >{{ $t('document.overview.sidebar.checklist.view.tutorial.button') }}</span
                    >
                  </div>

                  <div v-if="item.action === 'invite_tutorial'" class="flex justify-start pb-3 w-full">
                    <button class="invite-btn" :disabled="disableButtons" @click="onInviteClick">
                      {{ $t('document.overview.sidebar.checklist.invite.team.button') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="showAllTutorialViewedMsg" class="px-10 mt-10">
              <div class="d-flex can-order-creation text-sm rounded-lg">
                <Ok />
                <p>{{ $t('document.overview.sidebar.checklist.viewed.all.content') }}</p>
              </div>
            </div>

            <!-- Participate in award banner -->
            <div
              v-if="
                isCreationNominated !== null &&
                isCreationNominated === false &&
                !isLinda &&
                showAwardUI &&
                !awardVotingEnded
              "
              class="banner-container"
            >
              <div
                class="banner"
                :style="`background-image: url(${
                  $i18n.locale === 'nl'
                    ? require('@/assets/participate_award_banner_nl_2024.png')
                    : require('@/assets/participate_award_banner_2024.png')
                })`"
              >
                <img
                  :src="require('@/assets/info_icon_blue.png')"
                  alt=""
                  class="badge-position"
                  @click="$store.commit('SET_SHOW_AWARD_GUIDE_MODAL', true)"
                />

                <button class="banner_button" @click="$store.commit('SET_SHOW_PARTICIPATE_AWARD_MODAL', true)">
                  {{ $t('magazine.award.right.sidebar.banner.participate.button') }}
                </button>
              </div>
            </div>

            <div v-if="$store.getters['getIsShared']" class="relative">
              <p class="flex justify-center items-center share" v-on:click="copyShareLink()">
                <SuccessShared v-if="showSuccess" class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2" />
                <Copy /> <span class="font-weight-bold cursor-pointer ml-2">{{ $t('pageflip.share') }}</span> &nbsp;{{
                  $t('pageflip.shareTwo')
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <DoneEditing
        v-if="
          this.$store.getters['getCreationEditedSuccessfullY'] &&
          (this.$store.getters['getUpdateCreationType'] === 'added' ||
            this.$store.getters['getUpdateCreationType'] === 'deleted')
        "
      />
    </b-sidebar>
  </div>
</template>

<script>
import httpClient from '../../../httpClient'

import SideBarRightFlatHeader from '@/components/sidebarright/headers/SideBarRightFlatHeader'
import SideBarRightRoundedHeader from '@/components/sidebarright/headers/SideBarRightRoundedHeader'
import SideBarRightHomeContent from '@/components/sidebarright/body/SideBarRightHomeContent'
// import SideBarRightInviteTeamContent from '@/components/sidebarright/body/SideBarRightInviteTeamContent'
// import SideBarRightViewTeamContent from '@/components/sidebarright/body/SideBarRightViewTeamContent'
import Ok from '@/components/Ok'

import Copy from '@/components/viewteam/Copy'
import SuccessShared from '@/components/SuccessShared'

// import Button from "@/components/Button";
// import Lock from "@/components/Lock";
// import Ok from "@/components/Ok";
// import Avatar from "@/components/Avatar";
// import Close from "@/components/Close";
import DoneEditing from '../DoneEditing'
import InfoSvg from '../../vectors/InfoSvg'

export default {
  name: 'SideBarRight',
  components: {
    InfoSvg,
    Ok,
    // SideBarRightViewTeamContent,
    SideBarRightFlatHeader,
    SideBarRightRoundedHeader,
    SideBarRightHomeContent,
    // SideBarRightInviteTeamContent,
    DoneEditing,
    Copy,
    SuccessShared,
  },

  props: {
    isCreationNominated: {
      type: Boolean,
      default: false,
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
    isLinda: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: null,
    },
    magazineId: {
      type: String,
      default: null,
    },
    frontCoverId: {
      type: String,
      default: null,
    },
    videoUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editorUrl: process.env.VUE_APP_EDITOR_URL,
      route: this.$route.name,
      showNotifications: false,
      notifications: this.$store.getters['getNotifications'],
      allNotifications: JSON.parse(JSON.stringify(this.$store.getters['getNotifications'])),
      showNotificationFilters: false,
      selectedNotificationFilter: 'All',
      notificationFilters: this.$store.getters['getFilters'],
      templateLoadedMessage: false,
      tabs: [
        {
          title: this.$t('document.overview.sidebar.checklist.create.account'),
          content: '',
          action: '',
          completed: true,
        },
        {
          title: this.$t('document.overview.sidebar.checklist.add.title'),
          content: '',
          action: '',
          completed: true,
        },
        {
          title: this.$t('document.overview.sidebar.checklist.start.editing'),
          content: this.$t('document.overview.sidebar.checklist.start.editing.description'),
          action: 'editing_tutorial',
          completed: false,
          videoUrl: 'https://www.youtube.com/embed/Jacdvg0K_QY?autoplay=1',
        },
        {
          title: this.$t('document.overview.sidebar.checklist.add.remove.pages'),
          content: this.$t('document.overview.sidebar.checklist.add.remove.pages.description'),
          action: 'page_add_remove_tutorial',
          completed: false,
          videoUrl: 'https://www.youtube.com/embed/tGuLHFBNUVU?autoplay=1',
        },
        {
          title: this.$t('document.overview.sidebar.checklist.share.link'),
          content: this.$t('document.overview.sidebar.checklist.share.link.description'),
          action: 'share_link_tutorial',
          completed: false,
          videoUrl: 'https://www.youtube.com/embed/Go4HeMPYyhI?autoplay=1',
        },
        {
          title: this.$t('document.overview.sidebar.checklist.invite.team'),
          content: this.$t('document.overview.sidebar.checklist.invite.team.description'),
          action: 'invite_tutorial',
          completed: false,
          videoUrl: '',
        },
      ],
      activeTab: null,
      progress: 0,
      updatedDocsLength: 0,
      showAllTutorialViewedMsg: false,
      waitForVideoModalClose: false,
      showSuccess: false,
      shareLink: process.env.VUE_APP_HEADLESS_PREVIEW,
    }
  },

  methods: {
    copyShareLink() {
      const el = document.createElement('textarea')
      el.value =
        this.shareLink && this.shareLink.trim().endsWith('/')
          ? this.shareLink + this.$route.params.id
          : this.shareLink + '/' + this.$route.params.id
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    },

    toggleNotifications() {
      this.showNotifications = !this.showNotifications
    },
    toggleFilters() {
      this.showNotificationFilters = !this.showNotificationFilters
    },
    filterNotifications(date) {
      this.toggleFilters()
      this.selectedNotificationFilter = date

      if (this.selectedNotificationFilter === 'All') {
        this.notifications = JSON.parse(JSON.stringify(this.allNotifications))
        return
      }

      const object = this.allNotifications.dates.filter((x) => {
        return x.date === this.selectedNotificationFilter
      })

      this.notifications.dates = object
    },
    onInviteClick() {
      if (this.$store.getters['getDocumentRole'] !== 3) {
        this.showInviteModal()
      } else {
        this.showViewTeamModal()
      }
    },
    showInviteModal() {
      this.$store.dispatch('setShowInviteForm', true)
      this.$store.dispatch('setShowModal', true)
    },
    showViewTeamModal() {
      this.$store.dispatch('setShowViewTeamModal', true)
    },
    async onClickViewTutorial(item) {
      this.$emit('set-video-url', item.videoUrl)

      if (!item.completed) {
        try {
          const payload = {
            key: '',
            value: true,
          }

          if (item.action === 'editing_tutorial') {
            payload.key = 'task-editing-completed'
          } else if (item.action === 'page_add_remove_tutorial') {
            payload.key = 'task-pages-completed'
          } else if (item.action === 'share_link_tutorial') {
            payload.key = 'task-share-completed'
          } else if (item.action === 'invite_tutorial') {
            payload.key = 'task-invite-completed'
          }

          if (payload.key) {
            const res = await httpClient.post('/v3/maker/meta', payload)

            if (res && res.data && res.data.status === 'Success') {
              await this.getTabsStatus()

              // Check if all tutorials are viewed
              if (
                this.tabs[2].completed &&
                this.tabs[3].completed &&
                this.tabs[4].completed &&
                this.tabs[5].completed
              ) {
                this.waitForVideoModalClose = true
              }
            }
          }
        } catch (err) {
          //
        }
      }
    },

    async getTabsStatus() {
      try {
        const res = await httpClient.get('/v3/maker/meta')

        if (res && res.data && res.data.data) {
          const arr = res.data.data

          arr.forEach((el) => {
            if (el.key === 'task-editing-completed') {
              this.tabs[2].completed = el.value
            } else if (el.key === 'task-pages-completed') {
              this.tabs[3].completed = el.value
            } else if (el.key === 'task-share-completed') {
              this.tabs[4].completed = el.value
            } else if (el.key === 'task-invite-completed') {
              this.tabs[5].completed = el.value
            }
          })
        }
      } catch (err) {
        //
      }
    },
  },

  computed: {
    showAwardUI() {
      return this.$store.state.magazineAward.showAwardUI
    },
    getBgColorAwardBadge() {
      if (this.candidate && this.candidate.CountryRanking) {
        switch (this.candidate.CountryRanking) {
          case 1:
            return 'background: linear-gradient(90deg, #C49817 0.14%, #D0A751 46.07%, #AE8E25 99.99%)'
          case 2:
            return 'background: linear-gradient(90deg, #676767 0.14%, #AEABA5 46.07%, #686868 99.99%)'
          case 3:
            return 'background: linear-gradient(90deg, #863406 0.14%, #E6772C 50.82%, #843505 99.99%)'
          default:
            return 'background: linear-gradient(90deg, #000000 0.14%, #1F1010 46.07%, #000000 99.99%)'
        }
      } else {
        return ''
      }
    },
    getDocuments() {
      return this.$store.getters['getDocumentsFromApi'] || []
    },
    getUpdatedDocsLength() {
      return this.$store.state.documents.updatedDocumentsLength
    },
    getTotalDocsLength() {
      return this.getDocuments.length || 0
    },
    awardVotingEnded() {
      return this.$store.state.magazineAward.awardVotingEnded
    },
  },

  watch: {
    disableButtons(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.templateLoadedMessage = true

        setTimeout(() => {
          this.templateLoadedMessage = false
        }, 5000)
      }
    },

    getUpdatedDocsLength(val) {
      if (val) {
        const progress = Math.round((val / this.getTotalDocsLength) * 100)

        if (this.progress < progress) {
          this.progress = progress
        }

        if (this.updatedDocsLength < val) {
          this.updatedDocsLength = val
        }
      }
    },

    showAllTutorialViewedMsg(val) {
      if (val) {
        setTimeout(() => {
          this.showAllTutorialViewedMsg = false
        }, 5000)
      }
    },

    videoUrl(newVal, oldVal) {
      if (oldVal !== newVal && this.waitForVideoModalClose) {
        this.waitForVideoModalClose = false
        this.showAllTutorialViewedMsg = true
      }
    },
  },

  async created() {
    await this.getTabsStatus()
  },
}
</script>

<style scoped>
.invite-btn {
  @apply bg-wg-light-accent rounded px-3 py-2.5 text-white text-sm;
  margin: 0 !important;
}
.invite-btn:hover {
  @apply bg-wg-light-accent-hover;
}

.invite-btn:disabled {
  @apply bg-wg-10 rounded px-3 py-2.5 text-sm;
  color: #ccc4bc;
  margin: 0 !important;
}

.award-notice {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(90deg, #000000 0.14%, #1f1010 46.07%, #000000 99.99%);
}

.award-notice .award-notice-btn {
  font-size: 10px;
  color: #f2f0ed;
  font-weight: 400;
  /* border-bottom: 1px solid #2a65c4;
  padding-bottom: 1.5px; */
  text-decoration: underline;
  width: max-content;
  cursor: pointer;
  user-select: none;
  padding: 2px 24px 4px 4px;
}

.banner {
  width: 296px;
  height: 168px;
  overflow: hidden;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto 30px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 6px;
  margin-top: 1.5rem;
}

.badge-position {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px !important;
  height: auto !important;
  width: auto !important;
  cursor: pointer;
}

.banner_button {
  width: 154px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #111;
  font-weight: 600;
  text-align: center;
  margin: 0 12px 13px auto !important;
  height: auto !important;
  padding: 10px 0 !important;
  white-space: nowrap;
}
</style>
