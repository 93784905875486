<template>
  <div
    class="bg-black bg-opacity-50 fixed top-0 left-0 w-screen h-1 min-h-screen z-50 flex justify-center items-end pt-8 px-16"
    @click.self="$emit('on-close')"
  >
    <div
      ref="container"
      class="bg-white relative rounded-t-2xl w-full h-full"
      style="max-width: 1370px; max-height: 95vh; padding: 36px 80px 0px 60px"
    >
      <!-- Single Template Info -->
      <div
        ref="wrapper"
        class="z-10 relative scrollbar overflow-y-auto h-full pb-10"
        style="padding-right: 8px !important"
      >
        <div v-if="getSelectedTemplate" class="flex justify-center items-stretch space-x-8" style="min-height: 491px">
          <div class="w-3/4 flex justify-center items-stretch rounded-lg p-4" style="background-color: #ecf4ff">
            <div id="mainSlider" class="w-5/6 overflow-hidden flex items-center justify-center">
              <Splide v-if="getSelectedTemplate" ref="mainSlider" :options="mainSliderOptions">
                <!-- Front cover -->
                <SplideSlide>
                  <div class="flex justify-center items-stretch">
                    <button @click="onPrev" class="inline-block" style="width: 316px; height: 443px">
                      <img :src="getBackCover" alt="" class="w-full h-full object-cover object-center" />
                    </button>
                    <button @click="onNext" class="inline-block" style="width: 316px; height: 443px">
                      <img :src="getFrontCover" alt="" class="w-full h-full object-cover object-center" />
                    </button>
                  </div>
                </SplideSlide>

                <!-- Other pages -->

                <SplideSlide v-for="(item, i) in getMiddlePages" :key="'item-' + i">
                  <div class="flex justify-center items-stretch">
                    <button @click="onPrev" class="inline-block" style="width: 316px; height: 443px">
                      <img :src="item[0].s3_fullsize_url" alt="" class="w-full h-full object-cover object-center" />
                    </button>
                    <button @click="onNext" class="inline-block" style="width: 316px; height: 443px">
                      <img :src="item[1].s3_fullsize_url" alt="" class="w-full h-full object-cover object-center" />
                    </button>
                  </div>
                </SplideSlide>

                <!-- Back cover -->
                <!-- <SplideSlide>
                  <div class="flex justify-center items-stretch">
                    <button @click="onPrev" class="inline-block" style="width: 316px; height: 443px">
                      <img :src="getBackCover" alt="" class="w-full h-full object-cover object-center" />
                    </button>
                    <button @click="onNext" class="inline-block" style="width: 316px; height: 443px">
                      <img
                        :src="require('@/assets/templates/slider-1.png')"
                        alt=""
                        class="w-full h-full object-cover object-center"
                      />
                    </button>
                  </div>
                </SplideSlide> -->
              </Splide>
            </div>

            <div id="paginationSlider" class="w-1/6 overflow-hidden flex items-center justify-center">
              <Splide
                v-if="getSelectedTemplate"
                ref="paginationSlider"
                :options="paginationSliderOptions"
                @splide:click="onClickSlide"
              >
                <SplideSlide>
                  <div class="flex justify-center items-stretch cursor-pointer">
                    <img :src="getBackCover" alt="" class="w-full object-contain" style="height: 68px" />
                    <img :src="getFrontCover" alt="" class="w-full object-contain" style="height: 68px" />
                  </div>
                </SplideSlide>
                <SplideSlide v-for="(item, i) in getMiddlePages" :key="i">
                  <div class="flex justify-center items-stretch cursor-pointer">
                    <img :src="item[0].s3_thumbnail_url" alt="" class="w-full object-contain" style="height: 68px" />
                    <img :src="item[1].s3_thumbnail_url" alt="" class="w-full object-contain" style="height: 68px" />
                  </div>
                </SplideSlide>
                <!-- <SplideSlide>
                  <div class="flex justify-center items-stretch cursor-pointer">
                    <img :src="getBackCover" alt="" class="w-full object-contain" style="height: 68px" />
                    <img
                      :src="require('@/assets/templates/slider-1.png')"
                      alt=""
                      class="w-full object-contain"
                      style="height: 68px"
                    />
                  </div>
                </SplideSlide> -->
              </Splide>
            </div>
          </div>

          <!-- Right side info panel -->
          <div class="w-1/4">
            <div class="" style="width: 176px; height: 247px">
              <img
                :src="(getSelectedTemplate && getSelectedTemplate.s3_cover_thumbnail_url) || ''"
                alt=""
                class="w-full h-full object-cover object-center"
              />
            </div>

            <button
              class="bg-wg-light-accent hover:bg-wg-light-accent-hover font-semibold text-white rounded py-2.5 px-3"
              style="margin-top: 1rem"
              @click="
                $router.push({ name: 'PreparingTemplate', params: { id: getSelectedTemplate.id } })
                $emit('on-close')
              "
            >
              {{ $t('template.modal.use.template.button') }}
            </button>

            <div style="margin-top: 1rem">
              <span class="flex justify-start items-center space-x-2">
                <span
                  class="bg-wg-light-information rounded p-1.5 flex justify-center items-center"
                  style="height: 24px; width: 24px"
                >
                  <span class="jilster-font text-lg text-wg-light-accent">Z</span>
                </span>
                <span class="text-sm text-wg-100">{{ $t('template.modal.flexible.template') }}</span>
              </span>

              <span class="flex justify-start items-center space-x-2" style="margin-top: 0.75rem">
                <span
                  class="bg-wg-light-information rounded p-1.5 flex justify-center items-center"
                  style="height: 24px; width: 24px"
                >
                  <span class="jilster-font text-lg text-wg-light-accent">a</span>
                </span>
                <span class="text-sm text-wg-100"
                  >{{ $t('template.modal.pages') }}: {{ (getSelectedTemplate && getSelectedTemplate.pageCount) || '' }}
                  <span v-if="!isLindaCatSelected" style="font-weight: bold">(A4 - 21 x 29,7 cm)</span>
                  <span v-else style="font-weight: bold">(23 x 27,3 cm)</span>
                </span>
              </span>

              <span class="flex justify-start items-center space-x-2" style="margin-top: 0.75rem">
                <span
                  class="bg-wg-light-information rounded p-1.5 flex justify-center items-center"
                  style="height: 24px; width: 24px"
                >
                  <span class="jilster-font text-lg text-wg-light-accent">1</span>
                </span>
                <span class="text-sm text-wg-100">{{ $t('template.modal.share.print.download') }}</span>
              </span>

              <span class="flex justify-start items-center space-x-2" style="margin-top: 0.75rem">
                <span
                  class="bg-wg-light-information rounded p-1.5 flex justify-center items-center"
                  style="height: 24px; width: 24px"
                >
                  <span class="jilster-font text-lg text-wg-light-accent">,</span>
                </span>
                <span class="text-sm text-wg-100">{{ $t('template.modal.fully.customizable') }}</span>
              </span>
            </div>
          </div>
        </div>

        <div v-else class="flex justify-center items-center" style="min-height: 491px">
          <img :src="require('@/assets/loading.gif')" alt="" class="w-20 h-20" />
        </div>

        <h2 class="text-wg-100 font-bold text-2xl mb-2" style="margin-top: 24px">
          {{ isLindaCatSelected ? $t('template.modal.linda.title') : $t('template.modal.title') }}
        </h2>

        <p class="text-wg-80">
          {{
            isLindaCatSelected
              ? $t('template.modal.linda.text.before.click.here')
              : $t('template.modal.text.before.click.here')
          }}
          <br v-if="!isLindaCatSelected" />
          <span v-if="!showBindingOptions">
            <button
              class="text-wg-light-accent border-b border-solid border-wg-light-accent"
              style="padding-bottom: 0.25px"
              @click="showBindingOptions = true"
            >
              {{ $t('template.modal.click.here.button') }}
            </button>
            {{
              isLindaCatSelected
                ? $t('template.modal.linda.text.after.click.here')
                : $t('template.modal.text.after.click.here')
            }}
          </span>
          <span v-else>
            <button
              class="text-wg-light-accent border-b border-solid border-wg-light-accent"
              style="padding-bottom: 0.25px"
              @click="showBindingOptions = false"
            >
              {{ $t('template.modal.close.button') }}
            </button>
          </span>
        </p>

        <div
          v-if="showBindingOptions"
          ref="bindingOptions"
          class="py-14 pl-12 pr-24 rounded-lg relative"
          style="
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
            background-color: #f9f8f8;
            margin: 1.25rem 0;
          "
        >
          <button
            @click="showBindingOptions = false"
            class="absolute top-0 right-0"
            style="margin-top: 0.75rem; margin-right: 1.5rem"
          >
            <span class="jilster-font" style="font-size: 35px; color: #999087">k</span>
          </button>

          <BindingOptions :is-linda="isLindaCatSelected" />
        </div>

        <div>
          <Templates
            :show-more-options="false"
            is-modal
            @on-template-click="scrollToTop"
            @on-select-category="(val) => (selectedCat = val)"
          />
        </div>
      </div>

      <!-- Close button -->
      <button
        @click="$emit('on-close')"
        class="absolute top-0 right-0"
        style="margin-top: 0.75rem; margin-right: 1.5rem"
      >
        <span class="jilster-font" style="font-size: 35px; color: #999087">k</span>
      </button>

      <!-- Arrow Buttons -->
      <div
        class="fixed top-1/2 left-1/2 transform -translate-x-1/2 flex justify-between items-center w-full pr-1 z-0"
        style="max-width: 1480px"
      >
        <button class="jilster-font text-white text-5xl" @click="goToPrevTemplate">h</button>
        <button class="jilster-font text-white text-5xl" @click="goToNextTemplate">‰</button>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '../../../httpClient'

import { Splide, SplideSlide } from '@splidejs/vue-splide'

import Templates from './Templates.vue'
import BindingOptions from './BindingOptions.vue'

import '@splidejs/splide/dist/css/splide.min.css'

export default {
  name: 'TemplateModal',

  components: {
    Templates,
    BindingOptions,
    Splide,
    SplideSlide,
  },

  props: {
    selectedTemplate: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showBindingOptions: false,
      mainSliderOptions: {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        width: '100%',
        pagination: false,
        arrows: false,
        autoplay: false,
      },
      paginationSliderOptions: {
        type: 'loop',
        direction: 'ttb',
        height: '410px',
        autoWidth: true,
        gap: '1rem',
        autoHeight: true,
        perPage: 5,
        perMove: 1,
        pagination: false,
        arrows: true,
        autoplay: false,
      },
      getSelectedTemplate: null,
      selectedCat: null,
      isLindaCatSelected: false,
    }
  },

  watch: {
    async showBindingOptions(val) {
      await this.$nextTick()

      const container = this.$refs.container
      const wrapper = this.$refs.wrapper

      if (val) {
        const bindingOptions = this.$refs.bindingOptions

        // Get container top position relative to wrapper top position
        const containerTop = container.offsetTop - wrapper.offsetTop

        // Scroll up wrapper div to bindingOptions top position
        wrapper.scrollTo({
          top: bindingOptions.offsetTop - containerTop,
          behavior: 'smooth',
        })
      } else {
        // Get container top position relative to wrapper top position
        const containerTop = container.offsetTop - wrapper.offsetTop

        // Scroll up wrapper div to top position
        wrapper.scrollTo({
          top: containerTop,
          behavior: 'smooth',
        })
      }
    },

    selectedTemplate: {
      async handler(val) {
        if (val) {
          // Check if it is linda
          const linda = val.tags.find((cat) => cat.name.en && cat.name.en.toLowerCase() === 'linda')

          if (linda) {
            this.isLindaCatSelected = true
          } else {
            this.isLindaCatSelected = false
          }

          // Call the API to get the selected template
          this.getSelectedTemplate = null

          try {
            const res = await httpClient.get('/v3/template/' + val.id)

            if (res && res.data && res.data.data) {
              this.getSelectedTemplate = res.data.data

              await this.$nextTick()

              const mainSlider = this.$refs.mainSlider
              const paginationSlider = this.$refs.paginationSlider

              mainSlider.sync(paginationSlider.splide)
            }
          } catch (err) {
            //
          }
        }
      },
      immediate: true,
      deep: true,
    },

    selectedCat: {
      handler(val) {
        const linda = this.categories.find((cat) => cat.name.en && cat.name.en.toLowerCase() === 'linda')

        if (linda) {
          if (val === linda.id) {
            this.isLindaCatSelected = true
          } else {
            this.isLindaCatSelected = false
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    getFrontCover() {
      if (this.getSelectedTemplate && this.getSelectedTemplate.canvasses && this.getSelectedTemplate.canvasses.length) {
        const frontCover = this.getSelectedTemplate.canvasses.find(
          (canvas) => canvas.id === this.getSelectedTemplate.cover_id
        )

        if (frontCover) {
          return frontCover.s3_fullsize_url
        } else {
          return ''
        }
      } else {
        return ''
      }
    },

    getBackCover() {
      if (this.getSelectedTemplate && this.getSelectedTemplate.canvasses && this.getSelectedTemplate.canvasses.length) {
        const frontCover = this.getSelectedTemplate.canvasses.find(
          (canvas) => canvas.id === this.getSelectedTemplate.backCover_id
        )

        if (frontCover) {
          return frontCover.s3_fullsize_url
        } else {
          return ''
        }
      } else {
        return ''
      }
    },

    getMiddlePages() {
      if (this.getSelectedTemplate && this.getSelectedTemplate.canvasses && this.getSelectedTemplate.canvasses.length) {
        const middlePages = this.getSelectedTemplate.canvasses.filter(
          (canvas) =>
            canvas.id !== this.getSelectedTemplate.cover_id && canvas.id !== this.getSelectedTemplate.backCover_id
        )

        if (middlePages) {
          // return middePages array in set of two
          return middlePages.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 2)

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
          }, [])
        } else {
          return []
        }
      } else {
        return []
      }
    },
    categories() {
      return this.$store.state.home.categories
    },
  },

  methods: {
    onPrev() {
      this.$refs.mainSlider.splide.go('<')
    },

    onNext() {
      this.$refs.mainSlider.splide.go('>')
    },

    onClickSlide(splide, slide) {
      this.$refs.mainSlider.splide.go(slide.index)
    },

    scrollToTop() {
      this.$refs.wrapper.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },

    goToNextTemplate() {
      const templates = this.$store.getters['getFilteredTemplates'](this.selectedCat)

      if (templates && templates.length) {
        const index = templates.findIndex((template) => template.id === this.selectedTemplate.id)

        if (index !== -1) {
          if (index === templates.length - 1) {
            this.$store.commit('SET_SELECTED_TEMPLATE', templates[0])
          } else {
            this.$store.commit('SET_SELECTED_TEMPLATE', templates[index + 1])
          }
        }
      }
    },

    goToPrevTemplate() {
      const templates = this.$store.getters['getFilteredTemplates'](this.selectedCat)

      if (templates && templates.length) {
        const index = templates.findIndex((template) => template.id === this.selectedTemplate.id)

        if (index !== -1) {
          if (index === 0) {
            this.$store.commit('SET_SELECTED_TEMPLATE', templates[templates.length - 1])
          } else {
            this.$store.commit('SET_SELECTED_TEMPLATE', templates[index - 1])
          }
        }
      }
    },
  },
}
</script>

<style scoped>
.scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#paginationSlider >>> .splide__list .is-active {
  border: 3px solid #2a65c4;
}

#paginationSlider >>> .splide__arrows .splide__arrow {
  background: white;
  opacity: 1;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16);
  height: 40px;
  width: 40px;
}

#paginationSlider >>> .splide__arrows .splide__arrow svg {
  fill: #999187;
}

#paginationSlider >>> .splide__arrows .splide__arrow--prev {
  bottom: 93%;
  top: auto;
}

#paginationSlider >>> .splide__arrows .splide__arrow--next {
  top: 92%;
  bottom: auto;
}

#paginationSlider >>> .splide__arrows .splide__arrow--prev:hover,
#paginationSlider >>> .splide__arrows .splide__arrow--next:hover {
  background: #e3e0dd;
}

#paginationSlider >>> .splide__arrows .splide__arrow--prev:hover svg,
#paginationSlider >>> .splide__arrows .splide__arrow--next:hover svg {
  fill: #111111;
}

#paginationSlider >>> .splide__arrows .splide__arrow--prev:active,
#paginationSlider >>> .splide__arrows .splide__arrow--next:active {
  background: #b7afa7;
}

#paginationSlider >>> .splide__arrows .splide__arrow--prev:active svg,
#paginationSlider >>> .splide__arrows .splide__arrow--next:active svg {
  fill: #111111;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #cdc7c2;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
