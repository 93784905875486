<template>
  <div class="centerContent">
    <b-container fluid id="order-cannot" style="margin-top: 62px;">
      <h2>{{$t("checkout.how.to.print")}}</h2>
      <p>{{$t("checkout.follow.steps")}}</p>
      <div class="content">
        <div class="d-flex" style="position: relative">
          <p class="number-step" style="top: 11%;">1</p>
          <img style="height: 56px; width: 390px;" src="@/assets/step1.png" />
          <p class="step-text">{{$t("checkout.step1.info")}}</p>
        </div>
        <div class="d-flex" style="margin-top: 20px; position: relative">
          <p class="number-step" style="top: 39%;">2</p>
          <img src="@/assets/step2.png" />
          <div class="d-flex" style="position: relative">
            <img src="@/assets/check.png" style="position: relative; top: 40%; left: -23px; height: 40px; width: 40px;"/>
            <p class="step-text" style="margin-top: 80px; margin-left: -12px;">{{$t("checkout.step2.info")}}</p>
          </div>

        </div>
        <div class="d-flex" style="position: relative; margin-top: 20px; margin-bottom: 20px; width: 100%;">
          <p class="number-step">3</p>
          <p style=" margin-left: 65px; margin-top: 10px;">{{$t("checkout.step3.info")}}</p>
        </div>
        <div class="d-flex justify-content-between" style="width: 100%;">
          <Button :button-text="$t('checkout.close.screen')" button-type="primary" @click.native="closeScreen()"/>
          <img class="banner-cannot" :src="'/banner-cannot_' + language + '.jpg'" v-on:click="orderPDF()" style="margin-left: 20px"/>
        </div>

      </div>
    </b-container>
  </div>
</template>

<script>
  import Button from "@/components/Button";
  export default {
    name: "OrderCannot",
    data() {
      return {
        language: localStorage.getItem("language") || "en"
      }
    },
    components: {Button},
    methods: {
      orderPDF() {
        this.$store.dispatch('setCannotOrder', false)
        this.$store.dispatch('setCurrentPage', 'contact')
        this.$store.dispatch('setCanOrderPFDOnly', true)
      },
      closeScreen() {
        this.$router.push('/document/' + this.$route.params.id)
      }
    }
  }
</script>

<style scoped>

</style>