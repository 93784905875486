<template>
  <div class="checkout-product">
    <div class="checkout-product-container">
      <img :src="productImage" alt="" />
      <div class="product-info">
        <p>{{ productTitle }}</p>
        <p>{{ productType }}</p>
      </div>
      <div class="increase-amount-container">
        <i @click="decreaseAmount" class="icon-chevron-left"></i>
        <span>{{ amountCounter }}</span>
        <i @click="increaseAmount" class="icon-chevron-right"></i>
      </div>
      <div class="price-container">
        <p>&#8364; {{ productPrice }}</p>
        <i @click="removeSelectedOrder(id)" class="icon-delete"></i>
        <i class="icon-chevron-down" @click="openOptions(id)"></i>
      </div>
    </div>
    <b-collapse :visible="isFirst.true" :id="`options-${id}`" class="mt-2">
      <div>
        <b-form-group
          label="Definitive or trialversion"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio
            v-model="selected"
            :aria-describedby="ariaDescribedby"
            :name="`version_radios_1_${id}`"
            :value="12"
            >Definitive version</b-form-radio
          >
          <b-form-radio
            v-model="selected"
            :aria-describedby="ariaDescribedby"
            :name="`version_radios_1_${id}`"
            :value="10"
            >Trail version</b-form-radio
          >
        </b-form-group>
        <b-form-group label="Magazine type" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="magazineType"
            :aria-describedby="ariaDescribedby"
            :name="`option-radios_1_${id}`"
            :value="5"
            >Nails with matte cover</b-form-radio
          >
          <b-form-radio
            v-model="magazineType"
            :aria-describedby="ariaDescribedby"
            :name="`option-radios_1_${id}`"
            :value="3"
            >Nails with shining cover</b-form-radio
          >
        </b-form-group>
        <p>Extra options</p>
        <b-form-checkbox
          :id="`checkbox_extra_options_1_${id}`"
          v-model="extraOptions"
          :name="`checkbox_extra_options_1_${id}`"
          :value="2"
          :unchecked-value="0"
        >
          Low quality dpi
        </b-form-checkbox>
        <b-form-checkbox
          :id="`checkbox_extra_options_2_${id}`"
          v-model="extraOptions"
          :name="`checkbox_extra_options_2_${id}`"
          :value="5"
          :unchecked-value="0"
        >
          High quality dpi
        </b-form-checkbox>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: [
    "productImage",
    "productTitle",
    "amount",
    "price",
    "productType",
    "id",
    "isFirst",
  ],
  name: "OrderProduct",
  data() {
    return {
      amountCounter: this.amount,
      selected: 0,
      magazineType: 0,
      extraOptions: [],
      priceGiven: this.price,
      versionCounter: 0,
    };
  },
  computed: {
    productPrice: function () {
      let price =
        this.priceGiven +
        this.selected +
        this.magazineType +
        this.extraOptionsCalculate;
      let priceToStore = {
        id: this.id,
        price: price * this.amountCounter,
        amount: this.amountCounter,
      };
      this.$store.dispatch("setNewPrice", priceToStore);
      this.setPrice();

      return price * this.amountCounter;
    },
    extraOptionsCalculate: function () {
      let price = 0;
      for (let i = 0; i < this.extraOptions.length; i++) {
        price += this.extraOptions[i];
      }
      if (price === 0) {
        return 0;
      } else {
        return price;
      }
    },
  },
  methods: {
    setPrice() {
      let priceToSend = 0;
      let defPrice = 0;
      for (let i = 0; i < this.$store.getters["getOrderCheckout"].length; i++) {
        priceToSend += this.$store.getters["getOrderCheckout"][i].price;
      }
      defPrice = priceToSend;
      this.$store.dispatch("setCheckoutPrice", defPrice);
    },
    increaseAmount() {
      this.amountCounter++;
    },
    decreaseAmount() {
      if (this.amountCounter > 0) {
        this.amountCounter--;
      }
    },
    openOptions(id) {
      this.$root.$emit("bv::toggle::collapse", "options-" + id);
    },
    removeSelectedOrder(id) {
      this.$store.dispatch("removeSelectedOrder", id);
    },
  },
  created() {
    this.$store.dispatch("setCheckoutPrice", this.productPrice);
  },
};
</script>
