<template>
  <b-button
    class="jilster_btn"
    :class="[
      {
        primary_button: buttonType === 'primary',
        basic_button: buttonType === 'basic',
        hint_button: buttonType === 'hint',
        success_button: buttonType === 'success',
        hint_50_button: buttonType === 'hint-50',
        wrong_button: buttonType === 'wrong_button',
        wrong_button_5: buttonType === 'wrong_button_5',
        white_button: buttonType === 'white_button',
        outline_button_primary: buttonType === 'outline_button_primary',
        disabled_button: buttonType === 'disabled',
        black_button: buttonType === 'black_button',
        invitation_decline: buttonType === 'invitation_decline',
        invitation_accept: buttonType === 'invitation_accept',
      },
    ]"
    :style="{
      height: heightAuto ? 'auto !important' : '48px',
    }"
    :disabled="disabled"
    @click="handleClick"
    @keyup.enter="handleClick"
  >
    <div style="display: flex; align-items: flex-start; justify-content: center">
      <i v-if="icon" :style="{ color: iconColor }" v-bind:class="`icon-${icon}`"></i>
      <span>{{ buttonText }}</span>
    </div>
  </b-button>
</template>

<script>
export default {
  props: ['buttonText', 'buttonType', 'icon', 'iconColor', 'disabled', 'heightAuto'],
  name: 'Button',
  methods: {
    handleClick() {
      this.$emit('click.native')
    },
  },
}
</script>
