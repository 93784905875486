<template>
  <div :class="labelContainer">
    <svg fill="none" height="14" viewBox="0 0 51 14" width="51" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M3.4 0.936V10.344H7.16V13H0.264V0.936H3.4ZM11.4356 0.936V13H8.29963V0.936H11.4356ZM11.7159 13V0.936H14.8519L20.6439 8.312V0.936H23.7639V13H20.6439L14.8519 5.624V13H11.7159ZM27.19 10.344H27.91C28.454 10.344 28.9393 10.2587 29.366 10.088C29.7927 9.91733 30.15 9.68267 30.438 9.384C30.7367 9.08533 30.9607 8.73333 31.11 8.328C31.27 7.912 31.35 7.45867 31.35 6.968C31.35 6.488 31.27 6.04 31.11 5.624C30.95 5.208 30.7207 4.85067 30.422 4.552C30.134 4.25333 29.7767 4.01867 29.35 3.848C28.9233 3.67733 28.4433 3.592 27.91 3.592H27.19V10.344ZM24.054 0.936H28.694C29.5153 0.936 30.2833 1.10133 30.998 1.432C31.7233 1.76267 32.3527 2.20533 32.886 2.76C33.43 3.304 33.8567 3.944 34.166 4.68C34.4753 5.40533 34.63 6.168 34.63 6.968C34.63 7.75733 34.4753 8.52 34.166 9.256C33.8673 9.98133 33.446 10.6213 32.902 11.176C32.3687 11.7307 31.7393 12.1733 31.014 12.504C30.2993 12.8347 29.526 13 28.694 13H24.054V0.936ZM41.0765 8.52L39.6685 4.504L38.2605 8.52H41.0765ZM41.9085 10.904H37.4285L36.7085 13H33.3645L37.9565 0.936H41.3805L45.9725 13H42.6285L41.9085 10.904ZM46.6061 11.464C46.6061 11.208 46.6541 10.968 46.7501 10.744C46.8461 10.5093 46.9795 10.3067 47.1501 10.136C47.3208 9.96533 47.5181 9.832 47.7421 9.736C47.9768 9.64 48.2221 9.592 48.4781 9.592C48.7341 9.592 48.9741 9.64 49.1981 9.736C49.4328 9.832 49.6355 9.96533 49.8061 10.136C49.9768 10.3067 50.1101 10.5093 50.2061 10.744C50.3021 10.968 50.3501 11.208 50.3501 11.464C50.3501 11.72 50.3021 11.9653 50.2061 12.2C50.1101 12.424 49.9768 12.6213 49.8061 12.792C49.6355 12.9627 49.4328 13.096 49.1981 13.192C48.9741 13.288 48.7341 13.336 48.4781 13.336C48.2221 13.336 47.9768 13.288 47.7421 13.192C47.5181 13.096 47.3208 12.9627 47.1501 12.792C46.9795 12.6213 46.8461 12.424 46.7501 12.2C46.6541 11.9653 46.6061 11.72 46.6061 11.464Z"
          fill="white"/>
    </svg>
    <p v-bind:class="['certified-carrier-text', textContainer]">{{$t("linda.carrier")}}</p>
  </div>
</template>

<script>
export default {
  props: ['labelContainer', 'textContainer'],
  name: "CertifiedCarrier",
}
</script>
