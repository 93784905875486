import StartCreation from './StartCreation.vue'
import CreationsOverview from './CreationsOverview.vue'
import NewDesign from '@/domains/creations/NewDesign'
import Preview from '@/domains/creations/Preview'

export default [
  {
    path: '/start-creation',
    name: 'StartCreation',
    component: StartCreation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'CreationsOverview',
    component: CreationsOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/new-design',
    name: 'NewDesign',
    component: NewDesign,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/preview/:id',
    name: 'Preview',
    component: Preview,
    meta: {
      requiresAuth: true,
    },
  },
]
