<template>
  <div id="isMobile">
    <div class="jilster_mobile_mask-container">
      <div :style="{ backgroundImage: 'url(' + gifs[chooseGif] + ')',}" class="jilster_mobile_mask"></div>
    </div>
    <h1>{{ $t('mobile_header') }}</h1>
    <p>{{ $t('mobile_text') }}</p>
    <AppStore @click.native="setRedirect" v-if="detectWhatMobilePhone === 'iOS'"/>
    <GooglePlaystore @click.native="setRedirect" v-else/>
  </div>
</template>

<script>
import AppStore from "../../vectors/AppStore";
import detectMobilePhone from "../../mixins/detectMobilePhone";
import GooglePlaystore from "../../vectors/GooglePlaystore";

export default {
  name: "IsMobile",
  components: {GooglePlaystore, AppStore},
  mixins: [detectMobilePhone],
  data() {
    return {
      gifs: ['/jilster_hoodie.gif', '/jilster_owl.gif', '/jilster_selena.gif', '/jilster_warning.gif']
    }
  },
  computed: {
    chooseGif(){
      return Math.floor(Math.random() * 3);
    },
    setRedirect() {
      if (this.detectWhatMobilePhone === 'iOS') {
        return window.location.href = 'https://apps.apple.com/app/jilster/id1555272047';
      }
      return window.location.href = 'https://play.google.com/store/apps/details?id=com.rebelslab.jilstercompanion.app&hl=nl&gl=US';
    }
  }
}
</script>
