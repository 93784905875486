<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="black"
    />
    <path
      d="M17.3281 16L22.4062 10.9609C22.8229 10.5182 22.8229 10.0625 22.4062 9.59375C21.9375 9.17708 21.4818 9.17708 21.0391 9.59375L16 14.6719L10.9609 9.59375C10.4922 9.17708 10.0365 9.17708 9.59375 9.59375C9.17708 10.0625 9.17708 10.5182 9.59375 10.9609L14.6719 16L9.59375 21.0391C9.17708 21.4818 9.17708 21.9375 9.59375 22.4062C9.82812 22.5885 10.0625 22.6797 10.2969 22.6797C10.5052 22.6797 10.7266 22.5885 10.9609 22.4062L16 17.3281L21.0391 22.4062C21.2734 22.5885 21.4948 22.6797 21.7031 22.6797C21.9375 22.6797 22.1719 22.5885 22.4062 22.4062C22.8229 21.9375 22.8229 21.4818 22.4062 21.0391L17.3281 16Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseButton",
};
</script>
