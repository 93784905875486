<template>
  <div
    class="rounded-xl"
    style="
      background-color: #ebf5fe;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
      padding: 40px 80px;
      width: 484px;
    "
  >
    <h3 class="text-wg-100 font-bold text-xl text-center" style="margin-bottom: 16px">
      {{ $t('add.title.confirmation.title') }}
    </h3>
    <div class="btn-container text-center text-wg-100">
      <p>{{ $t('add.title.confirmation.description') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleAdded',
}
</script>

<style scoped>
.btn-position {
  right: 0.75rem;
  top: 0;
  transform: translateY(40%);

  margin: auto;
}

.btn-container {
  position: relative;
}

.input-custom >>> input {
  padding-right: 2.25rem;
  width: 100%;
}
</style>
