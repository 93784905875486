<template>
  <div class="jilster_select" @click="openDropdown()">
    <span class="d-block" style=" margin-bottom: -17px;">{{labelText}}</span>
    <i
        v-if="icon"
        :style="{ color: iconColor }"
        v-bind:class="`icon-${icon}`"
    ></i>
    <!--    <b-form-select @input="selectedItem" @click="openDropdown($event)" v-model="selected" :options="options" no-caret></b-form-select>-->
    <div
        :class="{
        activeSelect:
          this.$store.getters['getShowSettingsDropDown'] &&
          this.$store.getters['getDropDownId'] == id,
      }"
        class="customSelect"
    >
      <span>{{ selectedOption.name }}</span>
    </div>
    <div
        class="dropDownMenu shadow-lg"
        v-if="
        this.$store.getters['getShowSettingsDropDown'] &&
        this.$store.getters['getDropDownId'] == id
      "
    >
      <div
          v-for="o in options"
          :key="o.name"
          class="customOption"
          @click="selectOption(o)"
      >
        {{ o.name }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: ["options", "icon", "iconColor", "id", "initialValue", "labelText"],
  name: "Select",
  data() {
    return {
      selected: null,
      selectedOption: this.options[0],
    };
  },
  methods: {
    openDropdown() {
      this.$store.dispatch(
          "setShowSettingsDropDown",
          !this.$store.getters["getShowSettingsDropDown"]
      );
      this.$store.dispatch("setDropDownId", this.id);
    },
    selectOption(o) {
      this.selectedOption = o;
      this.$store.dispatch("setShowSettingsDropDown", false);
      this.$emit("valueChanged", o);
      this.openDropdown();
    },
  },
};
</script>
