<template>
  <div id="checkout">
    <b-container>
      <b-row>
        <b-col lg="7">
          <div class="product-overview">
            <div class="product-overview-header">
              <router-link to="/">
                <i class="icon-chevron-left"></i>
                <span>Continue Shopping</span>
              </router-link>
            </div>
            <div class="products">
              <h2>Shopping cart</h2>
              <p>
                You have
                {{ this.$store.getters["getOrderCheckout"].length }} items in
                your cart
              </p>
              <OrderProduct
                :isFirst="{ true: index === 0 }"
                v-for="(order, index) in this.$store.getters[
                  'getOrderCheckout'
                ]"
                :key="order.id"
                :id="order.id"
                :productImage="order.productImage"
                :productType="order.productType"
                :productTitle="order.productTitle"
                :amount="order.amount"
                :price="order.price"
              />
            </div>
          </div>
        </b-col>
        <b-col lg="5">
          <div class="checkout-overview">
            <CheckoutAddress v-if="step === 1" />
            <CheckoutDelivery v-if="step === 2" />
            <CheckoutPaymentOption v-if="step === 3" />
            <Button
              v-if="this.$store.getters['getStepComplete']"
              @click.native="increaseStep"
              button-type="basic"
              button-text="Next"
            />
            <Button v-else disabled button-type="basic" button-text="Next" />
            <Button
              v-if="step !== 1"
              @click.native="decreaseStep"
              button-type="basic"
              button-text="Back"
            />
            <div class="price-calculation">
              <div class="prices">
                <p v-if="!discount">Subtotal</p>
                <p v-else>Subtotal (inc 20% discount)</p>
                <p>&#8364; {{ subtotalPrice }}</p>
              </div>
              <div class="prices">
                <p>Shipping</p>
                <p>
                  &#8364; {{ this.$store.getters["getShippingCost"].value }}
                </p>
              </div>
              <div class="prices">
                <p>Total price</p>
                <p>&#8364; {{ totalPrice }}(exc tax)</p>
              </div>
              <Button
                v-if="this.$store.getters['getCheckoutDataComplete'] === false"
                button-type="basic"
                button-text="Checkout"
                disabled
                style="width: 100%"
              />
              <Button
                v-if="this.$store.getters['getCheckoutDataComplete'] === true"
                button-type="basic"
                button-text="Checkout"
                style="width: 100%"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OrderProduct from "@/domains/orders/OrderProduct";
import CheckoutAddress from "@/domains/orders/CheckoutAddress";
import Button from "@/components/Button";
import CheckoutDelivery from "@/domains/orders/CheckoutDelivery";
import CheckoutPaymentOption from "@/domains/orders/CheckoutPaymentOption";

export default {
  name: "Checkout",
  components: {
    CheckoutPaymentOption,
    CheckoutDelivery,
    Button,
    CheckoutAddress,
    OrderProduct,
  },
  data() {
    return {
      step: 1,
      subtotalPriceData: 0,
      discount: false,
    };
  },
  computed: {
    totalPrice: function () {
      return (
        this.subtotalPriceData + this.$store.getters["getShippingCost"].value
      );
    },
    subtotalPrice: function () {
      let totalAmountOfProducts = 0;
      let price = 0;
      this.$store.getters["getOrderCheckout"].forEach((element) => {
        totalAmountOfProducts += element.amount;
      });

      if (totalAmountOfProducts > 15) {
        let discount = (this.$store.getters["getCheckoutPrice"] / 100) * 20;
        price =
          this.$store.getters["getCheckoutPrice"] +
          this.$store.getters["getShippingCost"].value -
          discount;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.subtotalPriceData = price;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.discount = true;
      } else {
        price =
          this.$store.getters["getCheckoutPrice"] +
          this.$store.getters["getShippingCost"].value;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.subtotalPriceData = price;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.discount = false;
      }

      return price;
    },
  },
  methods: {
    increaseStep() {
      this.step++;
      this.$store.dispatch("stepComplete", false);
    },
    decreaseStep() {
      if (this.step !== 0) {
        this.step--;
      }
    },
  },
};
</script>

<style scoped></style>
