import axios from 'axios'
import i18n from '../i18n'

const state = {
  showAwardGuideModal: false,
  showUnNominateModal: false,
  showUnNominateConfirmationModal: false,
  showParticipateAwardModal: false,
  showParticipateAwardConfirmationModal: false,
  activeCampaign: null,
  awardVotingEnded: false,
  showAwardUI: false,
  states: [],
  nominatedMagazines: [],
}

const mutations = {
  SET_SHOW_AWARD_GUIDE_MODAL(state, payload) {
    state.showAwardGuideModal = payload
  },
  SET_AWARD_VOTING_ENDED(state, payload) {
    state.awardVotingEnded = payload
  },
  SET_SHOW_UNNOMINATE_MODAL(state, payload) {
    state.showUnNominateModal = payload
  },
  SET_SHOW_UNNOMINATE_CONFIRMATION_MODAL(state, payload) {
    state.showUnNominateConfirmationModal = payload
  },
  SET_SHOW_PARTICIPATE_AWARD_MODAL(state, payload) {
    state.showParticipateAwardModal = payload
  },
  SET_SHOW_PARTICIPATE_AWARD_CONFIRMATION_MODAL(state, payload) {
    state.showParticipateAwardConfirmationModal = payload
  },
  SET_ACTIVE_CAMPAIGN(state, payload) {
    state.activeCampaign = payload
  },
  SET_STATES(state, payload) {
    state.states = payload
  },
  SET_NOMINATED_MAGAZINES(state, payload) {
    state.nominatedMagazines = payload
  },
  SET_SHOW_AWARD_UI(state, payload) {
    state.showAwardUI = payload
  },
}

const actions = {
  setActiveCampaign({ commit, dispatch, getters }) {
    axios
      .get(process.env.VUE_APP_AWARD_APP_API_URL + '/campaign/active', {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.data && res.data.data && typeof res.data.data === 'object') {
          if (res.data.data.EndDate) {
            const endDate = new Date(res.data.data.EndDate)
            const today = new Date()

            if (today > endDate) {
              commit('SET_AWARD_VOTING_ENDED', true)
            } else {
              commit('SET_AWARD_VOTING_ENDED', false)
            }
          }

          commit('SET_ACTIVE_CAMPAIGN', res.data.data || null)
          dispatch('getStates', res.data.data.CountryId)
          dispatch('getUsersNominatedMagazines', (getters['getLoggedInUser'] && getters['getLoggedInUser'].email) || '')

          if (i18n.locale === 'en' || i18n.locale === 'nl') {
            commit('SET_SHOW_AWARD_UI', true)
          }
        } else {
          commit('SET_ACTIVE_CAMPAIGN', null)
          commit('SET_SHOW_AWARD_UI', false)
        }
      })
      .catch(() => {
        commit('SET_ACTIVE_CAMPAIGN', null)
        commit('SET_SHOW_AWARD_UI', false)
      })
  },

  getStates({ commit }, payload) {
    axios
      .get(process.env.VUE_APP_AWARD_APP_API_URL + '/country/states/' + payload, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      })
      .then((res) => {
        commit('SET_STATES', (res.data && res.data.data) || [])
      })
      .catch(() => {
        commit('SET_STATES', [])
      })
  },

  getUsersNominatedMagazines({ commit, state }, payload) {
    if (state.activeCampaign) {
      axios
        .get(
          process.env.VUE_APP_AWARD_APP_API_URL +
            '/candidate/requester/' +
            payload +
            '/campaign/' +
            state.activeCampaign.Id,
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
          }
        )
        .then((res) => {
          commit('SET_NOMINATED_MAGAZINES', (res.data && res.data.data) || [])
        })
        .catch(() => {
          commit('SET_NOMINATED_MAGAZINES', [])
        })
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
