<template>
  <b-container id="auth" class="justify-content-center align-items-center" v-if="!isLoading">
    <div class="form-container">
      <h1 style="color: #303030; margin-bottom: 1rem">{{ $t('auth.updatepassword.header') }}</h1>

      <Error v-if="systemError" type="system" :errorMessage="systemError" class="mb-4" />

      <label class="incorrectCredentials succes" v-else-if="success">
        <span class="mr-1"
          ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#51CF66" />
            <path
              d="M9.69531 15.125L7.23438 12.7422C7.02604 12.5339 6.76562 12.4297 6.45312 12.4297C6.14062 12.4297 5.88021 12.5339 5.67188 12.7422C5.46354 12.9505 5.35938 13.2109 5.35938 13.5234C5.35938 13.8099 5.46354 14.0573 5.67188 14.2656L8.99219 17.5078C9.22656 17.7422 9.5 17.8594 9.8125 17.8594C10.151 17.8333 10.4245 17.6901 10.6328 17.4297L18.4453 8.75781C18.6276 8.52344 18.6927 8.26302 18.6406 7.97656C18.6146 7.66406 18.4844 7.41667 18.25 7.23438C17.6771 6.79167 17.1562 6.85677 16.6875 7.42969L9.69531 15.125Z"
              fill="white"
            />
          </svg>
        </span>
        <span>
          {{ $t('auth.updatepassword.succes') }}
        </span>
      </label>

      <label v-if="!success">{{ $t('auth.updatepassword.subheader') }}</label>

      <b-form v-if="!success" @submit.prevent="updatePassword">
        <Input
          icon="view"
          icon-color="#534C45"
          :label-text="$t('auth.register.your.password')"
          :placeholder-text="$t('auth.register.choose.password')"
          type="password"
          v-model="password"
          :class="{ errorBorder: this.errors.password }"
          style="margin-top: 1rem !important"
          @input="errors.password = ''"
          @focusout.native="checkPasswordInput"
          @blur.native="checkPasswordInput"
        />
        <Error v-if="errors.password" icon="warning" icon-color="#C23934" :errorMessage="errors.password" />

        <Input
          icon="view"
          icon-color="#534C45"
          :label-text="$t('auth.udpatepassword.confirm')"
          :placeholder-text="$t('auth.udpatepassword.confirm')"
          type="password"
          v-model="passwordConfirm"
          :class="{ errorBorder: this.errors.passwordConfirm }"
          @input="errors.passwordConfirm = ''"
          @focusout.native="checkConfirmPasswordInput"
          @blur.native="checkConfirmPasswordInput"
        />
        <Error
          v-if="errors.passwordConfirm"
          icon="warning"
          icon-color="#C23934"
          :errorMessage="errors.passwordConfirm"
        />

        <Button
          type="submit"
          class="mx-auto w-25"
          :disabled="isDisabled"
          :button-text="$t('auth.forgotpassword.button')"
          button-type="primary"
          @click.native="updatePassword"
        />
      </b-form>

      <router-link v-if="!success" class="return-login" to="/login" style="font-size: 14px !important">
        <i class="icon-chevron-left"></i>
        {{ $t('auth.forgotpassword.return') }}
      </router-link>

      <router-link v-else class="return-login return-login-success" to="/login" style="font-size: 14px !important">
        {{ $t('auth.forgotpassword.return.success') }}
        <span class="ml-1">
          <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.34375 -9.31599e-07C1.53125 -9.15207e-07 1.6875 0.0624991 1.8125 0.187499L7.125 5.53125C7.27083 5.65625 7.34375 5.8125 7.34375 6C7.34375 6.1875 7.27083 6.34375 7.125 6.46875L1.8125 11.8125C1.47917 12.1042 1.16667 12.1042 0.875 11.8125C0.541667 11.4792 0.541667 11.1667 0.875 10.875L5.71875 6L0.875001 1.125C0.541668 0.833332 0.541668 0.520832 0.875001 0.187499C1 0.062499 1.15625 -9.47991e-07 1.34375 -9.31599e-07Z"
              fill="#2A65C4"
            />
          </svg>
        </span>
      </router-link>
    </div>
  </b-container>
  <div v-else class="loading-icon">
    <img src="/loading.gif" alt="loading" />
  </div>
</template>

<script>
import Input from '@/components/Input'
import Button from '@/components/Button'
import Error from '@/components/Error'

export default {
  name: 'UpdatePassword',
  components: { Input, Button, Error },
  data() {
    return {
      email: this.$route.query.email,
      password: '',
      passwordConfirm: '',
      isLoading: false,
      success: false,
      errors: {
        password: '',
        passwordConfirm: '',
      },

      systemError: '',
    }
  },

  computed: {
    isDisabled() {
      return this.errors.password || this.errors.passwordConfirm ? true : false
    },
  },

  methods: {
    async updatePassword() {
      this.$store.dispatch('setError', '')
      this.systemError = ''

      this.errors = {
        password: '',
        passwordConfirm: '',
      }

      let okToProceed = true

      if (!this.password.length) {
        this.errors.password = this.$t('auth.updatepassword.password.empty')
        okToProceed = false
      }

      if (!this.passwordConfirm) {
        this.errors.passwordConfirm = this.$t('auth.updatepassword.password.confirm.empty')
        okToProceed = false
      }

      if (okToProceed) {
        const data = {
          email: this.email,
          password: this.password,
          passwordresettoken: this.$route.query.token,
        }

        this.isLoading = true
        await this.$store.dispatch('updatePassword', data)
        this.isLoading = false
        this.success = true
        // await this.$router.push('/login')
      }
    },

    checkPasswordInput() {
      if (this.password && this.password.length < 8) {
        this.errors.password = this.$t('auth.register.minimum.characters')
      } else {
        this.errors.password = ''
      }
    },

    checkConfirmPasswordInput() {
      if (this.passwordConfirm && this.passwordConfirm !== this.password) {
        this.errors.passwordConfirm = this.$t('auth.updatepassword.not.match')
      } else {
        this.errors.passwordConfirm = ''
      }
    },
  },
}
</script>

<style scoped>
.return-login-success {
  text-align: center;
  margin: 0 auto;
  color: #2a65c4 !important;
  font-weight: bold;
}
</style>
