<template>
  <div class="orderCannot-modal">
    <div class="orderCannot-modal-content">
      <b-container fluid id="order-cannot">
        <div class="header">
          <h2>{{$t("checkout.how.to.print")}}</h2>
          <button @click="closeModal()">
            <i class="icon-close close-icon" />
          </button>
        </div>

        <p>{{$t("checkout.follow.steps")}}</p>
        <div class="content">
          <div class="d-flex" style="position: relative">
            <p class="number-step" style="top: 11%;">1</p>
            <img style="height: 56px; width: 390px;" src="@/assets/step1.png" />
            <p class="step-text">{{$t("checkout.step1.info")}}</p>
          </div>
          <div class="d-flex" style="margin-top: 20px; position: relative">
            <p class="number-step" style="top: 39%;">2</p>
            <img src="@/assets/step2.png" />
            <div class="d-flex" style="position: relative">
              <img src="@/assets/check.png" style="position: relative; top: 40%; left: -23px; height: 40px; width: 40px;"/>
              <p class="step-text" style="margin-top: 80px; margin-left: -12px;">{{$t("checkout.step2.info")}}</p>
            </div>

          </div>
          <div class="d-flex" style="position: relative; margin-top: 20px; margin-bottom: 20px; width: 100%;">
            <p class="number-step">3</p>
            <p style=" margin-left: 65px; margin-top: 10px;">{{$t("checkout.step3.info")}}</p>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCannotModal",
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
}
</script>

<style scoped>

</style>