import { getCreationMembers } from "../creations/creationEndpoint";
import { destroyInvitation, unsubscribeOtherUser } from "./documentsEndpoint"

const state = {
  invitees: [],
  selectedForInvite: [],
  selectedDocuments: [],
  searchedMakers: [],
  selectedInvitee: 0
};

const getters = {
  getInvitees: (state) => {
    return state.invitees;
  },
  getSearchedMakers: (state) => {
    return state.searchedMakers;
  },
  getSelectedForInvite: (state) => {
    return state.selectedForInvite;
  },
  getSelectedDocuments: (state) => {
    return state.selectedDocuments;
  },
};

const actions = {
  async setInvitedMembers({ commit }, magazineId) {
    try {
      const result = await getCreationMembers(magazineId);
      commit("SET_INVITED_MEMBERS", result.data.data);
      commit("SORT_INVITED_MEMBERS")
    } catch (e) {
      //
    }
  },
  async setSelectedForInvite({ commit }, user) {
    commit("SET_SELECTED_FOR_INVITE", user);
  },
  async getSearchedMakers({ commit }, users) {
    commit("SET_SEARCHED_MAKERS", users);
  },
  async destroyInvitation({ commit }, data) {
    try {
      (data.member.invitationId !== 0) ? await destroyInvitation(data.member.invitationId, data.creationId) : await unsubscribeOtherUser(data.creationId, data.member.id)
      commit("REMOVE_MEMBER", data.member);
    } catch (error) {
      //
    }
  },
  deleteAllSelectedUsersForInvite({ commit }) {
    commit("DELETE_SELECTED_USERS_FOR_INVITE");
  },
  deleteSelectedUserForInvite({ commit }, user) {
    commit("REMOVE_SELECTED_USER", user);
  },
  removeInvitedUser({ commit }, email) {
    commit("REMOVE_INVITED_USER", email);
  },
  selectDocumentsToInviteTo({ commit }, document) {
    commit("SET_SELECTED_DOCUMENT", document);
  },
  removeSelectedDocument({ commit }, document) {
    commit("REMOVE_SELECTED_DOCUMENT", document);
  },
  clearSelectedDocuments({ commit }) {
    commit("CLEAR_SELECTED_DOCUMENTS");
  },
  removeUser({ commit }, user) {
    commit("REMOVE_USER", user);
  },
  setRank({ commit }, userRole) {
    commit("SET_RANK", userRole);
  },
  setPages({ commit }, pages) {
    commit("SET_PAGES", pages);
  },
  changeMember({ commit }, user) {
    commit("CHANGE_MEMBER", user);
  },
  clearInvitees({commit}) {
    commit("ClEAR_INVITEES", [])
  },
  setSelectedInvitee({ commit }, id) {
    commit("SET_SELECTED_INVITEE", id)
  },
  setInviteeMessage({ commit }, message) {
    commit("SET_MESSAGE", message)
  },
  setSameMessage({ commit }, message) {
    commit("SET_SAME_MESSAGE", message)
  }
};

const mutations = {
  SET_INVITED_MEMBERS(state, invitees) {
    state.invitees = invitees;
  },
  SORT_INVITED_MEMBERS(state){
    state.invitees.sort((a, b) => {
      if (a.role !== 3 || b.role !== 3) {
        return a.role - b.role;
      } else if (a.pages.length < 1 || b.pages.length < 1){
        return b.pages.length - a.pages.length;
      } else {
        return a.pages[0].number - b.pages[0].number;
      }
    });
  },
  REMOVE_MEMBER(state, member) {
    const index = state.invitees.indexOf(member)
    state.invitees.splice(index, 1)
  },
  SET_SELECTED_FOR_INVITE(state, user) {
    state.selectedForInvite.push(user);
  },
  SET_SEARCHED_MAKERS(state, users) {
    state.searchedMakers = users;
  },
  DELETE_SELECTED_USERS_FOR_INVITE(state) {
    state.selectedForInvite = [];
  },
  REMOVE_INVITED_USER(state, email) {
    removeByAttr(state.selectedForInvite, "email", email);
  },
  SET_SELECTED_DOCUMENT(state, document) {
    if (state.selectedInvitee !== 0) {
      let invitee = state.selectedForInvite.find(i => i.id === state.selectedInvitee)
      invitee.magazine_pages.push(document)
    }
    state.selectedDocuments.push(document);
    state.selectedDocuments = state.selectedDocuments.sort(function (a, b) {
      return a.number - b.number
    })
  },
  REMOVE_SELECTED_DOCUMENT(state, document) {
    removeByAttr(state.selectedDocuments, "id", document.id);
    if(state.selectedInvitee !== 0) {
      let invitee = state.selectedForInvite.find(i => i.id === state.selectedInvitee)
      if(invitee){
        removeByAttr(invitee.magazine_pages, "id", document.id);
      }
    }
  },
  CLEAR_SELECTED_DOCUMENTS(state) {
    state.selectedDocuments = [];
  },
  REMOVE_SELECTED_USER(state, user) {
    removeByAttr(state.selectedForInvite, "id", user.id);
  },
  REMOVE_USER(state, user) {
    const index = state.invitees.indexOf(user);
    state.invitees.splice(index, 1);
  },
  SET_RANK(state, userRole) {
    let invitee = state.selectedForInvite.find(i => i.id === userRole.id)
    if(invitee) {
      invitee.role = userRole.role
      if(userRole.role === 2) {
        invitee.magazine_pages = []
      }
    }
  },
  SET_PAGES(state, pages) {
    for (let i = 0; i < state.selectedForInvite.length; i++) {
      state.selectedForInvite[i].pages = pages;
    }
  },
  CHANGE_MEMBER(state, object) {
    let user = state.invitees.find((i) => i.email === object.email);
    user.role = object.role;
    user.pages = object.pages;
  },
  ClEAR_INVITEES(state, members) {
    state.invitees = members
  },
  SET_SELECTED_INVITEE(state, id) {
    state.selectedDocuments = []
    state.selectedInvitee = id
    if(id !== 0) {
      const invitee = state.selectedForInvite.find(i => i.id === id)
      if(invitee) {
        for(let i = 0; i < invitee.magazine_pages.length; i++) {
          state.selectedDocuments.push(invitee.magazine_pages[i])
        }
      }
    }
  },
  SET_MESSAGE(state, message) {
    let invitee = state.selectedForInvite.find(i => i.id === state.selectedInvitee)
    invitee.message = message
  },
  SET_SAME_MESSAGE(state, message) {
    let invitees = state.selectedForInvite
    for(let i = 0; i < invitees.length; i++) {
      invitees[i].message = message
    }
  }
};

let removeByAttr = function (arr, attr, value) {
  let i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      // eslint-disable-next-line no-prototype-builtins
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export default {
  state,
  getters,
  actions,
  mutations,
};
