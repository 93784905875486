import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Gravatar from 'vue-gravatar'
import { FlippingWidgets } from 'vue-turnjs'
import 'vue-turnjs/dist/vue-turnjs.esm.css'

import './assets/scss/main.css'
import './assets/scss/tailwind.css'
import i18n from './i18n'

import Pusher from 'pusher-js'
Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG === 'true'
const getAuthToken = () => localStorage.getItem('jilster_token')
let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: 'eu',
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
  auth: {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  },
})

Vue.prototype.$pusher = pusher

Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(FlippingWidgets)
Vue.component('v-gravatar', Gravatar)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
