<template>
  <div class="order-error">
    <i
        v-if="icon"
        :style="{ color: iconColor }"
        v-bind:class="`icon-${icon}`"
    ></i>
    <p>{{errorText}}</p>
  </div>
</template>

<script>
  export default {
    props: ['errorText', 'icon', 'iconColor'],
    name: "OrderError"
  }
</script>
