<template>
  <div id="video-popup">
    <Close color="#534C45" @click.native="setVideoPopup" />
    <iframe
      width="100%"
      height="100%"
      :src="setVideo"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import Close from '../Close'

export default {
  name: 'VideoPopup',
  components: { Close },
  methods: {
    setVideoPopup() {
      localStorage.setItem('videopopup', false)
      this.$store.commit('SET_VIDEOPOPUP_ACTIVE', false)
    },
  },
  computed: {
    setVideo() {
      const language = localStorage.getItem('language')

      switch (language) {
        case 'nl':
          return 'https://www.youtube.com/embed/1zUzZrdnba8?autoplay=1&showinfo=0&controls=0'
        case 'de':
          return 'https://www.youtube.com/embed/EkmeJUQiJ_E?autoplay=1&showinfo=0&controls=0'
        default:
          return 'https://www.youtube.com/embed/oLwMg6-58HQ?autoplay=1&showinfo=0&controls=0'
      }
    },
  },
}
</script>
