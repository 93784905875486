<template>
  <div class="order-confirmation">
    <p class="order-id">{{$t("checkout.order")}} {{getOrderId}}</p>
    <h2>{{$t("checkout.thank.you")}} {{$store.getters['getLoggedInUser'].firstName}}</h2>
    <p class="order-description" v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']">
      {{$t("checkout.order.is.confirmed")}}.
      <span v-if="getOrderExtras">{{$t("checkout.you.can.download")}}</span>
    </p>
    <p class="order-description" v-else-if="this.$store.getters['getCanOrderExtrasOnly']">
      {{$t("checkout.order.is.confirmed")}}.
    </p>
    <p class="order-description" v-else>
      {{$t("checkout.you.can.download")}}
      <router-link to="/orders">{{$t("checkout.order.downloads")}}</router-link>.
    </p>
    <div class="order-review-item" v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']">
      <p class="infoItem">{{$t("checkout.contact")}}</p>
      <p class="order-item-last" style="margin-left: 10px">{{$store.getters['getLoggedInUser'].email}}</p>
    </div>
    <div class="order-review-item" v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']  && checkAddress && !orderExtrasPdfCheck">
      <p v-if="this.$store.getters['getSuccessOrder'].expectedDeliveryDate !== ''" class="infoItem">{{$t("checkout.delivery")}}</p>
      <div v-if="this.$store.getters['getSuccessOrder'].expectedDeliveryDate !== ''" style="margin-left: 10px; width: 348px;">
        <p class="order-item-last">{{getDeliveryDate}}</p>
        <p class="mt-3">{{$t("checkout.when.order.is.shipped")}}</p>
      </div>
    </div>
    <div class="order-review-item" v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly'] && !orderExtrasPdfCheck && checkAddress">
      <p class="infoItem">{{$t("checkout.location")}}</p>
      <div v-if="this.$store.getters['getSuccessOrder'].shippingmethod_id !== 4">
        <p style="margin-left: 10px;"><strong>{{$t("checkout.we.will.deliver.here")}}:</strong><br/>{{getAddress}}</p>
      </div>
      <div v-else>
        <p style="margin-left: 10px;"><strong>{{$t("pickup_title")}}</strong></p>
        <p style="margin-left: 10px;">{{$t("pickup_descpription")}}</p>
      </div>

    </div>
    <div class="d-flex return-order">
      <div class="d-flex return-order-text">
        <span style="color: #111111; margin-right: 5px">{{$t("checkout.need.help.order")}}</span>
        <span v-on:click="contactTel()">{{$t("checkout.contact.us")}}</span>
      </div>
      <div class="d-flex">
        <Button style="margin-left: 13px" :button-text="$t('checkout.button.view.order')" button-type="primary" @click.native="viewOrder()" v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']"/>
        <Button style="margin-left: 13px" :button-text="$t('checkout.back.to.makerspace')" button-type="primary" @click.native="backToMakerspace()" v-else></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "OrderConfirmation",
  components: {Button},
  data() {
    return {
      days: [this.$t("sunday"), this.$t("monday"), this.$t("tuesday"), this.$t("wednesday"), this.$t("thursday"), this.$t("friday"), this.$t("saturday")],
      months: [this.$t("january"), this.$t("february"), this.$t("march"), this.$t("april"), this.$t("may"), this.$t("juny"), this.$t("july"), this.$t("august"), this.$t("september"), this.$t("october"), this.$t("november"), this.$t("december")]
    }
  },
  computed: {
    getOrderId() {
      return "#" + this.$store.getters['getSuccessOrder'].id
    },
    getDeliveryDate() {
      let dateString = this.$store.getters['getSuccessOrder'].expectedDeliveryDate
      var dateParts = dateString.split("-");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

      return this.days[dateObject.getDay()] + " " + dateObject.getDate() + " " + this.months[dateObject.getMonth()]
    },
    getAddress() {
      const address = this.$store.getters['getSuccessOrder'];
      return `${address.street} ${address.houseNumber}${address.houseNumberSuffix ? address.houseNumberSuffix : ""} ${address.postalCode}, ${address.city}`
    },
    checkAddress(){
      const address = this.$store.getters['getSuccessOrder'];

      return address.city !== null && address.street !== null && address.houseNumber !== null && address.postalCode !== null;
    },
    getOrderExtras() {
      return this.$store.getters['getSuccessOrder'].orderextras.length > 0
    },
    orderExtrasPdfCheck(){
      let orders = this.$store.getters['getSuccessOrder'].orderextra_ids;
      if(orders === "1,2"){
        return true;
      }

      if(orders === "1"){
        return true;
      }

      if(orders === "2"){
        return true;
      }

      return false;
    }
  },
  methods: {
    contactTel() {
      window.location.href = "tel: " + this.$store.getters['getContactPhone']
    },
    backToMakerspace() {
      this.$router.push('/')
    },
    viewOrder() {
      this.$router.push('/orders')
    }
  }
}
</script>
