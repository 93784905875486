<template>
  <svg width="48" height="35" viewBox="0 0 48 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="35" fill="white"/>
    <path d="M4 2.59451V32.4055C4 33.8325 5.185 35 6.63332 35H24.7111C38.378 35 44.303 27.4629 44.303 17.4611C44.303 7.51112 38.378 0 24.7111 0H6.63332C5.185 0 4 1.16753 4 2.59451Z" fill="white"/>
    <path d="M16.0996 7.31625V29.3437H25.8297C34.6646 29.3437 38.496 24.4271 38.496 17.4738C38.496 10.8188 34.6646 5.65576 25.8297 5.65576H17.7849C16.8501 5.65576 16.0996 6.40817 16.0996 7.31625Z" fill="#CC0066"/>
    <path d="M24.7118 32.6263H8.99088C7.59522 32.6263 6.46289 31.5106 6.46289 30.1355V4.87794C6.46289 3.50285 7.59522 2.38721 8.99088 2.38721H24.7118C39.6296 2.38721 41.8548 11.8442 41.8548 17.4743C41.8548 27.2427 35.7586 32.6263 24.7118 32.6263ZM8.99088 3.21745C8.05605 3.21745 7.30555 3.95689 7.30555 4.87794V30.1355C7.30555 31.0566 8.05605 31.796 8.99088 31.796H24.7118C35.2188 31.796 41.0121 26.7108 41.0121 17.4743C41.0121 5.07253 30.7948 3.21745 24.7118 3.21745H8.99088Z" fill="black"/>
    <path d="M19.5239 14.5032C19.8662 14.5032 20.1822 14.5551 20.4851 14.6589C20.7879 14.7627 21.0381 14.9313 21.2619 15.1389C21.4726 15.3594 21.6437 15.6318 21.7754 15.9432C21.8939 16.2675 21.9597 16.6437 21.9597 17.0847C21.9597 17.4739 21.9071 17.8242 21.8149 18.1485C21.7096 18.4728 21.5647 18.7582 21.3672 18.9917C21.1697 19.2252 20.9196 19.4068 20.6167 19.5495C20.3139 19.6793 19.9584 19.7571 19.5502 19.7571H17.2461V14.4902H19.5239V14.5032ZM19.4449 18.7971C19.6161 18.7971 19.7741 18.7712 19.9452 18.7193C20.1032 18.6674 20.2481 18.5766 20.3666 18.4469C20.4851 18.3171 20.5904 18.1615 20.6694 17.9539C20.7484 17.7464 20.7879 17.5128 20.7879 17.2145C20.7879 16.955 20.7616 16.7085 20.7089 16.501C20.6562 16.2934 20.5641 16.0988 20.4456 15.9561C20.3271 15.8134 20.1691 15.6837 19.9716 15.6059C19.7741 15.528 19.5371 15.4891 19.2474 15.4891H18.4048V18.8101H19.4449V18.7971Z" fill="white"/>
    <path d="M26.7141 14.5032V15.4762H23.8964V16.6048H26.4902V17.4999H23.8964V18.7842H26.7799V19.7571H22.7246V14.4902H26.7141V14.5032Z" fill="white"/>
    <path d="M30.7409 14.5029L32.7423 19.7698H31.5178L31.1096 18.6023H29.1083L28.6869 19.7698H27.502L29.5164 14.5029H30.7409ZM30.8068 17.7331L30.1353 15.8002H30.1221L29.4243 17.7331H30.8068Z" fill="white"/>
    <path d="M34.6523 14.5029V18.7968H37.2593V19.7698H33.4805V14.5029H34.6523Z" fill="white"/>
    <path d="M11.7024 19.5627C13.0622 19.5627 14.1646 18.4766 14.1646 17.1368C14.1646 15.797 13.0622 14.7109 11.7024 14.7109C10.3426 14.7109 9.24023 15.797 9.24023 17.1368C9.24023 18.4766 10.3426 19.5627 11.7024 19.5627Z" fill="black"/>
    <path d="M13.5582 29.3437C11.491 29.3437 9.83203 27.6961 9.83203 25.6724V22.8055C9.83203 21.7936 10.6615 20.9634 11.7017 20.9634C12.7287 20.9634 13.5713 21.7807 13.5713 22.8055V29.3437H13.5582Z" fill="black"/>
  </svg>

</template>

<script>
export default {
name: "Ideal"
}
</script>

<style scoped>

</style>