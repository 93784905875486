<template>
  <div class="document-invite" id="documentInvite" v-if="$store.getters['getInvitees'].length !== 0 && !isLoading">
    <div class="d-flex justify-content-between">
      <h3>{{ $t('documentinvite.header') }}</h3>

      <!-- Language dropdown -->
      <div class="d-flex" style="position: relative">
        <Netherlands v-if="language === 'nl'" />
        <Germany v-else-if="language === 'de'" />
        <UnitedKingdom v-else />

        <i class="icon-chevron-down" @click="toggleLanguageDropDown()"></i>
        <div class="languageDropDown" v-if="showLanguageDropDown">
          <div class="country" @click="selectLanguage('nl')">
            <Netherlands />
          </div>
          <div class="country" @click="selectLanguage('en')">
            <UnitedKingdom />
          </div>
          <div class="country" @click="selectLanguage('de')">
            <Germany />
          </div>
        </div>
      </div>
    </div>

    <!-- Default input for inviting users when invitees is empty -->
    <Input
      v-if="this.$store.getters['getSelectedForInvite'].length === 0"
      v-model="email"
      :placeholder-text="$t('documentinvite.others.placeholder')"
      @keydown.native="typing($event)"
      @paste.native="pasteEmails($event)"
      :onBlur="onEmailInputBlur"
    />

    <!-- Wrapper when invitees is more than 0. Each invitee goes into a div -->
    <div
      v-else
      :style="[this.$store.getters['getSelectedForInvite'] ? { padding: '4px 10px' } : { padding: 12 }]"
      class="invited-users"
    >
      <div
        v-for="user in this.$store.getters['getSelectedForInvite']"
        :key="'u' + user.id"
        class="invited-user"
        :class="{ 'invalid-user': !checkEmail(user.email) }"
      >
        <div v-if="checkEmail(user.email)" class="valid-user">
          <span @click="setSelectedInvitee(user.id, $event.target)">{{ user.email }}</span>
          <i class="icon-close" @click="removeInvitedUser(user)"></i>
        </div>
        <div v-else class="invalid-email">
          <span>{{ user.email }}</span>
          <i class="icon-close" @click="removeInvitedUser(user)"></i>
        </div>
      </div>

      <!-- Extra input after invitees -->
      <Input
        ref="secondInviteInput"
        v-model="email"
        class="invited-users-input"
        :placeholder-text="$t('documentinvite.search.placeholder')"
        @keydown.native="typing($event)"
        @paste.native="pasteEmails($event)"
        :onBlur="onEmailInputBlur"
      />
    </div>

    <!-- Textarea for optional message with checkbox send same message to all users. Checkbox only enables if message is not empty -->
    <textarea
      v-model="message"
      :placeholder="$t('documentinvite.message.placeholder')"
      @input="mixin_autoResize_resize"
      @keyup="typingMessage"
    ></textarea>
    <b-form-checkbox
      id="checkbox-1"
      v-model="status"
      :class="{ disabledCheckBox: message === '' }"
      :disabled="message === ''"
      class="mt-1 mb-3 messageCheckbox"
      name="checkbox-1"
      :unchecked-value="false"
      :value="true"
    >
      {{ $t('documentinvite.use.same.message') }}
    </b-form-checkbox>

    <!-- Custom dropdown for rank. Maker or ChiefMaker -->
    <div :class="{ selectingRank: rankSelect }" class="document-invite-select" @click="rankSelectToggle">
      <p v-if="this.rank === 0">{{ $t('documentinvite.send.invitation') }}</p>
      <p v-else>{{ this.ranks.find((r) => r.role === this.rank).name }}</p>
      <i class="icon-chevron-down"></i>
    </div>
    <div v-if="rankSelect" class="rank-select-container">
      <!-- Display all ranks in div -->
      <div
        v-for="rank in ranks"
        :key="'r' + rank.role"
        class="rank"
        @click="selectRank(rank.role)"
        v-html="rank.text"
      ></div>
    </div>

    <!-- Show select pages info if rank is selected and not in edited-mode -->
    <div v-if="rank === 3 || editedUserId !== 0" class="selectPages">
      <div class="selectPagesInfo">
        <i :class="`icon-info-fill`"></i>
        <p v-if="editedUserId !== 0">{{ $t('documentinvite.select.pages.pencil') }}</p>
        <p v-else>{{ $t('documentinvite.select.pages') }}</p>
      </div>
      <p id="invitePageInfo" class="mt-0 invite-to-pages">
        {{ $t('documentinvite.assign.pages') }}:
        <span v-for="(document, index) in $store.getters['getSelectedDocuments']" :key="'sd' + document.number">
          {{ document.number }}
          <span v-if="index !== $store.getters['getSelectedDocuments'].length - 1">,</span>
        </span>
      </p>
    </div>

    <!-- Send invite button and close button -->
    <div class="justify-content-end" style="display: flex; margin-top: 20px">
      <Button
        :button-text="$t('documentinvite.button.cancel')"
        button-type="outline_button_primary"
        class="mr-2"
        @click.native="close()"
      />
      <Button
        :button-type="canInvite"
        :disabled="canInvite === 'disabled'"
        :button-text="$tc('documentinvite.button.send', $store.getters['getSelectedForInvite'].length)"
        icon="invite"
        @click.native="sendInvite"
      />
    </div>

    <!-- Display errorMessage if necessary -->
    <div class="errorMessage">
      <p>{{ this.errorMessage }}</p>
    </div>

    <!-- Display all users from this magazine. Owner first -->
    <div class="added_makers">
      <p class="heading-invite">{{ $t('documentinvite.members.header') }}</p>
      <div v-for="invitee in this.$store.getters['getInvitees']" :key="'i' + invitee.email" class="member-container">
        <div class="d-flex justify-content-between align-items-end" style="width: 100%">
          <div class="userWrapper">
            <div class="d-flex">
              <v-gravatar class="maker-profile" :email="invitee.email" />
              <div class="userInfoWrapper">
                <p
                  class="memberHeader"
                  v-b-tooltip.hover
                  :title="
                    $store.getters['getLoggedInUser'].id === invitee.id
                      ? 'You'
                      : invitee.name === ''
                      ? invitee.email
                      : invitee.name
                  "
                >
                  {{
                    $store.getters['getLoggedInUser'].id === invitee.id
                      ? 'You'
                      : invitee.name === ''
                      ? invitee.email
                      : invitee.name
                  }}
                </p>
                <p class="memberEmail">
                  {{ invitee.accepted ? invitee.email : $t('documentinvite.pending') }}
                </p>
                <!-- Show default pages -->
                <p v-if="invitee.pages.length > 0 && editedUserId !== invitee.email" class="invited-pages mt-0 mb-0">
                  {{ $t('dashboard.entities.magazinepage') }}:
                  <span v-for="(page, index) in invitee.pages" :key="page.id">
                    {{ page.number }}
                    <span v-if="index !== invitee.pages.length - 1">,</span>
                  </span>
                </p>
                <!-- Show edit pages -->
                <p v-else-if="editedUserId === invitee.email" class="invited-pages mt-0 mb-0">
                  {{ $t('dashboard.entities.magazinepage') }}:
                  <span v-for="(page, index) in $store.getters['getSelectedDocuments']" :key="page.id">
                    {{ page.number }}
                    <span v-if="index !== $store.getters['getSelectedDocuments'].length - 1">,</span>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div v-if="invitee.role === 3" class="d-flex invitee-options">
            <div class="maker-information">
              <div class="d-flex align-items-end">
                <i class="icon icon-edit mr-1" :id="invitee.email" @click="setUserEdit(invitee, $event)"></i>
                <!-- <i class="icon icon-view mr-3 activeEditIcon" @click="setUserView(invitee, $event)"></i> -->
              </div>
            </div>

            <Select
              :id="invitee.email"
              v-model="invitee.role"
              :initialValue="invitee.role"
              :options="[
                { value: 3, text: 'Maker' },
                { value: 2, text: 'Chiefmaker' },
              ]"
              icon="chevron-down"
              @valueChanged="valueChanged"
            />
            <i class="icon-close" @click="removeUser(invitee)"></i>
          </div>
          <div v-else-if="invitee.role === 2" class="d-flex invitee-options">
            <template v-if="$store.getters['getLoggedInUser'].id === invitee.id">
              <div class="ownerLabel">
                <span>Chiefmaker</span>
              </div>
              <i class="icon-close hiddenIcon"></i>
            </template>
            <template v-else>
              <Select
                :id="invitee.email"
                v-model="invitee.role"
                :initialValue="invitee.role"
                :options="[
                  { value: 2, text: 'Chiefmaker' },
                  { value: 3, text: 'Maker' },
                ]"
                icon="chevron-down"
                @valueChanged="valueChanged"
              />

              <i class="icon-close" @click="removeUser(invitee)"></i>
            </template>
          </div>
          <div v-else-if="invitee.role === 1" class="d-flex invitee-options">
            <div class="ownerLabel">
              <span>Owner</span>
            </div>
            <i class="icon-close hiddenIcon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-icon" v-else>
    <img src="/loading.gif" alt="loading" />
  </div>
</template>

<script>
import Input from '@/components/Input'
import Select from '@/components/Select'
import Button from '@/components/Button'
import mixinAutoResize from '../mixins/autoResize.js'
import { updateInvitation, sendInvitations } from '../domains/document/documentsEndpoint'
import Netherlands from './country/Netherlands'
import UnitedKingdom from './country/UnitedKingdom'
import Germany from './country/Germany'

export default {
  name: 'DocumentInvite',
  components: { Germany, UnitedKingdom, Netherlands, Button, Select, Input },
  mixins: [mixinAutoResize],
  data() {
    return {
      magazineId: this.$route.params.id !== undefined ? this.$route.params.id : this.$store.getters['getCreationId'],
      status: false,
      userId: null,
      editedUserId: 0,
      selectedInvitee: 0,
      rank: 0,
      email: '',
      rankSelect: false,
      message: '',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      ranks: [
        {
          text:
            '<span>' +
            this.$t('documentinvite.chiefmaker') +
            '</span>' +
            '<span>' +
            this.$t('documentinvite.chiefmaker.info') +
            '</span>',
          name: 'Chief-Maker',
          role: 2,
        },
        {
          text:
            '<span>' +
            this.$t('documentinvite.maker') +
            '</span><span>' +
            this.$t('documentinvite.maker.info') +
            '</span>',
          name: 'Maker',
          role: 3,
        },
      ],
      showLanguageDropDown: false,
      language: 'nl',
      isLoading: false,
      oldUser: {},
      errorMessage: '',
      isAddingUser: false,
    }
  },
  methods: {
    pasteEmails(e) {
      const data = e.clipboardData.getData('text/plain')
      // first replace all commas's, spaces, tabs and linebreaks at beginning and end
      // then split at any combination of comma's, spaces tabs or linebreaks
      const emails = data.replace(/(^[,\s\t\n]+)|([,\s\t\n]+$)/g, '').split(/[\t\s\n,]+/)

      for (let i = 0; i < emails.length; i++) {
        this.addUser(emails[i])
      }
    },
    toggleLanguageDropDown() {
      this.showLanguageDropDown = !this.showLanguageDropDown
    },
    selectLanguage(language) {
      this.language = language
      this.toggleLanguageDropDown()
    },
    async addUser(email = null) {
      if (this.email === this.$store.getters['getLoggedInUser'].email) {
        this.errorMessage = this.$t('cannot_invite_yourself')
      } else {
        const inviteeEmail = email || this.email
        const invitee = {
          id: Math.floor(Math.random() * 1000),
          email: inviteeEmail,
          role: 0,
          message: this.status ? this.message : '',
          magazine_pages: [],
        }
        await this.$store.dispatch('setSelectedForInvite', invitee)
        const elems = document.getElementsByClassName('valid-user')
        const elem = elems[elems.length - 1]
        if (this.reg.test(inviteeEmail)) {
          this.setSelectedInvitee(invitee.id, elem)
        }
      }

      this.email = ''
    },
    checkEmail(email) {
      return this.reg.test(email)
    },
    onEmailInputBlur() {
      if (this.email !== '' && !this.isAddingUser) this.addUser()
    },
    async typing(e) {
      if ((e.key === 'Enter' || e.key === 'Tab' || e.key === ' ' || e.key === ',') && this.email !== '') {
        this.isAddingUser = true

        await this.addUser()

        this.$refs.secondInviteInput.focusOnInput()

        this.isAddingUser = false
        e.preventDefault()
      }
    },
    typingMessage() {
      if (this.selectedInvitee === 0) {
        this.errorMessage = this.$t('select_an_invitee')
      } else {
        if (this.status) {
          this.$store.dispatch('setSameMessage', this.message)
        } else {
          this.$store.dispatch('setInviteeMessage', this.message)
        }
      }
    },
    close() {
      const invitees = this.$store.getters['getInvitees']
      const makersWithoutPages = invitees.filter((i) => i.pages.length === 0 && i.role === 3)
      if (makersWithoutPages.length > 0) {
        this.errorMessage = this.$t('invite.maker.no.pages')
      } else {
        this.errorMessage = ''
        this.$store.dispatch('setShowModal', false)
        this.$store.dispatch('setAllowDocumentSelect', false)
        this.$store.dispatch('clearSelectedDocuments')
        this.$store.dispatch('clearUserView')
        this.$store.dispatch('deleteAllSelectedUsersForInvite')
      }
    },
    removeInvitedUser(user) {
      this.$store.dispatch('removeInvitedUser', user.email)
      if (user.id === this.selectedInvitee) {
        this.selectedInvitee = 0
        this.$store.dispatch('setSelectedInvitee', 0)
      }
    },
    rankSelectToggle() {
      this.rankSelect = !this.rankSelect
    },
    setSelectedInvitee(id, elem) {
      if (this.selectedInvitee !== id) {
        const elems = document.getElementsByClassName('valid-user')
        for (let i = 0; i < elems.length; i++) {
          elems[i].closest('.invited-user').classList.remove('activeUser')
        }

        const wrapper = elem.closest('.invited-user')
        wrapper.classList.add('activeUser')
        this.selectedInvitee = id
        this.$store.dispatch('setSelectedInvitee', id)
        this.errorMessage = ''
        const userRole = this.$store.getters['getSelectedForInvite'].find((i) => i.id === id)
        if (userRole) {
          this.rank = userRole.role
        }
        if (!this.status) {
          this.message = userRole.message
        }
        this.$store.dispatch('setAllowDocumentSelect', userRole.role === 3)
      }
    },
    selectRank(role) {
      this.errorMessage = ''
      if (this.selectedInvitee !== 0) {
        const userRole = { id: this.selectedInvitee, role: role }
        this.$store.dispatch('setRank', userRole)
        this.rank = role
        this.rankSelect = false

        if (role === 3) {
          this.$store.dispatch('setAllowDocumentSelect', true)
        } else {
          this.$store.dispatch('setAllowDocumentSelect', false)
          this.$store.dispatch('clearSelectedDocuments')
        }
      } else {
        this.errorMessage = this.$t('select_an_invitee')
      }
    },
    async sendInvite() {
      this.errorMessage = ''
      const invitees = this.$store.getters['getSelectedForInvite']
      const inviteesPayload = []

      for (let i = 0; i < invitees.length; i++) {
        const invitee = invitees[i]
        const user = {
          email: invitee.email,
          pages: invitee.magazine_pages,
        }

        const overlayingPages = this.findOverlayingPageIds(user)
        if (overlayingPages.length > 0) {
          this.errorMessage = this.$t('invite.pages.duplicate.warning') + ` ${overlayingPages.join(',')}`
          return
        }

        const inviteePayload = {
          email: invitee.email,
          message: invitee.message,
          language: this.language,
          coChiefEditor: invitee.role === 2,
          magazine_pages: invitee.magazine_pages.map((p) => p.id),
        }
        inviteesPayload.push(inviteePayload)
      }
      const payload = {
        magazine_id: this.magazineId,
        invitees: inviteesPayload,
      }

      this.isLoading = true
      const submitted = await sendInvitations(payload)

      if (submitted.status !== 200) {
        this.errorMessage = this.$t('invite.somthing.went.wrong')
        this.message = ''
        this.rank = 0
        this.selectedInvitee = 0
        await this.$store.dispatch('setSelectedInvitee', 0)
        await this.$store.dispatch('deleteAllSelectedUsersForInvite')
        await this.$store.dispatch('setAllowDocumentSelect', false)
        await this.$store.dispatch('clearSelectedDocuments')
        this.isLoading = false
      } else {
        await this.$store.dispatch('setInvitedMembers', this.magazineId)
        this.setAutoUserView()

        setTimeout(() => {
          this.email = ''
          this.message = ''
          this.rank = 0
          this.selectedInvitee = 0
          this.$store.dispatch('setSelectedInvitee', 0)
          this.$store.dispatch('deleteAllSelectedUsersForInvite')
          this.$store.dispatch('setAllowDocumentSelect', false)
          this.$store.dispatch('clearSelectedDocuments')
          this.isLoading = false
        }, 2000)
      }
    },
    setUserView(user, event) {
      if (event.target.classList.contains('activeEditIcon')) {
        event.target.classList.remove('activeEditIcon')
        this.$store.dispatch('removeUserView', user)
      } else {
        event.target.classList.add('activeEditIcon')
        this.$store.dispatch('addUserView', user)
      }
    },
    setUserEdit(user, event) {
      this.errorMessage = ''
      if (this.selectedInvitee !== 0) {
        this.errorMessage = 'Please finish the invitations first'
        return
      }
      if (this.editedUserId !== 0 && this.editedUserId !== user.email) {
        this.errorMessage = this.$t('invite.pages.save.warning')
        return
      }

      if (event.target.classList.contains('activeEditIcon')) {
        if (this.$store.getters['getSelectedDocuments'].length === 0) {
          this.errorMessage = this.$t('invite.maker.no.pages')
          return
        }

        const pages = this.$store.getters['getSelectedDocuments']
        const userObject = {
          email: user.email,
          pages: pages,
        }

        const overlayingPages = this.findOverlayingPageIds(userObject)
        if (overlayingPages.length > 0) {
          this.errorMessage = this.$t('invite.pages.duplicate.warning') + ` ${overlayingPages.join(',')}`
          return
        }

        event.target.classList.remove('activeEditIcon')
        this.$store.dispatch('setAllowDocumentSelect', false)

        user.pages = this.$store.getters['getSelectedDocuments'].map((p) => p.id)

        this.updateInvitee(user.email, user.pages, user.role).then(async (u) => {
          if (u) {
            this.isLoading = true
            await this.$store.dispatch('clearInvitees')
            await this.$store.dispatch('setInvitedMembers', this.magazineId)
            this.setAutoUserView()
            this.isLoading = false
            await this.$store.dispatch('clearSelectedDocuments')
            this.editedUserId = 0
          } else {
            await this.$store.dispatch('changeMember', this.oldUser)
            await this.$store.dispatch('clearSelectedDocuments')
            this.editedUserId = 0
            this.errorMessage = this.$t('invite.somthing.went.wrong')
          }
        })
      } else {
        this.oldUser = JSON.parse(JSON.stringify(user))
        Array.from(document.getElementsByClassName('userFocus')).forEach(function (el) {
          el.classList.remove('activeEditIcon')
        })

        event.target.classList.add('activeEditIcon')
        event.target.classList.add('userFocus')
        for (let i = 0; i < user.pages.length; i++) {
          this.$store.dispatch('selectDocumentsToInviteTo', user.pages[i])
        }
        this.$store.dispatch('setAllowDocumentSelect', true)
        this.editedUserId = user.email
      }
    },
    async removeUser(user) {
      const magazineId = this.$route.params.id || this.$store.getters['getCreationId']
      const data = { member: user, creationId: magazineId }
      this.isLoading = true
      await this.$store.dispatch('destroyInvitation', data)
      await this.$store.dispatch('removeUserView', user)
      this.errorMessage = ''
      this.isLoading = false
    },
    async valueChanged(object) {
      this.errorMessage = ''
      const user = this.$store.getters['getInvitees'].find((u) => u.email === object[1])
      user.role = object[0]

      const overlayingPages = this.findOverlayingPageIds(user)
      if (overlayingPages.length > 0) {
        this.errorMessage = this.$t('invite.pages.duplicate.warning') + ` ${overlayingPages.toString()}`
      }

      if (user.role === 3) {
        await this.$store.dispatch('changeMember', user)
        const elem = document.getElementById(user.email)
        elem.click()
      } else {
        this.$store.dispatch('setAllowDocumentSelect', false)
        this.editedUserId = 0
        this.$store.dispatch('clearSelectedDocuments')

        const newUser = await this.updateInvitee(user.email, user.pages, user.role)

        if (newUser) {
          await this.$store.dispatch('changeMember', user)
          this.isLoading = true
          await this.$store.dispatch('clearInvitees')
          await this.$store.dispatch('setInvitedMembers', this.magazineId)
          this.setAutoUserView()
          this.isLoading = false
        }
      }
    },
    async updateInvitee(userKey, pages, role) {
      if (role === 3) {
        pages.map((p) => p.id)
      } else {
        pages = null
      }

      const payload = {
        userKey: userKey,
        role,
        magazine_pages: pages,
      }

      try {
        const result = await updateInvitation(this.magazineId, payload)
        return result.data.data
      } catch (e) {
        return false
      }
    },
    findOverlayingPageIds(currentUser) {
      const inviteesPages = this.$store.getters['getInvitees']
        .filter((u) => {
          return u.email !== currentUser.email
        })
        .map((u) => u.pages.map((p) => p.number))

      const newPages = this.$store.getters['getSelectedForInvite']
        .filter((u) => {
          return u.email !== currentUser.email
        })
        .map((u) => u.magazine_pages.map((p) => p.number))

      const allPages = inviteesPages.concat(newPages)

      if (allPages.length > 0) {
        const allMappedPages = []
        allPages.map((p) => allMappedPages.push(...p))
        const currentAssignedPages = currentUser.pages.map((cp) => cp.number)
        // const currentSelectedPages = this.$store.getters['getSelectedDocuments'].map(d => d.number);
        // const newSelectedPages = currentSelectedPages.filter(p => !currentAssignedPages.includes(p));
        // return allMappedPages.filter(p => newSelectedPages.includes(p));
        return allMappedPages.filter((p) => currentAssignedPages.includes(p))
      } else {
        return []
      }
    },
    setAutoUserView() {
      this.$store.dispatch('clearUserView')
      const users = this.$store.getters['getInvitees']
      for (let i = 0; i < users.length; i++) {
        this.$store.dispatch('addUserView', users[i])
      }
    },
  },
  computed: {
    canInvite() {
      const invitees = this.$store.getters['getSelectedForInvite']
      if (
        invitees.length > 0 &&
        !invitees.find((i) => i.role === 0) &&
        !invitees.find((i) => i.role === 3 && i.magazine_pages.length === 0)
      ) {
        return 'hint'
      } else {
        return 'disabled'
      }
    },
  },
  watch: {
    status: function () {
      if (this.status) {
        this.$store.dispatch('setSameMessage', this.message)
      }
    },
  },
  async created() {
    await this.$store.dispatch('clearInvitees')
    await this.$store.dispatch('setInvitedMembers', this.magazineId)
    this.setAutoUserView()
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
