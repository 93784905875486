<template>
  <svg width="49" height="35" viewBox="0 0 49 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="35" transform="translate(0.0244141)" fill="white"/>
    <path d="M20.8329 25.3127H16.9162L19.3641 10.2688H23.2812L20.8329 25.3127ZM13.6212 10.2688L9.88719 20.6161L9.44534 18.3879L9.44574 18.3887L8.12784 11.6233C8.12784 11.6233 7.96848 10.2688 6.26989 10.2688H0.0968488L0.0244141 10.5235C0.0244141 10.5235 1.91214 10.9163 4.1214 12.2431L7.52421 25.3131H11.6051L17.8365 10.2688H13.6212ZM44.428 25.3127H48.0244L44.8888 10.2684H41.7403C40.2864 10.2684 39.9323 11.3895 39.9323 11.3895L34.0908 25.3127H38.1737L38.9902 23.0781H43.9693L44.428 25.3127ZM40.1182 19.9911L42.1761 14.3614L43.3339 19.9911H40.1182ZM34.3971 13.8865L34.956 10.6559C34.956 10.6559 33.2313 10 31.4333 10C29.4896 10 24.8739 10.8495 24.8739 14.9803C24.8739 18.8668 30.2912 18.9151 30.2912 20.9565C30.2912 22.998 25.4321 22.6322 23.8284 21.3449L23.2461 24.7227C23.2461 24.7227 24.995 25.5722 27.6671 25.5722C30.3399 25.5722 34.3721 24.1883 34.3721 20.4217C34.3721 16.5103 28.9061 16.1461 28.9061 14.4455C28.9065 12.7445 32.721 12.963 34.3971 13.8865Z" fill="#2566AF"/>
    <path d="M9.44574 18.3894L8.12784 11.6241C8.12784 11.6241 7.96848 10.2695 6.26989 10.2695H0.0968488L0.0244141 10.5243C0.0244141 10.5243 2.99142 11.1391 5.83729 13.443C8.55842 15.645 9.44574 18.3894 9.44574 18.3894Z" fill="#E6A540"/>
  </svg>

</template>

<script>
export default {
name: "VisaCard"
}
</script>

<style scoped>

</style>