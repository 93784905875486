var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.maintenanceModeCheck)?_c('Maintenance'):(_vm.detectMobilePhone)?_c('IsMobile'):_c('b-container',{attrs:{"id":"app","fluid":""},on:{"loggedIn":_vm.setPusherForDuplicationNotifications}},[(_vm.isNotAuth)?_c('Navbar',{on:{"on-click":function($event){_vm.$store.commit('SET_SHOW_SCRATCH_MODAL_LINDA', true)
      _vm.$store.commit('SET_SHOW_SCRATCH_MODAL', true)}}}):_c('AuthNavbar'),(!this.$store.getters['getLoadingDocument'] && _vm.sidebarVisibilityCheck)?_c('Sidebar',{on:{"show-take-a-look-modal":function($event){_vm.showBannerModal = true}}}):_vm._e(),_c('router-view'),(_vm.$store.getters['getDuplicatedCreation'])?_c('DoneEditing',{attrs:{"duplication":true}}):_vm._e(),(_vm.showAwardGuideModal && _vm.showAwardUI)?_c('AwardInfoModal'):_vm._e(),(_vm.showScratchModal)?_c('ModalContainer',{attrs:{"title":_vm.startCreationModal.title,"description":_vm.startCreationModal.description,"is-short":true},on:{"on-close":function($event){return _vm.$store.commit('SET_SHOW_SCRATCH_MODAL', false)}}},[_c('div',{staticClass:"grid grid-cols-2 gap-4 lg:gap-8"},[_c('Card',{attrs:{"data":_vm.startCreationMagazine,"show-info-btn":""},on:{"on-click":_vm.startMagazine,"on-info-click":() => {
            _vm.showInfoModal = true
            _vm.infoModalContent.title = _vm.startCreationMagazine.infoModalTitle
            _vm.infoModalContent.description = _vm.startCreationMagazine.infoModalDescription
          }}}),_c('Card',{attrs:{"data":_vm.startCreationBook,"show-info-btn":""},on:{"on-click":_vm.startBook,"on-info-click":() => {
            _vm.showInfoModal = true
            _vm.infoModalContent.title = _vm.startCreationBook.infoModalTitle
            _vm.infoModalContent.description = _vm.startCreationBook.infoModalDescription
          }}}),_c('Card',{attrs:{"data":_vm.startCreationGlossy,"show-info-btn":""},on:{"on-click":_vm.startGlossy,"on-info-click":() => {
            _vm.showInfoModal = true
            _vm.infoModalContent.title = _vm.startCreationGlossy.infoModalTitle
            _vm.infoModalContent.description = _vm.startCreationGlossy.infoModalDescription
          }}}),(_vm.checkLindaSetting)?_c('Card',{attrs:{"data":_vm.startCreationLinda},on:{"on-click":_vm.startLinda}}):_vm._e(),(_vm.showScratchModalLinda)?_c('Card',{attrs:{"data":_vm.startCreationTemplates},on:{"on-click":_vm.startTemplate}}):_vm._e()],1)]):_vm._e(),(_vm.showInfoModal)?_c('div',[_c('div',{staticClass:"fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return (() => {
          _vm.showInfoModal = false
          _vm.infoModalContent.title = ''
          _vm.infoModalContent.description = ''
        }).apply(null, arguments)}}},[_c('div',{staticClass:"bg-white p-8 rounded-2xl relative",staticStyle:{"max-width":"683px"}},[_c('h3',{staticClass:"text-2xl font-bold text-wg-light-accent"},[_vm._v(_vm._s(_vm.infoModalContent.title))]),_c('div',{staticClass:"text-wg-80 text-lg md-body mt-6",domProps:{"innerHTML":_vm._s(_vm.infoModalContent.description)}}),_c('button',{staticClass:"absolute top-0 right-0",staticStyle:{"margin-top":"0.75rem","margin-right":"0.75rem"},on:{"click":() => {
              _vm.showInfoModal = false
              _vm.infoModalContent.title = ''
              _vm.infoModalContent.description = ''
            }}},[_c('span',{staticClass:"jilster-font",staticStyle:{"font-size":"24px","color":"#999087"}},[_vm._v("k")])])])])]):_vm._e(),(_vm.showBannerModal)?_c('TakeALookBanner',{on:{"on-close":function($event){_vm.showBannerModal = false}}}):_vm._e(),(_vm.showTemplateModal)?_c('TemplateModal',{attrs:{"selected-template":_vm.selectedTemplate},on:{"on-close":function($event){return _vm.$store.commit('SET_SHOW_TEMPLATE_MODAL', false)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }