<template>
  <div class="order-error-review">
    <p class="order-error-sub-heading">Ooops</p>
    <h2>{{$t('payment.failed.title')}}</h2>
    <p>{{$t('payment.failed.text')}}</p>
    <div class="d-flex return-order">
      <div class="d-flex return-order-text">
        <span style="color: #111111;">{{$t('payment.failed.help')}}</span>
        <a :href="contactURL" target="_blank">{{$t('payment.failed.contact')}}</a>
      </div>
      <div class="d-flex">
        <Button style="margin-left: 13px" :button-text="$t('payment.failed.try.again')" button-type="primary" @click.native="tryAgain()"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from "@/components/Button";

  export default {
    name: "OrderErrorReview",
    components: {Button},
    data() {
      return {
        contactURL: ''
      }
    },
    methods: {
      tryAgain() {
        window.location.href = '/order-checkout/' + this.$route.params.id;
      }
    },
    mounted() {
      let language = localStorage.getItem("language");

      switch (language){
        case 'nl': this.contactURL = "https://www.jilster.nl/contact/"; break;
        case 'de': this.contactURL = "https://www.jilster.de/kontakt-neu/"; break;
        default: this.contactURL = "https://www.jilster.com/contact-new/"; break;
      }
    }
  }
</script>
