<template>
  <div id="teamBackModal">
    <b-row class="m-auto bg-light" id="content">
      <b-col class="p-0" id="illustration">
        <Illustration />
      </b-col>
      <b-col id="teamContent">
        <b-row>
          <b-col class="justify-content-end align-content-end text-right">
            <CloseButton @click.native="closeModal()" id="closeButton" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p id="header">{{$t('view_team')}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p id="membersText">{{$t('view_members')}}</p>
          </b-col>
        </b-row>
        <div class="scroll">
          <b-row v-for="invitee in acceptedInvitees" :key="invitee.id" class="teamRow ml-2 mr-2">
            <b-col class="pl-0 pr-0 avatar">
              <Avatar />
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <p class="nameText">{{ invitee.name }}</p>
                </b-col>
              </b-row>
              <b-row v-if="invitee.pages !== undefined">
                <b-col>
                  <p class="pagesText" v-if="invitee.role === 3">
                    {{$t('dashboard.entities.magazinepage')}}:<span v-for="(page, index) in invitee.pages" :key="invitee.id + page"
                      >&nbsp;{{ page.number
                      }}<span v-if="index !== invitee.pages.length - 1">,</span>
                    </span>
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="text-right pr-0 role">
              <div class="roleWrapper">
                <p class="mb-0">{{ getRoleById(invitee.role) }}</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Illustration from "@/components/viewteam/Illustration";
import CloseButton from "@/components/viewteam/CloseButton";
import Avatar from "@/components/viewteam/Avatar";

export default {
  name: "ViewTeam",
  components: { Illustration, CloseButton, Avatar },
  data() {
    return {
      magazineId: this.$route.params.id,
    };
  },
  computed: {
    acceptedInvitees: function () {
      return this.$store.getters['getInvitees'].filter(invitee => invitee.accepted);
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch("setShowViewTeamModal", false);
    },
    getRoleById(roleId) {
      if (roleId === 1) {
        return "Owner";
      } else if (roleId === 2) {
        return "Chiefmaker";
      } else if (roleId === 3) {
        return "Maker";
      }
    },
  },
  created() {
    this.$store.dispatch("setInvitedMembers", this.magazineId);
  },
};
</script>
