var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-participate"},[_c('div',{staticClass:"overlay",on:{"click":function($event){_vm.$store.commit('SET_SHOW_PARTICIPATE_AWARD_MODAL', false)
      _vm.$store.commit('SET_SHOW_PARTICIPATE_AWARD_CONFIRMATION_MODAL', false)
      _vm.school = ''
      _vm.searchStateText = ''
      _vm.selectedState = null
      _vm.termsAgreed = false
      _vm.errors = {
        school: '',
        province: '',
        terms: '',
      }
      _vm.showError = false
      _vm.isLoading = false}}}),_c('div',{staticClass:"modal-participate-content"},[_c('h2',[_vm._v(_vm._s(_vm.$t('magazine.award.participate.award.modal.headline')))]),_c('div',{staticClass:"inputs-container"},[_c('div',{staticClass:"template-cover"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.coverImage),expression:"coverImage"}],attrs:{"src":_vm.coverImage,"alt":""}})]),_c('div',{staticClass:"inputs"},[_c('div',{staticStyle:{"position":"relative"}},[_c('p',[_vm._v(_vm._s(_vm.$t('magazine.award.participate.award.modal.school.name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.school),expression:"school"}],attrs:{"type":"text","placeholder":_vm.$t('magazine.award.participate.award.modal.school.name.placeholder')},domProps:{"value":(_vm.school)},on:{"input":[function($event){if($event.target.composing)return;_vm.school=$event.target.value},function($event){_vm.errors.school = ''}]}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.school))])]),_c('div',{staticStyle:{"position":"relative"}},[_c('p',[_vm._v(_vm._s(_vm.$t('magazine.award.participate.award.modal.province')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchStateText),expression:"searchStateText"}],attrs:{"type":"text","placeholder":_vm.$t('magazine.award.participate.award.modal.province.placeholder')},domProps:{"value":(_vm.searchStateText)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchStateText=$event.target.value},function($event){_vm.errors.province = ''}]}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.province))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.suggestedStates.length),expression:"suggestedStates.length"}],staticClass:"drop-down-list"},[_c('ul',_vm._l((_vm.suggestedStates),function(state){return _c('li',{key:state.Id,on:{"click":function($event){_vm.selectedState = state
                  _vm.searchStateText = state.Name}}},[_vm._v(" "+_vm._s(state.Name)+" ")])}),0)])]),_c('div',{staticClass:"checkbox",staticStyle:{"position":"relative"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.termsAgreed),expression:"termsAgreed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.termsAgreed)?_vm._i(_vm.termsAgreed,null)>-1:(_vm.termsAgreed)},on:{"change":[function($event){var $$a=_vm.termsAgreed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.termsAgreed=$$a.concat([$$v]))}else{$$i>-1&&(_vm.termsAgreed=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.termsAgreed=$$c}},function($event){_vm.errors.terms = ''}]}}),_c('p',[_vm._v(_vm._s(_vm.$t('magazine.award.participate.award.modal.terms')))]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.terms))])]),_c('div',{staticStyle:{"position":"relative"}},[_c('button',{staticClass:"start-btn",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('magazine.award.participate.award.modal.button'))+" ")]),(_vm.showError)?_c('Error',{attrs:{"type":"system","errorMessage":_vm.$t('magazine.award.modal.system.error.message')}}):_vm._e(),(_vm.imageError)?_c('Error',{attrs:{"type":"system","errorMessage":_vm.$t('magazine.award.modal.image.not.present.message')}}):_vm._e()],1)])]),_c('button',{staticClass:"close-btn",on:{"click":function($event){_vm.$store.commit('SET_SHOW_PARTICIPATE_AWARD_MODAL', false)
        _vm.$store.commit('SET_SHOW_PARTICIPATE_AWARD_CONFIRMATION_MODAL', false)
        _vm.school = ''
        _vm.searchStateText = ''
        _vm.selectedState = null
        _vm.termsAgreed = false
        _vm.errors = {
          school: '',
          province: '',
          terms: '',
        }
        _vm.showError = false
        _vm.isLoading = false}}},[_c('svg',{attrs:{"width":"30","height":"29","viewBox":"0 0 30 29","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M17.6562 15L27.8125 4.92188C28.6458 4.03646 28.6458 3.125 27.8125 2.1875C26.875 1.35417 25.9635 1.35417 25.0781 2.1875L15 12.3438L4.92188 2.1875C3.98438 1.35417 3.07292 1.35417 2.1875 2.1875C1.35417 3.125 1.35417 4.03646 2.1875 4.92188L12.3438 15L2.1875 25.0781C1.35417 25.9635 1.35417 26.875 2.1875 27.8125C2.65625 28.1771 3.125 28.3594 3.59375 28.3594C4.01042 28.3594 4.45312 28.1771 4.92188 27.8125L15 17.6562L25.0781 27.8125C25.5469 28.1771 25.9896 28.3594 26.4062 28.3594C26.875 28.3594 27.3438 28.1771 27.8125 27.8125C28.6458 26.875 28.6458 25.9635 27.8125 25.0781L17.6562 15Z","fill":"#999187"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }