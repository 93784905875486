<template>
  <div class="flex justify-center items-start min-h-screen w-full" style="background-color: white !important">
    <div class="mt-32 relative">
      <Error
        v-if="systemError"
        type="system"
        class="absolute top-0 z-50"
        style="left: 50%; transform: translateX(-50%); min-width: 300px"
        :errorMessage="systemErrorMessage"
      />

      <div class="flex justify-center items-center w-full mb-6">
        <!-- <lottie-vue-player
          :src="`/lottie/preparing-template.json`"
          :player-size="'standard'"
          :minimizable="false"
          :player-controls="false"
          :autoplay="true"
          :loop="true"
          :speed="1"
          style="width: 350px; height: 350px"
        /> -->
        <img v-if="getSelectedTemplate" :src="getFrontCover" alt="" class="h-72" />
      </div>

      <div class="flex justify-center items-center flex-col relative z-10 mb-10">
        <p>{{ $t('preparing.template.title') }}</p>

        <div class="mt-2 flex justify-between items-center" style="width: 212px">
          <div class="w-full bg-wg-80 bg-opacity-30 h-3 rounded-2xl relative overflow-hidden">
            <span class="absolute top-0 left-0 h-3 bg-black" :style="`width: ${progress}%`"></span>
          </div>
        </div>
      </div>

      <!-- 4 Checkboxes -->
      <div class="flex justify-center items-center space-x-5 mb-10">
        <div class="space-x-1 flex justify-start items-center">
          <span
            class="rounded-full flex justify-center items-center"
            :class="steps.stepOne.completed ? 'bg-wg-light-accent' : 'bg-wg-light-information'"
            style="width: 22px; height: 22px"
          >
            <svg
              v-show="steps.stepOne.completed"
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9391 1.47559C11.8888 1.61766 11.8086 1.74888 11.7033 1.86153L5.7051 8.59627C5.59839 8.71604 5.46619 8.81369 5.31691 8.88301C5.16764 8.95232 5.00456 8.99177 4.83809 8.99884C4.67162 9.00592 4.50539 8.98046 4.35002 8.9241C4.19464 8.86774 4.05352 8.7817 3.93564 8.67148L0.336751 5.30411C0.118229 5.09241 -0.00268791 4.80887 4.5349e-05 4.51457C0.00277861 4.22026 0.128943 3.93874 0.351364 3.73062C0.573786 3.52251 0.874668 3.40446 1.18921 3.40191C1.50375 3.39935 1.80678 3.51249 2.03303 3.71695L4.70221 6.21554L9.86062 0.42478C9.96005 0.307533 10.0835 0.210076 10.2238 0.138142C10.3641 0.0662071 10.5183 0.0212483 10.6774 0.00590995C10.8365 -0.00942841 10.9973 0.00516291 11.1503 0.0488268C11.3033 0.0924907 11.4455 0.164346 11.5684 0.260162C11.6912 0.355979 11.7924 0.473823 11.8659 0.606762C11.9394 0.739701 11.9837 0.88505 11.9963 1.03426C12.0089 1.18347 11.9894 1.33352 11.9391 1.47559Z"
                fill="white"
              />
            </svg>
          </span>
          <span class="text-sm" :class="steps.stepOne.completed ? 'text-wg-100' : 'text-wg-70'">{{
            $t('preparing.template.step.zero')
          }}</span>
        </div>

        <div class="space-x-1 flex justify-start items-center">
          <span
            class="rounded-full flex justify-center items-center"
            :class="steps.stepTwo.completed ? 'bg-wg-light-accent' : 'bg-wg-light-information'"
            style="width: 22px; height: 22px"
          >
            <svg
              v-show="steps.stepTwo.completed"
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9391 1.47559C11.8888 1.61766 11.8086 1.74888 11.7033 1.86153L5.7051 8.59627C5.59839 8.71604 5.46619 8.81369 5.31691 8.88301C5.16764 8.95232 5.00456 8.99177 4.83809 8.99884C4.67162 9.00592 4.50539 8.98046 4.35002 8.9241C4.19464 8.86774 4.05352 8.7817 3.93564 8.67148L0.336751 5.30411C0.118229 5.09241 -0.00268791 4.80887 4.5349e-05 4.51457C0.00277861 4.22026 0.128943 3.93874 0.351364 3.73062C0.573786 3.52251 0.874668 3.40446 1.18921 3.40191C1.50375 3.39935 1.80678 3.51249 2.03303 3.71695L4.70221 6.21554L9.86062 0.42478C9.96005 0.307533 10.0835 0.210076 10.2238 0.138142C10.3641 0.0662071 10.5183 0.0212483 10.6774 0.00590995C10.8365 -0.00942841 10.9973 0.00516291 11.1503 0.0488268C11.3033 0.0924907 11.4455 0.164346 11.5684 0.260162C11.6912 0.355979 11.7924 0.473823 11.8659 0.606762C11.9394 0.739701 11.9837 0.88505 11.9963 1.03426C12.0089 1.18347 11.9894 1.33352 11.9391 1.47559Z"
                fill="white"
              />
            </svg>
          </span>
          <span class="text-sm" :class="steps.stepTwo.completed ? 'text-wg-100' : 'text-wg-70'">{{
            $t('preparing.template.step.one')
          }}</span>
        </div>

        <div class="space-x-1 flex justify-start items-center">
          <span
            class="rounded-full flex justify-center items-center"
            :class="steps.stepThree.completed ? 'bg-wg-light-accent' : 'bg-wg-light-information'"
            style="width: 22px; height: 22px"
          >
            <svg
              v-show="steps.stepThree.completed"
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9391 1.47559C11.8888 1.61766 11.8086 1.74888 11.7033 1.86153L5.7051 8.59627C5.59839 8.71604 5.46619 8.81369 5.31691 8.88301C5.16764 8.95232 5.00456 8.99177 4.83809 8.99884C4.67162 9.00592 4.50539 8.98046 4.35002 8.9241C4.19464 8.86774 4.05352 8.7817 3.93564 8.67148L0.336751 5.30411C0.118229 5.09241 -0.00268791 4.80887 4.5349e-05 4.51457C0.00277861 4.22026 0.128943 3.93874 0.351364 3.73062C0.573786 3.52251 0.874668 3.40446 1.18921 3.40191C1.50375 3.39935 1.80678 3.51249 2.03303 3.71695L4.70221 6.21554L9.86062 0.42478C9.96005 0.307533 10.0835 0.210076 10.2238 0.138142C10.3641 0.0662071 10.5183 0.0212483 10.6774 0.00590995C10.8365 -0.00942841 10.9973 0.00516291 11.1503 0.0488268C11.3033 0.0924907 11.4455 0.164346 11.5684 0.260162C11.6912 0.355979 11.7924 0.473823 11.8659 0.606762C11.9394 0.739701 11.9837 0.88505 11.9963 1.03426C12.0089 1.18347 11.9894 1.33352 11.9391 1.47559Z"
                fill="white"
              />
            </svg>
          </span>
          <span class="text-sm" :class="steps.stepThree.completed ? 'text-wg-100' : 'text-wg-70'"
            >{{ $t('preparing.template.step.second.before.pages') }} {{ pageCount }}
            {{ $t('preparing.template.step.second.after.pages') }}</span
          >
        </div>

        <div class="space-x-1 flex justify-start items-center">
          <span
            class="rounded-full flex justify-center items-center"
            :class="steps.stepFour.completed ? 'bg-wg-light-accent' : 'bg-wg-light-information'"
            style="width: 22px; height: 22px"
          >
            <svg
              v-show="steps.stepFour.completed"
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9391 1.47559C11.8888 1.61766 11.8086 1.74888 11.7033 1.86153L5.7051 8.59627C5.59839 8.71604 5.46619 8.81369 5.31691 8.88301C5.16764 8.95232 5.00456 8.99177 4.83809 8.99884C4.67162 9.00592 4.50539 8.98046 4.35002 8.9241C4.19464 8.86774 4.05352 8.7817 3.93564 8.67148L0.336751 5.30411C0.118229 5.09241 -0.00268791 4.80887 4.5349e-05 4.51457C0.00277861 4.22026 0.128943 3.93874 0.351364 3.73062C0.573786 3.52251 0.874668 3.40446 1.18921 3.40191C1.50375 3.39935 1.80678 3.51249 2.03303 3.71695L4.70221 6.21554L9.86062 0.42478C9.96005 0.307533 10.0835 0.210076 10.2238 0.138142C10.3641 0.0662071 10.5183 0.0212483 10.6774 0.00590995C10.8365 -0.00942841 10.9973 0.00516291 11.1503 0.0488268C11.3033 0.0924907 11.4455 0.164346 11.5684 0.260162C11.6912 0.355979 11.7924 0.473823 11.8659 0.606762C11.9394 0.739701 11.9837 0.88505 11.9963 1.03426C12.0089 1.18347 11.9894 1.33352 11.9391 1.47559Z"
                fill="white"
              />
            </svg>
          </span>
          <span class="text-sm" :class="steps.stepFour.completed ? 'text-wg-100' : 'text-wg-70'">{{
            $t('preparing.template.step.third')
          }}</span>
        </div>
      </div>

      <div class="w-full flex justify-center items-center">
        <transition
          name="add-title"
          enter-class="translate-y-full opacity-0"
          enter-active-class="transition ease-out duration-500"
          enter-to-class="-translate-y-0 opacity-100"
        >
          <AddTitle v-show="showAddTitle" :loading="loading" @add-title="onAddTitle" @on-input="systemError = false" />
        </transition>

        <TitleAdded v-show="showTitleAdded" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

import Error from '@/components/Error'

import AddTitle from './AddTitle.vue'
import TitleAdded from './TitleAdded.vue'

import httpClient from '../../../httpClient'

Vue.use(LottieVuePlayer)

export default {
  components: {
    AddTitle,
    TitleAdded,
    Error,
  },

  data() {
    return {
      showAddTitle: false,
      showTitleAdded: false,
      progress: 0,
      magazineId: '',
      systemError: false,
      systemErrorMessage: this.$t('preparing.template.error'),
      steps: {
        stepOne: {
          completed: false,
        },
        stepTwo: {
          completed: false,
        },
        stepThree: {
          completed: false,
        },
        stepFour: {
          completed: false,
        },
      },
      loading: false,
      pageCount: 0,
      getSelectedTemplate: null,
    }
  },

  computed: {
    getFrontCover() {
      if (this.getSelectedTemplate && this.getSelectedTemplate.canvasses && this.getSelectedTemplate.canvasses.length) {
        const frontCover = this.getSelectedTemplate.canvasses.find(
          (canvas) => canvas.id === this.getSelectedTemplate.cover_id
        )

        if (frontCover) {
          return frontCover.s3_fullsize_url
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
  },

  watch: {
    showTitleAdded(val) {
      if (val) {
        const that = this

        const channel = this.$pusher.subscribe(`private-creation.${this.magazineId}`)

        let time = 0

        const timer = setInterval(() => {
          time += 1
        }, 1000)

        channel.bind('StartFromTemplateCreationProgress', async (data) => {
          const progress = data && data.progress && data.progress.p ? data.progress.p : 0

          if (progress > that.progress) {
            // Need to delay the progress if it is too fast

            // Progress can't be 100 before 5 seconds. Need to slow down the progress if it is too fast
            if (progress >= 95 && time < 7) {
              await this.delay(2000)
              that.progress = 15

              await this.delay(3000)
              that.progress = 65

              await this.delay(2000)
              that.progress = 95
            } else if (progress >= 65 && time < 5) {
              await this.delay(3000)
              that.progress = 65

              await this.delay(2000)
              that.progress = 95
            } else if (progress >= 15 && time < 2) {
              await this.delay(2000)
              that.progress = 15
            } else {
              that.progress = progress
            }

            if (progress >= 95 && time >= 7) {
              clearInterval(timer)
            }
          }
        })
      }
    },

    progress(val) {
      if (val >= 15) {
        this.steps.stepTwo.completed = true
      }

      if (val >= 65) {
        this.steps.stepThree.completed = true
      }

      if (val >= 95) {
        this.steps.stepFour.completed = true
      }
    },

    steps: {
      handler(val) {
        if (val.stepFour.completed) {
          setTimeout(() => {
            this.$router.push({
              name: 'DocumentOverview',
              params: {
                id: this.magazineId,
              },
            })
          }, 1000)
        }
      },
      deep: true,
    },
  },

  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async getTemplate() {
      const templateId = this.$route.params.id

      try {
        const res = await httpClient.get('/v3/template/' + templateId)

        if (res && res.data && res.data.data) {
          this.getSelectedTemplate = res.data.data
          this.pageCount = res.data.data.pageCount
        }
      } catch (err) {
        console.log(err.message)
        this.systemError = true
      }
    },

    async onAddTitle(val) {
      this.loading = true
      this.systemError = false

      if (this.$route.params.id) {
        try {
          const res = await httpClient.post('/v3/creation/start-from-template', {
            title: val,
            templateId: this.$route.params.id,
          })

          if (res.data && res.data.status === 'Success') {
            this.magazineId = (res.data.data && res.data.data.id) || ''

            this.showAddTitle = false
            this.showTitleAdded = true
          } else {
            this.systemError = true
          }

          this.loading = false
          this.steps.stepOne.completed = true
        } catch (err) {
          this.systemError = true
          this.loading = false
        }
      }
    },
  },

  mounted() {
    this.getTemplate()

    this.showAddTitle = true
  },
}
</script>

<style scoped>
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 16px;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
