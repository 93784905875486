<template>
  <div class="checkout-delivery">
    <h2>Delivery options</h2>
    <!--    <b-form-group v-slot="{ ariaDescribedby }">-->
    <!--      <b-form-radio @change="checkForm" v-model="deliveryType" :aria-describedby="ariaDescribedby" name="PostNL_radio" :value=10>-->
    <!--        PostNL <br>-->
    <!--        98% kans op levering-->
    <!--        <img class="delivery-image" src="https://www.postnl.nl/img/logo.png" alt="">-->
    <!--      </b-form-radio>-->
    <!--      <b-form-radio @change="checkForm" v-model="deliveryType" :aria-describedby="ariaDescribedby" name="PostNL_radio" :value=10>-->
    <!--        DHL <br>-->
    <!--        98% kans op levering-->
    <!--        <img class="delivery-image" src="https://www.dhlexpress.nl/sites/default/files/dhl_logo_met_gele_achtergrond.png" alt="">-->
    <!--      </b-form-radio>-->
    <!--      <b-form-radio @change="checkForm" v-model="deliveryType" :aria-describedby="ariaDescribedby" name="PostNL_radio" :value=10>-->
    <!--        TNT <br>-->
    <!--        98% kans op levering-->
    <!--        <img class="delivery-image" src="https://www.randstad.nl/binaries/cardImageDesktopNormal/content/gallery/randstad/card-images/werknemers/topwerkgevers/topwerkver-tnt-logo.png" alt="">-->
    <!--      </b-form-radio>-->
    <!--      <b-form-radio @change="checkForm" v-model="deliveryType" :aria-describedby="ariaDescribedby" name="PostNL_radio" :value=10>-->
    <!--        GLS <br>-->
    <!--        98% kans op levering-->
    <!--        <img class="delivery-image" src="https://gls-group.eu/NL/media/icons/Logo_neg_315x128_RGB-download-35140.jpg" alt="">-->
    <!--      </b-form-radio> -->
    <!--    </b-form-group>-->
    <b-row>
      <b-col
        lg="3"
        v-for="deliveryMethod in deliveryMethods"
        :key="deliveryMethod.id"
      >
        <div
          class="payment-option"
          @click="selectDeliveryMethod(deliveryMethod)"
          v-bind:class="{
            selectDeliveryMethodStyle:
              selectDeliveryMethodStyle(deliveryMethod),
          }"
        >
          <img :src="deliveryMethod.img" alt="" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CheckoutDelivery",
  data() {
    return {
      deliveryMethod: {
        value: 0,
      },
      deliveryMethods: [
        {
          id: 1,
          name: "PostNL",
          value: 10,
          img: "https://www.postnl.nl/img/logo.png",
        },
        {
          id: 2,
          name: "DHL",
          value: 10,
          img: "https://www.dhlexpress.nl/sites/default/files/dhl_logo_met_gele_achtergrond.png",
        },
      ],
    };
  },
  methods: {
    checkForm() {
      if (this.deliveryMethod !== "") {
        this.$store.dispatch("stepComplete", true);
        this.$store.dispatch("setShippingPrice", this.deliveryMethod);
      } else {
        this.$store.dispatch("stepComplete", false);
        this.$store.dispatch("setShippingPrice", this.deliveryMethod);
      }
    },
    selectDeliveryMethod(delivery) {
      this.deliveryMethod = delivery;
      this.checkForm();
    },
    selectDeliveryMethodStyle(delivery) {
      return this.deliveryMethod.id === delivery.id;
    },
  },
  created() {
    this.checkForm();
  },
};
</script>
