<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#339AF0"/>
    <path d="M15.75 17.8203C15.75 18.0547 15.6719 18.263 15.5156 18.4453C15.3594 18.6016 15.1641 18.6797 14.9297 18.6797H9.07031C8.86198 18.6797 8.66667 18.6016 8.48438 18.4453C8.32812 18.263 8.25 18.0547 8.25 17.8203C8.25 17.612 8.32812 17.4297 8.48438 17.2734C8.64062 17.0911 8.83594 17 9.07031 17H11.3359V11.2578H9.5C9.29167 11.2578 9.09635 11.1797 8.91406 11.0234C8.75781 10.8411 8.67969 10.6328 8.67969 10.3984C8.67969 10.1901 8.75781 10.0078 8.91406 9.85156C9.07031 9.66927 9.26562 9.57812 9.5 9.57812H12.2344C12.4688 9.57812 12.6641 9.65625 12.8203 9.8125C13.0026 9.96875 13.0938 10.1641 13.0938 10.3984V17H15.0078C15.1901 17 15.3594 17.0911 15.5156 17.2734C15.6719 17.4297 15.75 17.612 15.75 17.8203ZM11.2578 7.74219C11.5443 7.92448 11.7917 8.01562 12 8.01562C12.2604 8.01562 12.599 7.8724 13.0156 7.58594C13.276 7.32552 13.4062 7.01302 13.4062 6.64844C13.4062 6.41406 13.3281 6.16667 13.1719 5.90625C13.0156 5.69792 12.8203 5.52865 12.5859 5.39844C12.3516 5.34635 12.0781 5.32031 11.7656 5.32031C11.4792 5.39844 11.2578 5.51562 11.1016 5.67188C10.9453 5.80208 10.8021 6.04948 10.6719 6.41406C10.6198 6.59635 10.6458 6.84375 10.75 7.15625C10.8542 7.46875 11.0234 7.66406 11.2578 7.74219Z" fill="white"/>
  </svg>

</template>

<script>
export default {
  name: "InfoSvg"
}
</script>
