<template>
  <div id="order-detail" style="max-width: 890px">
    <b-container v-if="this.$store.getters['getPreviewOrder'].length !== 0">
      <i @click="goBack" class="icon-chevron-left back"
        ><span>{{ $t('orderdetails.button.back') }}</span></i
      >
      <div v-if="this.$store.getters['getPreviewOrder'].id != null">
        <h4>{{ $t('orderdetails.order.details') }}</h4>
        <b-row>
          <b-col lg="12">
            <div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orders.table.date') }}:</b-col>
                  <b-col lg="6">{{ getDate(this.$store.getters['getPreviewOrder'].created_at) }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orders.table.number') }}:</b-col>
                  <b-col lg="6">{{ this.$store.getters['getPreviewOrder'].id }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orders.table.title') }}:</b-col>
                  <b-col lg="6" v-if="this.$store.getters['getPreviewOrder'].magazine"
                    >{{ this.$store.getters['getPreviewOrder'].magazine.title }}
                  </b-col>
                  <b-col lg="6" v-else>No magazine title</b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.type') }}:</b-col>
                  <b-col lg="6" v-if="this.$store.getters['getPreviewOrder'].magazine"
                    >{{ getType(this.$store.getters['getPreviewOrder'].magazine.canvasVersion) }}
                  </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.payment.method') }}:</b-col>
                  <b-col lg="6">{{ this.$store.getters['getPreviewOrder'].paymentmethod.name }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.status') }}:</b-col>
                  <b-col lg="6">
                    <span class="table-status canceled">
                      {{ this.$store.getters['getPreviewOrder'].orderstatus.title }}
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <h4>{{ $t('orderdetails.contact.details') }}</h4>
        <b-row>
          <b-col lg="12">
            <div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.client') }}:</b-col>
                  <b-col lg="6">{{ this.$store.getters['getPreviewOrder'].firstName }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.email') }}:</b-col>
                  <b-col lg="6">{{ this.$store.getters['getPreviewOrder'].user.email }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.company') }}:</b-col>
                  <b-col lg="6">{{ this.$store.getters['getPreviewOrder'].companyName }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.address') }}:</b-col>
                  <b-col lg="6"
                    >{{ this.$store.getters['getPreviewOrder'].street }}
                    {{ this.$store.getters['getPreviewOrder'].houseNumber }}
                  </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.mobile.number') }}:</b-col>
                  <b-col lg="6">{{ this.$store.getters['getPreviewOrder'].phoneNumber }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.other') }}:</b-col>
                  <b-col lg="6">{{ this.$store.getters['getPreviewOrder'].user.phoneNumber }} </b-col>
                </b-row>
              </div>
              <div class="detail">
                <b-row>
                  <b-col lg="2">{{ $t('orderdetails.status') }}:</b-col>
                  <b-col lg="6">
                    <span class="table-status canceled">
                      {{ this.$store.getters['getPreviewOrder'].orderstatus.title }}
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <div>
          <h4>{{ $t('orderdetails.final.order') }}</h4>
          <table>
            <tr>
              <th>{{ $t('orderdetails.table.product') }}</th>
              <th>{{ $t('orderdetails.table.amount') }}</th>
              <th>{{ $t('orders.table.price') }}</th>
            </tr>
            <tr v-if="this.$store.getters['getPreviewOrder'].amount !== 0">
              <td v-if="this.$store.getters['getPreviewOrder'].magazine">
                {{ this.$store.getters['getPreviewOrder'].magazine.title }}
              </td>
              <td v-else>No magazine title</td>
              <td>{{ this.$store.getters['getPreviewOrder'].amount }}</td>
              <td>€{{ getPrice(this.$store.getters['getPreviewOrder'].magazinesPriceWithoutVAT) }}</td>
            </tr>
            <template v-if="this.$store.getters['getPreviewOrder'].orderextras !== '[]'">
              <tr v-for="extra in JSON.parse($store.getters['getPreviewOrder'].orderextras)" :key="extra.id">
                <!--              <td>{{ $t("orderdetails.table.extras") }}</td>-->
                <!--              <td>{{JSON.parse(this.$store.getters['getPreviewOrder'].orderextras).length}}</td>-->
                <!--              <td v-html="getOrderExtras()"></td>-->
                <td>
                  {{ setName(extra.name) }}
                </td>
                <td>1</td>
                <td>€{{ getPrice(extra.priceWithoutVAT) }}</td>
              </tr>
            </template>

            <!--            <tr v-for="extra in JSON.parse(this.$store.getters['getPreviewOrder'].orderextras)">-->
            <!--              -->
            <!--            </tr>-->
            <tr v-if="getPrice(this.$store.getters['getPreviewOrder'].shippingPriceWithVAT) !== '0,00'">
              <td>
                <strong style="font-size: 14px !important">{{ $t('orderdetails.table.shipping') }}</strong>
              </td>
              <td></td>
              <td>€{{ getPrice(this.$store.getters['getPreviewOrder'].shippingPriceWithoutVAT) }}</td>
            </tr>
            <tr
              v-if="
                getExtraShippingPrice(this.$store.getters['getPreviewOrder'].orderextrasShippingWithoutVAT) !== '0,00'
              "
            >
              <td>
                <strong style="font-size: 14px !important">{{ $t('orderdetails.table.shipping.extra') }}</strong>
              </td>
              <td></td>
              <td>
                €{{ getExtraShippingPrice(this.$store.getters['getPreviewOrder'].orderextrasShippingWithoutVAT) }}
              </td>
            </tr>
            <tr>
              <td>
                <strong style="font-size: 14px !important">{{ $t('orderdetails.table.btw') }}</strong>
              </td>
              <td></td>
              <td>
                €{{
                  getPrice(
                    this.$store.getters['getPreviewOrder'].totalLowVAT +
                      this.$store.getters['getPreviewOrder'].totalHighVAT
                  )
                }}
              </td>
            </tr>
            <tr class="order_ending">
              <td>
                <strong>{{ $t('orderdetails.table.total') }}</strong>
              </td>
              <td></td>
              <td>€{{ getPrice(this.$store.getters['getPreviewOrder'].totalPriceWithVAT) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </b-container>
    <b-container v-else>
      <div class="loading-icon">
        <img src="/loading.gif" alt="loading" />
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Orderdetail',
  methods: {
    async goBack() {
      await this.$store.dispatch('setBackToOrders', false)
      await this.$store.dispatch('getOrders')
      await this.$store.dispatch('getDownloads')
    },
    getDate(dateString) {
      const date = new Date(dateString)
      return (
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        date.getFullYear()
      )
    },
    getType(canvasVersion) {
      if (canvasVersion === 'default') {
        return 'Magazine'
      }
      return canvasVersion
    },
    getPrice(price) {
      return (price / 100).toFixed(2).toString().replace('.', ',')
    },
    getPriceExtras(extras) {
      let total = 0
      for (let i = 0, _len = extras.length; i < _len; i++) {
        total += extras[i]['priceWithVAT']
      }
      return (total / 100).toFixed(2).toString().replace('.', ',')
    },
    getExtraShippingPrice(withVat) {
      return (withVat / 100).toFixed(2).toString().replace('.', ',')
    },
    setName(name) {
      let extraName = ''
      switch (name) {
        case 'poster_a0':
          extraName = 'Poster A0'
          break
        case 'poster_a1':
          extraName = 'Poster A1'
          break
        case 'poster_a2':
          extraName = 'Poster A2'
          break
        case 'poster_a3_plus':
          extraName = 'Poster A3 Plus'
          break
        case 'poster_a3':
          extraName = 'Poster A3'
          break
        case 'poster_a4':
          extraName = 'Poster A4'
          break
        case 'pdf_72dpi_magazine':
          extraName = this.$t('pdf_order_72')
          break
        case 'pdf_300dpi_cover':
          extraName = this.$t('pdf_order_300')
          break
      }
      return extraName
    },
    getOrderExtras() {
      const extras = JSON.parse(this.$store.getters['getPreviewOrder'].orderextras)
      return extras
      // return extras.map(i => i.name.replace(/(_)/g, " ")).join(", </br>");
    },
  },
}
</script>
