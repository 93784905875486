import Orders from "./Orders.vue";
import Orderdetail from "./Orderdetail.vue";
import DownloadOrder from "./DownloadOrder.vue";
import Checkout from "./Checkout.vue";

export default [
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order/:id",
    name: "Orderdetail",
    component: Orderdetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/download-order/:id",
    name: "DownloadOrder",
    component: DownloadOrder,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      requiresAuth: true,
    },
  },
];
