var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.getters['getDocumentsFromApi'].length !== 0)?_c('div',{staticClass:"document-settings"},[_c('div',{staticClass:"heading"},[(!this.$store.getters['getShowInviteForm'])?_c('p',[_vm._v(" "+_vm._s(_vm.$t("documentsettings.drag.pages"))+" ")]):(this.$store.getters['getAllowDocumentSelect'])?_c('p',[_vm._v(" "+_vm._s(_vm.$t('documentinvite.select.instructions'))+" ")]):_vm._e(),_c('div',{staticClass:"settings-views"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"view-as-project viewIcon",class:{
          itemActive: !this.$store.getters['getViewedAsCreationInSettings'],
        },attrs:{"title":"Document view"},on:{"click":function($event){return _vm.$store.dispatch('setViewedAsCreationInSettingsStatus', false)}}},[_c('i',{staticClass:"icon-book"})]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"view-as-creation viewIcon",class:{
          itemActive: this.$store.getters['getViewedAsCreationInSettings'],
        },attrs:{"title":"Page view"},on:{"click":function($event){return _vm.$store.dispatch('setViewedAsCreationInSettingsStatus', true)}}},[_c('i',{staticClass:"icon-page"})])])]),_c('div',{staticClass:"document-settings-content",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"document-settings-item-container"},[(this.$store.getters['getViewedAsCreationInSettings'])?_c('div',{staticStyle:{"height":"100%"}},[(!this.$store.getters['getInviteMenu'])?_c('b-row',[(_vm.getFrontCover !== undefined)?_c('b-col',{class:{ lindaColSize: _vm.getIsLindaMagazine },attrs:{"lg":"2","draggable":"false"}},[_c('Document',{attrs:{"invitee":_vm.getMakerByPage(_vm.getFrontCover),"page":_vm.getFrontCover.number,"pageCover":_vm.getFrontCover.type,"magazine-page-id":_vm.getFrontCover.id,"magazine-id":_vm.getFrontCover.magazine_id,"imageUrl":_vm.createThumbnailUrl(_vm.getFrontCover.magazine_id, _vm.getFrontCover.id)}})],1):_vm._e(),_vm._l((_vm.sortDocuments),function(document){return _c('b-col',{key:document.id,staticClass:"can-be-dragged",class:{ lindaColSize: _vm.getIsLindaMagazine },attrs:{"data-number":document.number,"draggable":!_vm.$store.getters['getShowInviteForm'],"lg":"2"},on:{"dragstart":function($event){return _vm.startDrag($event, document)},"dragend":function($event){return _vm.endDrag($event)},"drop":function($event){return _vm.onDrop($event, document)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('Document',{class:[
                {
                  documentAllowHover:
                    !_vm.$store.getters['getShowInviteForm'] &&
                    _vm.$store.getters['getDocumentsToDelete'].find(
                      (docId) => docId == document.id
                    ) === undefined,
                },
                { isNewPage: document.newPage },
                { selected_document: _vm.selectedPageStyle(document) },
              ],attrs:{"IdsToDelete":_vm.$store.getters['getDocumentsToDelete'],"documentId":document.id,"invitee":_vm.getMakerByPage(document),"newPage":document.newPage,"page":document.number,"magazine-page-id":document.id,"magazine-id":document.magazine_id,"imageUrl":_vm.createThumbnailUrl(document.magazine_id, document.id)},nativeOn:{"click":function($event){_vm.$store.getters['getShowInviteForm'] &&
                _vm.$store.getters['getAllowDocumentSelect']
                  ? _vm.selectDocumentToInviteTo(document)
                  : _vm.clickNotAllowed()}}})],1)}),(_vm.getBackCover !== undefined)?_c('b-col',{class:{ lindaColSize: _vm.getIsLindaMagazine },attrs:{"lg":"2","draggable":"false"}},[_c('Document',{attrs:{"invitee":_vm.getMakerByPage(_vm.getBackCover),"page":_vm.getBackCover.number,"pageCover":_vm.getBackCover.type,"magazine-page-id":_vm.getBackCover.id,"magazine-id":_vm.getBackCover.magazine_id,"imageUrl":_vm.createThumbnailUrl(_vm.getBackCover.magazine_id, _vm.getBackCover.id)}})],1):_vm._e()],2):_c('b-row',[(_vm.getFrontCover !== undefined)?_c('b-col',{class:{ lindaColSize: _vm.getIsLindaMagazine },attrs:{"lg":"2","draggable":"false"}},[_c('Document',{attrs:{"documentId":_vm.getFrontCover.id,"invitee":_vm.getMakerByPage(_vm.getFrontCover),"page":_vm.getFrontCover.number,"pageCover":_vm.getFrontCover.type,"magazine-page-id":_vm.getFrontCover.id,"magazine-id":_vm.getFrontCover.magazine_id,"imageUrl":_vm.createThumbnailUrl(_vm.getFrontCover.magazine_id, _vm.getFrontCover.id)}})],1):_vm._e(),_vm._l((_vm.sortDocuments),function(document){return _c('b-col',{key:document.id,staticClass:"can-be-dragged",class:{ lindaColSize: _vm.getIsLindaMagazine },attrs:{"lg":"2","data-number":document.number,"draggable":!_vm.$store.getters['getShowInviteForm']},on:{"dragstart":function($event){return _vm.startDrag($event, document)},"dragend":function($event){return _vm.endDrag($event)},"drop":function($event){return _vm.onDrop($event, document)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('Document',{class:[
                {
                  documentAllowHover:
                    !_vm.$store.getters['getShowInviteForm'] &&
                    _vm.$store.getters['getDocumentsToDelete'].find(
                      (docId) => docId == document.id
                    ) === undefined,
                },
                { isNewPage: document.newPage },
                { selected_document: _vm.selectedPageStyle(document) },
              ],attrs:{"IdsToDelete":_vm.$store.getters['getDocumentsToDelete'],"documentId":document.id,"invitee":_vm.getMakerByPage(document),"magazine-page-id":document.id,"magazine-id":document.magazine_id,"newPage":document.newPage,"page":document.number,"imageUrl":_vm.createThumbnailUrl(document.magazine_id, document.id)},nativeOn:{"click":function($event){_vm.$store.getters['getShowInviteForm'] &&
                _vm.$store.getters['getAllowDocumentSelect']
                  ? _vm.selectDocumentToInviteTo(document)
                  : _vm.clickNotAllowed()}}})],1)}),(_vm.getBackCover !== undefined)?_c('b-col',{class:{ lindaColSize: _vm.getIsLindaMagazine },attrs:{"lg":"2","draggable":"false"}},[_c('Document',{class:[
                { isNewPage: _vm.getBackCover.newPage },
                { selected_document: _vm.selectedPageStyle(_vm.getBackCover) },
              ],attrs:{"invitee":_vm.getMakerByPage(_vm.getBackCover),"page":_vm.getBackCover.number,"pageCover":_vm.getBackCover.type,"magazine-page-id":_vm.getBackCover.id,"magazine-id":_vm.getBackCover.magazine_id,"imageUrl":_vm.createThumbnailUrl(_vm.getBackCover.magazine_id, _vm.getBackCover.id)}})],1):_vm._e()],2)],1):_c('div',{staticClass:"settingsDocumentView",staticStyle:{"height":"100%","max-width":"100%"}},[_c('b-row',{staticStyle:{"flex-direction":"row"}},[(_vm.getFrontCover !== undefined)?_c('b-col',{class:{ lindaColSize: _vm.getIsLindaMagazine, zeroPadding: _vm.getIsLindaMagazine },attrs:{"lg":"2","draggable":"false"}},[_c('Document',{class:[
                { isNewPage: _vm.getFrontCover.newPage },
                { selected_document: _vm.selectedPageStyle(_vm.getFrontCover) },
              ],attrs:{"invitee":_vm.getMakerByPage(_vm.getFrontCover),"magazine-page-id":_vm.getFrontCover.id,"magazine-id":_vm.getFrontCover.magazine_id,"page":_vm.getFrontCover.number,"pageCover":_vm.getFrontCover.type,"imageUrl":_vm.createThumbnailUrl(_vm.getFrontCover.magazine_id, _vm.getFrontCover.id)}})],1):_vm._e(),_c('b-col',{class:{ lindaColSize: _vm.getIsLindaMagazine },attrs:{"lg":"12"}}),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","flex-wrap":"wrap"}},_vm._l((Math.ceil(_vm.sortDocuments.length / 2)),function(i){return _c('div',{key:'d' + i,staticStyle:{"display":"flex","flex-direction":"row"}},_vm._l((_vm.sortDocuments.slice((i - 1) * 2, ((i - 1) * 2) + 2)),function(document,index){return _c('b-col',{key:document.id,staticClass:"can-be-dragged",class:{ margingRight: (index === 1), lindaColSize: _vm.getIsLindaMagazine, zeroPadding: _vm.getIsLindaMagazine },attrs:{"lg":"2","data-number":document.number,"draggable":!_vm.$store.getters['getShowInviteForm']},on:{"dragstart":function($event){return _vm.startDrag($event, document)},"dragend":function($event){return _vm.endDrag($event)},"drop":function($event){return _vm.onDrop($event, document)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('Document',{class:[
                {
                  documentAllowHover:
                    !_vm.$store.getters['getShowInviteForm'] &&
                    _vm.$store.getters['getDocumentsToDelete'].find(
                      (docId) => docId == document.id
                    ) === undefined,
                },
                { isNewPage: document.newPage },
                { selected_document: _vm.selectedPageStyle(document) },
              ],attrs:{"IdsToDelete":_vm.$store.getters['getDocumentsToDelete'],"documentId":document.id,"invitee":_vm.getMakerByPage(document),"magazine-page-id":document.id,"magazine-id":document.magazine_id,"newPage":document.newPage,"page":document.number,"imageUrl":_vm.createThumbnailUrl(document.magazine_id, document.id)},nativeOn:{"click":function($event){_vm.$store.getters['getShowInviteForm'] &&
                _vm.$store.getters['getAllowDocumentSelect']
                  ? _vm.selectDocumentToInviteTo(document)
                  : _vm.clickNotAllowed()}}})],1)}),1)}),0),_c('b-col',{attrs:{"lg":"12"}}),(_vm.getBackCover !== undefined)?_c('b-col',{class:{ lindaColSize: _vm.getIsLindaMagazine, zeroPadding: _vm.getIsLindaMagazine },attrs:{"lg":"2","draggable":"false"}},[_c('Document',{class:[
                { isNewPage: _vm.getBackCover.newPage },
                { selected_document: _vm.selectedPageStyle(_vm.getBackCover) },
              ],attrs:{"invitee":_vm.getMakerByPage(_vm.getBackCover),"magazine-page-id":_vm.getBackCover.id,"magazine-id":_vm.getBackCover.magazine_id,"page":_vm.getBackCover.number,"pageCover":_vm.getBackCover.type,"imageUrl":_vm.createThumbnailUrl(_vm.getBackCover.magazine_id, _vm.getBackCover.id)}})],1):_vm._e()],1)],1)])])]):_c('div',{staticClass:"loading-icon"},[_c('img',{attrs:{"src":"/loading.gif","alt":"loading"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }