<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8.4C11.3376 8.4 10.8 7.8624 10.8 7.2C10.8 6.5376 11.3376 6 12 6C12.6624 6 13.2 6.5376 13.2 7.2C13.2 7.8624 12.6624 8.4 12 8.4ZM13.2 16.8C13.2 17.4624 12.6624 18 12 18C11.3376 18 10.8 17.4624 10.8 16.8V10.8C10.8 10.1376 11.3376 9.6 12 9.6C12.6624 9.6 13.2 10.1376 13.2 10.8V16.8ZM12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6264 24 24 18.6276 24 12C24 5.3724 18.6264 0 12 0Z" fill="#2C5DE5"/>
    <mask id="mask0_3231_3486" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8.4C11.3376 8.4 10.8 7.8624 10.8 7.2C10.8 6.5376 11.3376 6 12 6C12.6624 6 13.2 6.5376 13.2 7.2C13.2 7.8624 12.6624 8.4 12 8.4ZM13.2 16.8C13.2 17.4624 12.6624 18 12 18C11.3376 18 10.8 17.4624 10.8 16.8V10.8C10.8 10.1376 11.3376 9.6 12 9.6C12.6624 9.6 13.2 10.1376 13.2 10.8V16.8ZM12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6264 24 24 18.6276 24 12C24 5.3724 18.6264 0 12 0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_3231_3486)">
    </g>
  </svg>

</template>

<script>
export default {
  name: "InfoTwoSvg"
}
</script>

<style scoped>

</style>
