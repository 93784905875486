import DocumentOverview from "./DocumentOverview.vue";

export default [
  {
    path: "/document/:id",
    name: "DocumentOverview",
    component: DocumentOverview,
    meta: {
      requiresAuth: true,
    },
  },
];
