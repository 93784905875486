<template>
  <div class="bg-white" v-if="!this.isLoading">
    <b-container id="auth" class="justify-content-center align-items-center">
      <div class="form-container">
        <h1>Log in</h1>

        <Error v-if="systemError" type="system" :errorMessage="systemError" />

        <b-form @submit.prevent="login" class="mx-auto">
          <Input
            @focusout.native="checkEmailInput"
            @blur.native="checkEmailInput"
            @input="errors.email = ''"
            :label-text="$t('auth.general.email')"
            placeholder-text="hello@jilster.app"
            type="text"
            v-model="email"
            :class="{ errorBorder: this.errors.email }"
          />
          <Error v-if="errors.email" icon="warning" icon-color="#C23934" :errorMessage="errors.email" />
          <Input
            @input="errors.password = ''"
            icon="view"
            icon-color="#534C45"
            :label-text="$t('auth.general.password')"
            :placeholder-text="$t('auth.login.enter.password')"
            type="password"
            v-model="password"
            :class="{ errorBorder: this.errors.password }"
          />
          <Error v-if="errors.password" icon="warning" icon-color="#C23934" :errorMessage="errors.password" />

          <div class="form-footer">
            <b-form-checkbox
              id="remember_me"
              v-model="remember_me"
              :name="$t('auth.login.remember_me')"
              value="accepted"
              unchecked-value="not_accepted"
            >
              <span>{{ $t('auth.login.remember_me') }}</span>
            </b-form-checkbox>
            <router-link to="/forgot-password">{{ $t('auth.login.password_forgotten') }}</router-link>
          </div>
          <Button
            type="submit"
            class="mx-auto w-25 btnLogin"
            :button-text="$t('auth.login.button')"
            button-type="primary"
          />
        </b-form>
        <p class="text-center mt-4 smallText" style="font-weight: 400 !important">
          {{ $t('auth.login.no_account') }}
          <router-link to="/register">{{ $t('auth.login.create.account') }} </router-link>
        </p>

        <BrowserBanner />
      </div>
    </b-container>

    <div class="w-full mt-4">
      <img :src="require('@/assets/login-create-account.png')" alt="" class="w-full" />
    </div>
  </div>

  <div v-else class="loading-icon">
    <img src="/loading.gif" alt="loading" />
  </div>
</template>

<script>
import Error from '@/components/Error'
import Input from '@/components/Input'
import Button from '@/components/Button'
import { email, required } from 'vuelidate/lib/validators'
import BrowserBanner from './BrowserBanner.vue'
import Pusher from 'pusher-js'
import Vue from 'vue'
import systemSettingsSetup from '../../mixins/systemSettingsSetup'

export default {
  name: 'Login',
  components: { BrowserBanner, Input, Button, Error },
  mixins: [systemSettingsSetup],
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      errorStatus: '',
      remember_me: 'not_accepted',
      isLoading: false,
      errors: {
        email: '',
        password: '',
      },
      systemError: '',
    }
  },
  methods: {
    async login() {
      this.$store.dispatch('setError', '')
      this.systemError = ''

      this.errors = {
        email: '',
        password: '',
      }

      let okToProceed = true

      if (!this.email) {
        this.errors.email = this.$t('auth.error.email.empty')
        okToProceed = false
      } else if (!this.emailCheck(this.email)) {
        this.errors.email = this.$t('auth.error.email.invalid')
        okToProceed = false
      }

      if (!this.password) {
        this.errors.password = this.$t('auth.error.password.empty')
        okToProceed = false
      }

      if (okToProceed) {
        let user = {
          email: this.email,
          password: this.password,
          device_name: 'computer',
          token: this.$route.query.token,
          magazine_id: this.$route.query.magazine_id,
        }

        this.isLoading = true

        await this.$store.dispatch('login', user)

        if (this.$store.getters['getError'] === 'Credentials are incorrect') {
          this.errors.email = this.$t('auth.error')
        } else if (this.$store.getters['getError']) {
          this.systemError = this.$t('auth.error.system')
        }

        if (this.remember_me === 'accepted') {
          localStorage.setItem('remember_email', this.email)
        }

        this.$emit('loggedIn')

        if (this.$store.getters['getLoggedInUser'].locale === null) {
          const locale = this.$store.getters['getVisitor'].toLowerCase()

          const settings = {
            country_id: this.$store.getters['getLoggedInUser'].country_id ?? 1,
            locale:
              locale === 'nl' || locale === 'de' || locale === 'en'
                ? this.$store.getters['getVisitor'].toLowerCase()
                : 'en',
          }

          await this.$store.dispatch('updateUserCountryId', settings)

          localStorage.setItem('language', settings.locale)
        }

        Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG === 'true'

        const getAuthToken = () => localStorage.getItem('jilster_token')

        Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
          cluster: 'eu',
          authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
          auth: {
            headers: {
              Authorization: `Bearer ${getAuthToken()}`,
            },
          },
        })

        this.systemSettingsSetup()

        this.isLoading = false
      }
    },

    emailCheck(email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },

    checkEmailInput() {
      if (this.email && !this.emailCheck(this.email)) {
        this.errors.email = this.$t('auth.error.email.invalid')
      }
    },
  },
  async created() {
    if (this.$route.query.lettherebeanadminwhomcanhelpmakers) {
      await this.$store.dispatch('setAdminLogin', this.$route.query.lettherebeanadminwhomcanhelpmakers)
      Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG === 'true'
      const getAuthToken = () => localStorage.getItem('jilster_token')
      Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: 'eu',
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        },
      })
    }

    await this.$store.dispatch('setError', '')
    if (localStorage.getItem('remember_email') !== undefined) {
      this.email = localStorage.getItem('remember_email')
      this.remember_me = 'accepted'
    }

    this.$store.dispatch('setError', '')
  },
}
</script>
