import Order from './Order.vue'

export default [
    {
        path: '/order-checkout/:id',
        name: 'OrderCheckout',
        component: Order,
        meta:{
            requiresAuth: true,
        }
    },
]

