<template>
  <div class="order-item-tile">
    <span class="itemText">{{text}}</span>
    <span class="itemPrice">€{{price}}</span>
  </div>
</template>

<script>
  export default {
    name: "OrderItemTile",
    props: ['text', 'price']
  }
</script>