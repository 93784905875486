import Invitation from "./Invitation.vue";
import InvitationOverview from "./InvitationOverview";

export default [
  {
    path: '/invitation',
    name: 'Invitation',
    component: Invitation,
  },
  {
    path: '/invitation-overview',
    name: 'invitation-overview',
    component: InvitationOverview
  }
];
