<template>
  <div class="document-settings-options document-settings" style="height: unset" v-if="!isLoading">
    <div>
      <div class="document-settings-options-intro">
        <h3 class="documentSettingsHeader">{{ $t('documentsettings.header') }}</h3>
        <h4>{{ $t('documentsettings.page.options') }}</h4>
        <div
          class="linda-info-settings"
          v-if="
            (this.$store.getters['getIsLindaMagazine'] && pushedDelete) ||
            (this.$store.getters['getIsLindaMagazine'] && newDocumentsCount !== 0)
          "
        >
          <InfoTwoSvg />
          <p>{{ $t('linda_changes_message') }}</p>
        </div>
        <div
          v-if="
            this.sortDocuments.filter((d) => (d.type === 2 || d.type === 3) && !d.newPage).length >= 4 ||
            this.$store.getters['getDocumentsToDelete'].length > 0
          "
          class="option-toggle-container"
        >
          <p
            v-if="
              (this.$store.getters['getDocuments'].length > 44 &&
                this.$store.getters['getIsLindaMagazine'] &&
                !addingToDocument) ||
              (this.$store.getters['getDocuments'].length > 44 &&
                this.$store.getters['getIsGlossyMagazine'] &&
                !addingToDocument) ||
              (!this.$store.getters['getIsLindaMagazine'] && !this.$store.getters['getIsGlossyMagazine'])
            "
            class="deleteLast4PagesText"
          >
            {{ $t('documentsettings.delete.pages') }}
          </p>
          <b-form-checkbox
            v-if="
              (this.$store.getters['getDocuments'].length > 44 &&
                this.$store.getters['getIsLindaMagazine'] &&
                !addingToDocument) ||
              (this.$store.getters['getDocuments'].length > 44 &&
                this.$store.getters['getIsGlossyMagazine'] &&
                !addingToDocument) ||
              (!this.$store.getters['getIsLindaMagazine'] && !this.$store.getters['getIsGlossyMagazine'])
            "
            :checked="pushedDelete"
            @change="setDocumentsToDelete()"
            class="jilster_toggle checkBox"
            switch
            size="lg"
          ></b-form-checkbox>
        </div>
      </div>
      <div class="slider-container">
        <p class="addPagesText">{{ $t('documentsettings.add.pages') }}</p>
        <vue-slider
          v-model="newDocumentsCount"
          :interval="4"
          :max="20"
          :processStyle="{ background: '#000' }"
          :tooltipStyle="{ background: '#000' }"
          @change="addToDocument"
          @mouseleave="hideInfo()"
          @mouseover="showInfo()"
        ></vue-slider>
      </div>
      <p v-if="newDocumentsCount !== 0 || isShowInfo" class="slider-explanation sliderInfo">
        {{ $t('documentsettings.add.pages.info') }}
      </p>
      <h4 class="view-options">{{ $t('documentsettings.view.options') }}</h4>
      <div class="option-toggle-container">
        <p>{{ $t('documentsettings.show.page.numbers') }}</p>
        <b-form-checkbox
          :checked="$store.getters['getShowPageNumbers']"
          class="jilster_toggle checkBox"
          size="lg"
          switch
          @change="setShowPageNumbers()"
        ></b-form-checkbox>
      </div>
      <div class="option-toggle-container">
        <p>{{ $t('documentsettings.makers.can.view.all') }}</p>
        <b-form-checkbox
          class="jilster_toggle checkBox"
          size="lg"
          switch
          @change="setMakersViewDocument"
          :checked="$store.getters['getEditorPreview']"
        ></b-form-checkbox>
      </div>
      <div class="option-toggle-container">
        <div class="shareLink">
          <p>{{ $t('documentsettings.share.creation') }}</p>
          <div class="linkToShare" v-if="this.isShared">
            <Copy />
            <span class="link" v-on:click="copy()">{{ getShareLink() }}</span>
          </div>
          <SuccessShared v-if="showSuccess" />
        </div>
        <b-form-checkbox
          class="jilster_toggle checkBox"
          size="lg"
          switch
          @change="setShared"
          :checked="$store.getters['getIsShared']"
        ></b-form-checkbox>
      </div>
      <div class="shareWarning" v-if="this.isShared === false">
        <div class="warningContent">
          <Warning />
          <span>The creation won’t be shared and the makers who had previous access will not see it anymore</span>
        </div>
      </div>
      <div class="buttons-container">
        <Button
          :button-text="$t('documentsettings.button.cancel')"
          buttonType="outline_button_primary"
          @click.native="closeSettings"
        />
        <Button
          :buttonType="checkDisabledButton() ? 'disabled' : 'hint'"
          :disabled="checkDisabledButton()"
          :button-text="$t('documentsettings.button.confirm')"
          class="btnConfirm"
          @click.native="submitSettings"
        />
      </div>
    </div>
  </div>
  <div class="loading-icon" v-else>
    <img src="/loading.gif" alt="loading" />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import { updateInvitation } from '../../../src/domains/document/documentsEndpoint'

import Button from '@/components/Button'
import Copy from '../viewteam/Copy'
import Warning from '../Warning'
import SuccessShared from '../SuccessShared'
import InfoTwoSvg from '../../vectors/InfoTwoSvg'

export default {
  name: 'DocumentOptions',
  components: { InfoTwoSvg, SuccessShared, Warning, VueSlider, Button, Copy },
  data() {
    return {
      disabledButton: true,
      newDocumentsCount: 0,
      documentsToDelete: [],
      pushedDelete: false,
      isShowInfo: false,
      isLoading: false,
      isShared: undefined,
      showSuccess: false,
      shareLink: process.env.VUE_APP_HEADLESS_PREVIEW,
      addingToDocument: false,
    }
  },
  methods: {
    copy() {
      const el = document.createElement('textarea')
      el.value = this.getShareLink()
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.showSuccess = true
      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    },
    getShareLink() {
      return this.shareLink + this.$route.params.id
    },
    async submitSettings() {
      await this.$store.dispatch('setUpdateCreationType', 'default')
      const currentDocuments = this.$store.getters['getDocuments']

      let sortedDocuments = currentDocuments.sort((a, b) => {
        return a.number - b.number
      })

      const updateCreation = {
        frontCoverId: currentDocuments.find((d) => d.type === 1).id,
        backCoverId: currentDocuments.find((d) => d.type === 4).id,
        pages: sortedDocuments.filter((d) => d.id !== null || undefined).map((d) => d.id),
        pagesToAdd: 0,
      }

      const documentsToDelete = this.$store.getters['getDocumentsToDelete']

      await this.$store.dispatch('setUpdateCreationType', '')

      if (documentsToDelete.length > 0) {
        updateCreation.pages = currentDocuments.filter((d) => !documentsToDelete.includes(d.id)).map((d) => d.id)
        await this.$store.dispatch('setUpdateCreationType', 'deleted')
      } else {
        updateCreation.pagesToAdd = currentDocuments.filter((d) => d.newPage).length

        if (updateCreation.pagesToAdd > 0) {
          await this.$store.dispatch('setUpdateCreationType', 'added')
        }
      }

      updateCreation.editorPreview = this.$store.getters['getEditorPreview']
      updateCreation.showPageNumbers = this.$store.getters['getShowPageNumbers']
      updateCreation.isShared = this.$store.getters['getIsShared']

      this.pushedDelete = false

      this.isLoading = true

      await this.$store.dispatch('updateCreation', {
        id: this.$route.params.id,
        creations: updateCreation,
      })

      if (documentsToDelete.length) {
        const magazineId = this.$route.params.id || this.$store.getters['getCreationId']

        await this.$store.dispatch('setInvitedMembers', magazineId)

        const invitees = this.$store.getters['getInvitees'] || []

        // If any invitee has a deleted page, remove it from the invitee.pages array
        invitees.forEach((invitee) => {
          if (invitee.role !== 1) {
            const pagesToRemove = invitee.pages.filter((page) => documentsToDelete.includes(page.id))

            if (pagesToRemove.length > 0) {
              const updatedPages = invitee.pages.filter((page) => !documentsToDelete.includes(page.id))

              if (updatedPages.length) {
                const payload = {
                  userKey: invitee.email,
                  role: invitee.role,
                  magazine_pages: updatedPages.map((page) => page.id),
                }

                updateInvitation(this.$route.params.id, payload)
              } else {
                const data = { member: invitee, creationId: magazineId }

                this.$store.dispatch('destroyInvitation', data)
              }
            }
          }
        })
      }

      await this.$store.dispatch('setCreationEditedSuccessfully', true)

      this.resetSettings()

      this.closeSettings()

      // await this.$store.dispatch('clearDocumentsFromApi')
      await this.$store.dispatch('setDocuments', this.$route.params.id)

      this.isLoading = false
    },

    resetSettings() {
      this.documentsToDelete = []
      this.newDocumentsCount = 0
      this.$store.dispatch('setDocumentsToDelete', this.documentsToDelete)
      this.$store.dispatch('setSettingsChanged', false)
    },
    addToDocument() {
      this.$store.dispatch('setDocumentsToDelete', [])
      this.pushedDelete = false
      this.documentsToDelete = []
      this.addingToDocument = true

      if (this.newDocumentsCount === 0) {
        this.$store.dispatch('removeNewPages')
      } else {
        this.$store.dispatch('removeNewPages')
        let items = []
        this.$store.getters['getDocuments'].filter((document) => {
          if (
            parseInt(document.number) &&
            document.number !== 1 &&
            document.number !== this.$store.getters['getDocuments'].length
          ) {
            items.push(document)
          }
        })
        let lastPosition = Math.max.apply(
          Math,
          items.map(function (o) {
            return o.number
          })
        )

        this.$store.dispatch('addDocumentsToCreation', {
          count: this.newDocumentsCount,
          lastPosition: lastPosition,
        })

        let objDiv = document.getElementsByClassName('document-settings-item-container')[0]
        objDiv.scrollTop = objDiv.scrollHeight
      }
    },
    setDocumentsToDelete() {
      this.$store.dispatch('removeNewPages')
      this.newDocumentsCount = 0
      if (!this.pushedDelete) {
        let list = this.sortDocuments
        let documentsToDelete = list.slice(Math.max(list.length - 4, 0))

        if (documentsToDelete.length !== 4) {
          return
        }

        documentsToDelete.forEach((document) => this.documentsToDelete.push(document.id))
        this.$store.dispatch('setDocumentsToDelete', this.documentsToDelete)

        let objDiv = document.getElementsByClassName('document-settings-item-container')[0]
        objDiv.scrollTop = objDiv.scrollHeight
      } else {
        this.resetSettings()
      }
      this.pushedDelete = !this.pushedDelete
    },
    closeSettings() {
      this.resetSettings()
      this.$store.dispatch('removeNewPages')
      this.$store.dispatch('setShowModal', false)
      this.$store.dispatch('updateDocumentPositions')
    },
    checkDisabledButton() {
      return (
        this.$store.getters['getDocumentsToDelete'].length === 0 &&
        this.newDocumentsCount === 0 &&
        !this.$store.getters['getSettingsChanged']
      )
    },
    setMakersViewDocument() {
      this.$store.dispatch('setSettingsChanged', true)
      this.$store.dispatch('setEditorPreview', !this.$store.getters['getEditorPreview'])
    },
    setShared() {
      this.$store.dispatch('setSettingsChanged', true)
      this.$store.dispatch('setIsShared', !this.$store.getters['getIsShared'])
      this.isShared = this.$store.getters['getIsShared']
      this.showSuccess = false
    },
    setShowPageNumbers() {
      this.$store.dispatch('setSettingsChanged', true)
      this.$store.dispatch('setShowPageNumbers', !this.$store.getters['getShowPageNumbers'])
    },
    showInfo() {
      this.isShowInfo = true
    },
    hideInfo() {
      this.isShowInfo = false
    },
    removeBorders() {
      let documents = document.getElementsByClassName('can-be-dragged')
      for (let i = 0; i < documents.length; i++) {
        documents[i].classList.remove('border-drag-left')
        documents[i].classList.remove('border-drag-right')
        documents[i].classList.remove('book-border-right')
        documents[i].classList.remove('book-border-left')
        documents[i].classList.remove('border-drag-right-book')
        documents[i].classList.remove('border-drag-left-book')
      }
    },
    toggleBorder(e) {
      if (e.target.classList.contains('dragImg')) {
        let elem = e.target.closest('.can-be-dragged')
        if (elem === null) {
          return
        }

        const number = elem.getAttribute('data-number')
        const draggingNumber = this.$store.getters['getDraggingNumber']
        if (number !== undefined && number !== null && parseInt(number) !== parseInt(draggingNumber)) {
          this.removeBorders()
          if (this.$store.getters['getViewedAsCreationInSettings']) {
            if (number > draggingNumber) {
              elem.classList.add('border-drag-right')
            } else {
              elem.classList.add('border-drag-left')
            }
          } else {
            if (number > draggingNumber) {
              elem.classList.add('book-border-right')
              elem.classList.add('border-drag-right-book')
            } else {
              elem.classList.add('book-border-left')
              elem.classList.add('border-drag-left-book')
            }
          }
        }
      }
    },
  },
  mounted() {
    let vm = this
    document.addEventListener('dragenter', this.toggleBorder, false)

    document.addEventListener(
      'dragleave',
      function (e) {
        if (!e.target.classList.contains('dragImg')) {
          return
        }
        let elem = e.target.closest('.can-be-dragged')
        if (elem !== null) {
          setTimeout(() => {
            elem.classList.remove('border-drag-left')
            elem.classList.remove('border-drag-right')
            elem.classList.remove('book-border-right')
            elem.classList.remove('book-border-left')
            elem.classList.remove('border-drag-right-book')
            elem.classList.remove('border-drag-left-book')
          }, 500)
        }
      },
      false
    )
    document.addEventListener(
      'drop',
      function (e) {
        vm.removeBorders()
        e.preventDefault()
      },
      false
    )
    document.addEventListener(
      'dragover',
      function (event) {
        event.preventDefault()
      },
      false
    )
  },
  computed: {
    getBackCover() {
      let documents = this.$store.getters['getDocuments']
      return documents.find((document) => {
        return document.type === 4
      })
    },
    getFrontCover() {
      let documents = this.$store.getters['getDocuments']
      return documents.find((document) => {
        return document.type === 1
      })
    },
    sortDocuments() {
      let documents = this.$store.getters['getDocuments']
      let list = []
      documents.filter((document) => {
        if (document.type === 2 || document.type === 3) {
          list.push(document)
        }
      })
      return list.sort((a, b) => {
        return a.number - b.number
      })
    },
  },
}
</script>
