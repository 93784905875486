<template>
  <img src="@/assets/product_book.png" />
</template>

<script>

export default {
  name: 'Book'
}

</script>
