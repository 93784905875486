<template>
  <div class="orderPayment">
    <h2>{{ $t('checkout.payment') }}</h2>
    <div
      v-for="paymentMethod in $store.getters['getPaymentMethods']"
      :key="paymentMethod.id"
      class="d-flex orderPaymentItem"
    >
      <div class="d-flex">
        <b-form-radio
          v-if="$store.getters['getPayment'] !== '0'"
          @change="selectPayment"
          class="payment-radio"
          v-model="selected"
          name="payment"
          :value="paymentMethod.id"
        />
        <Ideal v-if="paymentMethod.name === 'iDEAL'" />
        <Mastercard v-if="paymentMethod.name === 'MasterCard'" />
        <VisaCard v-if="paymentMethod.name === 'VisaCard'" />
        <Bancontant v-if="paymentMethod.name === 'Bancontant / Mister Cash'" />
        <american-express v-if="paymentMethod.name === 'American Express'" />
        <paypal v-if="paymentMethod.name === 'PayPal'" />
        <p class="payment-name">{{ paymentMethod.name }}</p>
      </div>
      <p class="price" v-if="paymentMethod.paymentCostsPercentage !== 0">{{ paymentMethod.paymentCostsPercentage }}%</p>
      <p class="price" v-else>{{ $t('checkout.free') }}</p>
    </div>
    <div class="d-flex return-order">
      <div
        class="d-flex return-order-text"
        @click="$store.dispatch('setCurrentPage', 'contact')"
        v-if="
          $store.getters['getIsLinda'] ||
          $store.getters['getCanOrderPFDOnly'] ||
          $store.getters['getCanOrderExtrasOnly'] ||
          Number($store.getters['getVolume']) === 0
        "
      >
        <i class="icon-chevron-left"></i>
        <span>{{ $t('checkout.return.to.contact') }}</span>
      </div>
      <div class="d-flex return-order-text" @click="$store.dispatch('setCurrentPage', 'delivery')" v-else>
        <i class="icon-chevron-left"></i>
        <span>{{ $t('checkout.return.to.shipping') }}</span>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column">
        <div class="d-flex">
          <Button
            @click.native="selected > 0 ? $store.dispatch('setCurrentPage', 'review') : (errorStatus = true)"
            :button-text="$t('checkout.review.order')"
            button-type="primary"
            class="btnNoMarginLeft"
          />
          <OrderError v-if="errorStatus" :error-text="$t('checkout.choose.option.to.continue')" />
        </div>
        <p class="wontCharged">{{ $t('checkout.wont.charged.yet') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Ideal from '@/vectors/Ideal'
import Mastercard from '@/vectors/Mastercard'
import VisaCard from '@/vectors/VisaCard'
import Bancontant from '@/vectors/Bancontant'
import AmericanExpress from '@/vectors/AmericanExpress'
import Paypal from '@/vectors/Paypal'
import Button from '@/components/Button'
import OrderError from './OrderError'

export default {
  name: 'OrderPayment',
  components: { Paypal, AmericanExpress, Bancontant, VisaCard, Mastercard, Ideal, Button, OrderError },
  data() {
    return {
      errorStatus: false,
      selected: this.$store.getters['getPayment'],
    }
  },
  methods: {
    async selectPayment() {
      if (this.$store.getters['getCanOrderPFDOnly']) {
        await this.$store.dispatch('setPayment', this.selected)
        const order = {
          id: this.$route.params.id,
          orderExtraIds: '1',
          paymentId: this.selected,
        }
        await this.$store.dispatch('getCheckoutPDFOnly', order)
        return
      }

      let order = {
        id: this.$route.params.id,
        extraOrderIds: this.$store.getters['getOrderExtraIds'],
        printId: Number(this.$store.getters['getVolume']) === 0 ? 0 : this.$store.getters['getPrint'],
        volume: this.$store.getters['getVolume'],
        paymentId: this.selected,
        countryId: this.$store.getters['getPaymentUser'].countryId || 1,
        deliveryId: this.$store.getters['getShipment'],
        proof: this.$store.getters['getProof'],
      }

      await this.$store.dispatch('setPayment', this.selected)

      await this.$store.dispatch('getCheckout', order)
    },
  },
  created() {
    if (this.$store.getters['getPayment'] === '0') {
      this.selected = this.$store.getters['getPaymentMethods'][0].id
      this.selectPayment()
    }

    this.$store.dispatch('setCurrentPage', 'payment')
  },
  computed: {
    checkIfPosterSelected() {
      return this.$store.getters['getOrderExtraIds'].split(',').filter((o) => o > 2).length === 0
    },
  },
}
</script>
