<template>
  <div id="selectionCreationNewAccount">
    <b-container>
      <h1>What do you want to make?</h1>
      <p>Choose an option to start with</p>
      <b-row>
        <b-col
          @click="selectCreationItem(item)"
          v-for="item in creationSelectItens"
          :key="item.id"
          class="mx-auto"
          lg="3"
        >
          <div
            v-bind:class="{
              selectedCreationItem: selectedCreationItem === item,
            }"
            class="creation-menu-item"
          >
            <svg
              v-if="item === 'Poster'"
              width="106"
              height="106"
              viewBox="0 0 106 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="106" height="106" rx="4" fill="#F2F0ED" />
              <rect x="31" y="20" width="44" height="66" fill="#CDC7C2" />
            </svg>
            <svg
              v-else-if="item === 'Magazine'"
              width="106"
              height="106"
              viewBox="0 0 106 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="106" height="106" rx="4" fill="#F2F0ED" />
              <rect x="31" y="20" width="44" height="66" fill="#CDC7C2" />
            </svg>
            <svg
              v-else-if="item === 'Book'"
              width="106"
              height="106"
              viewBox="0 0 106 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="106" height="106" rx="4" fill="#F2F0ED" />
              <rect x="31" y="20" width="44" height="66" fill="#CDC7C2" />
            </svg>
            <h5>{{ item }}</h5>
          </div>
        </b-col>
      </b-row>
      <p class="tip">
        We will add 4 pages to your document to start with. You can always add
        more pages if you need to.
      </p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SelectionCreaterionNewAccount",
  data() {
    return {
      creationSelectItens: ["Poster", "Magazine", "Book"],
      selectedCreationItem: "",
    };
  },
  methods: {
    selectCreationItem(item) {
      this.selectedCreationItem = item;
    },
  },
};
</script>

<style scoped></style>
