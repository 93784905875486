<template>
  <b-container id="auth" class="justify-content-center align-items-center" v-if="!isLoading">
    <div class="form-container">
      <h1 style="color: #303030">{{ $t('auth.forgotpassword.heading') }}</h1>
      <p v-if="!completed">
        {{ $t('auth.forgotpassword.text') }}
      </p>

      <Error v-if="systemError" type="system" :errorMessage="systemError" />

      <label class="incorrectCredentials succes" v-else-if="success">
        <span class="mr-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#51CF66" />
            <path
              d="M9.69531 15.125L7.23438 12.7422C7.02604 12.5339 6.76562 12.4297 6.45312 12.4297C6.14062 12.4297 5.88021 12.5339 5.67188 12.7422C5.46354 12.9505 5.35938 13.2109 5.35938 13.5234C5.35938 13.8099 5.46354 14.0573 5.67188 14.2656L8.99219 17.5078C9.22656 17.7422 9.5 17.8594 9.8125 17.8594C10.151 17.8333 10.4245 17.6901 10.6328 17.4297L18.4453 8.75781C18.6276 8.52344 18.6927 8.26302 18.6406 7.97656C18.6146 7.66406 18.4844 7.41667 18.25 7.23438C17.6771 6.79167 17.1562 6.85677 16.6875 7.42969L9.69531 15.125Z"
              fill="white"
            />
          </svg>
        </span>
        <span>
          {{ $t('auth.forgotpassword.succes') }}
        </span>
        <span class="resend-email" @click="forgotPassword">{{ $t('auth.forgotpassword.resend') }}</span>
      </label>

      <b-form v-if="!completed && !success" @submit.prevent="forgotPassword">
        <Input
          @focusout.native="checkEmailInput"
          @blur.native="checkEmailInput"
          @input="errors.email = ''"
          :label-text="$t('auth.general.email')"
          placeholder-text="hello@jilster.app"
          type="text"
          v-model="email"
          :class="{ errorBorder: this.errors.email }"
        />
        <Error v-if="errors.email" icon="warning" icon-color="#C23934" :errorMessage="errors.email" />

        <Button
          style="height: auto !important"
          type="submit"
          class="mx-auto w-25"
          :button-text="$t('auth.forgotpassword.button')"
          button-type="primary"
          v-if="!completed"
        />
      </b-form>
      <router-link class="return-login" to="/login" style="font-size: 14px !important">
        <i class="icon-chevron-left"></i>
        {{ $t('auth.forgotpassword.return') }}
      </router-link>
    </div>
  </b-container>
  <div v-else class="loading-icon">
    <img src="/loading.gif" alt="loading" />
  </div>
</template>

<script>
import Error from '@/components/Error'
import Input from '@/components/Input'
import Button from '@/components/Button'
export default {
  name: 'ForgotPassword',
  components: { Input, Button, Error },
  data() {
    return {
      email: '',
      errorStatus: '',
      isLoading: false,
      success: false,
      completed: false,
      errors: {
        email: '',
      },
      systemError: '',
    }
  },
  methods: {
    async forgotPassword() {
      this.$store.dispatch('setError', '')
      this.systemError = ''

      this.errors = {
        email: '',
      }

      let okToProceed = true

      if (!this.email) {
        this.errors.email = this.$t('auth.error.email.empty')
        okToProceed = false
      } else if (!this.emailCheck(this.email)) {
        this.errors.email = this.$t('auth.error.email.invalid')
        okToProceed = false
      }

      if (okToProceed) {
        const data = {
          email: this.email,
          locale: localStorage.getItem('language'),
        }

        this.isLoading = true
        await this.$store.dispatch('resetPassword', data)
        this.success = true
        this.completed = true
        this.isLoading = false
      }
    },
    async setLocale() {
      const locale = this.$route.query.locale

      if (locale) {
        await this.$store.dispatch('setLanguage', locale)
        localStorage.setItem('language', locale.toString())
        window.location.replace('/forgot-password')
      }
    },
    emailCheck(email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },

    checkEmailInput() {
      if (this.email && !this.emailCheck(this.email)) {
        this.errors.email = this.$t('auth.error.email.invalid')
      }
    },
  },
  async created() {
    if (this.$route.query.locale !== '') {
      await this.setLocale()
    }
  },
}
</script>

<style scoped>
.resend-email {
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
}
</style>
