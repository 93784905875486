<template>
  <div id="sidebarHeader">
    <div class="px-4 pb-4 pt-2">
      <b-row class="sidebarHeaderSmallText">
        <b-col>
          <small>Select one page to start with or invite the team</small>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pr-0 svgCol">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.599 3.23964L4.24896 12.2396C4.08955 12.3931 4 12.6012 4 12.8182V16.0909C4 16.5428 4.38056 16.9091 4.85 16.9091H8.25C8.47543 16.9091 8.69164 16.8229 8.85104 16.6695L18.201 7.66945C18.533 7.34993 18.533 6.83189 18.201 6.51237L14.801 3.23964C14.4691 2.92012 13.9309 2.92012 13.599 3.23964ZM14.2 4.97591L16.3973 7.09091L7.8964 15.2727H5.7V13.1569L14.2 4.97591ZM21 20.1818C21 19.7299 20.6194 19.3636 20.15 19.3636H4.85L4.75087 19.3691C4.32813 19.4164 4 19.7622 4 20.1818C4 20.6337 4.38056 21 4.85 21H20.15L20.2491 20.9945C20.6719 20.9472 21 20.6014 21 20.1818Z"
              fill="black"
            />
          </svg>
        </b-col>
        <b-col>
          <p class="mb-0 font-weight-bold">Untitled 1</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBarRightRoundedHeader",
};
</script>
