<template>
  <div id="shareCreation">
    <div v-if="!this.$store.getters['getPages'].length > 0" class="loading">
      <div class="loading_container">
        <h3>Charging document</h3>
        <b-spinner variant="success" label="Small Spinner"></b-spinner>
        <h4>20%</h4>
      </div>
    </div>
    <b-container v-else>
      <div class="share-creation-container">
        <h5>Choose pages to edit. Click again to unselect</h5>
        <div
          class="selected_page_name"
          v-if="this.$store.getters['getSelectedPage']"
        >
          <span
            v-for="page in this.$store.getters['getSelectedPage']"
            :key="page.id"
            >{{ page.pageNumber }},</span
          >
        </div>
        <b-row>
          <b-col
            v-for="page in this.$store.getters['getPages']"
            :key="page.id"
            lg="2"
          >
            <div
              @click="setSelectedPage(page)"
              class="page"
              v-bind:class="{ selected_page: selectedPageStyle(page) }"
            >
              <img :src="page.page" alt="" />
              <p>{{ page.pageNumber }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ShareCreation",
  methods: {
    setSelectedPage(page) {
      // this.$root.$emit('bv::toggle::collapse','sidebar-right');

      if (
        this.$store.getters["getSelectedPage"]
          .map((a) => a.pageNumber)
          .includes(page.pageNumber)
      ) {
        this.$store.dispatch("removeSelectedPage", page);
      } else {
        this.$store.dispatch("setSelectedPage", page);
      }
    },
    selectedPageStyle(page) {
      return this.$store.getters["getSelectedDocuments"]
        .map((a) => a.pageNumber)
        .includes(page.pageNumber);
    },
  },
};
</script>
