import {acceptInvitation, getInvitation} from "./invitationEndpoint";

const state = {
    invitation: [],
    invitations: [],
    invitationError: false
};

const getters = {
    getInvitation: (state) => {
        return state.invitation;
    },
    getInvitations: (state) => {
        return state.invitations;
    },
    getInvitationError: (state) => {
        return state.invitationError;
    }
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    async acceptInvitation({ commit }, data) {
        try {
            await acceptInvitation(data.id, data.token);
        } catch (e) {
            console.error(e);
        }
    },
    async getInvitation({ commit }, token) {
        try {
            const response = await getInvitation(token);
            commit("SET_INVITATION", response.data.data)

        } catch (e) {
            commit("SET_INVITATION_ERROR", true)
            throw 'error';
        }
    }
};

const mutations = {
    SET_INVITATION(state, invitation) {
        state.invitation = invitation;
    },
    SET_INVITATION_ERROR(state, bool) {
        state.invitationError = bool;
    },
    SET_INVITATIONS(state, invitations){
        state.invitations = invitations;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
