<template>
  <div
    class="bg-black bg-opacity-50 fixed top-0 left-0 w-screen h-1 min-h-screen z-50 flex justify-center pt-8 px-16 overflow-y-auto scrollbar"
    :class="isShort ? 'pb-8 items-center' : 'items-end'"
    @click.self="$emit('on-close')"
  >
    <div
      class="bg-white relative rounded-t-2xl w-full"
      :class="isShort ? 'rounded-b-2xl' : 'h-full'"
      style="max-width: 1370px; padding: 36px 80px 36px 60px"
    >
      <!-- Close button -->

      <button
        @click="$emit('on-close')"
        class="absolute top-0 right-0"
        style="margin-top: 0.75rem; margin-right: 1.5rem"
      >
        <span class="jilster-font" style="font-size: 35px; color: #999087">k</span>
      </button>

      <div class="z-10 relative scrollbar overflow-y-auto h-full" style="padding-right: 8px !important">
        <h2 class="text-wg-light-accent font-bold text-2xl mb-2">
          {{ title }}
        </h2>

        <p class="text-wg-80 text-lg">
          {{ description }}
        </p>

        <div class="mt-8 pb-4">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalContainer',

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isShort: {
      type: Boolean,
      required: false,
    },
  },
}
</script>

<style scoped>
.scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
