<template>
  <div id="sidebar" :class="getTopbarActive ? 'pt-24' : 'pt-14'" style="z-index: 9; background-color: #f2f0ed">
    <div class="sidebar-container flex flex-grow flex-col" v-on:click="closeSidebar()">
      <!-- <router-link :style="{ paddingTop: topbarActive }" to="/new-design">
        <Button :button-text="$t('make.a.design')" button-type="primary" />
      </router-link> -->

      <ul class="list-unstyled nav-container-list w-full">
        <li v-bind:class="{ active: this.$route.name === 'CreationsOverview' }">
          <router-link to="/">
            <span class="flex justify-start items-center space-x-2">
              <span class="jilster-font text-2xl">v</span>
              <span>{{ $t('creations.header.title') }}</span>
            </span>
          </router-link>
        </li>

        <li v-bind:class="{ active: this.$route.name === 'Homepage' }">
          <router-link to="/all-templates">
            <span class="flex justify-start items-center space-x-2">
              <span class="jilster-font text-2xl">H</span>
              <span>{{ $t('homepage.header.title') }}</span>
            </span>
          </router-link>
        </li>

        <li style="position: relative" v-bind:class="{ active: this.$route.name === 'invitation-overview' }">
          <div class="has-invite" v-if="$store.getters['getInvitations'].length > 0"></div>
          <router-link to="/invitation-overview">
            <span class="flex justify-start items-center space-x-2">
              <span class="flex justify-center items-center" style="width: 24px; height: 32px">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.82812 13.9844C1.29688 13.9844 0.875 13.8125 0.5625 13.4688C0.1875 13.1562 0 12.7344 0 12.2031V1.79688C0 1.26563 0.1875 0.84375 0.5625 0.53125C0.875 0.1875 1.29688 0.015625 1.82812 0.015625H16.1719C16.7031 0.015625 17.125 0.1875 17.4375 0.53125C17.8125 0.84375 18 1.26563 18 1.79688V12.2031C18 12.7344 17.8125 13.1562 17.4375 13.4688C17.125 13.8125 16.7031 13.9844 16.1719 13.9844H1.82812ZM1.73438 12.2031H1.82812H16.1719H16.2656V5.45312L15.9844 5.73438C14.5156 6.82812 13.125 7.90625 11.8125 8.96875C11.75 9 11.6094 9.09375 11.3906 9.25C11.2031 9.40625 11.0625 9.51562 10.9688 9.57812C10.875 9.67188 10.5781 9.84375 10.0781 10.0938C9.67188 10.25 9.3125 10.3281 9 10.3281C8.6875 10.3281 8.32812 10.25 7.92188 10.0938C7.42188 9.84375 7.125 9.67188 7.03125 9.57812C6.9375 9.51562 6.78125 9.40625 6.5625 9.25C6.375 9.09375 6.25 9 6.1875 8.96875C4.875 7.90625 3.48438 6.82812 2.01562 5.73438L1.73438 5.45312V12.2031ZM8.625 8.5C8.71875 8.53125 8.84375 8.54688 9 8.54688C9.15625 8.54688 9.28125 8.53125 9.375 8.5C9.5625 8.40625 9.70312 8.3125 9.79688 8.21875C9.98438 8.125 10.125 8.03125 10.2188 7.9375C10.2812 7.90625 10.4375 7.79688 10.6875 7.60938C10.875 7.42188 10.9844 7.32812 11.0156 7.32812C11.5469 6.92188 12.2656 6.35938 13.1719 5.64062C14.1094 4.92188 14.7031 4.46875 14.9531 4.28125C15.2031 4.09375 15.5 3.75 15.8438 3.25C16.125 2.8125 16.2656 2.45312 16.2656 2.17188V1.9375V1.84375L16.2188 1.79688H16.125H1.82812H1.73438C1.73438 2.76563 2.17188 3.59375 3.04688 4.28125C3.29688 4.46875 3.875 4.92188 4.78125 5.64062C5.71875 6.35938 6.45312 6.92188 6.98438 7.32812C7.01562 7.32812 7.125 7.42188 7.3125 7.60938C7.5625 7.79688 7.71875 7.90625 7.78125 7.9375C7.875 8.03125 8.01562 8.125 8.20312 8.21875C8.29688 8.3125 8.4375 8.40625 8.625 8.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span>{{ $t('menu_invitation') }}</span>
            </span>
          </router-link>
        </li>

        <li v-bind:class="{ active: this.$route.name === 'Orders' }">
          <router-link to="/orders">
            <span class="flex justify-start items-center space-x-2">
              <span class="jilster-font text-2xl">r</span>
              <span>{{ $t('orders.header.title') }}</span>
            </span>
          </router-link>
        </li>

        <li v-bind:class="{ active: this.$route.name === 'Settings' }">
          <router-link to="/settings">
            <span class="flex justify-start items-center space-x-2">
              <span class="jilster-font text-2xl">[</span>
              <span>{{ $t('settings.header.title') }}</span>
            </span>
          </router-link>
        </li>
      </ul>

      <!-- Award Banner -->
      <!-- {{ $t('magazine.award.left.sidebar.banner.button') }} -->
      <div class="mt-auto">
        <div
          v-if="showAwardUI"
          class="banner"
          :style="`background-image: url(${require('@/assets/magazine_award_banner_2024.svg')}); margin-bottom: 16px`"
        >
          <button class="banner_button" @click="$store.commit('SET_SHOW_AWARD_GUIDE_MODAL', true)">
            {{ $t('magazine.award.left.sidebar.banner.button') }}
          </button>
        </div>

        <!-- Banner -->
        <!--
        <div class="w-full flex justify-center items-center" style="margin-bottom: 16px; min-height: 220px">
          <button class="relative w-full" style="height: 220px; width: 151px; margin: 0px">
            <img
              :src="bannerImage"
              alt=""
              style="width: 100%; height: 100%; object-fit: cover; object-position: center; margin: 0"
            />

            <div class="absolute top-0 left-0 w-full h-full text-white text-left pt-2.5 px-3.5">
              <h3 class="text-lg font-bold">{{ $t('sidebar.template.banner.title') }}</h3>

              <p class="text-xs mt-1.5">{{ $t('sidebar.template.banner.description') }}</p>

              <div class="flex justify-center w-full">
                <button
                  class="font-semibold text-sm text-white bg-wg-light-accent text-center inline-block w-max rounded py-2.5 px-2.5 hover:bg-wg-light-accent-hover"
                  @click="$emit('show-take-a-look-modal')"
                >
                  {{ $t('sidebar.template.banner.button') }}
                </button>
              </div>
            </div>
          </button>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import { getMyInvitations } from '../domains/invitation/invitationEndpoint'

import bannerImage from '@/assets/sidebar-banner.png'

export default {
  name: 'Sidebar',
  components: {},
  data() {
    return {
      hasInvitations: false,
      bannerImage,
    }
  },
  computed: {
    getTopbarActive() {
      return this.$store.getters['getTopbarActive']
    },
    getLoggedInUser() {
      return this.$store.getters['getLoggedInUser']
    },
    showAwardUI() {
      return this.$store.state.magazineAward.showAwardUI
    },
  },
  methods: {
    closeSidebar() {
      if (this.$store.getters['getSidebarOpen']) {
        this.$store.dispatch('setSidebar', false)
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        this.$store.dispatch('setSidebarType', 'default')
        this.$store.dispatch('clearSelectedCreations')
        this.$store.dispatch('setSelectedSharedCreationRole', 'none')
      }
    },
  },
  async created() {
    const response = await getMyInvitations()
    this.$store.commit('SET_INVITATIONS', response.data.data)
  },
}
</script>

<style scoped>
.container-extra-class {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.menu-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 59px;
}

.banner {
  width: 149px;
  height: 251.7px;
  overflow: hidden;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 6px;
}

.badge-position {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 !important;
  height: auto !important;
  width: auto !important;
}

.banner_button {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #111;
  font-weight: 600;
  text-align: center;
  margin: 0 0 16px 0 !important;
  height: auto !important;
  padding: 10px 0 !important;
  white-space: nowrap;
}
</style>
