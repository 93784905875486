<template>
  <div class="billing-address-popup">
    <h2>Billing address</h2>
    <div>
      <div class="gender-select">
        <b-form-group :label="$t('salutation')" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender-delivery-radio" value="male">Male</b-form-radio>
          <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender-delivery-radio" value="female">Female</b-form-radio>
          <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender-delivery-radio" value="other">Prefer not to say</b-form-radio>
        </b-form-group>
        <Error v-if="!this.$v.gender.required && this.errorStatus" icon="warning" icon-color="#C23934"
               :errorMessage="$t('no_gender_error')"/>
      </div>
      <div class="d-flex">
        <div>
          <Input style="width: 164px; margin-right: 16px" :label-text="$t('checkout.firstname')" :placeholder-text="$t('checkout.firstname')" v-model="firstName" :class="{ errorBorder: !this.$v.firstName.required && this.errorStatus}"/>
          <Error v-if="!this.$v.firstName.required && this.errorStatus" icon="warning" icon-color="#C23934" :errorMessage="$t('checkout.error.no.firstname')"/>
        </div>
        <div>
          <Input style="width: 222px" :label-text="$t('checkout.lastname')" :placeholder-text="$t('checkout.lastname')" v-model="lastName" :class="{ errorBorder: !this.$v.lastName.required && this.errorStatus}"/>
          <Error v-if="!this.$v.lastName.required && this.errorStatus" icon="warning" icon-color="#C23934" :errorMessage="$t('checkout.error.no.lastname')"/>
        </div>
      </div>
      <Input style="width: 402px" :label-text="$t('webshop.street')" :placeholder-text="$t('webshop.street')" v-model="street" :class="{ errorBorder: !this.$v.street.required && this.errorStatus}"/>
      <Error v-if="!this.$v.street.required && this.errorStatus" icon="warning" icon-color="#C23934" :errorMessage="$t('checkout.error.no.street')"/>
      <div class="d-flex">
        <div>
          <Input style="width: 138px; margin-right: 16px" :label-text="$t('webshop.house_number')" :placeholder-text="$t('webshop.house_number')" v-model="houseNumber" :class="{ errorBorder: !this.$v.houseNumber.required && this.errorStatus}"/>
          <Error v-if="!this.$v.houseNumber.required && this.errorStatus" icon="warning" icon-color="#C23934" :errorMessage="$t('checkout.error.no.housenumber')"/>
        </div>
        <div>
          <Input style="width: 138px; margin-right: 16px" :label-text="$t('webshop.house_number_suffix')" :placeholder-text="$t('webshop.house_number_suffix')" v-model="suffix"/>
        </div>
      </div>
      <div class="d-flex">
        <div>
          <Input style="width: 138px; margin-right: 16px" :label-text="$t('checkout.postalcode')" :placeholder-text="$t('checkout.postalcode')" v-model="postalCode" :class="{ errorBorder: !this.$v.postalCode.required && this.errorStatus}"/>
          <Error v-if="!this.$v.postalCode.required && this.errorStatus" icon="warning" icon-color="#C23934" :errorMessage="$t('checkout.error.no.postalcode')"/>
        </div>
          <Input style="width: 248px" :label-text="$t('checkout.city')" :placeholder-text="$t('checkout.city')" v-model="city" :class="{ errorBorder: !this.$v.city.required && this.errorStatus}"/>
        <Error v-if="!this.$v.city.required && this.errorStatus" icon="warning" icon-color="#C23934" :errorMessage="$t('checkout.error.no.city')"/>
        <div>
      </div>
      </div>
      <div>
        <Select id="countryBilling" @valueChanged="valueChanged" :label-text="$t('checkout.country')" :initialValue="this.localCountryId" :options="options" icon-color="#534C45" icon="chevron-down"/>
<!--        <Error v-if="!this.$v.countryId.required && this.errorStatus" icon="warning" icon-color="#C23934" :errorMessage="$t('checkout.error.no.country')"/>-->
      </div>
    </div>
      <div class="d-flex justify-content-end">
        <p @click="cancel()">{{$t("checkout.button.cancel")}}</p>
        <Button @click.native="saveNewAddress()" button-type="primary" :button-text="$t('checkout.button.confirm')"/>
      </div>
    </div>
</template>

<script>
import Input from "@/components/Input";
import Select from "@/components/Select";
import Button from "@/components/Button";
import Error from "@/components/Error";

import {required} from "vuelidate/lib/validators";

export default {
  name: "BillingAddressPopup",
  components: {Button, Input, Select, Error},
  validations: {
    firstName: {
      required
    },
    lastName:{
      required
    },
    street:{
      required
    },
    houseNumber:{
      required
    },
    postalCode:{
      required
    },
    city:{
      required
    },
    gender: {
      required
    }
  },
  data(){
    return{
      firstName: (this.$store.getters['getNewBillingAddress'].firstName) ? this.$store.getters['getNewBillingAddress'].firstName : "",
      lastName: (this.$store.getters['getNewBillingAddress'].lastName) ? this.$store.getters['getNewBillingAddress'].lastName : "",
      street: (this.$store.getters['getNewBillingAddress'].street) ? this.$store.getters['getNewBillingAddress'].street : "",
      houseNumber: (this.$store.getters['getNewBillingAddress'].houseNumber) ? this.$store.getters['getNewBillingAddress'].houseNumber : "",
      suffix: (this.$store.getters['getNewBillingAddress'].houseNumberSuffix) ? this.$store.getters['getNewBillingAddress'].houseNumberSuffix : "",
      postalCode: (this.$store.getters['getNewBillingAddress'].postalCode) ? this.$store.getters['getNewBillingAddress'].postalCode : "",
      city: (this.$store.getters['getNewBillingAddress'].city) ? this.$store.getters['getNewBillingAddress'].city : "",
      errorStatus: false,
      localCountryId: "",
      countryId: "",
      options: [],
      gender: ""
    }
  },
  methods:{
    saveNewAddress(){
      if(this.$v.$invalid){
        this.errorStatus = true
      }else {
        let newBillingAddress = {
          firstName: this.firstName,
          lastName: this.lastName,
          street: this.street,
          houseNumber: this.houseNumber,
          houseNumberSuffix: this.suffix,
          postalCode: this.postalCode,
          city: this.city,
          countryId: this.countryId,
          gender: this.gender
        }

        this.$store.dispatch('setNewBillingAddress', newBillingAddress);
        this.$store.dispatch('setHasNewBillingAddress', true);
        this.$store.dispatch('setShowPopup', false)
      }
    },
    cancel() {
      this.$store.dispatch('setNewBillingAddress', {});
      this.$store.dispatch('setHasNewBillingAddress', false);
      this.$store.dispatch('setShowPopup', false)
    },
    valueChanged(object) {
      this.countryId = object[0]
    },
    getCountries() {
      //TODO needs to be refactored or extracted to a shared function/class
      const defaultSelected = this.$store.getters['getVisitor'];
      const language = localStorage.getItem("language");
      let code = (language === undefined) ? "en" : language
      const countries = this.$store.getters['getCountries'];
      let options = []
      for (let i = 0; i < countries.length; i ++) {
        const country = countries[i];
        options.push({ value: country.id, text: country['name_' + code] })
      }
      if (code === 'en') {
        code = "gb"
      }

      if(!this.$store.getters['getNewBillingAddress'].countryId) {
        this.localCountryId = countries.find(c => c.code === defaultSelected).id;
        this.countryId = countries.find(c => c.code === defaultSelected).id;
      }
      else {
        this.localCountryId = countries.find(c => c.id === this.$store.getters['getNewBillingAddress'].countryId).id;
        this.countryId = countries.find(c => c.id === this.$store.getters['getNewBillingAddress'].countryId).id;
      }
      this.options = options
    }
  },
  created() {
    this.getCountries()
  }
}
</script>
